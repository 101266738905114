<ng-container *ngIf="!firingSchema.firingSchemaStepList.isEmpty() then steps else placeholder"></ng-container>
<ng-template #steps>
  <div
    *ngFor="let step of firingSchema.firingSchemaStepList"
    class="production-schema__record">
    <div class="production-schema__record__step-column">
      <label
        class="production-schema__record__step-column__box-label">
        {{getEnumValue(step.temperatureFluctuation)}}
      </label>
      <ng-container
        *ngIf="step && transformSecondsToDurationObject(step?.stepDuration?.seconds) as stepDuration">
        <label class="global-subtitle global-flex-wrap">{{'production.step-duration' |translate}}</label>
        <label>{{transformDurationObjectToString(stepDuration)}}</label>
      </ng-container>
    </div>
    <div *ngIf="step.startTemperature !== null"
         class="production-schema__record__step-column">
      <label>{{'production.start-temperature' | translate}} [℃]</label>
      <p>{{step.startTemperature}}</p>
    </div>
    <div class="production-schema__record__step-column">
      <label>{{(step.startTemperature ? 'production.target-temperature' : 'production.temperature') | translate}}[℃]</label>
      <p>{{step.targetTemperature}}</p>
    </div>
    <div class="production-schema__record__step-column">
      <label>{{'production.description' | translate}}</label>
      <textarea readonly class="global-schema-step-description">{{step.description}}</textarea>
    </div>
  </div>
</ng-template>

<ng-template #placeholder>
  <div class="production-schema__placeholder global-margin__small">
    <h3>{{'production.there-is-no-defined-steps' | translate}}</h3>
  </div>
</ng-template>
