import {Injectable} from '@angular/core';
import {SearchCriteriaForClass, TableDataJoiner, WorkCalendarData} from '../../core/sdk/model-dto';
import {Observable, Subject} from 'rxjs';
import {Worker} from '../../core/sdk/model-roles';
import {WorkerCtrl} from '../../core/sdk/controllers-roles';
import {TaskCtrl, WorkerTaskCtrl} from '../../core/sdk/controllers-tasks';
import {WorkerTask} from '../../core/sdk/model-tasks';
import {Utility} from '../utilities/utility';
import {Person, WorkCalendar, WorkspaceGroup, WorkspaceInstance} from '../../core/sdk/bighero-model';
import {WorkCalendarCtrl} from '../../core/sdk/bighero-controllers';
import {JsonScope} from '../../core/sdk/jsonScope';
import {JsonScopedSerializer} from '../../core/sdk/JsonParser';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ErrorHandlerService} from '../../core/sdk/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {
  httpService: HttpClient;
  errorHandlerService: ErrorHandlerService;


  workersData: TableDataJoiner[];

  workersForCalendarData: Person[];

  workCalendarEntryData: WorkCalendar[];

  private workCalendarData: WorkCalendar[];

  constructor(private workerCtrl: WorkerCtrl,
              private taskCtrl: TaskCtrl,
              private workerTaskCtrl: WorkerTaskCtrl,
              private workCalendarCtrl: WorkCalendarCtrl,
              httpService: HttpClient,
              errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

  getGroupLeaderWorkers(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number)
    : Observable<TableDataJoiner[]> {
    return this.workerCtrl.getGroupLeaderWorkers(searchCriteriaForClass, pageNumber, pageSize);
  }

  countGroupLeaderWorkers(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.workerCtrl.countGroupLeaderWorkers(searchCriteriaForClass);
  }

  setWorkersData(data: TableDataJoiner[]): void {
    this.workersData = data;
  }

  getWorkersData(): TableDataJoiner[] {
    return this.workersData;
  }

  getWorker(workerId): Observable<Worker> {
    return this.workerCtrl.getObject(Utility.getObjectId(workerId));
  }

  getWorkerInDto(workerId: string): Observable<TableDataJoiner> {
    return this.workerCtrl.getSingleWorkerWithActiveTaskCount(Utility.getObjectId(workerId));
  }

  convertWorkspaceGroupSetToFormData(groupSet: Set<string>): FormData {
    const formData = new FormData();
    formData.append('workspaceGroupIDs', new Blob([JsonScopedSerializer.stringify(Array.from(groupSet.values()),
      new JsonScope(false, []))], {type: 'application/json'}));
    return formData;
  }

  countWorkersForWorkspaceGroupList(searchCriteria: SearchCriteriaForClass, formData: FormData): Observable<number> {
    const searchCriteriaBlob = new Blob([JsonScopedSerializer.stringify(searchCriteria,
        new JsonScope(false, []))],
      {type: 'application/json'});
    if (formData.has('searchCriteria')) {
      formData.set('searchCriteria', searchCriteriaBlob);
    } else {
      formData.append('searchCriteria', searchCriteriaBlob);
    }
    const headers = new HttpHeaders();
    const subject = new Subject<number>();
    this.httpService.post<number>('/api/worker/r/workspace-groups-count-workers', formData
      , {headers})
      .subscribe(res => subject.next(res), error => {
        this.errorHandlerService.handleErrors(error);
        subject.error(error);
      });
    return subject.asObservable();
  }

  getWorkersForWorkspaceGroupList(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                                  formData: FormData): Observable<Worker[]> {
    const searchCriteriaBlob = new Blob([JsonScopedSerializer.stringify(searchCriteria,
        new JsonScope(false, []))],
      {type: 'application/json'});
    if (formData.has('searchCriteria')) {
      formData.set('searchCriteria', searchCriteriaBlob);
    } else {
      formData.append('searchCriteria', searchCriteriaBlob);
    }

    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});

    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders();
    const subject = new Subject<Worker[]>();
    this.httpService.post('/api/worker/r/workspace-groups-get-workers', formData
      , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
        this.errorHandlerService.handleErrors(error);
        subject.error(error);
      });
    return subject.asObservable();
  }


  countTasksForWorker(searchCriteriaForClass: SearchCriteriaForClass, personId: string): Observable<number> {
    return this.taskCtrl.countPersonTasks(searchCriteriaForClass, Utility.getObjectId(personId));
  }

  getTasksForWorker(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number, personId: string)
    : Observable<WorkerTask[]> {
    return this.taskCtrl.getPersonTasks(searchCriteriaForClass, pageNumber, pageSize,
      Utility.getObjectId(personId)) as Observable<WorkerTask[]>;
  }

  getWorkerTask(taskId: string): Observable<WorkerTask> {
    return this.workerTaskCtrl.getObject(Utility.getObjectId(taskId));
  }

  decrementTasksAmountInWorkersData(workerId: string): void {
    for (const tableData of this.workersData) {
      if ((tableData.tableDataRootObject as Worker).id === workerId) {
        tableData.joinedDataObject = tableData.joinedDataObject - 1;
      }
    }
  }

  ///// WORK_CALENDAR

  public getNewWorkCalendarEntry(worker: Worker, workspaceInstance: WorkspaceInstance, workspaceGroup: WorkspaceGroup,
                                 dateFrom: Date, dateTo: Date): WorkCalendar {
    return {
      id: 'work-calendar/' + Utility.getUUID(),
      version: 0,
      timestamp: Date.now(),
      worker,
      workspaceInstance,
      workspaceGroup: workspaceGroup ?? workspaceInstance.workspaceGroup,
      dateFrom: dateFrom.getTime(),
      dateTo: dateTo.getTime()
    };
  }

  createWorkCalendarEntry(entry: WorkCalendar): Observable<WorkCalendar> {
    return this.workCalendarCtrl.createObject(entry, new JsonScope(false, ['workspace-group', 'workspace-instance',
      'worker', 'person']));
  }

  updateWorkCalendarEntry(entry: WorkCalendar): Observable<WorkCalendar> {
    return this.workCalendarCtrl.updateObject(entry, new JsonScope(false, ['worker', 'person', 'workspace-group',
      'workspace-instance']));
  }

  setWorkCalendarEntryData(data: WorkCalendar[]): void {
    this.workCalendarEntryData = data;
  }

  getWorkCalendarEntryData(): WorkCalendar[] {
    return this.workCalendarEntryData;
  }

  setWorkersForCalendarData(data: Person[]): void {
    this.workersForCalendarData = data;
  }

  getWorkersForCalendarData(): Person[] {
    return this.workersForCalendarData;
  }

  getWorkCalendarEntry(entryId: string): Observable<WorkCalendar> {
    return this.workCalendarCtrl.getObject(Utility.getObjectId(entryId));
  }

  countWorkCalendarEntries(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.workCalendarCtrl.countWorkCalendars(searchCriteriaForClass);
  }

  getWorkCalendarEntries(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<WorkCalendar[]> {
    return this.workCalendarCtrl.getWorkCalendars(searchCriteriaForClass, pageNumber, pageSize);
  }


  public getWorkCalendarData(): WorkCalendar[] {
    return this.workCalendarData;
  }

  public setWorkCalendarData(data: WorkCalendar[]): void {
    this.workCalendarData = data;
  }

  public clearWorkCalendarData(): void {
    this.workCalendarData = [];
  }

  public getWorkCalendarsForWorkspaceInstance(searchCriteria: SearchCriteriaForClass, workspaceInstanceID: string,
                                              pageNumber: number, pageSize: number): Observable<WorkCalendar[]> {
    return this.workCalendarCtrl.getWorkCalendarsForWorkspaceInstance(searchCriteria, Utility.getObjectId(workspaceInstanceID),
      pageNumber, pageSize);
  }

  public countWorkCalendarsForWorkspaceInstance(searchCriteria: SearchCriteriaForClass, workspaceInstanceID: string): Observable<number> {
    return this.workCalendarCtrl.countWorkCalendarsForWorkspaceInstance(searchCriteria, Utility.getObjectId(workspaceInstanceID));
  }

  public getWorkCalenderDataForWorkspaceInstance(workspaceInstanceID: string, yearNumber: number, monthNumber: number)
    : Observable<WorkCalendarData[]> {
    return this.workCalendarCtrl.getWorkCalendarDataForWorkspaceInstance(Utility.getObjectId(workspaceInstanceID),
      yearNumber, monthNumber);
  }


  public getWorkCalendarDataForWorkspaceGroups(workspaceGroupIDs: string[], yearNumber: number, monthNumber: number)
    : Observable<{ [key: string]: WorkCalendarData[] }> {
    return this.workCalendarCtrl.getWorkCalendarDataForWorkspaceGroups(workspaceGroupIDs, yearNumber, monthNumber);
  }

  public getWorkCalendarDataForLoggedInGroupLeader(yearNumber: number, monthNumber: number)
    : Observable<{ [key: string]: WorkCalendarData[] }> {
    return this.workCalendarCtrl.getWorkCalendarDataForLoggedInGroupLeader(yearNumber, monthNumber);
  }

  public getActiveWorkersFromCalendarEntriesForWorkspaceGroup(workspaceGroupID: string): Observable<Worker[]> {
    return this.workCalendarCtrl.getActiveWorkersFromCalendarEntriesForWorkspaceGroup(Utility.getObjectId(workspaceGroupID));
  }

  public getWorkCalendarsForWorkspaceGroup(searchCriteria: SearchCriteriaForClass, workspaceGroupID: string,
                                           pageNumber: number,
                                           pageSize: number,): Observable<WorkCalendar[]> {
    return this.workCalendarCtrl.getWorkCalendarsForWorkspaceGroup(searchCriteria, Utility.getObjectId(workspaceGroupID),
      pageNumber, pageSize);
  }

  public countWorkCalendarsForWorkspaceGroup(searchCriteria: SearchCriteriaForClass,
                                             workspaceGroupID: string): Observable<number> {
    return this.workCalendarCtrl.countWorkCalendarsForWorkspaceGroup(searchCriteria, Utility.getObjectId(workspaceGroupID));
  }


}
