<div class="faulty-reason-tile">
  <h2>{{'production.production-cycle-faulty-reasons' | translate}}</h2>
  <div class="faulty-reason-tile__content-container"
       *ngFor="let faultyReason of faultyReasons">
    <div class="faulty-reason-tile__content">

      <ng-container
        [ngTemplateOutlet]="faultyReasonCountTemplateRef"
        [ngTemplateOutletContext]="{$implicit: faultyReason}">
      </ng-container>

      <ng-container
        *ngIf="faultyReason.id !== itemWithDisplayedDescription then showDescirptionButton else hideDescriptionButton"></ng-container>
      <ng-template #showDescirptionButton>
        <button
          type="button"
          class="global-button"
          (click)="setItemForWhichDescriptionShouldBeShown(faultyReason.id)"
        >{{'production.show-description' | translate}}</button>
      </ng-template>
      <ng-template #hideDescriptionButton>
        <button
          type="button"
          class="global-button"
          (click)="resetChosenItemId()"
        >{{'production.hide-description' | translate}}</button>
      </ng-template>
    </div>
    <div
      class="faulty-reason-tile__description-container"
      [ngClass]="faultyReason.id === itemWithDisplayedDescription ? 'collapse' : 'expand'">
      <ng-container
        [ngTemplateOutlet]="faultyReasonDescriptionTemplateRef"
        [ngTemplateOutletContext]="{$implicit: faultyReason}">
      </ng-container>
    </div>

  </div>
</div>
