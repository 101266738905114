import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Utility} from '../../utilities/utility';
import {TranslateService} from '../../../core/translations/translate.service';
import {ProductionSchemaTemplate} from '../../../core/sdk/model-productionschema';
import {ProductForService, ServiceSchemaStep} from '../../../core/sdk/model-productforservice';
import {ProductionSchemaService} from '../../services/production-schema.service';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationService} from '../../services/notification.service';
import {SpinnerService} from '../../services/spinner.service';
import {ServiceSchemaStepService} from '../../services/service-schema-step.service';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {PTableControlService} from '../../services/p-table-control.service';
import {Router} from '@angular/router';
import {BaseSubNavbarService} from '../../base-components/base-sub-navbar/base-sub-navbar.service';
import {SchemaService} from '../../services/schema.service';

@Component({
  selector: 'app-full-service-schema-edition',
  templateUrl: './full-service-schema-edition.component.html',
})
export class FullServiceSchemaEditionComponent implements OnInit, AfterViewInit {

  private totalStepsDuration = {days: 0, hours: 0, minutes: 0, seconds: 0};
  public templatesOptions: ProductionSchemaTemplate[];
  public selectedTemplate: ProductionSchemaTemplate;
  public productForService: ProductForService;
  public importTemplateFlag = false;
  public serviceSchemaStepToCreate: ServiceSchemaStep;

  constructor(private translateService: TranslateService,
              private productionSchemaService: ProductionSchemaService,
              private notificationService: NotificationService,
              private spinnerService: SpinnerService,
              private serviceSchemaStepService: ServiceSchemaStepService,
              private pTableControlService: PTableControlService,
              private router: Router,
              private baseSubNavbarService: BaseSubNavbarService,
              private schemaService: SchemaService) {

  }

  public ngOnInit(): void {
    this.productForService = this.serviceSchemaStepService.getCurrentProductForService();
    this.initSummaryServiceSchemaStepDuration(this.productForService.serviceSchemaStepList);
    this.baseSubNavbarService.displayFilteringButtons(false);
    this.schemaService.reloadSummaryStepDurationSourceContent.subscribe(steps => {
      this.initSummaryServiceSchemaStepDuration(steps);
    });
  }

  public ngAfterViewInit(): void {
    this.fetchTemplates();
  }

  private fetchTemplates(): void {
    this.productionSchemaService.getAllProductionSchemaTemplatesId().subscribe({
      next: (response: ProductionSchemaTemplate[]) => {
        this.templatesOptions = response;
        this.templatesOptions.sort((a, b) =>
          Utility.compareNumbers(a.name, b.name));
        this.selectedTemplate = this.templatesOptions[0];
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private initSummaryServiceSchemaStepDuration(steps: ServiceSchemaStep[]): void {
    this.totalStepsDuration = this.getTotalDurationOfSteps(steps);
  }

  private getTotalDurationOfSteps(steps: ServiceSchemaStep[]): {
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  } {
    const seconds: number = this.schemaService.getAllStepsTotalDuration(steps);
    return Utility.convertSecondsToDurationObject(seconds ?? 0);
  }

  public onSubmit(): void {
    if (this.serviceSchemaStepService.getServiceSchemaData().isEmpty() ||
      this.serviceSchemaStepService.getServiceSchemaData().filter(item => item.productForService !== null).isEmpty()) {
      this.notificationService.displayNotificationToast('products.there-is-no-defined-service-schema-steps',
        NotificationMessageType.WARNING);
    } else {
      this.updateServiceSchemaSteps();
    }
  }

  public isSomeOpenSteps(): boolean {
    return this.serviceSchemaStepService.getOpenSteps()?.length > 0;
  }

  private updateServiceSchemaSteps(): void {
    this.spinnerService.activateSpinner();
    const steps = this.serviceSchemaStepService.getServiceSchemaData();
    steps.forEach((step, index) => {
      step.index = index + 1;
    });
    this.serviceSchemaStepService.updateServiceSchemaStepList(steps, this.productForService.id).subscribe({
      next: () => {
        this.notificationService.displayNotificationToast('products.service-schema-steps-updated-successfully',
          NotificationMessageType.SUCCESS);
        this.serviceSchemaStepService.flushAllData();
        this.returnToServiceSchemaSteps();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public transformDurationToString(): string {
    return Utility.transformDurationObjectToUserFriendlyString(this.totalStepsDuration, this.translateService);
  }

  public toggleServiceSchemaImportFlag(): void {
    this.importTemplateFlag = !this.importTemplateFlag;
  }

  public importSelectedTemplate(templateId: string): void {
    this.spinnerService.activateSpinner();
    this.serviceSchemaStepService.importFromProductionSchemaTemplate(this.productForService.id, templateId)
      .subscribe({
        next: (response: ServiceSchemaStep[]) => {
          response.sort((a, b) => Utility.compareNumbers(a.index, b.index));
          this.serviceSchemaStepService.setServiceSchemaData(response);
          this.notificationService.displayNotificationToast('products.production-schema-imported-successfully',
            NotificationMessageType.SUCCESS);
          this.returnToServiceSchemaSteps();
        },
        error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
  }

  private returnToServiceSchemaSteps(): void {
    this.pTableControlService.pushExpandedRowToMap('productForServiceTable', this.productForService.id);
    this.pTableControlService.setOneTimeSelectedTab('productForServiceTable', 'products.service-schema');
    this.router.navigate(['bh/products/product-for-service-management']);
  }

  public closeComponent(): void {
    this.serviceSchemaStepService.flushAllData();
    this.returnToServiceSchemaSteps();
  }

  public createNewStep(): void {
    this.serviceSchemaStepToCreate = this.serviceSchemaStepService.getNewEmptyServiceSchemaStep(this.productForService);
    this.serviceSchemaStepToCreate.index = this.serviceSchemaStepService.getServiceSchemaData()
      .filter(item => item.productForService !== null) ?
      this.serviceSchemaStepService.getServiceSchemaData().filter(item =>
        item.productForService !== null).length + 1 : 1;
    this.serviceSchemaStepService.tagServiceSchemaStepAsOpen(this.serviceSchemaStepToCreate.id);
    this.serviceSchemaStepService.pushNewStepToServiceSchemaData(this.serviceSchemaStepToCreate);
  }

  public clearAllStep(): void {
    this.serviceSchemaStepService.tagAllServiceSchemaStepsAsDeleted();
    this.initSummaryServiceSchemaStepDuration([]);
  }

}
