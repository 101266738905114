<ng-template [ngIf]="!showMonthlyCalendarViewFlag && !createWorkCalendarEntryFlag && !showWorkspaceInstancesFlag">

  <div class="global-flex global-justify__space-between">
    <div *ngIf="isOvenOperation()">
      <button
        type="button"
        class="global-button"
        (click)="toggleShowWorkspaceInstancesFlag()"
      >{{'production.see-workspace-instances' | translate}}</button>
    </div>

    <div class="global-flex global-margin--auto-left">
      <button
        type="button"
        class="global-button"
        (click)="resetFilters()"
      >{{'production.reset-filters' | translate}}</button>
      <button
        type="button"
        class="global-button"
        (click)="toggleShowMonthlyCalendarViewFlag()"
      >{{'production.see-monthly-calendar-view' | translate}}</button>
      <button
        type="button"
        class="global-button"
        (click)="toggleCreateWorkCalendarEntryFlag()"
      >{{'production.create-work-calendar-entry' | translate}}</button>
      <button
        type="button"
        class="global-button"
        (click)="closeView()"
      >{{'production.return' | translate}}</button>
    </div>
  </div>

  <div class="global-flex global-justify__flex-end">

  </div>

  <p-table
    class="main-table-header"
    responsiveLayout="scroll"
    dataKey="id"
    [value]="getTableData()"
    (onPage)="paginate($event)"
    [paginator]="true"
    [lazy]="true"
    [rowHover]="true"
    [(first)]="pageable.pageNumber"
    [rows]="pageable.pageSize"
    [totalRecords]="pageable.count">

    <ng-template pTemplate="header">
      <tr>
        <th (click)="sortByColumn('name', false, 'worker', 'Person', ['person'] )"
            pSortableColumn="workerName">{{'production.assigned-worker' | translate}}
          <p-sortIcon class="no-print" field="workerName"></p-sortIcon>
        </th>
        <th (click)="sortByColumn('dateFrom')"
            pSortableColumn="dateFrom">{{'production.assigned-from' | translate}}
          <p-sortIcon class="no-print" field="dateFrom"></p-sortIcon>
        </th>
        <th (click)="sortByColumn('dateTo')"
            pSortableColumn="dateTo">{{'production.assigned-until' | translate}}
          <p-sortIcon class="no-print" field="dateTo"></p-sortIcon>
        </th>
      </tr>

      <tr
        class="primeng-table__search-row no-print">
        <td>
          <input
            appStringLengthInputValidation
            type="text"
            placeholder="{{ 'production.search-by-worker-name' | translate }}"
            class="global-input primeng-table__filter-col"
            (keydown.enter)="searchByString($event, 'name',
        'worker', 'Person', ['person'])">
        </td>

        <td>
          <p-calendar
            appendTo="body"
            dateFormat="dd.mm.yy"
            (onSelect)="!calendarSelectionModeMap.get('dateFrom') ?  searchByDate($event, 'dateFrom') : null"
            selectionMode="{{ calendarSelectionModeMap.get('dateFrom') | calendarSelectionMode}}"
            [ngModel]="calendarSelectionModeMap.get('dateFrom') ? rangeDateMap.get('dateFrom') : ''"
            (ngModelChange)="assignRangeDateValue($event, 'dateFrom')"
            [showButtonBar]="true"
            [readonlyInput]="true"
            [showIcon]="true"
            [clearButtonStyleClass]="'display--false'"
            [showOnFocus]="false"
            [inputStyleClass]="'global-input'"
            [styleClass]="'global-width--100'"
            class="primeng-table__calendar-filter-col"
            placeholder="{{ 'production.search-by-beginning-of-assignment' | translate }}">
            <ng-template pTemplate="footer">
              <div class="global-flex global-align__items-center global-justify__space-between global-margin-top__1rem">
                <button
                  type="button"
                  class="global-button global-height--fit-content global-margin--none"
                  (click)="toggleRangeSelectionModeApplied('dateFrom')">
                  <i [ngClass]="!calendarSelectionModeMap.get('dateFrom') | calendarButtonClass"></i>
                </button>
                <button
                  *ngIf="calendarSelectionModeMap.get('dateFrom')"
                  type="button"
                  class="global-button global-margin--none"
                  [disabled]=" rangeDateMap.get('dateFrom').isEmpty() || rangeDateMap.get('dateFrom').includes(null)"
                  (click)="searchByDate(rangeDateMap.get('dateFrom'), 'dateFrom')"
                >{{ 'common.search' | translate }}
                </button>
              </div>
              <span
                class="global-text-align__center global-margin-top__1rem"
              >{{calendarSelectionModeMap.get('dateFrom') | calendarButtonLabel | translate}}</span>
            </ng-template>
          </p-calendar>
        </td>

        <td>
          <p-calendar
            appendTo="body"
            dateFormat="dd.mm.yy"
            (onSelect)="!calendarSelectionModeMap.get('dateTo') ? searchByDate($event, 'dateTo') : null"
            selectionMode="{{ calendarSelectionModeMap.get('dateTo') | calendarSelectionMode}}"
            [ngModel]="calendarSelectionModeMap.get('dateTo') ? rangeDateMap.get('dateTo') : ''"
            (ngModelChange)="assignRangeDateValue($event, 'dateTo')"
            [showButtonBar]="true"
            [readonlyInput]="true"
            [showIcon]="true"
            [clearButtonStyleClass]="'display--false'"
            [showOnFocus]="false"
            [inputStyleClass]="'global-input'"
            [styleClass]="'global-width--100'"
            class="primeng-table__calendar-filter-col"
            placeholder="{{ 'production.search-by-end-of-assignment' | translate }}">
            <ng-template pTemplate="footer">
              <div class="global-flex global-align__items-center global-justify__space-between global-margin-top__1rem">
                <button
                  type="button"
                  class="global-button global-height--fit-content global-margin--none"
                  (click)="toggleRangeSelectionModeApplied('dateTo')">
                  <i [ngClass]="!calendarSelectionModeMap.get('dateTo') | calendarButtonClass"></i>
                </button>
                <button
                  *ngIf="calendarSelectionModeMap.get('dateTo')"
                  type="button"
                  class="global-button global-margin--none"
                  [disabled]=" rangeDateMap.get('dateTo').isEmpty() || rangeDateMap.get('dateTo').includes(null)"
                  (click)="searchByDate(rangeDateMap.get('dateTo'), 'dateTo')"
                >{{ 'common.search' | translate }}
                </button>
              </div>
              <span
                class="global-text-align__center global-margin-top__1rem"
              >{{calendarSelectionModeMap.get('dateTo') | calendarButtonLabel | translate}}</span>
            </ng-template>
          </p-calendar>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3">
          {{'common.no-records-available' | translate}}
        </td>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-workCalendar>
      <tr
        class="global-pointer">
        <td>{{workCalendar.worker.person?.name}}</td>
        <td>{{convertTimestampToStringDate(workCalendar.dateFrom)}}</td>
        <td>{{convertTimestampToStringDate(workCalendar.dateTo)}}</td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<ng-template [ngIf]="showMonthlyCalendarViewFlag && !createWorkCalendarEntryFlag">
  <app-monthly-calendar-view-for-workers
    [workspaceInstance]="workspaceInstance"
    [workspaceGroups]="[mainWorkspaceGroup]"
    (closeViewEmitter)="toggleShowMonthlyCalendarViewFlag()">
  </app-monthly-calendar-view-for-workers>
</ng-template>

<ng-template [ngIf]="createWorkCalendarEntryFlag">

  <ng-container *ngIf="showAvailableWorkersTable then table else form"></ng-container>

  <ng-template #form>
    <div class="global-flex global-justify__center global-color--white global-margin-top__2rem">
      <h2>{{'production.create-work-calendar-entry' | translate}}</h2>
    </div>

    <div class="form-details global-margin-top__1rem">
      <div class="form-details__container form-details__container--background-color">
        <form
          [formGroup]="formGroup"
          (ngSubmit)="onSubmit()">
          <div class="form-details__field">
            <label>{{'production.worker' | translate}}</label>
            <div class="global-flex global-align__items-center">
              <input
                readonly
                type="text"
                formControlName="worker"
                placeholder="{{'production.select-worker' | translate}}">
              <button
                type="button"
                class="global-button"
                (click)="assignWorker()"
              >{{'production.assign' | translate}}</button>
            </div>
          </div>

          <div class="form-details__field">
            <label for="fromDate">{{'production.from' | translate}}</label>
            <p-calendar
              appendTo="body"
              formControlName="fromDate"
              id="fromDate"
              dateFormat="dd.mm.yy"
              [minDate]="todayDate"
              [showButtonBar]="true"
              [readonlyInput]="true"
              [clearButtonStyleClass]="'display--false'"
              [showIcon]="true"
              [showOnFocus]="false"
              class="primeng-table__calendar-filter-col"
              placeholder="{{'production.set-from-date' | translate }}"
              (onSelect)="assignFromDate($event)">
            </p-calendar>
          </div>

          <div class="form-details__field">
            <label for="toDate">{{'production.to' | translate}}</label>
            <p-calendar
              appendTo="body"
              formControlName="toDate"
              id="toDate"
              dateFormat="dd.mm.yy"
              [minDate]="fromDate"
              [showButtonBar]="true"
              [readonlyInput]="true"
              [clearButtonStyleClass]="'display--false'"
              [showIcon]="true"
              [showOnFocus]="false"
              class="primeng-table__calendar-filter-col"
              placeholder="{{ 'offers.set-delivery-date' | translate }}"
              (onSelect)="assignToDate($event)">
            </p-calendar>
          </div>

          <div class="global-flex global-justify__center">
            <button
              type="submit"
              [disabled]="formGroup.invalid"
            >{{'common.submit' | translate}}</button>
            <button
              type="button"
              (click)="onCancel()"
            >{{'common.return' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  <ng-template #table>
    <app-production-planning-person-table
      [persons]="processStep.workspaceGroup.workerList"
      [applyAssigning]="true"
      (chosenWorkerEmitter)="setChosenWorker($event)"
      (closeViewEmitter)="toggleShowAvailableWorkersTable()">
    </app-production-planning-person-table>
  </ng-template>

</ng-template>


<ng-template [ngIf]="showWorkspaceInstancesFlag">
  <div class="global-flex global-justify__flex-end">
    <button
      type="button"
      class="global-button"
      (click)="toggleShowWorkspaceInstancesFlag()"
    >{{'production.return' | translate}}</button>
  </div>
  <div class="global-data-container">
    <div *ngFor="let workspaceInstance of mainWorkspaceGroup.workspaceInstanceList; let i = index">
      <div class="global-flex">
        <label>{{i + 1}}.</label>
        <label class="global-margin--1rem-left">{{workspaceInstance.shorthand}}</label>
      </div>
    </div>
  </div>
</ng-template>
