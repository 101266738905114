import {UnplannedWorkerTaskCtrl} from '../../../core/sdk/controllers-unplanned';
import {Injectable} from '@angular/core';
import {UnplannedPostProcessingStep, UnplannedWorkerTask} from '../../../core/sdk/model-unplanned';
import {Utility} from '../../utilities/utility';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {ProcessStep, WorkspaceInstance} from '../../../core/sdk/bighero-model';
import {TaskStatus} from '../../../core/sdk/enums-statuses';
import {TaskType} from '../../../core/sdk/enums-types';
import {Worker} from '../../../core/sdk/model-roles';

@Injectable({
  providedIn: 'root'
})
export class UnplannedWorkerTaskService {

  constructor(private unplannedWorkerTaskCtrl: UnplannedWorkerTaskCtrl) {
  }

  public getNewEmptyUnplannedWorkerTask(processStep: ProcessStep, worker: Worker,
                                        workspaceInstance: WorkspaceInstance,
                                        goal: number, unplannedPostProcessingStep: UnplannedPostProcessingStep): UnplannedWorkerTask {
    return {
      id: 'unplanned-worker-task/' + Utility.getUUID(),
      creationDate: Date.now(),
      description: '',
      doneFaultyQuantity: 0,
      doneGoodQuantity: 0,
      estimatedDuration: processStep.estimatedTime,
      goal,
      moldInstance: null,
      name: '',
      personRole: worker,
      processStep,
      progressStatus: TaskStatus.ACTIVE,
      startDate: 0,
      timestamp: Date.now(),
      type: TaskType.WORKER_TASK,
      unplannedPostProcessingStep,
      version: 0,
      workerSubmittedLackOfAwaitingProductsForTask: false,
      workspaceInstance
    };
  }

  public getUnplannedWorkerTasksForUnplannedPostProcessingStep(searchCriteria: SearchCriteriaForClass,
                                                               unplannedPostProcessingStepID: string,
                                                               pageNumber: number,
                                                               pageSize: number): Observable<UnplannedWorkerTask[]> {
    return this.unplannedWorkerTaskCtrl.getUnplannedWorkerTasksForUnplannedPostProcessingStep(searchCriteria,
      Utility.getObjectId(unplannedPostProcessingStepID), pageNumber, pageSize);
  }

  public countUnplannedWorkerTasksForUnplannedPostProcessingStep(searchCriteria: SearchCriteriaForClass,
                                                                 unplannedPostProcessingStepID: string,): Observable<number> {
    return this.unplannedWorkerTaskCtrl.countUnplannedWorkerTasksForUnplannedPostProcessingStep(searchCriteria,
      Utility.getObjectId(unplannedPostProcessingStepID));
  }

  public createUnplannedWorkerTask(entity: UnplannedWorkerTask): Observable<UnplannedWorkerTask> {
    return this.unplannedWorkerTaskCtrl.create(entity);
  }


}
