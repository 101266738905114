import {Component, Input} from '@angular/core';
import {SplitProductStoredInstance} from '../../../../core/sdk/model-warehouse';
import {StoringUnitInstance} from '../../../../core/sdk/bighero-model';
import {StoringUnitTypeEnum} from '../../../../core/sdk/enums-types';
import {TranslationKeyEnum} from '../../../enums/translation-key-enum';

@Component({
  selector: 'app-mobile-split-product-in-storing-details-tile',
  templateUrl: './mobile-split-product-in-storing-details-tile.component.html',
})
export class MobileSplitProductInStoringDetailsTileComponent {

  protected readonly StoringUnitTypeEnum = StoringUnitTypeEnum;
  protected readonly TranslationKeyEnum = TranslationKeyEnum;

  @Input() public splitProductStoredInstance: SplitProductStoredInstance;
  @Input() public storingUnitInstance: StoringUnitInstance;
  @Input() public forRepack: boolean;

}
