import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {BasePrimengTableDirective} from '../../../base-primeng-table/base-primeng-table.directive';
import {WorkspaceGroup, WorkspaceInstance} from '../../../../core/sdk/bighero-model';
import {SpinnerService} from '../../../services/spinner.service';
import {PTableControlService} from '../../../services/p-table-control.service';
import {BaseSubNavbarService} from '../../base-sub-navbar/base-sub-navbar.service';
import {SearchCriteriaService} from '../../../../core/search-criteria.service';
import {TableRefreshService} from '../../../services/table-refresh.service';
import {Utility} from '../../../utilities/utility';
import {WorkspaceInstanceService} from '../../../services/production/workspace-instance.service';

@Component({
  selector: 'app-workspace-instances-for-workspace-group',
  templateUrl: './workspace-instances-for-workspace-group.component.html',
})
export class WorkspaceInstancesForWorkspaceGroupComponent extends BasePrimengTableDirective<WorkspaceInstance> implements AfterViewInit {

  @Input() public selectedWorkspaceGroup: WorkspaceGroup;
  @Input() public showAssignButton = false;
  @Output() public closeComponentEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() public chosenWorkspaceInstanceEmitter: EventEmitter<WorkspaceInstance> = new EventEmitter<WorkspaceInstance>();

  constructor(private workspaceInstanceService: WorkspaceInstanceService,
              protected spinnerService: SpinnerService,
              protected pTableControlService: PTableControlService,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService);
    this.assignClassName('WorkspaceInstance');
    this.assignTableName('workspaceInstancesForWorkspaceGroupTable');
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public doCount(): void {
    this.workspaceInstanceService.countWorkspaceInstancesForWorkspaceGroup(this.searchCriteriaForClass,
      Utility.getObjectId(this.selectedWorkspaceGroup.id)).subscribe(countedWorkspaceInstances => {
      this.pageable.count = countedWorkspaceInstances;
    });
  }

  public doLoad(): void {
    this.workspaceInstanceService.getWorkspaceInstancesForWorkspaceGroup(this.searchCriteriaForClass,
      Utility.getObjectId(this.selectedWorkspaceGroup.id), this.pageable.pageNumber, this.pageable.pageSize)
      .subscribe(response => {
        this.data = response;
        this.spinnerService.deactivateSpinner();
      });
  }

  public getTableData(): WorkspaceInstance[] {
    return this.data;
  }

  public closeComponent(): void {
    this.closeComponentEmitter.emit();
  }

  public emitChosenWorkspaceInstanceToAssign(workspaceInstance: WorkspaceInstance): void {
    this.chosenWorkspaceInstanceEmitter.emit(workspaceInstance);
  }

}
