import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BasePrimengTableDirective} from '../../base-primeng-table/base-primeng-table.directive';
import {Country} from '../../../core/sdk/model-address';
import {NotificationService} from '../../services/notification.service';
import {SpinnerService} from '../../services/spinner.service';
import {PTableControlService} from '../../services/p-table-control.service';
import {BaseSubNavbarService} from '../base-sub-navbar/base-sub-navbar.service';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {TableRefreshService} from '../../services/table-refresh.service';
import {CountryService} from '../../services/country.service';
import {ComponentType} from '@angular/cdk/overlay';
import {CountryDetailsComponent} from './country-details/country-details.component';
import {BasketService} from '../../services/basket.service';
import {Table} from 'primeng/table';
import {CrossFunctionalService} from '../../services/cross-functional.service';
import {ExportTableDataService} from '../../services/exportTableData.service';
import {TranslationKeyEnum} from '../../enums/translation-key-enum';

@Component({
  selector: 'app-country-management-table',
  templateUrl: './country-management-table.component.html',
})
export class CountryManagementTableComponent extends BasePrimengTableDirective<Country> implements OnInit, AfterViewInit, OnDestroy {

  public createCountryFlag = false;
  public colspan: number;
  public isCrossActive = false;
  public countryNameFilteringField: string;
  public codeISO31661alpha2FilteringField: string;
  @ViewChild('pTable') public pTable: Table;
  @Input() public assignCountryFlag = false;
  @Input() public translateModule = 'admin.';
  @Output() public returnEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() public assignCountryEmitter: EventEmitter<Country> = new EventEmitter<Country>();

  constructor(private notificationService: NotificationService,
              private countryService: CountryService,
              private crossFunctionalService: CrossFunctionalService,
              protected spinnerService: SpinnerService,
              protected pTableControlService: PTableControlService,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService,
              protected basketService: BasketService,
              protected exportTableDataService: ExportTableDataService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService,
      basketService, exportTableDataService);
    this.assignClassName('Country');
    this.assignTableName('countryTable');
    this.assignTableInstance(this);
    this.tableComponentType = CountryManagementTableComponent;
    this.translationKey = TranslationKeyEnum.COMMON;
    this.isCrossActive
      = this.crossFunctionalService.isCrossFunctionalActive('goToCountryManagementTableToAssignCountryToCurrentTaxRate');
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.assignCountryFlag) {
      this.loadFilterFlag();
    } else {
      this.baseSubNavbarService.displayFilteringButtons(false);
      this.showFilteringRow = true;
    }
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public resetSorting() {
    super.resetSorting();
    this.codeISO31661alpha2FilteringField = null;
    this.countryNameFilteringField = null;
  }

  public assignNewValuesToTable(data: Country[]): void {
    this.shouldExportBeApplied ? this.exportTableDataService.setTableData(data) : this.countryService.setCountryData(data);
  }

  public getTableData(): Country[] {
    return this.shouldExportBeApplied ? this.exportTableDataService.getTableData() : this.countryService.getCountryData();
  }

  public doCount(): void {
    if (!this.shouldExportBeApplied) {
      this.countryService.countCountries(this.searchCriteriaForClass).subscribe({
        next: (response) => this.pageable.count = response,
        error: (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
    }
  }

  public doLoad(): void {
    this.countryService.getCountries(this.searchCriteriaForClass, this.pageable.pageNumber, this.pageable.pageSize)
      .subscribe({
        next: (response) => {
          this.assignNewValuesToTable(response);
          this.spinnerService.deactivateSpinner();
        },
        error: (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
  }

  public reloadDataAfterCreateCountry(): void {
    this.baseSubNavbarService.displayFilteringButtons(true);
    this.refreshTable();
    this.toggleCreateCountryFlag();
  }

  public closeCreatingCountryForm(): void {
    this.toggleCreateCountryFlag();
    this.baseSubNavbarService.displayFilteringButtons(true);

  }

  public getTabsMap(country: Country): Map<string, ComponentType<any>> {
    this.map.set(country.name, CountryDetailsComponent);
    return this.map;
  }

  public bindData(country: Country): Map<string, object> {
    const dataBind: Map<string, any> = new Map<string, object>();
    dataBind.set('currentCountry', country);
    dataBind.set('isCrossActive', this.isCrossActive);
    return dataBind;
  }

  public toggleCreateCountryFlag(): void {
    this.createCountryFlag = !this.createCountryFlag;
  }

  public assignCountry(country: Country): void {
    this.assignCountryEmitter.emit(country);
  }

  public getTableRef(): Table {
    return this.pTable;
  }

  public getCountriesInBasket(): (Country)[] {
    return Array.from(this.basketService.getBasketList(this.tableName) as Set<(Country)>);
  }

  public turnBasketOn(): void {
    this.unselectAllRows();
    this.basketService.registerBasket(this.tableName);
  }

  public turnBasketOff(): void {
    this.unselectAllRows();
    this.basketService.flushBasket(this.tableName);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.isCrossActive) {
      this.crossFunctionalService.flushData('goToCountryManagementTableToAssignCountryToCurrentTaxRate');
    }
  }

  public resetFilters(): void {
    this.countryNameFilteringField = null;
    this.codeISO31661alpha2FilteringField = null;
    this.tableRefreshService.onTableRefresh(this.tableName, true);
  }
}
