<div class="form-details">
  <div class="form-details__container form-details__container--background-color">
    <div class="global-flex global-flex-direction__column">
      <div
        *ngIf="!updateCountryFlag"
        class="global-flex global-justify__center">
        <button
          type="button"
          class="global-button global-margin--none"
          (click)="toggleShowISOCodeInputFlag()"
        >{{ 'admin.create-using-iso-code'| translate }}
        </button>
      </div>
      <div>
        <form
          [formGroup]="formGroup"
          (ngSubmit)="onSubmit()">
          <div
            *ngIf="!showISOCodeInputFlag"
            class="form-details__field">
            <label for="name">{{ 'admin.name' | translate }}</label>
            <input
              id="name"
              type="text"
              formControlName="name"
              placeholder="{{'admin.type-country-name' | translate}}">
            <span
              [ngClass]="nameControl.invalid && (nameControl.dirty || nameControl.touched) ? 'visibility--true' : 'visibility--false'"
              class="global-error-message"
            >{{ 'common.field-required' | translate }}</span>
          </div>
          <div
            *ngIf="showISOCodeInputFlag || updateCountryFlag"
            class="form-details__field">
            <label for="codeISO31661alpha2">{{ 'admin.code-iso' | translate }}</label>
            <input
              id="codeISO31661alpha2"
              type="text"
              formControlName="codeISO31661alpha2"
              placeholder="{{'admin.type-iso-code' | translate}}">
            <span
              [ngClass]="codeISO31661alpha2Control.invalid && (codeISO31661alpha2Control.dirty || codeISO31661alpha2Control.touched) ? 'visibility--true' : 'visibility--false'"
              class="global-error-message"
            >{{ 'common.field-required' | translate }}</span>
          </div>

          <div class="form-details__check-box-field">
            <p-checkbox
              id="isInsideEuropeanUnion"
              label="{{'admin.is-inside-european-union' |translate}}"
              formControlName="isInsideEuropeanUnion"
              binary="true">
            </p-checkbox>
          </div>

          <div class="form-details__check-box-field">
            <p-checkbox
              id="isOutsideEuropeContinent"
              formControlName="isOutsideEuropeContinent"
              label="{{'admin.is-outside-europe-continent' |translate}}"
              binary="true">
            </p-checkbox>
          </div>

          <div class="global-flex global-justify__center">
            <button
              type="submit"
              [disabled]="formGroup.invalid || this.shouldFormBeDisabled"
            >{{ 'common.submit' | translate }}
            </button>
            <button
              *ngIf="!hideReturnButton"
              type="button"
              (click)="onCancel()"
            >{{ 'common.return' | translate }}
            </button>
          </div>

        </form>
      </div>


    </div>


  </div>

</div>
