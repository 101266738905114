import {AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BaseFormField} from '../base-form-field';
import {FormFieldTypeEnum} from '../../../enums/enums';
import {NotificationService} from '../../../services/notification.service';
import {TranslateService} from '../../../../core/translations/translate.service';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {BaseEnumEntity} from '../../../../core/sdk/model-enumentity';

@Component({
  selector: 'app-form-field',
  templateUrl: './base-form-field.component.html'
})
export class BaseFromFieldComponent<T> implements OnInit, AfterContentChecked {
  @Input() formField: BaseFormField<T>;
  @Input() formGroupStyle = '';
  @Input() form: FormGroup;

  previewUrl: any;
  uploadedFilePath: string;
  showPlaceholder = false;

  readonly MAX_FILE_SIZE = 1_000_000;

  formFieldTypeEnum = FormFieldTypeEnum;

  constructor(private notificationService: NotificationService,
              private cdRef: ChangeDetectorRef,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    if (this.isFileUpload()) {
      this.previewUrl = this.formField.preview;
      this.form.value[`select-logo`] = '';
    } else if (this.isSelect()) {
      this.setPlaceholderValue();
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  private setPlaceholderValue(): void {
    if (this.formField.placeholder) {
      this.showPlaceholder = true;
    }
  }

  public updateValue(change: Event): void {
    this.form.get(this.formField.key)?.setValue(change);
  }

  public getInputStyle(): string {
    if (this.formField.inputStyle) {
      return this.formField.inputStyle;
    } else if (this.isCheckBox()) {
      return '';
    } else if (this.isFileUpload()) {
      return 'global-full-width';
    }
    return 'global-input';
  }

  public getLabelStyle(): string {
    return this.formField.labelStyle ? this.formField.labelStyle : '';
  }

  public switchCheck(): void {
    const checkValue = this.form.get(this.formField.key);
    checkValue?.setValue(!JSON.parse(checkValue?.value));
  }

  public getFieldStyle(): string {
    if (this.formField.alert) {
      return '';
    }
    return 'base-form-field';
  }

  public isCheckBox(): boolean {
    return this.formField.controlType === this.formFieldTypeEnum.CHECKBOX;
  }

  public isFileUpload(): boolean {
    return this.formField.controlType === this.formFieldTypeEnum.FILE_UPLOAD;
  }

  public isSelect(): boolean {
    return this.formField.controlType === this.formFieldTypeEnum.SELECT;
  }

  fileProgress(event: Event): void {
    const files: FileList | null = (event.target as HTMLInputElement).files;
    if (files) {
      const tempFile: File | null = files.item(0);
      if (tempFile) {
        const fileData: File = tempFile;
        this.form.value[`select-logo`] = fileData;
        this.preview(fileData);
      }
    }
  }

  preview(fileData: File): void {
    // Show preview
    const mimeType = fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    } else {
      if (fileData.size > this.MAX_FILE_SIZE) {
        this.notificationService.displayNotificationToast('theme-creator.image-to-big', NotificationMessageType.ERROR);
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = (event) => {
          this.previewUrl = event.target?.result;
        };
      }
    }
  }

  get isValid(): boolean {
    return this.form.controls[this.formField.key].valid;
  }

  getTranslatedField(item: BaseEnumEntity, field: string): string {
    if (this.formField.translationModulePrefix) {
      return this.translateService.translate(this.formField.translationModulePrefix + item[field]);
    } else if (item.translations) {
      return item.translations[0].translationValue;
    } else {
      return item[field];
    }
  }

}
