<div class="base__tabs-container">
  <div class="base__left-side-bar"></div>
  <ul class="base__nav base__nav__tabs "
      data-cy="tabs-list">
    <li
      *ngFor="let tab of tabs"
      class="base__nav__tab"
      [ngClass]="[ tab.active? 'base__nav__tab--active':'base__nav__tab--inactive' ]"
      (click)="selectTab(tab)">
      <p href="#"
         class="base__nav__tab  "
         [ngStyle]="{'font-size': isTerminalDevice ? '3rem' : ''}"
         [ngClass]="[tab.active? 'base__nav__tab__title--active': 'base__nav__tab__title--inactive',
          isTerminalDevice ? 'terminal-tab' : '',
          titleIsTooOLong(tab.tabTitle | translate) ? 'global-white-space-brake-spaces' : 'global-white-space-no-wrap']"
         (click)="$event.preventDefault()">{{ tab.tabTitle | translate }}</p>
    </li>
  </ul>
</div>
<ng-container #vc></ng-container>
