import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  DryerModel,
  FurnaceModel,
  MachineModel,
  MixingMachineModel,
  ProductionDeviceGroup, ProductionDeviceInstance
} from '../../../../core/sdk/model-productiondevices';
import {SelectItem} from 'primeng/api';
import {DryerService} from '../../../services/production/dryer.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../../services/notification.service';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {MachineService} from '../../../services/production/machine.service';
import {FurnaceService} from '../../../services/production/furnace.service';
import {MixingMachineInstanceService} from '../../../services/mixing-machine-instance.service';
import {ProductionDeviceInstanceService} from '../../../services/production/production-device-instance.service';
import {HttpErrorResponse} from '@angular/common/http';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'app-device-instances-create-form',
  templateUrl: './device-instances-create-form.component.html',
})
export class DeviceInstancesCreateFormComponent implements OnInit {

  @Input() currentDeviceModel: DryerModel | MachineModel | FurnaceModel | MixingMachineModel;
  @Input() availableDeviceTypeOptions: SelectItem[];
  @Input() availableStatusOptions: SelectItem[];
  @Input() availableGroupOptions: ProductionDeviceGroup[];

  @Output() createdInstanceEmitter: EventEmitter<ProductionDeviceInstance> = new EventEmitter<ProductionDeviceInstance>();

  public selectedGroup: ProductionDeviceGroup;
  public selectedStatus: SelectItem;
  public assigningGroupFlag = false;
  public formGroup: FormGroup;

  constructor(private dryerService: DryerService,
              private machineService: MachineService,
              private furnaceService: FurnaceService,
              private mixingMachineInstanceService: MixingMachineInstanceService,
              private notificationService: NotificationService,
              private formBuilder: FormBuilder,
              private productionDeviceInstanceService: ProductionDeviceInstanceService,
              private spinnerService: SpinnerService) {
  }

  public ngOnInit(): void {
    this.initFormGroup();
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      status: [this.selectedStatus ? this.selectedStatus : '', [Validators.required]],
      group: [this.selectedGroup ? this.selectedGroup.name : '', [Validators.required]]
    });
  }


  public onSubmit(): void {
    const status = this.statusControlName.value;
    let productionDeviceInstance: ProductionDeviceInstance;
    if (this.currentDeviceModel.id.includes('dryer')) {
      productionDeviceInstance = this.dryerService.getNewDryerInstance(this.currentDeviceModel as DryerModel,
        this.selectedGroup, status.value);
    } else if (this.currentDeviceModel.id.includes('furnace')) {
      productionDeviceInstance = this.furnaceService.getNewFurnaceInstance(this.currentDeviceModel as FurnaceModel,
        this.selectedGroup, status.value);
    } else if (this.currentDeviceModel.id.includes('mixing')) {
      productionDeviceInstance = this.mixingMachineInstanceService.getNewMixingMachineInstance(
        this.currentDeviceModel as MixingMachineModel, this.selectedGroup, status.value);
    } else if (this.currentDeviceModel.id.includes('machine')) {
      productionDeviceInstance = this.machineService.getNewMachineInstance(this.currentDeviceModel as MachineModel,
        this.selectedGroup, status.value);
    }


    this.spinnerService.activateSpinner();
    this.productionDeviceInstanceService.createProductionDeviceInstance(productionDeviceInstance).subscribe({
      next: (response: ProductionDeviceInstance) => {
        this.createdInstanceEmitter.emit(response);
        this.spinnerService.deactivateSpinner();
        this.notificationService.displayNotificationToast('device-instance.production-device-instance-created-successfully',
          NotificationMessageType.SUCCESS);
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public toggleAssigningGroupFlag(): void {
    this.assigningGroupFlag = !this.assigningGroupFlag;
  }

  public get groupControlName(): AbstractControl {
    return this.formGroup.get('group');
  }

  public get statusControlName(): AbstractControl {
    return this.formGroup.get('status');
  }

  public assignGroup(group: ProductionDeviceGroup): void {
    if (group) {
      this.groupControlName.setValue(group.name);
      this.selectedGroup = group;
    }
    this.toggleAssigningGroupFlag();
  }

  public closeForm(): void {
    this.createdInstanceEmitter.emit(null);
  }

}
