<div
  *ngIf="isCrossFunctionalActive"
  class="global-flex global-justify__flex-end">
  <button
    type="button"
    class="global-button"
    (click)="returnToOffers(offer)"
  >{{'common.return' | translate}}</button>
</div>

<div class="order-address">
  <h2
    class="base-display__title"
  >{{'common.select-customer' | translate}}</h2>

  <div class="order-address__form">
    <p-table
      *ngIf="paymentTerms && deliveryTerms"
      class="main-table-header"
      responsiveLayout="scroll"
      dataKey="id"
      [value]="getTableData()"
      [paginator]="true"
      [rowHover]="true"
      [lazy]="true"
      [(first)]="pageable.first"
      [rows]="pageable.pageSize"
      [totalRecords]="pageable.count"
      (onPage)="paginate($event)">

      <ng-template pTemplate="header">
        <tr class="additional-address-line-table__headers additional-address-line-table__headers--border">
          <th
            pSortableColumn="companyName"
            (click)="sortByColumn('companyName', true)"
          >{{'common.company-name' | translate}}
            <p-sortIcon field="companyName"></p-sortIcon>
          </th>
          <th
            pSortableColumn="datevID"
            (click)="sortByColumn('datevID', false)"
          >{{'common.datev-id' | translate}}
            <p-sortIcon field="datevID"></p-sortIcon>
          </th>
          <th
            pSortableColumn="taxID"
            (click)="sortByColumn('taxID', true)"
          >{{'common.tax-id' | translate}}
            <p-sortIcon field="taxID"></p-sortIcon>
          </th>
          <th
            pSortableColumn="deliveryTerm"
            (click)="sortByColumn('deliveryTerm', false)"
          >{{'common.delivery-term' | translate}}
            <p-sortIcon field="deliveryTerm"></p-sortIcon>
          </th>
          <th
            pSortableColumn="paymentTerm"
            (click)="sortByColumn('paymentTerm', false)"
          >{{'common.payment-term' | translate}}
            <p-sortIcon field="paymentTerm"></p-sortIcon>
          </th>
          <th
            pSortableColumn="salesRepresentative"
            (click)="sortByColumn('salesRepresentative', false)"
          >{{'common.sales-representative' | translate}}
            <p-sortIcon field="salesRepresentative"></p-sortIcon>
          </th>
          <th>{{'common.action' | translate}}</th>
        </tr>

        <tr class="primeng-table__search-row" *ngIf="showFilteringRow">
          <td>
            <input
              pInputText
              type="text"
              class="global-input primeng-table__filter-col"
              placeholder="{{ 'common.search-by-company-name' | translate }}"
              [(ngModel)]="companyNameFilterField"
              (keydown.enter)="searchByString($event, 'companyName')">
          </td>
          <td>
            <div class="global-flex">
              <ng-template
                [ngIf]="columnOperationSignMap.get('datevID')?.operationSign !== OperationSign.RANGE_SIGN.toString()">
                <input
                  type="number"
                  class="global-input primeng-table__filter-col"
                  placeholder="{{ 'common.search-by-datev-id' | translate }}"
                  [(ngModel)]="datevIdFilterField"
                  (keydown.enter)="searchByNumber($event, 'datevID')">
              </ng-template>
              <app-custom-operation-sign-filter
                [fieldName]="'datevID'"
                [columnOperationSignMap]="columnOperationSignMap"
                [operationSigns]="getAvailableOperationSignsForNumber()"
                (rangeSignEmitter)="searchByNumber($event, 'datevID')"
                (operationSignEmitter)="operationSignValueChange('datevID', $event)"
              ></app-custom-operation-sign-filter>
            </div>
          </td>
          <td>
            <input
              pInputText
              type="number"
              class="global-input primeng-table__filter-col"
              placeholder="{{ 'common.search-by-tax-id' | translate }}"
              [(ngModel)]="taxIdFilterField"
              (keydown.enter)="searchByString($event, 'taxID')">
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
            <input
              pInputText
              type="text"
              class="global-input primeng-table__filter-col"
              placeholder="{{ 'common.search-by-sales-representative' | translate }}"
              [(ngModel)]="salesRepresentativeFilterField"
              (keydown.enter)="searchByString($event, 'name','salesRepresentative', 'Person')">
          </td>
          <td>
            <button
              type="button"
              class="global-button"
              (click)="resetFilters()"
            >{{'common.reset-filters' | translate}}</button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">
            {{'common.no-records-available' | translate}}
          </td>
        </tr>
      </ng-template>

      <ng-template
        pTemplate="body"
        let-customer>

        <tr class="additional-address-line-table__body">
          <td>{{customer.companyName}}</td>
          <td>{{customer.datevID}}</td>
          <td>{{customer.taxID ? customer.taxID : ('common.not-defined' | translate)}}</td>
          <td>{{getTranslationOfDeliveryTerm(customer.deliveryTerm)}}</td>
          <td>{{getTranslationOfPaymentTerm(customer.paymentTerm)}}</td>
          <td>{{customer.salesRepresentative ? customer.salesRepresentative.name : ('common.not-assigned' | translate)}}</td>
          <td>
            <button
              class="button button__submit-button button__submit-button--color button__submit-button--background-color global-width--fill-available
order-address__form__button"
              (click)="onSubmit(customer)"
            >{{'common.choose' | translate}}</button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
