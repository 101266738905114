import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FiringSchemaStep} from '../../../../../core/sdk/model-firingschema';
import {EnumService} from '../../../../services/enum.service';
import {FiringSchemaTemperatureFluctuation} from '../../../../../core/sdk/model-enums';
import {ProductionFiringSchemaService} from '../../../../services/production-firing-schema.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstanceValues} from '../../../../constance-values/constance-values';
import {Utility} from '../../../../utilities/utility';
import {positiveTotalTimeFormValidator} from '../../../../custom-validators/positive-total-time-form-validator';
import {SelectItem} from 'primeng/api';
import {TranslationKeyEnum} from '../../../../enums/translation-key-enum';

@Component({
  selector: 'app-firing-schema-step-form',
  templateUrl: './firing-schema-step-form.component.html'
})
export class FiringSchemaStepFormComponent implements OnInit, AfterViewInit, OnDestroy {

  private wasEmitterActivated = false;

  public formGroup: FormGroup;
  public stepTypeSelectOption = this.enumService.transformEnumOptionsForPrimeDropdown(FiringSchemaTemperatureFluctuation,
    TranslationKeyEnum.PRODUCTION);
  private readonly integerValidators = [Validators.required, Validators.min(ConstanceValues.MIN_NUMBER),
    Validators.max(ConstanceValues.MAX_NUMBER)];
  public isItNewStep = false;
  public isStepIncreasing = false;
  @Input() public step: FiringSchemaStep;
  @Input() public closeAllStepEditionEmitter: EventEmitter<void>;
  @Output() public submitStepEmitter = new EventEmitter<FiringSchemaStep>();

  constructor(private schemaService: ProductionFiringSchemaService,
              private formBuilder: FormBuilder,
              private enumService: EnumService) {
  }

  public ngOnInit(): void {
    this.initFormGroup();
    this.isItNewStep = this.schemaService.isItNewStep(this.step.id);
    this.closeAllStepEditionEmitter.subscribe(() => this.ngOnDestroy());
  }

  public ngAfterViewInit(): void {
    document.getElementById('stepForm').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }

  public ngOnDestroy(): void {
    if (!this.wasEmitterActivated) {
      this.cancelForm();
    }
  }

  private initFormGroup(): void {
    const {days, hours, minutes, seconds} = Utility.convertSecondsToDurationObject(this.step.stepDuration.seconds);
    this.formGroup = this.formBuilder.group({
        index: [this.step.index,
          [Validators.required, Validators.min(ConstanceValues.MIN_POSITIVE_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
        description: [this.step.description, [Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
        targetTemperature: [this.step.targetTemperature, this.integerValidators],
        temperatureFluctuation: [this.step.temperatureFluctuation, [Validators.required]],
        days: [days, [Validators.required, Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(999)]],
        hours: [hours, this.integerValidators],
        minutes: [minutes, this.integerValidators],
        seconds: [seconds, this.integerValidators],
      },
      {validators: [positiveTotalTimeFormValidator()]}
    );

    this.isStepIncreasing = this.temperatureFluctuationControl.value === FiringSchemaTemperatureFluctuation.INCREASE;

    if (this.isStepIncreasing) {
      this.attachStartTemperatureControl();
    }
  }

  private attachStartTemperatureControl(): void {
    this.formGroup.addControl('startTemperature', this.formBuilder.control(this.step.startTemperature, this.integerValidators));
  }

  private detachStartTemperatureControl(): void {
    this.formGroup.removeControl('startTemperature');
  }

  private assignValuesToStep(): void {
    this.step = {...this.step, ...this.formGroup.value};
    this.step.stepDuration = {
      nanos: 0,
      seconds: this.secondsControl.value + 60 * (this.minutesControl.value
        + 60 * (this.hoursControl.value + this.daysControl.value * 24) ?? 0)
    };

    if (!this.isStepIncreasing) {
      this.step.startTemperature = null;
    }
  }

  public adjustViewToSelectedTemperatureFluctuation(temperatureFluctuation: SelectItem): void {
    if (temperatureFluctuation.value === FiringSchemaTemperatureFluctuation.INCREASE) {
      this.attachStartTemperatureControl();
      this.isStepIncreasing = true;
    } else {
      this.isStepIncreasing = false;
      this.detachStartTemperatureControl();
    }
  }

  public cancelForm(): void {
    this.wasEmitterActivated = true;
    this.submitStepEmitter.emit();
  }

  public submitForm(): void {
    this.assignValuesToStep();
    this.wasEmitterActivated = true;
    this.submitStepEmitter.emit(this.step as FiringSchemaStep);
  }


  //// FORM_CONTROLS

  public get indexControl(): AbstractControl {
    return this.formGroup.get('index');
  }

  public get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  public get startTemperatureControl(): AbstractControl {
    return this.formGroup.get('startTemperature');
  }

  public get targetTemperatureControl(): AbstractControl {
    return this.formGroup.get('targetTemperature');
  }

  public get temperatureFluctuationControl(): AbstractControl {
    return this.formGroup.get('temperatureFluctuation');
  }

  public get daysControl(): AbstractControl {
    return this.formGroup.get('days');
  }

  public get hoursControl(): AbstractControl {
    return this.formGroup.get('hours');
  }

  public get minutesControl(): AbstractControl {
    return this.formGroup.get('minutes');
  }

  public get secondsControl(): AbstractControl {
    return this.formGroup.get('seconds');
  }


}
