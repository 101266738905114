<div
  *ngIf="displayAsMainTable && !showEditProductQualityNormForm && !showEditFormForNormTestAtTemperature"
  class="global-flex global-justify__flex-end">
  <button
    *ngIf="displayAsMainTable"
    type="button"
    class="global-button"
    (click)="toggleShowEditProductQualityNormForm()"
  >{{ 'products.edit' }}
  </button>
</div>

<ng-template [ngIf]="!showEditFormForNormTestAtTemperature && !showEditProductQualityNormForm">
  <div
    class="global-flex global-justify__flex-end">
    <button
      *ngIf="!displayAsMainTable"
      type="button"
      class="global-button"
      (click)="closeViewEmitter.emit()"
    >{{ 'products.return' }}
    </button>
  </div>

  <div class="global-flex global-justify__space-around">
    <div class="global-flex global-flex-direction__column global-align__items-center global-justify__center global-padding__small-bottom">

      <ng-template [ngIf]="displayAsMainTable">
        <div class="global-border global-padding__small global-background-color--white">
          <div
            class="global-flex global-flex-direction__column global-border-bottom">
            <span
            >{{ 'products.norm-type' | translate }}</span>
            <span
              class="global-font-weight--bold"
            >{{ productQualityNorm.normType | status : ProductQualityNormType : TranslationKeyEnum.PRODUCTS }}</span>
          </div>
          <div class="global-flex global-flex-direction__column global-border-bottom">
            <span
            >{{ 'products.din-norm' | translate }}</span>
            <span class="global-font-weight--bold"
            >{{ productQualityNorm.dinNorm }}</span>
          </div>
          <ng-template [ngIf]="productQualityNorm.id.includes('strength')">
            <div class="global-flex global-flex-direction__column global-border-bottom">
              <span
              >{{ 'products.temperature-prefix' | translate }}</span>
              <span class="global-font-weight--bold"
              >{{ productQualityNorm.temperaturePrefix | status: StrengthNormTemperaturePrefix : TranslationKeyEnum.PRODUCTS }}</span>
            </div>
            <div class="global-flex global-flex-direction__column global-border-bottom">
              <span
              >{{ 'products.strength-type' | translate }}</span>
              <span
                class="global-font-weight--bold"
              >{{ productQualityNorm.type | status: StrengthNormType: TranslationKeyEnum.PRODUCTS }}</span>
            </div>
          </ng-template>
          <ng-template [ngIf]="(productQualityNorm.id.includes('thermal')) || (productQualityNorm.id.includes('change'))">
            <div class="global-flex global-flex-direction__column global-border-bottom">
              <span
              >{{ 'products.progression-type' | translate }}</span>
              <span class="global-font-weight--bold"
              >{{ productQualityNorm.progressionType | status: ProductQualityNormProgressionType : TranslationKeyEnum.PRODUCTS }}</span>
            </div>
            <div class="global-flex global-flex-direction__column global-border-bottom">
              <span
              >{{ 'products.reversibility-type' | translate }}</span>
              <span
                class="global-font-weight--bold"
              >{{ productQualityNorm.reversibilityType | status: ProductQualityNormReversibilityType: TranslationKeyEnum.PRODUCTS }}</span>
            </div>
          </ng-template>
          <div class="global-flex global-flex-direction__column global-border-bottom">
            <span
            >{{ 'products.date-of-performing-norm-check' | translate }}</span>
            <span
              class="global-font-weight--bold"
            >{{ productQualityNorm.dateOfPerformingNormCheck ? displayDate(productQualityNorm.dateOfPerformingNormCheck) : ('products.not-defined' | translate) }}</span>
          </div>
          <div class="global-flex global-flex-direction__column global-border-bottom">
            <span
            >{{ 'products.creation-date' | translate }}</span>
            <span class="global-font-weight--bold"
            >{{ displayDateInProperFormat(productQualityNorm.creationDate) }}</span>
          </div>
          <div class="global-flex global-flex-direction__column">
            <span
            >{{ 'products.last-update' | translate }}</span>
            <span class="global-font-weight--bold"
            >{{ displayDateInProperFormat(productQualityNorm.timestamp) }}</span>
          </div>
        </div>
      </ng-template>

      <span class="global-color--white global-font-size__1-5rem"
      >{{ 'products.description' | translate }}</span>
      <div
        class="global-border global-flex global-align__items-center global-justify__center
       global-font-size__1-5rem global-background-color--white global-width--25vw global-height--40vh"
      >{{ productQualityNorm.additionalDescription ? productQualityNorm.additionalDescription : ('products.not-defined' | translate) }}
      </div>
    </div>
    <div class="global-flex global-flex-direction__column global-width--65vw global-justify__center">
      <div
        *ngIf="lineChartForNormTestAtTemperature"
        class="cycle-history-statistics__chart-style">
        <div echarts [options]="lineChartForNormTestAtTemperature" class="demo-chart"></div>
      </div>

      <app-norm-test-at-temperature-table
        [normType]="normType"
        [normTestAtTemperatureList]="normTestAtTemperatureList"
        (selectedNormTestAtTemperatureEmitter)="showNormTestAtTemperatureForm($event)">
      </app-norm-test-at-temperature-table>
    </div>

  </div>

</ng-template>

<ng-template [ngIf]="showEditFormForNormTestAtTemperature">
  <app-norm-test-at-temperature-form
    [normType]="normType"
    [normTestAtTemperature]="selectedNormTestAtTemperature"
    (submitFormEmitter)="updateNormTestAtTemperature($event)"
    (closeViewEmitter)="toggleShowEditFormForNormTestAtTemperatureFlag()"
  ></app-norm-test-at-temperature-form>
</ng-template>

<ng-template [ngIf]="showEditProductQualityNormForm">
  <app-product-quality-norm-form
    [productQuality]="productQualityNorm.productQuality"
    [productQualityNorm]="productQualityNorm"
    [updateModeApplied]="true"
    (closeViewEmitter)="closeView()">
  </app-product-quality-norm-form>
</ng-template>

