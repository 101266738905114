<ng-container
  *ngIf="getMixingSchemaSteps()?.length > 0 then mixingSchemaStepList else placeholder"></ng-container>

<ng-template #mixingSchemaStepList>
  <div
    *ngFor="let mixingSchemaStep of getMixingSchemaSteps()"
    class="global-flex global-align__items-center global-flex-direction__column">
    <app-mixing-schema-step
    [mixingSchemaStep]="mixingSchemaStep">
    </app-mixing-schema-step>
  </div>
</ng-template>

<ng-template #placeholder>
  <div
    class="production-schema__placeholder">
    <h3>{{ 'production.there-is-no-mixing-schema-steps' | translate }}</h3>
  </div>
</ng-template>
