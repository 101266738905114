<div
  class="split-product-in-storing-tile"
  [style.border]="displayBorder ? ' 5px solid red' : 'none'">
  <h2 class="global-font-weight--bold"
  >{{ 'mobile.split-product-in-storing' | translate }}</h2>
  <div class="global-grid grid-column-template--3">
    <div class="global-flex global-flex-direction__column global-text-align__center">
      <span class="global-font-weight--bold"
      >{{ 'mobile.status'| translate }}</span>
      <span
      >{{ splitProductStoredInstance.status | status: ProductStoredInstanceStatus: 'mobile' }}</span>
    </div>
    <div class="global-flex global-flex-direction__column global-text-align__center">
      <span class="global-font-weight--bold"
      >{{ 'mobile.product-name'| translate }}</span>
      <span
      >{{ splitProductStoredInstance.productType ? splitProductStoredInstance.productType.name : splitProductStoredInstance.productForService.name }}</span>
    </div>
    <div class="global-flex global-flex-direction__column global-text-align__center">
      <span class="global-font-weight--bold"
      >{{ 'mobile.article-number'| translate }}</span>
      <span
      >{{ splitProductStoredInstance.productType ? splitProductStoredInstance.productType.articleNumber : ('mobile.not-applicable' | translate) }}</span>
    </div>
    <div class="global-flex global-flex-direction__column global-text-align__center">
      <span class="global-font-weight--bold"
      >{{ 'mobile.quality'| translate }}</span>
      <span
      >{{ splitProductStoredInstance.productType ? splitProductStoredInstance.productType.quality.name : ('mobile.not-applicable' | translate) }}</span>
    </div>
    <div class="global-flex global-flex-direction__column global-text-align__center">
      <span class="global-font-weight--bold"
      >{{ 'mobile.creation-date' | translate }}</span>
      <span
      >{{ displayCreationDateInProperFormat(splitProductStoredInstance.creationDate) }}</span>
    </div>
  </div>
</div>
