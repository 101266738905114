import {ComponentFactoryResolver, Injectable, ViewContainerRef} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicFunctionalityComponentFactoryService {

  private viewContainer: ViewContainerRef;

  constructor(private componentResolver: ComponentFactoryResolver) {
  }

  attachDynamicFunctionalityComponent(component: any): void {
    const factory = this.componentResolver.resolveComponentFactory(component);
    if (this.viewContainer) {
      this.viewContainer.createComponent(factory);
    }
  }

  set container(container: ViewContainerRef) {
    this.viewContainer = container;
  }
}
