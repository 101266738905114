// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {TranslatePipe} from '../app/core/translations/translate.pipe';
import {AppComponent} from '../app/app.component';
import {StaticTranslationCtrl} from '../app/core/sdk/bighero-controllers';

export const environment = {
  production: false,
  appComponent: AppComponent,
  staticTranslationCtrl: StaticTranslationCtrl,
  translatePipe: TranslatePipe,
  apiUrl: 'https://dev.bh.demo-one.de/api/',
  appName: 'Big Hero',
  companyName: 'VGT-DYKO',
  developmentYear: 2021,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
