import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NormTestAtTemperature} from '../../../core/sdk/model-producttype';
import {ProductQualityNormService} from '../../services/product-quality-norm.service';
import {Utility} from '../../utilities/utility';
import {ProductQualityNormType} from '../../../core/sdk/enums-types';

@Component({
  selector: 'app-norm-test-at-temperature-table',
  templateUrl: './norm-test-at-temperature-table.component.html',
})
export class NormTestAtTemperatureTableComponent {

  protected readonly ProductQualityNormType = ProductQualityNormType;
  @Input() public normTestAtTemperatureList: NormTestAtTemperature[] = [];
  @Input() public createProductQualityNormMode: boolean = false;
  @Input() public normType: ProductQualityNormType;

  @Output() public selectedNormTestAtTemperatureEmitter: EventEmitter<NormTestAtTemperature>
    = new EventEmitter<NormTestAtTemperature>();

  constructor(private productQualityNormService: ProductQualityNormService) {
  }

  public removeNormTestAtTemperatureFromList(normTestAtTemperature: NormTestAtTemperature) {
    this.productQualityNormService.removeNormTestAtTemperatureFromData(normTestAtTemperature);
  }

  public displayDateInDetailedFormat(timestamp: number): string {
    return Utility.convertTimestampToLocalDateString(timestamp);
  }

  public startEditingNormTestAtTemperature(normTestAtTemperature: NormTestAtTemperature): void {
    this.selectedNormTestAtTemperatureEmitter.emit(normTestAtTemperature);
  }

}
