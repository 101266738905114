import {ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {ExportTableDataService} from '../../services/exportTableData.service';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Utility} from '../../utilities/utility';
import {TranslateService} from '../../../core/translations/translate.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
})
export class ExportComponent implements OnDestroy, OnInit {

  public componentRef: ComponentRef<any>;
  public exportedTableName: string;
  public searchCriteriaForExportedTable: SearchCriteriaForClass;
  public numberOfExportedTableItems: number;
  public exportedTableInstance: any;
  public className: string;

  constructor(private exportTableDataService: ExportTableDataService,
              private componentResolver: ComponentFactoryResolver,
              private changeDetector: ChangeDetectorRef,
              private translateService: TranslateService,
              private vc: ViewContainerRef) {
    this.exportedTableName = this.exportTableDataService.getExportedTableName();
  }

  public ngOnInit(): void {
    this.createDynamicTableComponent();
  }


  private createDynamicTableComponent(): void {
    this.vc.clear();

    this.collectRequiredDataForTableDataExport();

    const factory = this.componentResolver.resolveComponentFactory(this.exportedTableInstance.tableComponentType);
    this.componentRef = this.vc.createComponent(factory);

    const dynamicTableComponentInstance = this.componentRef.instance;

    dynamicTableComponentInstance.shouldExportBeApplied = true;
    dynamicTableComponentInstance.pageable.pageSize = this.numberOfExportedTableItems;
    dynamicTableComponentInstance.searchCriteriaForClass = this.searchCriteriaForExportedTable;

    if (this.className === 'MultiPartMoldType') {
      dynamicTableComponentInstance.rockerBarSwitchLeftSideButtonName = 'production.multi-part-mold-types';
    }
  }

  private collectRequiredDataForTableDataExport(): void {
    this.exportedTableInstance = this.exportTableDataService.getTableFunctionalityInstance();
    this.numberOfExportedTableItems = this.exportTableDataService.getNumberOfAllIExportedTableItems();
    this.searchCriteriaForExportedTable = this.exportTableDataService.getCurrentlyAppliedSearchCriteriaForTableFunctionality();
    this.className = this.exportTableDataService.getClassName();
  }

  public getCurrentDate(): string {
    return Utility.transformTimestampToUserFriendlyStringDateTime(Date.now());
  }

  public ngOnDestroy(): void {
    this.componentRef.destroy();
  }

}
