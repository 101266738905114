import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ProductionSchema,
  ProductionSchemaStep,
  ProductionSchemaTemplate
} from '../../../core/sdk/model-productionschema';
import {FiringSchema, FiringSchemaStep, FiringSchemaTemplate} from '../../../core/sdk/model-firingschema';
import {ProductionFiringSchemaService} from '../../services/production-firing-schema.service';
import {CrossFunctionalService} from '../../services/cross-functional.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-schema-step-list',
  templateUrl: './schema-step-list.component.html',
})
export class SchemaStepListComponent implements OnInit, OnDestroy {

  public currentType: 'firing' | 'production';
  public disabledArrowButtonsWhileCrossIsActive = false;
  private subscription: Subscription;

  @Input() currentSchema: ProductionSchema | FiringSchema | ProductionSchemaTemplate | FiringSchemaTemplate;
  @Input() indexColumnApplied = false;
  @Input() closeAllStepEditionEmitter: EventEmitter<void>;

  constructor(private productionFiringSchemaService: ProductionFiringSchemaService,
              private crossFunctionalService: CrossFunctionalService) {
  }

  public ngOnInit(): void {
      if (this.currentSchema.id.includes('firing')) {
        this.currentType = 'firing';
      } else {
        this.currentType = 'production';
      }
    if (!this.crossFunctionalService.isCrossFunctionalActive('returningFlag')) {
      this.productionFiringSchemaService.assignSchemaSteps(this.getStepsFromSchema());
    }
    if (this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepValues')) {
      this.disabledArrowButtonsWhileCrossIsActive = true;
    }
    this.productionFiringSchemaService.shouldArrowButtonsBeDisabledSourceContent.subscribe(
      (value: string[]) => this.disabledArrowButtonsWhileCrossIsActive = value.length > 0
    );
  }

  public isTemplate(templateId: string): boolean {
    return templateId.includes('template');
  }

  public ngOnDestroy(): void {
    if (!this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepId')) {
      this.productionFiringSchemaService.flushSchemaSteps();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getStepsFromSchema(): FiringSchemaStep[] | ProductionSchemaStep[] {
    switch (this.currentType) {
      case 'firing':
        return (this.currentSchema as FiringSchema | FiringSchemaTemplate).firingSchemaStepList;
      case 'production':
        return (this.currentSchema as ProductionSchema | ProductionSchemaTemplate).productionSchemaStepList;
    }
  }

  public getSteps(): FiringSchemaStep[] | ProductionSchemaStep[] {
    return this.productionFiringSchemaService.getSchemaSteps();
  }

}
