import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BasePrimengTableDirective} from '../../base-primeng-table/base-primeng-table.directive';
import {
  ChangeInLengthNorm,
  ProductQuality,
  ProductQualityNorm,
  StrengthNorm,
  ThermalExpansionNorm
} from '../../../core/sdk/model-producttype';
import {SpinnerService} from '../../services/spinner.service';
import {BaseSubNavbarService} from '../base-sub-navbar/base-sub-navbar.service';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {TableRefreshService} from '../../services/table-refresh.service';
import {PTableControlService} from '../../services/p-table-control.service';
import {ExportTableDataService} from '../../services/exportTableData.service';
import {TranslationKeyEnum} from '../../enums/translation-key-enum';
import {ProductQualityNormService} from '../../services/product-quality-norm.service';
import {NotificationService} from '../../services/notification.service';
import {HttpErrorResponse} from '@angular/common/http';
import {SelectItem} from 'primeng/api';
import {
  ProductQualityNormProgressionType,
  ProductQualityNormReversibilityType,
  ProductQualityNormType,
  StrengthNormType
} from '../../../core/sdk/enums-types';
import {EnumService} from '../../services/enum.service';
import {ComponentType} from '@angular/cdk/overlay';
import {StrengthNormTemperaturePrefix} from '../../../core/sdk/model-enums';
import {
  ProductQualityNormDetailsComponent
} from './product-quality-norm-details/product-quality-norm-details.component';
import {Utility} from '../../utilities/utility';

@Component({
  selector: 'app-product-quality-norm-table',
  templateUrl: './product-quality-norm-table.component.html',
})
export class ProductQualityNormTableComponent extends BasePrimengTableDirective<ProductQualityNorm>
  implements OnInit, AfterViewInit {

  public selectedFilteringNormTypeOption: SelectItem;
  public availableSizeChangeTypeOptions: SelectItem[];
  public availableStrengthNormTypeOptions: SelectItem[];
  public availableTemperaturePrefixOptions: SelectItem[];
  public availableProgressionTypeOptions: SelectItem[];
  public availableReversibilityTypeOptions: SelectItem[];
  public showDetailsView: boolean = false;
  public showUpdateForm: boolean = false;

  public normTypeFilterFieldValue: number;
  public dinNormFilterFieldValue: string;
  public temperaturePrefixFilterFieldValue: number;
  public progressionTypeFieldValue: number;
  public reversibilityTypeFieldValue: number;
  public typeFilterFieldValue: number;
  public productQualityFieldValue: number;
  public selectedNorm: ProductQualityNorm;

  protected readonly ProductQualityNormType = ProductQualityNormType;
  protected readonly TranslationKeyEnum = TranslationKeyEnum;
  protected readonly StrengthNormType = StrengthNormType;
  protected readonly StrengthNormTemperaturePrefix = StrengthNormTemperaturePrefix;
  protected readonly ProductQualityNormProgressionType = ProductQualityNormProgressionType;
  protected readonly ProductQualityNormReversibilityType = ProductQualityNormReversibilityType;

  @Input() displayAsMainTable: boolean = true;
  @Input() productQuality: ProductQuality;
  @Output() showDetailsViewEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private productQualityNormService: ProductQualityNormService,
              private notificationService: NotificationService,
              private enumService: EnumService,
              protected spinnerService: SpinnerService,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService,
              protected pTableControlService: PTableControlService,
              protected exportTableDataService: ExportTableDataService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService,
      null, exportTableDataService);
    this.assignClassName('ProductQualityNorm');
    this.assignTableName('productQualityNormTable');
    this.assignValuesForTableExport();
  }

  private assignValuesForTableExport(): void {
    if (this.displayAsMainTable) {
      this.assignTableInstance(this);
      this.tableComponentType = ProductQualityNormTableComponent;
      this.translationKey = TranslationKeyEnum.PRODUCT_QUALITY_CATALOG;
    }
  }

  public ngOnInit(): void {
    this.availableSizeChangeTypeOptions = this.enumService.transformEnumOptionsForPrimeDropdown(ProductQualityNormType,
      TranslationKeyEnum.PRODUCTS);
    if (this.displayAsMainTable) {
      this.loadFilterFlag();
    }
    if (this.shouldExportBeApplied) {
      this.selectedFilteringNormTypeOption = this.exportTableDataService.getAdditionalObjectPropertiesMap('selectedFilteringNormType');
    }
    super.ngOnInit();
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public exportTableData(): void {
    if (this.displayAsMainTable) {
      this.exportTableDataService.setAdditionalObjectPropertiesMap('selectedFilteringNormType',
        this.selectedFilteringNormTypeOption);
      super.exportTableData();
    }
  }

  public assignNewValuesToTable(data: ProductQualityNorm[]): void {
    this.shouldExportBeApplied ?
      this.exportTableDataService.setTableData(data) : this.productQualityNormService.setProductQualityNormData(data);
  }

  public getTableData(): ProductQualityNorm[] {
    return this.shouldExportBeApplied ?
      this.exportTableDataService.getTableData() : this.productQualityNormService.getProductQualityNormData();
  }

  public doCount(): void {
    if (!this.shouldExportBeApplied) {
      switch (this.selectedFilteringNormTypeOption?.value) {
        case ProductQualityNormType.STRENGTH_TEST : {
          this.displayAsMainTable ? this.countAllStrengthNorm() : this.countProductQualityStrengthNorms();
          break;
        }
        case ProductQualityNormType.THERMAL_EXPANSION : {
          this.displayAsMainTable ? this.countAllThermalExpansionNorm() : this.countProductQualityThermalExpansionNorms();
          break;
        }
        case ProductQualityNormType.CHANGE_IN_LENGTH : {
          this.displayAsMainTable ? this.countAllChangeInLengthNorm() : this.countProductQualityChangeInLengthNorms();
          break;
        }
        default : {
          this.displayAsMainTable ? this.countAllProductQualityNorms() : this.countProductQualityNorms();
        }
      }
    }
  }

  public doLoad(): void {
    switch (this.selectedFilteringNormTypeOption?.value) {
      case ProductQualityNormType.STRENGTH_TEST : {
        this.displayAsMainTable ? this.loadAllStrengthNorm() : this.loadProductQualityStrengthNorms();
        break;
      }
      case ProductQualityNormType.THERMAL_EXPANSION : {
        this.displayAsMainTable ? this.loadAllThermalExpansionNorm() : this.loadProductQualityThermalExpansionNorms();
        break;
      }
      case ProductQualityNormType.CHANGE_IN_LENGTH : {
        this.displayAsMainTable ? this.loadAllChangeInLengthNorm() : this.loadProductQualityChangeInLengthNorms();
      }
      default : {
        this.displayAsMainTable ? this.loadAllProductQualityNorm() : this.loadProductQualityNorms();
      }
    }
  }

  private countProductQualityNorms(): void {
    this.productQualityNormService.countProductQualityNorms(this.searchCriteriaForClass, this.productQuality.id)
      .subscribe({
        next: (response: number) => this.pageable.count = response,
        error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
  }

  private countProductQualityStrengthNorms(): void {
    this.productQualityNormService.countProductQualityStrengthNorms(this.searchCriteriaForClass, this.productQuality.id)
      .subscribe({
        next: (response: number) => this.pageable.count = response,
        error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
  }

  private countProductQualityChangeInLengthNorms(): void {
    this.productQualityNormService.countProductQualityChangeInLengthNorms(this.searchCriteriaForClass,
      this.productQuality.id).subscribe({
      next: (response: number) => this.pageable.count = response,
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private countAllStrengthNorm(): void {
    this.productQualityNormService.countAllStrengthNorm(this.searchCriteriaForClass).subscribe({
      next: (response: number) => this.pageable.count = response,
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private countAllThermalExpansionNorm(): void {
    this.productQualityNormService.countAllThermalExpansionNorm(this.searchCriteriaForClass).subscribe({
      next: (response: number) => this.pageable.count = response,
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private countAllChangeInLengthNorm(): void {
    this.productQualityNormService.countAllChangeInLengthNorm(this.searchCriteriaForClass).subscribe({
      next: (response: number) => this.pageable.count = response,
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private countProductQualityThermalExpansionNorms(): void {
    this.productQualityNormService.countProductQualityThermalExpansionNorms(this.searchCriteriaForClass,
      this.productQuality.id).subscribe({
      next: (response: number) => this.pageable.count = response,
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private countAllProductQualityNorms(): void {
    this.productQualityNormService.countAllProductQualityNorm(this.searchCriteriaForClass).subscribe({
      next: (response: number) => this.pageable.count = response,
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }


  private loadProductQualityStrengthNorms(): void {
    this.productQualityNormService.getProductQualityStrengthNorms(this.searchCriteriaForClass, this.productQuality.id,
      this.pageable.pageNumber, this.pageable.pageSize).subscribe({
      next: (response: StrengthNorm[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadProductQualityNorms(): void {
    this.productQualityNormService.getProductQualityNorms(this.searchCriteriaForClass, this.productQuality.id,
      this.pageable.pageNumber, this.pageable.pageSize).subscribe({
      next: (response: ProductQualityNorm[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadAllStrengthNorm(): void {
    this.productQualityNormService.getAllStrengthNorm(this.searchCriteriaForClass, this.pageable.pageNumber,
      this.pageable.pageSize).subscribe({
      next: (response: StrengthNorm[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadProductQualityChangeInLengthNorms(): void {
    this.productQualityNormService.getProductQualityChangeInLengthNorms(this.searchCriteriaForClass,
      this.productQuality.id, this.pageable.pageNumber, this.pageable.pageSize).subscribe({
      next: (response: ChangeInLengthNorm[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadAllThermalExpansionNorm(): void {
    this.productQualityNormService.getAllThermalExpansionNorm(this.searchCriteriaForClass,
      this.pageable.pageNumber, this.pageable.pageSize).subscribe({
      next: (response: ThermalExpansionNorm[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadProductQualityThermalExpansionNorms(): void {
    this.productQualityNormService.getProductQualityThermalExpansionNorms(this.searchCriteriaForClass,
      this.productQuality.id, this.pageable.pageNumber, this.pageable.pageSize).subscribe({
      next: (response: ThermalExpansionNorm[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadAllProductQualityNorm(): void {
    this.productQualityNormService.getAllProductQualityNorm(this.searchCriteriaForClass, this.pageable.pageNumber,
      this.pageable.pageSize).subscribe({
      next: (response: ProductQualityNorm[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadAllChangeInLengthNorm(): void {
    this.productQualityNormService.getAllChangeInLengthNorm(this.searchCriteriaForClass, this.pageable.pageNumber,
      this.pageable.pageSize).subscribe({
      next: (response: ChangeInLengthNorm[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public bindData(productQualityNorm: ProductQualityNorm): Map<string, object> {
    const dataBind: Map<string, any> = new Map<string, object>();
    dataBind.set('productQualityNorm', productQualityNorm);
    dataBind.set('displayAsMainTable', this.displayAsMainTable);
    return dataBind;
  }

  public getAvailableProductQualityNormTypeOptions(): SelectItem[] {
    return this.availableSizeChangeTypeOptions;
  }

  public getAvailableStrengthNormTypeOptions(): SelectItem[] {
    return this.availableStrengthNormTypeOptions;
  }

  public getAvailableTemperaturePrefixOptions(): SelectItem[] {
    return this.availableTemperaturePrefixOptions;
  }

  public getAvailableReversibilityTypeOptions(): SelectItem[] {
    return this.availableReversibilityTypeOptions;
  }

  public getAvailableProgressionTypeOptions(): SelectItem[] {
    return this.availableProgressionTypeOptions;
  }

  public getTabsMap(): Map<string, ComponentType<any>> {
    this.map.set('product-quality-catalog.details', ProductQualityNormDetailsComponent);
    return this.map;
  }

  public toggleShowDetailsViewFlag(): void {
    this.showDetailsView = !this.showDetailsView;
  }

  public toggleShowFilteringRowFlag(): void {
    if (this.showFilteringRow) {
      this.resetSelectedCriteria();
      this.clearData();
    }
    this.showFilteringRow = !this.showFilteringRow;
  }

  private resetSelectedCriteria(): void {
    switch (this.selectedFilteringNormTypeOption.value) {
      case ProductQualityNormType.STRENGTH_TEST : {
        this.searchCriteriaService.removeAllSearchCriteriaForGivenColumn(this.searchCriteriaForClass,
          'temperaturePrefix');
        this.searchCriteriaService.removeAllSearchCriteriaForGivenColumn(this.searchCriteriaForClass,
          'type');
        break;
      }
      case ProductQualityNormType.THERMAL_EXPANSION || ProductQualityNormType.CHANGE_IN_LENGTH : {
        this.searchCriteriaService.removeAllSearchCriteriaForGivenColumn(this.searchCriteriaForClass,
          'progressionType');
        this.searchCriteriaService.removeAllSearchCriteriaForGivenColumn(this.searchCriteriaForClass,
          'reversibilityType');
        break;
      }
    }
  }

  private clearData(): void {
    this.selectedFilteringNormTypeOption = null;
    this.normTypeFilterFieldValue = null;
    this.typeFilterFieldValue = null;
    this.temperaturePrefixFilterFieldValue = null;
    this.progressionTypeFieldValue = null;
    this.reversibilityTypeFieldValue = null;
    this.dinNormFilterFieldValue = null;
    this.productQualityFieldValue = null;
  }

  public resetSorting() {
    this.clearData();
    this.searchCriteriaService.updateFilteredClassNameInSearchCriteria(this.searchCriteriaForClass, 'ProductQualityNorm');
    super.resetSorting();
  }

  public resetFilters(): void {
    this.normTypeFilterFieldValue = null;
    this.dinNormFilterFieldValue = null;
    this.productQualityFieldValue = null;
    this.typeFilterFieldValue = null;
    this.temperaturePrefixFilterFieldValue = null;
    this.progressionTypeFieldValue = null;
    this.reversibilityTypeFieldValue = null;
    this.searchCriteriaService.updateFilteredClassNameInSearchCriteria(this.searchCriteriaForClass, 'ProductQualityNorm');
    this.tableRefreshService.onTableRefresh(this.tableName, true);
  }

  public handleNormTypeChangeEvent(data: any): void {
    if (this.displayAsMainTable && this.selectedFilteringNormTypeOption) {
      this.resetSelectedCriteria();
      this.progressionTypeFieldValue = null;
      this.reversibilityTypeFieldValue = null;
    }
    this.selectedFilteringNormTypeOption = data;
    switch (data.value) {
      case ProductQualityNormType.STRENGTH_TEST : {
        this.availableStrengthNormTypeOptions = this.enumService.transformEnumOptionsForPrimeDropdown(StrengthNormType,
          TranslationKeyEnum.PRODUCTS);
        this.availableTemperaturePrefixOptions = this.enumService.transformEnumOptionsForPrimeDropdown(StrengthNormTemperaturePrefix,
          TranslationKeyEnum.PRODUCTS);
        this.searchCriteriaService.updateFilteredClassNameInSearchCriteria(this.searchCriteriaForClass, 'StrengthNorm');
      }
        break;
      case ProductQualityNormType.THERMAL_EXPANSION : {
        this.loadProgressionTypeAndReversibilityTypeEnumOptions();
        this.searchCriteriaService.updateFilteredClassNameInSearchCriteria(this.searchCriteriaForClass, 'ThermalExpansionNorm');
        break;
      }
      case ProductQualityNormType.CHANGE_IN_LENGTH : {
        this.loadProgressionTypeAndReversibilityTypeEnumOptions();
        this.searchCriteriaService.updateFilteredClassNameInSearchCriteria(this.searchCriteriaForClass, 'ChangeInLengthNorm');
        break;
      }
    }
    if (this.displayAsMainTable) {
      this.refreshTable();
    }
  }

  private loadProgressionTypeAndReversibilityTypeEnumOptions(): void {
    this.availableProgressionTypeOptions = this.enumService.transformEnumOptionsForPrimeDropdown(
      ProductQualityNormProgressionType, TranslationKeyEnum.PRODUCTS);
    this.availableReversibilityTypeOptions = this.enumService.transformEnumOptionsForPrimeDropdown(
      ProductQualityNormReversibilityType, TranslationKeyEnum.PRODUCTS);
  }

  public getProperColSpanValue(): number {
    if (!this.displayAsMainTable) {
      if ((this.selectedFilteringNormTypeOption?.value === ProductQualityNormType.STRENGTH_TEST) ||
        (this.selectedFilteringNormTypeOption?.value === ProductQualityNormType.THERMAL_EXPANSION) ||
        (this.selectedFilteringNormTypeOption?.value === ProductQualityNormType.CHANGE_IN_LENGTH)) {
        return 6;
      } else {
        return 4;
      }
    } else {
      if ((this.selectedFilteringNormTypeOption?.value === ProductQualityNormType.STRENGTH_TEST) ||
        (this.selectedFilteringNormTypeOption?.value === ProductQualityNormType.THERMAL_EXPANSION) ||
        (this.selectedFilteringNormTypeOption?.value === ProductQualityNormType.CHANGE_IN_LENGTH)) {
        return 8;
      } else {
        return 6;
      }
    }
  }

  public displayDateInProperFormat(timestamp: number): string {
    return Utility.transformTimestampToUserFriendlyStringDateTime(timestamp);
  }

  public displayDate(timestamp: number): string {
    return Utility.convertTimestampToLocalDateString(timestamp);
  }

  private setSelectedNorm(strengthNorm: ProductQualityNorm): void {
    this.selectedNorm = strengthNorm;
  }

  public toggleShowUpdateFormFlag(): void {
    this.showUpdateForm = !this.showUpdateForm;
  }

  public showView(norm: StrengthNorm | ThermalExpansionNorm, detailsView: boolean): void {
    this.setSelectedNorm(norm);
    detailsView ? this.toggleShowDetailsViewFlag() : this.toggleShowUpdateFormFlag();
    this.showDetailsViewEmitter.emit(true);
  }

  public closeView(detailsView: boolean): void {
    this.setSelectedNorm(null);
    detailsView ? this.toggleShowDetailsViewFlag() : this.toggleShowUpdateFormFlag();
    this.showDetailsViewEmitter.emit(false);
  }

}
