<ng-container *ngIf="fileInfo then actionButtons else generateReportButton "></ng-container>
<ng-template #generateReportButton>
  <button
    type="button"
    class="common-button"
    (click)="generateInvoiceDocumentFile(locale)"
  >{{getProperButtonLabel() | translate}}</button>
</ng-template>
<ng-template #actionButtons>
  <div class="invoice-action">
    <span>{{('order-management.generated-at' | translate) + '\t' + displayTimestampToUserFriendlyStringDateTime(fileInfo.timestamp)}}</span>
    <button
      *ngIf="!isExcelFile()"
      type="button"
      (click)="displayInvoice(fileInfo)">
      <i class="pi pi-search"></i>
    </button>
    <button
      *ngIf="!isExcelFile()"
      type="button"
      (click)="printInvoice(fileInfo)">
      <i class="pi pi-print"></i>
    </button>
    <button
      type="button"
      (click)="downloadInvoice(fileInfo)">
      <i class="pi pi-download"></i>
    </button>
  </div>
</ng-template>
