import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {ErrorMessageService} from '../../shared/services/error-message.service';
import {ErrorMessage} from './model-dto';


@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  private readonly errorHandlers: ErrorHandler[];
  private blockOther = false;

  constructor(private router: Router,
              private errorMessageService: ErrorMessageService) {
    this.errorHandlers = [
      new ErrorHandler('401', new UnauthorizedCondition(), (() => {
        console.error('401 error is detected');
        if (router.url.toString().split('?')[0] !== '/login') {
          this.router.navigate(['/login']);
        }
      }))
      , new ErrorHandler(
        '404',
        new NotFoundCondition(),
        (response) => {
          console.error('404 error is detected');
          this.showMessage(response);
        })
      , new ErrorHandler(
        '403',
        new ForbiddenErrorCondition(),
        (response) => {
          console.error('403 error is detected');
          this.showMessage(response);
        })
      , new ErrorHandler(
        '500',
        new InternalServerErrorCondition(),
        (response) => {
          if (!this.checkIfErrorsNotBlocked()) {
            console.error('500 error is detected');
            this.showMessage(response);
          }
        }),
      new ErrorHandler(
        '504',
        new GatewayTimeoutErrorCondition(),
        () => {
          console.error('504 error is detected');
        }
      ),
      new ErrorHandler(
        '400',
        new ValidationErrorCondition(),
        () => {
          console.error('400 error is detected');
        }
      ),
      new ErrorHandler('511', new ReloginCondition(), ( () => {
        if (router.url.toString().split('?')[0] !== '/login') {
          this.blockOther = true;
          this.router.navigate(['/login']);
        }
      }))
    ];
  }
  private checkIfErrorsNotBlocked(): boolean {
    const isBlocked = this.blockOther;
    this.blockOther = false;
    return isBlocked;
  }

  /* Return true when the error was handled, false in other case */
  public handleErrors(response: HttpErrorResponse): boolean {
    return this.handleErrorsIfPresent(response);
  }

  private handleErrorsIfPresent(response: HttpErrorResponse): boolean {
    for (const handler of this.errorHandlers) {
      if (handler.handleErrorIfPresent(response)) {
        return true;
      }
    }
    return false;
  }

  public showMessage(response: HttpErrorResponse): void {
    const errorMessage: ErrorMessage = JSON.parse(response.error) as ErrorMessage;
    this.errorMessageService.setErrorData(errorMessage, response?.status);
    this.router.navigate(['/bh/error-message']);
  }

}

export abstract class HandlerCondition {

  public abstract isMetForGivenResponse(response: HttpErrorResponse): boolean;
}
class ReloginCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 511;
  }
}

class UnauthorizedCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }
}

class ForbiddenErrorCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 403;
  }
}

class NotFoundCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 404;
  }
}

class InternalServerErrorCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 500;
  }
}

class GatewayTimeoutErrorCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 504;
  }
}

class ValidationErrorCondition extends HandlerCondition {
  isMetForGivenResponse(response: HttpErrorResponse): boolean {
    return response.status === 400;
  }
}

export class ErrorHandler {
  id: string;
  action: (response) => void;
  condition: HandlerCondition;

  public constructor(id: string, condition: HandlerCondition, action: (response: any) => void) {
    this.id = id;
    this.action = action;
    this.condition = condition;
  }

  public handleErrorIfPresent(response: HttpErrorResponse): boolean {
    if (this.condition.isMetForGivenResponse(response)) {
      this.action(response);
      return true;
    }
    return false;
  }

}
