import {Injectable} from '@angular/core';
import {MachineInstanceCtrl, MachineModelCtrl} from '../../../core/sdk/controllers-productiondevices';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {MachineInstance, MachineModel, ProductionDeviceGroup} from '../../../core/sdk/model-productiondevices';
import {Utility} from '../../utilities/utility';
import {ProductionDeviceInstanceStatus} from '../../../core/sdk/enums-statuses';
import {MachineModelType} from '../../../core/sdk/model-enumentity';
import {MachineModelTypeCtrl} from '../../../core/sdk/controllers-enumentity';

@Injectable({
  providedIn: 'root'
})
export class MachineService {

  private machinesModelData: MachineModel[];

  constructor(private machineModelCtrl: MachineModelCtrl,
              private machineInstanceCtrl: MachineInstanceCtrl,
              private machineModelTypeCtrl: MachineModelTypeCtrl) {
  }

  //////// INSTANCES ////////


  public getNewMachineInstance(machineModel: MachineModel, productionDeviceGroup: ProductionDeviceGroup,
                               status?: ProductionDeviceInstanceStatus): MachineInstance {
    return {
      id: 'machine-instance/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      deleted: false,
      isDeleted: false,
      shorthand: null,
      workspaceInstance: null,
      group: productionDeviceGroup,
      machineModel,
      moldInstanceList: undefined,
      status: status ?? ProductionDeviceInstanceStatus.READY
    };
  }

  public countMachineInstances(searchCriteriaForClass: SearchCriteriaForClass, machineModelId: string): Observable<number> {
    return this.machineInstanceCtrl.countMachineInstances(searchCriteriaForClass, Utility.getObjectId(machineModelId));
  }

  public getMachineInstances(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number, machineModelId: string):
    Observable<MachineInstance[]> {
    return this.machineInstanceCtrl.getMachineInstances(searchCriteriaForClass, pageNumber, pageSize, Utility.getObjectId(machineModelId));
  }

  public removeMachineInstance(machineInstanceId: string): Observable<void> {
    return this.machineInstanceCtrl.deleteObject(Utility.getObjectId(machineInstanceId));
  }

  public removeMachineInstanceFromMachineModel(machineModelId: string, machineInstanceId: string): void {
    for (const index in this.machinesModelData) {
      if (this.machinesModelData[index].id === machineModelId) {
        Utility.deleteObjectFromArrayById(this.machinesModelData[index].machineInstanceList, machineInstanceId);
        break;
      }
    }
  }

  public addNewMachineInstanceToMachineModel(machineModelId: string, machineInstance: MachineInstance): void {
    for (const index in this.machinesModelData) {
      if (this.machinesModelData[index].id === machineModelId) {
        this.machinesModelData[index].machineInstanceList.push(machineInstance);
        break;
      }
    }
  }

  ////// MODELS /////

  public setMachineModelData(data: MachineModel[]): void {
    this.machinesModelData = data;
  }

  public getMachineModelData(): MachineModel[] {
    return this.machinesModelData;
  }

  public countMachineModels(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.machineModelCtrl.countMachineModels(searchCriteriaForClass);
  }

  public getMachineModels(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number):
    Observable<MachineModel[]> {
    return this.machineModelCtrl.getMachineModels(searchCriteriaForClass, pageNumber, pageSize);
  }

  public getMachineModel(machineModelId: string): Observable<MachineModel> {
    return this.machineModelCtrl.getObject(Utility.getObjectId(machineModelId));
  }

  ////// TYPES

  public getAllMachineModelType(): Observable<MachineModelType[]> {
    return this.machineModelTypeCtrl.findAllEnumObjects();
  }

}
