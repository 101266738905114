<div class="notification-modal">
  <img class="notification-modal__icon"
       src="assets/red-alert-icon.svg"
       alt="errorIcon">
  <h2 class="global-white-color">{{('common.number-of-violations' | translate) + ' ' + notifications.length}}</h2>
  <div
    *ngFor="let violation of notifications"
    class="global-width--100">
    <div class="notification-modal__title">
      <span>{{violation.errorUIkey | translate}}</span>
    </div>
    <div class="notification-modal__violation-param-container">
      <div
        *ngFor="let violationParam of violation.paramList let i = index"
        class="notification-modal__violations-params">
        <p>{{i + 1 + '. ' + (violationParam.name | translate)}}</p>
        <span>{{': ' + violationParam.value}}</span>
      </div>
    </div>
    <div class="notification-modal__divider"></div>
  </div>
  <button
    type="button"
    class="notification-modal__close-button"
    (click)="closeNotificationModal()"
  >{{'common.close' | translate}}</button>
</div>
<div class="spinner-background"></div>
