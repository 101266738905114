import {Component} from '@angular/core';
import {Violation} from '../../../core/sdk/model-dto';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
})
export class NotificationModalComponent{

  public notifications: Violation[];

  constructor(private notificationService: NotificationService) {
  }

  public closeNotificationModal(): void {
    this.notificationService.closeNotificationModal();
  }
}
