import {Component, OnDestroy, OnInit} from '@angular/core';
import {FunctionalityService} from '../../shared/services/functionality.service';
import {TranslateService} from '../../core/translations/translate.service';
import {BaseSubNavbarService} from '../../shared/base-components/base-sub-navbar/base-sub-navbar.service';
import {BaseSubNavbarComponentFactoryService} from '../../shared/services/base-sub-navbar-component-factory.service';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
})
export class WarehouseComponent implements OnInit, OnDestroy {

  constructor(private functionalityService: FunctionalityService,
              private translateService: TranslateService,
              private baseSubNavbarService: BaseSubNavbarService,
              private baseSubNavbarComponentFactoryService: BaseSubNavbarComponentFactoryService) {
    this.functionalityService.flushSubFunctionality();
    this.baseSubNavbarComponentFactoryService.attachSubNavbar();
    this.baseSubNavbarService.redirectToPreferredFunctionalityIfAvailable(['warehouse-management', 'inventory-counting']);
  }

  public ngOnInit(): void {
    this.translateService.loadTranslationModule('warehouse');
  }

  public ngOnDestroy(): void {
    this.baseSubNavbarComponentFactoryService.detachBaseSubNavbar();
  }

}
