import {Component, Input} from '@angular/core';
import {BaseObjectRecord} from '../../interfaces/interfaces';
import {OrderProduct} from '../../../core/sdk/model-order';
import {Utility} from '../../utilities/utility';

@Component({
  selector: 'app-production-planning-main-information',
  templateUrl: './production-planning-main-information.component.html',
})
export class ProductionPlanningMainInformationComponent {

  @Input() public orderProduct: OrderProduct;

  public initBaseObjectRecords(): BaseObjectRecord [] {
    const records: BaseObjectRecord [] = [];
    if (this.orderProduct?.productForService !== null) {
      records.push({label: 'product-name', value: this.orderProduct?.productForService.name});
    } else {
      records.push({label: 'product-name', value: this.orderProduct.productType.name});
      records.push({label: 'article-number', value: this.orderProduct.productType.articleNumber});
      records.push({label: 'quality', value: this.orderProduct.productType.quality.name});
    }
    records.push({label: 'quantity', value: this.orderProduct?.quantity});
    records.push({label: 'packed-quantity', value: this.orderProduct?.packedQuantity});
    records.push({label: 'position', value: this.orderProduct?.position});
    records.push({label: 'order-id', value: this.orderProduct?.order.shorthand});
    records.push({label: 'order-product-creation-date',
      value:  this.displayDateInProperFormat(this.orderProduct?.timestamp)});
    return records;
  }

  public displayDateInProperFormat(timestamp: number): string {
    return Utility.transformTimestampToUserFriendlyStringDateTime(timestamp);
  }


}
