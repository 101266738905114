import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minutesToDuration'
})
export class MinutesToDurationPipe implements PipeTransform {

  transform(value: number, separator: string = ':', suffix: string = '',
            padMinutesSign: string = '', padMinutesLength: number = 0,
            padHoursSign: string = '', padHoursLength: number = 0): string {
    let minutes = (value % 60).toFixed(0);
    let hours = Math.floor(value / 60).toFixed(0);
    minutes = minutes.toString().padStart(padMinutesLength, padMinutesSign);
    hours = hours.toString().padStart(padHoursLength, padHoursSign);
    return `${hours}${separator}${minutes}${suffix}`;
  }

}
