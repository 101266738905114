import {ComponentFactoryResolver, Injectable, ViewContainerRef} from '@angular/core';
import {SpinnerComponent} from '../spinner/spinner.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private viewContainer: ViewContainerRef;

  constructor(private componentResolver: ComponentFactoryResolver) {
  }

  public activateSpinner(): void {
    const factory = this.componentResolver.resolveComponentFactory(SpinnerComponent);
    this.viewContainer.createComponent(factory);
  }

  public deactivateSpinner(): void {
    this.viewContainer.clear();
  }

  set container(container: ViewContainerRef) {
    this.viewContainer = container;
  }
}
