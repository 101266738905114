import {Injectable} from '@angular/core';
import {Functionality, Person} from '../../core/sdk/bighero-model';
import {FunctionalityGroup} from '../../core/sdk/model-enums';
import {JsonScopedSerializer} from '../../core/sdk/JsonParser';
import {JsonScope} from '../../core/sdk/jsonScope';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  //  createSession(token: string, person: Person): void {
  //   this.setToken(token);
  //   this.setPerson(person);
  // }

  setToken(token: string): void {
    localStorage.setItem('sessionToken', token);
  }

  setPerson(person: Person): void {
    localStorage.setItem('loggedPerson', JsonScopedSerializer.stringify(person, new JsonScope(false, [])));
  }

  setFunctionalityMap(functionalityMap: { [key: string]: Functionality[] } | Map<string, Functionality[]>): void {
    localStorage.setItem('functionalityMap', JSON.stringify(functionalityMap));
  }

  setFunctionalityGroups(functionalityGroups: FunctionalityGroup[]): void {
    localStorage.setItem('functionalityGroups', JSON.stringify(functionalityGroups));
  }

  getToken(): string {
    const token = localStorage.getItem('sessionToken');
    return token ? token : '';
  }

  getPerson(): Person {
    const person = localStorage.getItem('loggedPerson');
    return person ? JSON.parse(person) : {};
  }

  getFunctionalityMap(): Map<string, Functionality[]> {
    const functionalityMap = localStorage.getItem('functionalityMap');
    return JSON.parse(functionalityMap);
  }

  getFunctionalityGroups(): FunctionalityGroup[] {
    const functionalityGroups = localStorage.getItem('functionalityGroups');
    return functionalityGroups ? JSON.parse(functionalityGroups) : [];
  }

  clearSession(): void {
    localStorage.clear();
  }
}
