import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {InlineEditionTableDirective} from '../../base-primeng-table/inline-edition-table.directive';
import {
  DryerInstance,
  DryerModel,
  FurnaceInstance,
  FurnaceModel,
  MachineInstance,
  MachineModel,
  MixingMachineInstance,
  MixingMachineModel,
  ProductionDeviceGroup,
  ProductionDeviceInstance
} from '../../../core/sdk/model-productiondevices';
import {TranslateService} from '../../../core/translations/translate.service';
import {EnumService} from '../../services/enum.service';
import {DryerService} from '../../services/production/dryer.service';
import {SpinnerService} from '../../services/spinner.service';
import {PTableControlService} from '../../services/p-table-control.service';
import {BaseSubNavbarService} from '../base-sub-navbar/base-sub-navbar.service';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {TableRefreshService} from '../../services/table-refresh.service';
import {SelectItem} from 'primeng/api';
import {ProductionDeviceInstanceStatus} from '../../../core/sdk/enums-statuses';
import {ProductionDeviceService} from '../../services/production/production-device.service';
import {FurnaceService} from '../../services/production/furnace.service';
import {MachineService} from '../../services/production/machine.service';
import {NotificationService} from '../../services/notification.service';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {MixingMachineInstanceService} from '../../services/mixing-machine-instance.service';
import {MixingMachineModelService} from '../../services/mixing-machine-model.service';
import {FormBuilder, Validators} from '@angular/forms';
import {
  ProductionDeviceModelForAssigningWorkspaceGroup
} from '../../interfaces/cross-functional-interfaces/cross-functional-interfaces';
import {WorkspaceInstance} from '../../../core/sdk/bighero-model';
import {ProductionDeviceInstanceService} from '../../services/production/production-device-instance.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslationKeyEnum} from '../../enums/translation-key-enum';

@Component({
  selector: 'app-device-instances',
  templateUrl: './device-instances.component.html'
})
export class DeviceInstancesComponent extends InlineEditionTableDirective<ProductionDeviceInstance>
  implements OnInit, AfterViewInit, OnDestroy {

  private deviceInstanceData: ProductionDeviceInstance[];
  public availableStatusOptions: SelectItem[];
  public availableGroupOptions: ProductionDeviceGroup[];
  public newDeviceFlag = false;
  public showWorkloadTable = false;
  public showWorkloadButton = false;
  public deviceInstanceId: string;
  public hideAssignWorkspaceInstanceButtonFlag: boolean;
  public applyAssigningWorkspaceInstanceFlag = false;
  public objectToAssignWorkspaceInstance: ProductionDeviceModelForAssigningWorkspaceGroup;
  public selectedDeviceInstance: ProductionDeviceInstance;
  private workspaceInstanceMap: Map<number, WorkspaceInstance> = new Map<number, WorkspaceInstance>();
  public currentRowIndex: number;

  @Input() public translationModule = TranslationKeyEnum.PRODUCTION;
  @Input() public currentDeviceModel: DryerModel | FurnaceModel | MachineModel | MixingMachineModel;
  @Input() public availableDeviceTypeOptions: SelectItem[];
  @Input() public isAssigningActionPerformed = false;
  @Input() public isTerminalDevice = false;

  @Output() public assignDeviceToWorkspaceInstanceEmitter: EventEmitter<ProductionDeviceInstance>
    = new EventEmitter<ProductionDeviceInstance>();

  constructor(private translateService: TranslateService,
              private productionDeviceService: ProductionDeviceService,
              private enumService: EnumService,
              private dryerService: DryerService,
              private furnaceService: FurnaceService,
              private machineService: MachineService,
              private mixingMachineInstanceService: MixingMachineInstanceService,
              private notificationService: NotificationService,
              private mixingMachineModelService: MixingMachineModelService,
              private productionDeviceInstanceService: ProductionDeviceInstanceService,
              protected spinnerService: SpinnerService,
              protected pTableControlService: PTableControlService,
              protected formBuilder: FormBuilder,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService) {
    super(formBuilder, searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService);
    this.assignTableName('deviceInstanceTable');
  }


  public ngOnInit(): void {
    this.translateService.loadTranslationModule('device-instance');
    super.ngOnInit();
    this.checkClass();
    this.reassignSearchCriteriaForClass();
    this.loadEnumSelectOptions();
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.selectedDeviceInstance = null;
  }

  private reassignSearchCriteriaForClass(): void {
    this.searchCriteriaForClass = this.searchCriteriaService.createSearchCriteriaForClass(
      this.className,
      'id',
      true);
  }

  public setFormGroupControls(): void {
    this.formControlObjects = [
      {name: 'group', validators: [Validators.required]},
      {name: 'status', validators: [Validators.required]}
    ];
  }

  public doActionAfterSave(rowIndex?: number): void {
    this.spinnerService.activateSpinner();
    this.updateInstance(rowIndex);
  }

  public doActionAfterInit(rowIndex: number): void {
    this.workspaceInstanceMap.set(rowIndex, this.getTableData()[rowIndex].workspaceInstance);
  }

  public doActionAfterCancel(rowIndex?: number): void {
    this.getTableData()[rowIndex].workspaceInstance = this.workspaceInstanceMap.get(rowIndex);
    this.workspaceInstanceMap.clear();
  }

  public checkClass(): void {
    if (this.currentDeviceModel.id.includes('dryer')) {
      this.assignClassName('DryerInstance');
    } else if (this.currentDeviceModel.id.includes('furnace')) {
      this.assignClassName('FurnaceInstance');
    } else if (this.currentDeviceModel.id.includes('mixing')) {
      this.assignClassName('MixingMachineInstance');
      // this.hideAssignWorkspaceInstanceButtonFlag = true;
    } else {
      this.assignClassName('MachineInstance');
    }
  }

  private loadEnumSelectOptions(): void {
    this.productionDeviceService.findAllGroups().subscribe(response => {
      this.availableGroupOptions = response;
    });
    this.availableStatusOptions = this.enumService.transformEnumOptionsForPrimeDropdown(ProductionDeviceInstanceStatus, 'device-instance');
  }

  public doCount(): void {
    switch (this.className) {
      case 'DryerInstance':
        this.doCountDryers();
        break;
      case 'FurnaceInstance':
        this.doCountFurnaces();
        break;
      case 'MachineInstance':
        this.doCountMachines();
        this.showWorkloadButton = true;
        break;
      case 'MixingMachineInstance':
        this.doCountMixers();
    }
  }

  private doCountDryers(): void {
    this.dryerService.countDryerInstances(this.searchCriteriaForClass, this.currentDeviceModel.id).subscribe(response => {
      this.pageable.count = response;
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  private doCountFurnaces(): void {
    this.furnaceService.countFurnaceInstances(this.searchCriteriaForClass, this.currentDeviceModel.id).subscribe(response => {
      this.pageable.count = response;
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  private doCountMachines(): void {
    this.machineService.countMachineInstances(this.searchCriteriaForClass, this.currentDeviceModel.id).subscribe(response => {
      this.pageable.count = response;
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  private doCountMixers(): void {
    this.mixingMachineInstanceService.countMixingMachineInstances(this.searchCriteriaForClass, this.currentDeviceModel.id)
      .subscribe(response => {
        this.pageable.count = response;
      }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public doLoad(): void {
    switch (this.className) {
      case 'DryerInstance':
        this.doLoadDryers();
        break;
      case 'FurnaceInstance':
        this.doLoadFurnaces();
        break;
      case 'MachineInstance':
        this.doLoadMachines();
        break;
      case 'MixingMachineInstance':
        this.doLoadMixers();
        break;
    }
  }

  private doLoadDryers(): void {
    this.dryerService.getDryerInstances(this.searchCriteriaForClass, this.pageable.pageNumber, this.pageable.pageSize,
      this.currentDeviceModel.id).subscribe(response => {
      this.setTableData(response);
      this.spinnerService.deactivateSpinner();
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  private doLoadFurnaces(): void {
    this.furnaceService.getFurnaceInstances(this.searchCriteriaForClass, this.pageable.pageNumber, this.pageable.pageSize,
      this.currentDeviceModel.id).subscribe(response => {
      this.setTableData(response);
      this.spinnerService.deactivateSpinner();
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  private doLoadMachines(): void {
    this.machineService.getMachineInstances(this.searchCriteriaForClass, this.pageable.pageNumber, this.pageable.pageSize,
      this.currentDeviceModel.id).subscribe(response => {
      this.setTableData(response);
      this.spinnerService.deactivateSpinner();
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  private doLoadMixers(): void {
    this.mixingMachineInstanceService.getMixingMachineInstances(this.searchCriteriaForClass, this.pageable.pageNumber,
      this.pageable.pageSize, this.currentDeviceModel.id).subscribe(response => {
      this.setTableData(response);
      this.spinnerService.deactivateSpinner();
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  private setTableData(data: ProductionDeviceInstance[]): void {
    this.deviceInstanceData = data;
  }

  public getTableData(): ProductionDeviceInstance[] {
    return this.deviceInstanceData;
  }

  private updateInstance(index: number): void {
    this.productionDeviceInstanceService.updateProductionDeviceInstance(this.getTableData()[index]).subscribe({
      next: (response: ProductionDeviceInstance) => {
        this.updateDeviceInstanceInData(response, index);
        this.spinnerService.deactivateSpinner();
        this.notificationService.displayNotificationToast('device-instance.production-device-instance-updated-successfully',
          NotificationMessageType.SUCCESS);
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private updateDeviceInstanceInData(item: ProductionDeviceInstance, index: number): void {
    this.deviceInstanceData[index] = item;
    if (this.selectedDeviceInstance) {
      this.refreshTable();
    }
  }

  public removeDeviceInstance(deviceInstanceId: string, index: number): void {
    this.spinnerService.activateSpinner();
    switch (this.className) {
      case 'DryerInstance':
        this.dryerService.removeDryerInstance(deviceInstanceId).subscribe(() => {
          this.removeDeviceInstanceFromData(index);
          this.dryerService.removeDryerInstanceFromDryerModelData(this.currentDeviceModel.id, deviceInstanceId);
          this.spinnerService.deactivateSpinner();
          this.notificationService.displayNotificationToast('device-instance.dryer-removed-successfully', NotificationMessageType.SUCCESS);
        }, (error) => {
          this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService);
        });
        break;
      case 'FurnaceInstance':
        this.furnaceService.removeFurnaceInstance(deviceInstanceId).subscribe(() => {
          this.removeDeviceInstanceFromData(index);
          this.furnaceService.removeFurnaceInstanceFromFurnaceModelData(this.currentDeviceModel.id, deviceInstanceId);
          this.spinnerService.deactivateSpinner();
          this.notificationService.displayNotificationToast('device-instance.furnace-removed-successfully',
            NotificationMessageType.SUCCESS);
        }, (error) => {
          this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService);
        });
        break;
      case 'MachineInstance':
        this.machineService.removeMachineInstance(deviceInstanceId).subscribe(() => {
          this.removeDeviceInstanceFromData(index);
          this.machineService.removeMachineInstanceFromMachineModel(this.currentDeviceModel.id, deviceInstanceId);
          this.spinnerService.deactivateSpinner();
          this.notificationService.displayNotificationToast('device-instance.machine-removed-successfully',
            NotificationMessageType.SUCCESS);
        }, (error) => {
          this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService);
        });
        break;
      case 'MixingMachineInstance':
        this.mixingMachineInstanceService.deleteMixingMachineInstance(deviceInstanceId).subscribe(() => {
          this.removeDeviceInstanceFromData(index);
          this.mixingMachineModelService.removeMixingMachineInstanceFromMixingMachineModelData(this.currentDeviceModel.id,
            deviceInstanceId);
          this.spinnerService.deactivateSpinner();
          this.notificationService.displayNotificationToast('device-instance.mixing-machine-removed-successfully',
            NotificationMessageType.SUCCESS);
        }, (error) => {
          this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService);
        });
        break;
    }

  }

  private removeDeviceInstanceFromData(rowIndex: number): void {
    this.deviceInstanceData.splice(rowIndex, 1);
  }

  public getAvailableStatusOptions(): SelectItem[] {
    return this.availableStatusOptions;
  }

  public getStatusByEnum(status: ProductionDeviceInstanceStatus): string {
    return this.translateService.translateEnum('device-instance.' + ProductionDeviceInstanceStatus[status]);
  }

  public toggleNewDeviceFlag(): void {
    if (!this.newDeviceFlag && this.currentRowIndex !== null) {
      this.deleteFormGroupForRow(this.currentRowIndex);
    }
    this.currentRowIndex = null;
    this.newDeviceFlag = !this.newDeviceFlag;
  }

  public onRowEditInit(rowIndex: number): void {
    this.currentRowIndex = rowIndex;
    super.onRowEditInit(rowIndex);
  }

  public addCreatedInstanceToData(deviceInstance: ProductionDeviceInstance | null): void {
    if (deviceInstance) {
      this.pageable.count++;
      if (this.pageable.count > this.pageable.pageSize) {
        this.pageable.pageSize++;
      }
      switch (this.className) {
        case 'DryerInstance':
          const dryerInstance = deviceInstance as DryerInstance;
          this.deviceInstanceData.push(dryerInstance);
          this.dryerService.addNewDryerInstanceToDryerModel(this.currentDeviceModel.id, dryerInstance);
          break;
        case 'FurnaceInstance':
          const furnaceInstance = deviceInstance as FurnaceInstance;
          this.deviceInstanceData.push(furnaceInstance);
          this.furnaceService.addNewInstanceToFurnaceModelData(this.currentDeviceModel.id, furnaceInstance);
          break;
        case 'MachineInstance':
          const machineInstance = deviceInstance as MachineInstance;
          this.deviceInstanceData.push(machineInstance);
          this.machineService.addNewMachineInstanceToMachineModel(this.currentDeviceModel.id, machineInstance);
          break;
        case 'MixingMachineInstance':
          const mixingMachineInstance = deviceInstance as MixingMachineInstance;
          this.deviceInstanceData.push(mixingMachineInstance);
          this.mixingMachineModelService.addNewMixingMachineInstanceToMixingMachineModelData(this.currentDeviceModel.id,
            mixingMachineInstance);
          break;
      }

    }
    this.toggleNewDeviceFlag();
  }

  public assignToWorkspaceInstance(deviceInstance: ProductionDeviceInstance): void {
    this.assignDeviceToWorkspaceInstanceEmitter.emit(deviceInstance);
  }

  public getAvailableGroupOptionsWithoutCurrentObject(group: ProductionDeviceGroup): ProductionDeviceGroup[] {
    return this.availableGroupOptions.filter(item => item.id !== group?.id);
  }

  public presentWorkloadTable(deviceInstanceId: string): void {
    this.deviceInstanceId = deviceInstanceId;
    this.showWorkloadTable = true;
  }

  public applyAssigningWorkspaceInstance(deviceInstance: ProductionDeviceInstance): void {
    this.objectToAssignWorkspaceInstance = {
      tabName: null,
      tableName: null,
      componentNameToNavigate: null,
      productionDeviceModel: this.currentDeviceModel,
      workspaceGroupType: null
    };
    this.selectedDeviceInstance = deviceInstance;
    this.toggleApplyAssigningWorkspaceInstanceFlag();
  }

  public toggleApplyAssigningWorkspaceInstanceFlag(): void {
    this.applyAssigningWorkspaceInstanceFlag = !this.applyAssigningWorkspaceInstanceFlag;
  }

  public assignWorkspaceInstanceToDeviceInstance(workspaceInstance: WorkspaceInstance): void {
    this.selectedDeviceInstance.workspaceInstance = workspaceInstance;
    this.toggleApplyAssigningWorkspaceInstanceFlag();
  }
}
