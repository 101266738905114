import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '../../core/translations/translate.service';
import {formatNumber} from '@angular/common';

@Pipe({
  name: 'localeNumber',
  pure: true
})
export class LocaleNumberPipe implements PipeTransform{

  private translateService = inject(TranslateService);

  public transform(value: number, ): any {
   return formatNumber(value, this.getLocalForCurrentLanguage(), '1.2-2');
  }

  private getLocalForCurrentLanguage(): string {
    return this.translateService.getCurrentLanguage()?.locale;
  }

}
