import {Directive, HostListener} from '@angular/core';
import {IgnoredKeyboardEventsService} from '../ignored-keyboard-events.service';
import {ConstanceValues} from '../../constance-values/constance-values';

@Directive({
  selector: '[appStringLengthInputValidation]'
})
export class StringLengthInputValidationDirective {

  @HostListener('keydown', ['$event'])
  public removeSignsOverLimit(e: KeyboardEvent): void {
    if (IgnoredKeyboardEventsService.shouldKeyboardEventBeIgnored(e)) {
      return;
    }
    if ((e.target as HTMLInputElement).value.length >= ConstanceValues.MAX_STRING_LENGTH) {
      e.preventDefault();
    }
  }
}
