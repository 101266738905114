<div class="global-flex global-justify__flex-end">
  <button
    type="button"
    class="global-button"
    (click)="closeView()"
  >{{ 'product-quality-catalog.return' }}
  </button>
</div>

<div class="product-quality-norm-form">
  <h2>{{ 'product-quality-catalog.create-product-quality-norm' | translate }}</h2>

  <div class="product-quality-norm-form__body">
    <div class="product-quality-norm-form__content">
      <ng-template [ngIf]="normTypeFieldValue == null && !updateModeApplied">
        <label style="color: black"
        >{{ 'product-quality-catalog.norm-type' | translate }}</label>
        <p-dropdown
          class="create-mold-type__select-dropdown"
          placeholder="{{'product-quality-catalog.select-norm-type' | translate}}"
          optionValue="value"
          [options]="normTypeEnumOptions"
          (onChange)="initFormValues()"
          [(ngModel)]="normTypeFieldValue">
          <ng-template
            let-item
            pTemplate="selectedItem"
          >{{ item.label | translate }}
          </ng-template>
          <ng-template
            let-item
            pTemplate="item"
          >{{ item.label | translate }}
          </ng-template>
        </p-dropdown>
      </ng-template>

      <ng-template [ngIf]="normTypeFieldValue != null">
          <span style="color: black; font-size: 1.5rem" *ngIf="normTypeFieldValue == ProductQualityNormType.STRENGTH_TEST"
          >{{ 'product-quality-catalog.you-are-creating-strength-norm-type'| translate }}</span>

        <span style="color: black; font-size: 1.5rem" *ngIf="normTypeFieldValue == ProductQualityNormType.THERMAL_EXPANSION"
          >{{ 'product-quality-catalog.you-are-creating-thermal-expansion-norm-type'| translate }}</span>

        <span style="color: black; font-size: 1.5rem" *ngIf="normTypeFieldValue == ProductQualityNormType.CHANGE_IN_LENGTH"
        >{{ 'product-quality-catalog.you-are-creating-change-in-length-norm-type'| translate }}</span>

        <button
          type="button"
          class="global-button"
          [disabled]="updateModeApplied"
          (click)="resetNormType()"
        >{{ 'product-quality-catalog.change-norm-type' | translate }}
        </button>

        <div class="form-details">

          <div class="form-details__container form-details__container--background-color">
            <form
              [formGroup]="formGroup">
              <ng-template [ngIf]="normTypeFieldValue === ProductQualityNormType.STRENGTH_TEST">
                <div class="form-details__field">
                  <label
                    for="temperaturePrefix"
                  >{{ 'product-quality-catalog.temperature-prefix' | translate }}</label>
                  <p-dropdown
                    id="temperaturePrefix"
                    class="create-mold-type__select-dropdown"
                    placeholder="{{'product-quality-catalog.select-prefix' | translate}}"
                    optionValue="value"
                    formControlName="temperaturePrefix"
                    [options]="temperaturePrefixEnumOptions">
                    <ng-template
                      let-item
                      pTemplate="selectedItem"
                    >{{ item.label | translate }}
                    </ng-template>
                    <ng-template
                      let-item
                      pTemplate="item"
                    >{{ item.label | translate }}
                    </ng-template>
                  </p-dropdown>
                  <span
                    [ngClass]="temperaturePrefixControl.invalid && (temperaturePrefixControl.dirty || temperaturePrefixControl.touched) ? 'visibility--true' : 'visibility--false'"
                    class="global-error-message"
                  >{{ 'common.field-required' | translate }}</span>
                </div>
                <div class="form-details__field">
                  <label
                    for="type"
                  >{{ 'product-quality-catalog.strength-norm-type' | translate }}</label>
                  <p-dropdown
                    id="type"
                    class="create-mold-type__select-dropdown"
                    placeholder="{{'product-quality-catalog.select-type' | translate}}"
                    optionValue="value"
                    formControlName="type"
                    [options]="strengthNormTypeEnumOptions">
                    <ng-template
                      let-item
                      pTemplate="selectedItem"
                    >{{ item.label | translate }}
                    </ng-template>
                    <ng-template
                      let-item
                      pTemplate="item"
                    >{{ item.label | translate }}
                    </ng-template>
                  </p-dropdown>
                  <span
                    [ngClass]="typeControl.invalid && (typeControl.dirty || typeControl.touched) ? 'visibility--true' : 'visibility--false'"
                    class="global-error-message"
                  >{{ 'common.field-required' | translate }}</span>
                </div>
              </ng-template>
              <ng-template [ngIf]="(normTypeFieldValue === ProductQualityNormType.THERMAL_EXPANSION ) ||
                                   (normTypeFieldValue === ProductQualityNormType.CHANGE_IN_LENGTH)">
                <div class="form-details__field">
                  <label
                    for="progressionType"
                  >{{ 'product-quality-catalog.progression-type' | translate }}</label>
                  <p-dropdown
                    id="progressionType"
                    class="create-mold-type__select-dropdown"
                    placeholder="{{'product-quality-catalog.select-type' | translate}}"
                    optionValue="value"
                    formControlName="progressionType"
                    [options]="progressionTypeEnumOptions">
                    <ng-template
                      let-item
                      pTemplate="selectedItem"
                    >{{ item.label | translate }}
                    </ng-template>
                    <ng-template
                      let-item
                      pTemplate="item"
                    >{{ item.label | translate }}
                    </ng-template>
                  </p-dropdown>
                  <span
                    [ngClass]="progressionTypeControl.invalid && (progressionTypeControl.dirty || progressionTypeControl.touched) ? 'visibility--true' : 'visibility--false'"
                    class="global-error-message"
                  >{{ 'common.field-required' | translate }}</span>
                </div>
                <div class="form-details__field">
                  <label
                    for="reversibilityType"
                  >{{ 'product-quality-catalog.reversibility-type' | translate }}</label>
                  <p-dropdown
                    id="reversibilityType"
                    class="create-mold-type__select-dropdown"
                    placeholder="{{'product-quality-catalog.select-type' | translate}}"
                    optionValue="value"
                    formControlName="reversibilityType"
                    [options]="reversibilityTypeEnumOptions">
                    <ng-template
                      let-item
                      pTemplate="selectedItem"
                    >{{ item.label | translate }}
                    </ng-template>
                    <ng-template
                      let-item
                      pTemplate="item"
                    >{{ item.label | translate }}
                    </ng-template>
                  </p-dropdown>
                  <span
                    [ngClass]="reversibilityTypeControl.invalid && (reversibilityTypeControl.dirty || reversibilityTypeControl.touched) ? 'visibility--true' : 'visibility--false'"
                    class="global-error-message"
                  >{{ 'common.field-required' | translate }}</span>
                </div>
              </ng-template>
              <div class="form-details__field">
                <label for="dinNorm">{{ 'product-quality-catalog.din-norm' | translate }}</label>
                <input
                  id="dinNorm"
                  type="text"
                  formControlName="dinNorm"
                  placeholder="{{'product-quality-catalog.din-norm' | translate}}">
                <span
                  [ngClass]="dinNormControl.invalid && (dinNormControl.dirty || dinNormControl.touched) ? 'visibility--true' : 'visibility--false'"
                  class="global-error-message"
                >{{ 'common.field-required' | translate }}</span>
              </div>

              <div class="form-details__field global-p-calendar-icon">
                <label
                  for="dateOfPerformingNormCheck"
                >{{ 'product-quality-catalog.date-of-performing-norm-check' | translate }}</label>
                <p-calendar
                  appendTo="body"
                  formControlName="dateOfPerformingNormCheck"
                  id="dateOfPerformingNormCheck"
                  dateFormat="dd.mm.yy"
                  [styleClass]="'global-width--100'"
                  [showButtonBar]="true"
                  [readonlyInput]="true"
                  [clearButtonStyleClass]="'display--false'"
                  [showIcon]="true"
                  [showOnFocus]="false"
                  placeholder="{{ 'product-quality-catalog.set-date' | translate }}"
                  (onSelect)="assignDate($event)">
                </p-calendar>
              </div>
              <div class="form-details__field">
                <label
                  for="additionalDescription">{{ 'product-quality-catalog.additional-description' | translate }}</label>
                <textarea
                  id="additionalDescription"
                  formControlName="additionalDescription"
                ></textarea>
              </div>

            </form>
          </div>

        </div>

        <ng-template [ngIf]="!showNormTestAtTemperatureForm">
          <div>
            <button
              type="button"
              class="global-button"
              (click)="startCreatingNormTestAtTemperature()"
            >{{ 'product-quality-catalog.add-norm-test-at-temperature' | translate }}
            </button>
            <app-norm-test-at-temperature-table
              [createProductQualityNormMode]="true"
              [normType]="normTypeFieldValue"
              [normTestAtTemperatureList]="normTestAtTemperatureList"
              (selectedNormTestAtTemperatureEmitter)="startEditingNormTestAtTemperature($event)"
            ></app-norm-test-at-temperature-table>
          </div>
        </ng-template>

        <ng-template [ngIf]="showNormTestAtTemperatureForm">
          <app-norm-test-at-temperature-form
            [normTestAtTemperature]="normTestAtTemperature"
            [normType]="normTypeFieldValue"
            (submitFormEmitter)="addNormTestAtTemperatureToList($event)"
            (closeViewEmitter)="toggleShowNormTestAtTemperatureForm()"
          ></app-norm-test-at-temperature-form>
        </ng-template>

        <div class="global-flex global-justify__center">
          <button
            type="submit"
            class="global-button"
            (click)="onSubmit()"
            [disabled]="formGroup.invalid || showNormTestAtTemperatureForm"
          >{{ 'common.submit' | translate }}
          </button>
        </div>

      </ng-template>
    </div>
  </div>
</div>
