<div class="additional-address-line-table" *ngIf="additionalAddressLineTypeList">
  <ng-container>
    <table
    class="global-width--100">
      <thead>
      <tr class="additional-address-line-table__headers">
        <th
          class="additional-address-line-table__headers--border">{{'customers.additional-address-line' | translate}}</th>
        <th
          class="additional-address-line-table__headers--border">{{'customers.additional-address-line-type' | translate}}</th>
        <th class="additional-address-line-table__headers--border">{{'common.action' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <ng-container
        *ngIf="getAdditionalAddressLines().length === 0; then noRecords; else records"></ng-container>
      <ng-template #noRecords>
        <tr class="additional-address-line-table__body">
          <td colspan="3" class="additional-address-line-table__body--border">
            <h2>{{'common.no-records-available' | translate}}</h2></td>
        </tr>
      </ng-template>
      <ng-template #records>
        <tr class="additional-address-line-table__body"
            *ngFor="let addressLine of getAdditionalAddressLines()">
          <td class="additional-address-line-table__body--border">{{addressLine.value}}</td>
          <td class="additional-address-line-table__body--border">{{returnTypeName(addressLine.type.id)}}</td>
          <td
            class="additional-address-line-table__body--border additional-address-line-table__body--padding global-text-align__center">
            <button pButton type="button" icon="pi pi-pencil"
                    class="ui-button-info additional-address-line-table__body--margin"
                    (click)="editLine(addressLine)"
                    data-cy="edit-line-button"></button>
            <button pButton type="button" icon="pi pi-trash"
                    class="global-background-color--red additional-address-line-table__body--margin"
                    (click)="removeLine(addressLine)"
                    data-cy="remove-line-button"></button>
          </td>
        </tr>
      </ng-template>
      </tbody>
    </table>
  </ng-container>

  <div class="additional-address-line-table__buttons">
    <button *ngIf="!editedLine"
            class="button button__submit-button button__submit-button--background-color button__submit-button--color"
            (click)="addNewLine()"
            data-cy="add-new-line-button">{{'common.add-new-line' | translate}}</button>
    <button *ngIf="editedLine && !addNewTypeFlag"
            class="button button__submit-button button__submit-button--background-color button__submit-button--color"
            (click)="addNewType()"
            data-cy="add-new-type-button">{{'common.add-new-type' | translate}}</button>

  </div>

  <div *ngIf="editedLine" class="additional-address-line-table__edit-container">
    <app-base-form *ngIf="!addNewTypeFlag" class="additional-address-line-table__edit-container__base-form"
                   [formFields]="formFields"
                   [customContainerStyle]="'additional-address-line-table__edit-container__base-form'"
                   [customRecordsStyle]="'additional-address-line-table__edit-container__base-form__records'"
                   [customSingleRecordStyle]="'additional-address-line-table__edit-container__base-form__records__single-record'"
                   [showCancelButton]="true"
                   [customDeclineButtonStyle]="'button button__submit-button button__submit-button--background-color' +
  ' button__submit-button--color additional-address-line-table__edit-container__base-form__button'"
                   [customConfirmButtonStyle]="'button button__submit-button button__submit-button--background-color' +
  ' button__submit-button--color additional-address-line-table__edit-container__base-form__button'"
                   (formCancelled)="cancelAdditionalAddressLineFields()"
                   (formSubmitted)="submitAdditionalAddressLine($event)"></app-base-form>


    <div *ngIf="addNewTypeFlag" class="additional-address-line-table__add-type-container">
      <app-base-form class="additional-address-line-table__edit-container__base-form"
                     [formFields]="newTypeFormFields"
                     [customContainerStyle]="'additional-address-line-table__edit-container__base-form'"
                     [customRecordsStyle]="'additional-address-line-table__edit-container__base-form__records'"
                     [customSingleRecordStyle]="'additional-address-line-table__edit-container__base-form__records__single-record'"
                     [showCancelButton]="true"
                     [customDeclineButtonStyle]="'button button__submit-button button__submit-button--background-color' +
  ' button__submit-button--color additional-address-line-table__edit-container__base-form__button'"
                     [customConfirmButtonStyle]="'button button__submit-button button__submit-button--background-color' +
  ' button__submit-button--color additional-address-line-table__edit-container__base-form__button'"
                     (formCancelled)="cancelAdditionalAddressLineTypeFields()"
                     (formSubmitted)="submitNewType($event)"
                     [insertedTemplate]="translationComponent"></app-base-form>
      <ng-template #translationComponent>
        <app-enum-translations #enumTranslationComponent>
        </app-enum-translations>
      </ng-template>

    </div>
  </div>
</div>
