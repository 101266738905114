<div class="global-flex global-align__items-center">
  <ng-template [ngIf]="columnOperationSignMap.get(fieldName)?.operationSign === OperationSign.RANGE_SIGN.toString() ">
    <app-range-operation-sign-form
      (submitEmitter)="rangeSignEmitter.emit($event)"
    ></app-range-operation-sign-form>
  </ng-template>
  <p-dropdown
    appendTo="body"
    class="global-test-dropdown global-padding__none global-width--auto global-height--2-5rem"
    [ngStyle]="{'height' : columnOperationSignMap.get(fieldName)?.operationSign === OperationSign.RANGE_SIGN.toString() ? '40px' : ''}"
    [dropdownIcon]="columnOperationSignMap.has(fieldName) ? columnOperationSignMap.get(fieldName).operationSignIconClass : 'fa-equal-sign'"
    [options]="operationSigns"
    (onChange)="operationSignEmitter.emit($event.value.toString())"
  ></p-dropdown>
</div>
