import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductType} from '../../../../core/sdk/model-producttype';
import {StoringUnitInstance} from '../../../../core/sdk/bighero-model';
import {ProductStoringUnitTypeService} from '../../../services/product-storing-unit-type.service';
import {NotificationService} from '../../../services/notification.service';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {Utility} from '../../../utilities/utility';

@Component({
  selector: 'app-product-storing-unit-edit',
  templateUrl: './product-storing-unit-edit.component.html',
})
export class ProductStoringUnitEditComponent implements OnInit {

  public storingUnitInstance: StoringUnitInstance;
  @Output() submitClicked: EventEmitter<StoringUnitInstance | null> = new EventEmitter<StoringUnitInstance | null>();
  @Input() productType: ProductType;

  constructor(private productStoringUnitTypeService: ProductStoringUnitTypeService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.storingUnitInstance = this.productType.storingUnitInstance;
  }

  public editProductTypeStoringUnitInstance(): void {
    this.productStoringUnitTypeService.updateStoringUnitInstance(this.storingUnitInstance).subscribe(returnedSKUInstance => {
      this.submitClicked.emit(returnedSKUInstance);
      this.notificationService.displayNotificationToast(
        'products.storing-unit-instance-updated-successfully', NotificationMessageType.SUCCESS);
    }, error => this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error),
      NotificationMessageType.ERROR));
  }

}
