import {Component, Input, OnInit} from '@angular/core';
import {OfferLockType} from '../../../core/sdk/enums-types';

@Component({
  selector: 'app-offer-lock-reason',
  templateUrl: './offer-lock-reason.component.html',
})
export class OfferLockReasonComponent implements OnInit {

  public warningMessage: string;
  @Input() public lockReason: number;

  public ngOnInit(): void {
    this.warningMessage = this.getWarningContentBasedOnLockReason(this.lockReason);
  }

  public getWarningContentBasedOnLockReason(offerReason: number): string {
    switch (offerReason) {
      case OfferLockType.ONE_OF_THE_PRODUCTS_MFC_WAS_UPDATED : {
        return 'offer.one-or-more-of-the-products-has-been-updated-please-recalculate-offer-for-the-most-recent-manufacturing-costs-data';
      }
    }
  }

}
