import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InventoryInStoringProduct, InventoryStoredProduct} from '../../../../core/sdk/model-inventory';
import {SpinnerService} from '../../../services/spinner.service';
import {NotificationService} from '../../../services/notification.service';
import {InventoryService} from '../../../services/warehouse/inventory.service';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {ConstanceValues} from '../../../constance-values/constance-values';

@Component({
  selector: 'app-mobile-inventory',
  templateUrl: './mobile-inventory.component.html',
})
export class MobileInventoryComponent implements OnInit {

  public formGroup: FormGroup;
  @Input() inventoryStoredProduct: InventoryStoredProduct | InventoryInStoringProduct;
  @Input() showReturnButton = false;
  @Output() actionEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private formBuilder: FormBuilder,
              private spinnerService: SpinnerService,
              private notificationService: NotificationService,
              private inventoryService: InventoryService) {
  }

  public ngOnInit(): void {
    this.initFormBuilder();
  }

  private initFormBuilder(): void {
    this.formGroup = this.formBuilder.group({
      countedQuantity: [0,
        [Validators.required, Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]]
    });
  }

  public get countedQuantityControl(): AbstractControl {
    return this.formGroup.get('countedQuantity');
  }

  public onSubmit(): void {
    this.spinnerService.activateSpinner();
    this.inventoryService.createInventoryDeclaration(this.inventoryStoredProduct.id, this.countedQuantityControl.value)
      .subscribe(() => {
        this.actionEmitter.emit();
        this.spinnerService.deactivateSpinner();
        this.notificationService.displayNotificationToast('mobile.declared-successfully',
          NotificationMessageType.SUCCESS);
      }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public onReturn(): void {
    this.actionEmitter.emit();
  }

}
