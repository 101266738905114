import {Pipe, PipeTransform} from '@angular/core';
import {Utility} from '../utilities/utility';

@Pipe({
  name: 'userFriendlyDateFormat',
  pure: true
})
export class UserFriendlyFormatDatePipe implements PipeTransform {

  public transform(timestamp: number): string {
    return Utility.transformTimestampToUserFriendlyStringDateTime(timestamp);
  }

}
