import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProcessStep} from '../../../core/sdk/bighero-model';
import {TaskService} from '../../services/production/task.service';
import {BasePrimengTableDirective} from '../../base-primeng-table/base-primeng-table.directive';
import {WorkerTask} from '../../../core/sdk/model-tasks';
import {NotificationService} from '../../services/notification.service';
import {TranslateService} from '../../../core/translations/translate.service';
import {SpinnerService} from '../../services/spinner.service';
import {PTableControlService} from '../../services/p-table-control.service';
import {BaseSubNavbarService} from '../base-sub-navbar/base-sub-navbar.service';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {TableRefreshService} from '../../services/table-refresh.service';
import {Utility} from '../../utilities/utility';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {TaskStatus} from '../../../core/sdk/enums-statuses';
import {EnumService} from '../../services/enum.service';
import {SelectItem} from 'primeng/api';
import {TranslationKeyEnum} from '../../enums/translation-key-enum';
import {UnplannedWorkerTaskService} from '../../services/production/unplanned-worker-task.service';
import {UnplannedPostProcessingStep, UnplannedWorkerTask} from '../../../core/sdk/model-unplanned';
import {HttpErrorResponse} from '@angular/common/http';
import {OperationSign} from '../../../core/sdk/model-enums';

@Component({
  selector: 'app-worker-tasks',
  templateUrl: './worker-tasks.component.html'
})
export class WorkerTasksComponent extends BasePrimengTableDirective<WorkerTask> implements OnInit, OnDestroy, AfterViewInit {

  public availableTasksStatus: SelectItem[];
  public creationDateFilterField: number;
  public startDateFilterField: number;
  public goalFilterField: number;
  public doneGoodQuantityFilterField: number;
  public doneFaultyQuantityFilterField: number;
  public workerNameFilterField: string;
  public progressStatusFilterField: number;

  @Input() public currentProcessStep: ProcessStep;
  @Input() public unplannedPostProcessingStep: UnplannedPostProcessingStep;

  constructor(private taskService: TaskService,
              private enumService: EnumService,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private unplannedWorkerTaskService: UnplannedWorkerTaskService,
              protected spinnerService: SpinnerService,
              protected pTableControlService: PTableControlService,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService);
    this.assignClassName('WorkerTask');
    this.assignTableName('workerTasksTable');
    this.pageable.pageSize = 5;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.availableTasksStatus = this.enumService.transformEnumOptionsForPrimeDropdown(TaskStatus, TranslationKeyEnum.PRODUCTION);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.columnOperationSignMap.clear();
    this.calendarSelectionModeMap.clear();
    this.rangeDateMap.clear();
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public doCount(): void {
    this.unplannedPostProcessingStep ? this.countUnplannedWorkerTasksForUnplannedPostProcessingStep() :
      this.countAllWorkerTasksForProcessStep();
  }

  private countAllWorkerTasksForProcessStep(): void {
    this.taskService.countAllWorkerTasksForProcessStep(this.searchCriteriaForClass, this.currentProcessStep.id)
      .subscribe(response => {
        this.pageable.count = response;
      });
  }

  private countUnplannedWorkerTasksForUnplannedPostProcessingStep(): void {
    this.unplannedWorkerTaskService.countUnplannedWorkerTasksForUnplannedPostProcessingStep(this.searchCriteriaForClass,
      this.unplannedPostProcessingStep.id).subscribe({
      next: (response: number) => this.pageable.count = response,
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public doLoad(): void {
    this.unplannedPostProcessingStep ? this.getUnplannedWorkerTasksForUnplannedPostProcessingStep() :
      this.getAllWorkerTasksForProcessStep();
  }

  private getAllWorkerTasksForProcessStep(): void {
    this.taskService.getAllWorkerTasksForProcessStep(this.searchCriteriaForClass, this.currentProcessStep.id,
      this.pageable.pageNumber, this.pageable.pageSize).subscribe(response => {
      this.assignNewValuesToTable(response);
      this.spinnerService.deactivateSpinner();
    });
  }

  private getUnplannedWorkerTasksForUnplannedPostProcessingStep(): void {
    this.unplannedWorkerTaskService.getUnplannedWorkerTasksForUnplannedPostProcessingStep(this.searchCriteriaForClass,
      this.unplannedPostProcessingStep.id, this.pageable.pageNumber, this.pageable.pageSize).subscribe({
      next: (response: UnplannedWorkerTask[]) => {
        this.assignNewValuesToTable(response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public getTableData(): WorkerTask[] {
    return this.data;
  }

  public assignNewValuesToTable(data: WorkerTask[]): void {
    this.data = data;
  }

  public convertTimestampToStringDate(timestamp: number): string {
    return Utility.convertTimestampToLocalDateString(timestamp);
  }

  public convertDurationObjectToString(seconds: number): string {
    return Utility.transformTimestampToUserFriendlyString(seconds, this.translateService);
  }

  public getTaskStatusEnumValue(taskStatus: TaskStatus): string {
    return this.enumService.getEnumValueWithModule(taskStatus, TaskStatus, 'production');
  }

  public finishWorkerTask(workerTask: WorkerTask): void {
    this.taskService.finishWorkerTaskWithLackOfAwaitingProducts(workerTask.id).subscribe(updateWorkerTask => {
      Utility.updateObjectInData(updateWorkerTask, this.getTableData());
      this.notificationService.displayNotificationToast('production.worker-task-finished-successfully', NotificationMessageType.SUCCESS);
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }

  public finishAllWorkerTasks(): void {
    this.taskService.finishWorkerTasksForProcessStepWithLackOfAwaitingProducts(this.currentProcessStep.id).subscribe(taskCounter => {
      this.refreshTable();
      this.notificationService.displayNotificationToast(
        'production.worker-tasks-finished-successfully', NotificationMessageType.SUCCESS, taskCounter.toString());
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }

  public isTaskOpenForFinish(workerTask: WorkerTask): boolean {
    return workerTask.workerSubmittedLackOfAwaitingProductsForTask && workerTask.progressStatus !== TaskStatus.DONE;
  }

  public getBooleanTranslation(workerSubmittedLackOfAwaitingProductsForTask: boolean): string {
    return this.translateService.translate('production.' + workerSubmittedLackOfAwaitingProductsForTask);
  }

  public resetFilters(): void {
    this.creationDateFilterField = null;
    this.startDateFilterField = null;
    this.goalFilterField = null;
    this.doneGoodQuantityFilterField = null;
    this.doneFaultyQuantityFilterField = null;
    this.workerNameFilterField = null;
    this.progressStatusFilterField = null;
    this.tableRefreshService.onTableRefresh(this.tableName, true);
  }

  protected readonly OperationSign = OperationSign;
}
