import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'calendarSelectionMode',
  pure: true
})
export class CalendarSelectionModePipe implements PipeTransform{

  public transform(flagValue: boolean): string {
    return flagValue ? 'range' : 'single';
  }

}
