import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appPlaceholderUserModalDirective]'
})
export class PlaceHolderUserModalDecisionDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }
}
