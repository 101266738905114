/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SearchCriteriaForClass} from './model-dto';
import {MixingSchema, MixingSchemaStep, MixingSchemaTemplate} from './model-mixingschema';
import {MixingMachineModel} from './model-productiondevices';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class MixingSchemaCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public assignMixingMachineModelsToMixingSchema(mixingMachineModelList: MixingMachineModel[], mixingSchemaID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchema>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'mixingSchemaID', value: mixingSchemaID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchema>();
    this.httpService.post('/api/mixing-schema/w/assign-mixing-machine-models', JsonScopedSerializer.stringify(mixingMachineModelList, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countMixingSchemas(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/mixing-schema/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countMixingSchemasWithSpecificMixingMachineModelAssigned(searchCriteria: SearchCriteriaForClass, mixingMachineModelID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'mixingMachineModelID', value: mixingMachineModelID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/mixing-schema/r/with-mixing-machine-model-count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: MixingSchema, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchema>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchema>();
    this.httpService.post('/api/mixing-schema/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/mixing-schema/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMixingSchemas(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchema[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchema[]>();
    this.httpService.post('/api/mixing-schema/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMixingSchemasWithSpecificMixingMachineModelAssigned(searchCriteria: SearchCriteriaForClass, mixingMachineModelID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchema[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'mixingMachineModelID', value: mixingMachineModelID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchema[]>();
    this.httpService.post('/api/mixing-schema/r/with-mixing-machine-model-get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<MixingSchema>  {
   const subject = new Subject<MixingSchema>();
    this.httpService.get('/api/mixing-schema/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public unassignMixingMachineModelsToMixingSchema(mixingMachineModelList: MixingMachineModel[], mixingSchemaID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchema>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'mixingSchemaID', value: mixingSchemaID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchema>();
    this.httpService.post('/api/mixing-schema/w/unassign-mixing-machine-models', JsonScopedSerializer.stringify(mixingMachineModelList, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: MixingSchema, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchema>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchema>();
    this.httpService.put('/api/mixing-schema/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class MixingSchemaStepCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: MixingSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchemaStep>();
    this.httpService.post('/api/mixing-schema-step/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateMixingSchemaStepList(mixingSchemaStepList: MixingSchemaStep[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchemaStep[]>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchemaStep[]>();
    this.httpService.post('/api/mixing-schema-step/w/list', JsonScopedSerializer.stringify(mixingSchemaStepList, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: MixingSchemaStep, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchemaStep>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchemaStep>();
    this.httpService.put('/api/mixing-schema-step/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class MixingSchemaTemplateCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createDuplicateFromMixingSchemaTemplate(templateID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchemaTemplate>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'templateID', value: templateID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchemaTemplate>();
    this.httpService.post('/api/mixing-schema-template/w/create-duplicate', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: MixingSchemaTemplate, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchemaTemplate>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchemaTemplate>();
    this.httpService.post('/api/mixing-schema-template/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/mixing-schema-template/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllTemplates(): Observable<MixingSchemaTemplate[]>  {
   const subject = new Subject<MixingSchemaTemplate[]>();
    this.httpService.get('/api/mixing-schema-template/r/all', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllTemplatesWithoutSteps(): Observable<MixingSchemaTemplate[]>  {
   const subject = new Subject<MixingSchemaTemplate[]>();
    this.httpService.get('/api/mixing-schema-template/r/all-small', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMixingSchemaTemplateWithDuplicatedStepList(templateID: string): Observable<MixingSchemaTemplate>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'templateID', value: templateID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<MixingSchemaTemplate>();
    this.httpService.get('/api/mixing-schema-template/r/import', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: MixingSchemaTemplate, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MixingSchemaTemplate>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MixingSchemaTemplate>();
    this.httpService.put('/api/mixing-schema-template/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

