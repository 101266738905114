import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {BasePrimengTableDirective} from '../../base-primeng-table/base-primeng-table.directive';
import {Workload} from '../../../core/sdk/bighero-model';
import {SpinnerService} from '../../services/spinner.service';
import {PTableControlService} from '../../services/p-table-control.service';
import {BaseSubNavbarService} from '../../base-components/base-sub-navbar/base-sub-navbar.service';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {TableRefreshService} from '../../services/table-refresh.service';
import {WorkloadService} from '../../services/production/workload.service';
import {Utility} from '../../utilities/utility';
import {Observable} from 'rxjs';
import {TranslateService} from '../../../core/translations/translate.service';
import {NotificationService} from '../../services/notification.service';
import {CustomFilterSortTableFieldEnum, FilteringTypeEnum} from '../../enums/enums';
import {SelectItem} from 'primeng/api';
import {ProductFilterService} from '../../services/product-filter.service';
import {FilterTableField, SortTableField} from '../../interfaces/interfaces';
import {CustomFilterSortTableFieldsService} from '../../services/custom-filter-sort-table-fields.service';

@Component({
  selector: 'app-workload-table',
  templateUrl: './workload-table.component.html',
})
export class WorkloadTableComponent extends BasePrimengTableDirective<Workload> implements OnInit,
  AfterViewInit, OnDestroy {

  public orderFilterInput = '';
  public productFilterInput = '';
  public qualityFilterInput = '';
  public workspaceInstanceFilterInput = '';
  public workerNameInstanceFilterInput = '';
  public showMachineInstanceInfo: boolean;
  public selectedFilteringOption: SelectItem;
  public filterFieldMap: Map<number, FilterTableField> = new Map<CustomFilterSortTableFieldEnum, FilterTableField>();
  public sortFieldMap: Map<number, SortTableField> = new Map<number, SortTableField>();

  @Input() instanceId: string;
  @Input() public showWorkspaceInstanceColumn: boolean = true;
  @Output() returnEmitter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('articleNumberInput') public articleNumberInput: ElementRef;
  @ViewChild('qualityInput') public qualityInput: ElementRef;
  @ViewChild('productNameInput') public productNameInput: ElementRef;

  constructor(private workloadService: WorkloadService,
              private translateService: TranslateService,
              private productFilterService: ProductFilterService,
              private customFilterSortTableFieldsService: CustomFilterSortTableFieldsService,
              protected spinnerService: SpinnerService,
              private notificationService: NotificationService,
              protected pTableControlService: PTableControlService,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService);
    this.assignClassName('Workload');
    this.assignTableName('workloadTable');
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.instanceId.includes('machine-instance') || this.instanceId.includes('machine-model')) {
      this.showMachineInstanceInfo = true;
    }
    this.setCustomSortingMap();
    this.setCustomFilteringMap();
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public assignNewValuesToTable(data: Workload[]): void {
    this.workloadService.setWorkloadData(data);
  }

  public getTableData(): Workload[] {
    return this.workloadService.getWorkloadData();
  }

  public doCount(): void {
    let loadedDataCounter: Observable<number>;
    switch (true) {
      case this.instanceId.includes('machine-instance'):
        loadedDataCounter = this.countMachineInstanceWorkload();
        this.showMachineInstanceInfo = true;
        break;
      case this.instanceId.includes('machine-model'):
        loadedDataCounter = this.countMachineModelWorkload();
        this.showMachineInstanceInfo = true;
        break;
      case this.instanceId.includes('workspace-group'):
        loadedDataCounter = this.countWorkspaceGroupWorkload();
        break;
      case this.instanceId.includes('workspace-instance'):
        loadedDataCounter = this.countWorkspaceInstanceWorkload();
        break;
    }
    loadedDataCounter.subscribe(response => {
      this.pageable.count = response;
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public doLoad(): void {
    let dataToLoad: Observable<Workload[]>;
    switch (true) {
      case this.instanceId.includes('machine-instance'):
        dataToLoad = this.loadMachineInstanceWorkload();
        break;
      case this.instanceId.includes('machine-model'):
        dataToLoad = this.loadMachineModelWorkload();
        break;
      case this.instanceId.includes('workspace-group'):
        dataToLoad = this.loadWorkspaceGroupWorkload();
        break;
      case this.instanceId.includes('workspace-instance'):
        dataToLoad = this.loadWorkspaceInstanceWorkload();
        break;
    }
    dataToLoad.subscribe(response => {
      this.assignNewValuesToTable(response);
      this.spinnerService.deactivateSpinner();
    }, error => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public toggleShowFilteringFlag(): void {
    this.showFilteringRow = !this.showFilteringRow;
  }

  public handleFiltering(): void {
    if (!this.searchCriteriaForClass.searchCriteriaList.isEmpty()) {
      this.tableRefreshService.onTableRefresh(this.tableName, true);
    }
    this.toggleShowFilteringFlag();
    this.clearFilteringFields();
  }

  public clearFilteringFields(): void {
    this.orderFilterInput = '';
    this.productFilterInput = '';
    this.qualityFilterInput = '';
    this.workspaceInstanceFilterInput = '';
    this.workerNameInstanceFilterInput = '';
  }

  public setSelectedFilteringOption(selectedOption: SelectItem): void {
    this.productFilterService.clearSearchDataIfNeeded(this.selectedFilteringOption, this.searchCriteriaForClass.searchCriteriaList,
      this.tableName, [this.productNameInput, this.qualityInput, this.articleNumberInput], this.tableRefreshService);
    this.selectedFilteringOption = selectedOption;
  }

  private setCustomFilteringMap(): void {
    this.filterFieldMap.set(CustomFilterSortTableFieldEnum.PRODUCT_TYPE,
      this.customFilterSortTableFieldsService.defineFilteringFields('name',
        'productType', 'ProductType'));
    this.filterFieldMap.set(CustomFilterSortTableFieldEnum.PRODUCT_FOR_SERVICE,
      this.customFilterSortTableFieldsService.defineFilteringFields('name',
        'productForService', 'ProductForService'));
  }

  private setCustomSortingMap(): void {
    this.sortFieldMap.set(CustomFilterSortTableFieldEnum.PRODUCT_TYPE,
      this.customFilterSortTableFieldsService.defineSortingFields('name', false,
        'productType', 'ProductType'));
    this.sortFieldMap.set(CustomFilterSortTableFieldEnum.PRODUCT_FOR_SERVICE,
      this.customFilterSortTableFieldsService.defineSortingFields('name', false,
        'productForService', 'ProductForService'));
  }

  private loadMachineModelWorkload(): Observable<Workload[]> {
    return this.workloadService.getMachineModelWorkload(this.searchCriteriaForClass, this.instanceId, this.pageable.pageNumber,
      this.pageable.pageSize);
  }

  private countMachineModelWorkload(): Observable<number> {
    return this.workloadService.countMachineModelWorkload(this.searchCriteriaForClass, this.instanceId);
  }

  private loadMachineInstanceWorkload(): Observable<Workload[]> {
    return this.workloadService.getMachineInstanceWorkload(this.searchCriteriaForClass, this.instanceId,
      this.pageable.pageNumber, this.pageable.pageSize);
  }

  private countMachineInstanceWorkload(): Observable<number> {
    return this.workloadService.countMachineInstanceWorkload(this.searchCriteriaForClass, this.instanceId);
  }

  private loadWorkspaceGroupWorkload(): Observable<Workload[]> {
    return this.workloadService.getWorkspaceGroupWorkload(this.searchCriteriaForClass, this.instanceId,
      this.pageable.pageNumber, this.pageable.pageSize);
  }

  private countWorkspaceGroupWorkload(): Observable<number> {
    return this.workloadService.countWorkspaceGroupWorkload(this.searchCriteriaForClass, this.instanceId);
  }

  private loadWorkspaceInstanceWorkload(): Observable<Workload[]> {
    return this.workloadService.getWorkspaceInstanceWorkload(this.searchCriteriaForClass, this.instanceId,
      this.pageable.pageNumber, this.pageable.pageSize);
  }

  private countWorkspaceInstanceWorkload(): Observable<number> {
    return this.workloadService.countWorkspaceInstanceWorkload(this.searchCriteriaForClass, this.instanceId);
  }

  public transformTimestampToUserFriendlyString(seconds: number): string {
    return Utility.transformTimestampToUserFriendlyString(seconds, this.translateService);
  }


  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public back(): void {
    this.returnEmitter.emit();
  }

  protected readonly FilteringTypeEnum = FilteringTypeEnum;
}
