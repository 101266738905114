import {Component, Input} from '@angular/core';
import {ServiceSchemaStep} from '../../../core/sdk/model-productforservice';
import {ServiceSchemaStepService} from '../../services/service-schema-step.service';

@Component({
  selector: 'app-service-schema-step-list',
  templateUrl: './service-schema-step-list.component.html',
})
export class ServiceSchemaStepListComponent {

  @Input() public indexColumnApplied = false;

  constructor(private serviceSchemaStepService: ServiceSchemaStepService) {
  }

  public getAllServiceSchemaSteps(): ServiceSchemaStep[] {
    return this.serviceSchemaStepService.getServiceSchemaData().filter(item => item.productForService !== null);
  }


}
