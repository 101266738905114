/**
 * The main component that renders single TabComponent
 * instances.
 */

import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ContentChildren,
  Input, OnInit,
  QueryList,
  ViewContainerRef
} from '@angular/core';
import {TabComponent} from '../tab/tab.component';
import {ComponentType} from '@angular/cdk/overlay';
import {PTableControlService} from '../../../services/p-table-control.service';
import {Utility} from '../../../utilities/utility';
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent implements OnInit, AfterContentInit, AfterViewInit {
  public tabsMap: Map<string, ComponentType<any>>;
  public isTerminalDevice: boolean;

  @Input('tabsMap') set setMap(map: Map<string, ComponentType<any>>) {
    if (map.size > 0) {
      this.tabsMap = map;
    }
  }

  @Input() defaultActiveTab: string;
  @Input() tableName: string;


  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  constructor(private resolver: ComponentFactoryResolver,
              private vc: ViewContainerRef,
              private pTableControlService: PTableControlService) {
  }

  public ngOnInit(): void {
    this.isTerminalDevice = Utility.isTerminalDevice();
  }


  public ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.defaultActiveTab) {
        this.selectTab(this.tabs.find(item => item.tabTitle === this.defaultActiveTab));
      } else {
        this.selectTab(this.tabs.first);
      }
    }, 1);
  }

  public ngAfterViewInit(): void {
    if (this.pTableControlService && this.pTableControlService?.isOneTimeSelectedTabActive(this.tableName)) {
      this.defaultActiveTab = this.pTableControlService?.getOneTimeSelectedTab(this.tableName);
    }
  }

  public titleIsTooOLong(value: string): boolean {
    return value.length > 30;
  }

  public selectTab(selectedTab: TabComponent): void {
    if (selectedTab) {
      this.tabs.toArray().forEach(tab => tab.active = false);
      selectedTab.active = true;
      this.vc.clear();
      this.createComponent(selectedTab);
    }
  }

  private createComponent(tab: TabComponent): void {
    const factory = this.resolver.resolveComponentFactory(this.tabsMap.get(tab.tabTitle));
    const instance = this.vc.createComponent(factory).instance;
    if (tab.dataBindMap) {
      tab.dataBindMap.forEach((value, key) => {
        instance[key] = value;
      });
    }
  }
}
