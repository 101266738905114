import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChangeInLengthNorm, NormTestAtTemperature, StrengthNorm, ThermalExpansionNorm} from '../../../../core/sdk/model-producttype';
import {ProductQualityNormService} from '../../../services/product-quality-norm.service';
import {SpinnerService} from '../../../services/spinner.service';
import {NotificationService} from '../../../services/notification.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ChartsService} from '../../../services/production/charts.service';
import {EChartsOption} from 'echarts';
import {Utility} from '../../../utilities/utility';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {
  ProductQualityNormProgressionType,
  ProductQualityNormReversibilityType,
  ProductQualityNormType,
  StrengthNormType
} from '../../../../core/sdk/enums-types';
import {TranslationKeyEnum} from '../../../enums/translation-key-enum';
import {StrengthNormTemperaturePrefix} from '../../../../core/sdk/model-enums';

@Component({
  selector: 'app-product-quality-norm-details',
  templateUrl: './product-quality-norm-details.component.html',
})
export class ProductQualityNormDetailsComponent implements OnInit {

  public normTestAtTemperatureList: NormTestAtTemperature[] = [];
  public lineChartForNormTestAtTemperature: EChartsOption;
  public showEditFormForNormTestAtTemperature: boolean = false;
  public selectedNormTestAtTemperature: NormTestAtTemperature;
  public showEditProductQualityNormForm: boolean = false;
  public normType: ProductQualityNormType;

  protected readonly ProductQualityNormType = ProductQualityNormType;
  protected readonly TranslationKeyEnum = TranslationKeyEnum;
  protected readonly StrengthNormType = StrengthNormType;
  protected readonly StrengthNormTemperaturePrefix = StrengthNormTemperaturePrefix;
  protected readonly ProductQualityNormReversibilityType = ProductQualityNormReversibilityType;
  protected readonly ProductQualityNormProgressionType = ProductQualityNormProgressionType;

  @Input() productQualityNorm: any;
  @Input() displayAsMainTable: boolean;
  @Output() public closeViewEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private productQualityNormService: ProductQualityNormService,
              private spinnerService: SpinnerService,
              private notificationService: NotificationService,
              private chartsService: ChartsService) {
  }

  public ngOnInit(): void {
    switch (this.productQualityNorm.id.split('/')[0]) {
      case 'strength-norm' : {
        this.normType = ProductQualityNormType.STRENGTH_TEST;
        this.loadStrengthNormData();
        break;
      }
      case 'thermal-expansion-norm' : {
        this.normType = ProductQualityNormType.THERMAL_EXPANSION;
        this.loadThermalExpansionNormData();
        break;
      }
      case 'change-in-length-norm' : {
        this.normType = ProductQualityNormType.CHANGE_IN_LENGTH;
        this.loadChangeInLengthNormData();
        break;
      }
    }
  }

  private loadStrengthNormData(): void {
    this.spinnerService.activateSpinner();
    this.productQualityNormService.getSingleStrengthNorm(this.productQualityNorm.id).subscribe({
      next: (response: StrengthNorm) => {
        this.loadChartWithPreparedData(response.normTestAtTemperatureList);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadThermalExpansionNormData(): void {
    this.spinnerService.activateSpinner();
    this.productQualityNormService.getSingleThermalExpansionNorm(this.productQualityNorm.id).subscribe({
      next: (response: ThermalExpansionNorm) => {
        this.loadChartWithPreparedData(response.normTestAtTemperatureList);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadChangeInLengthNormData(): void {
    this.spinnerService.activateSpinner();
    this.productQualityNormService.getSingleChangeInLengthNorm(this.productQualityNorm.id).subscribe({
      next: (response: ChangeInLengthNorm) => {
        this.loadChartWithPreparedData(response.normTestAtTemperatureList);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadChartWithPreparedData(normTestAtTemperatureList: NormTestAtTemperature[]): void {
    this.normTestAtTemperatureList = normTestAtTemperatureList
      .sort((a, b) => a.temperature - b.temperature);
    this.generateLineChartData();
  }

  private generateLineChartData(): void {
    const xAxisData: any[] = [];
    const seriesData: number[] = [];
    this.normTestAtTemperatureList.forEach(normTestAtTemperature => {
      xAxisData.push(normTestAtTemperature.value);
      seriesData.push(normTestAtTemperature.temperature);
    });
    this.lineChartForNormTestAtTemperature = this.chartsService.generateSimpleLineChartData(
      'products.force-at-temperature', xAxisData, seriesData, this.getXAxisChartName(), this.getXAxisUnit(), 'products.temperature', '[℃]');
  }
  private getXAxisChartName(): string {
     switch(this.normType) {
      case ProductQualityNormType.STRENGTH_TEST: {
        return 'products.force';
      }
      case ProductQualityNormType.THERMAL_EXPANSION: {
        return 'products.percentage';
      }
      case ProductQualityNormType.CHANGE_IN_LENGTH: {
        return 'products.percentage';
      }
    }
  }

  private getXAxisUnit(): string {
    switch(this.normType) {
      case ProductQualityNormType.STRENGTH_TEST: {
        return '[N/mm²]';
      }
      case ProductQualityNormType.THERMAL_EXPANSION: {
        return '[%]';
      }
      case ProductQualityNormType.CHANGE_IN_LENGTH: {
        return '[%]';
      }
    }
  }

  public showNormTestAtTemperatureForm(normTestAtTemperature: NormTestAtTemperature): void {
    this.selectedNormTestAtTemperature = normTestAtTemperature;
    this.toggleShowEditFormForNormTestAtTemperatureFlag();
  }

  public toggleShowEditFormForNormTestAtTemperatureFlag(): void {
    this.showEditFormForNormTestAtTemperature = !this.showEditFormForNormTestAtTemperature;
  }

  public updateNormTestAtTemperature(normTestAtTemperature: NormTestAtTemperature): void {
    this.spinnerService.activateSpinner();
    this.productQualityNormService.updateNormTestAtTemperature(normTestAtTemperature).subscribe({
      next: (response: NormTestAtTemperature) => {
        Utility.updateObjectInData(response, this.normTestAtTemperatureList);
        this.generateLineChartData();
        this.toggleShowEditFormForNormTestAtTemperatureFlag();
        this.notificationService.displayNotificationToast('products.norm-test-at-temperature-updated-successfully',
          NotificationMessageType.SUCCESS);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public toggleShowEditProductQualityNormForm(): void {
    this.showEditProductQualityNormForm = !this.showEditProductQualityNormForm;
  }

  public closeView(): void {
    this.toggleShowEditProductQualityNormForm();
  }

  public displayDateInProperFormat(timestamp: number): string {
    return Utility.transformTimestampToUserFriendlyStringDateTime(timestamp);
  }

  public displayDate(timestamp: number): string {
    return Utility.convertTimestampToLocalDateString(timestamp);
  }
}
