import {Component, Input, OnInit} from '@angular/core';
import {Utility} from '../../../utilities/utility';
import {TranslateService} from '../../../../core/translations/translate.service';
import {MixingSchemaStep} from '../../../../core/sdk/model-mixingschema';
import {MixingSchemaService} from '../../../services/production/mixing-schema.service';
import {MixingSchemaStepActionType} from '../../../../core/sdk/enums-types';
import {TranslationKeyEnum} from '../../../enums/translation-key-enum';
import {SchemaService} from '../../../services/schema.service';

@Component({
  selector: 'app-mixing-schema-step',
  templateUrl: './mixing-schema-step.component.html',
})
export class MixingSchemaStepComponent implements OnInit {

  public showMixingSchemaStepForm: boolean = false;
  @Input() public mixingSchemaStep: MixingSchemaStep;

  constructor(private schemaService: SchemaService,
              private translateService: TranslateService,
              protected mixingSchemaService: MixingSchemaService) {
  }

  public ngOnInit(): void {
    if (this.mixingSchemaService.someOfStepsIsOpen()) {
      this.toggleShowMixingSchemaStepForm();
    }
  }

  public changeIndexHigher(): void {
   this.mixingSchemaService.changeIndexHigher(this.mixingSchemaStep);
  }

  public changeIndexLower(): void {
    this.mixingSchemaService.changeIndexLower(this.mixingSchemaStep);
  }

  public removeMixingSchemaStep(): void {
    Utility.deleteObjectFromArray(this.mixingSchemaService.getMixingSchemaSteps(), this.mixingSchemaStep);
    this.reloadMixingSchemaSummarySteps();
  }

  public transformSecondsToDurationObject(timestamp: number): {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  } {
    return this.schemaService.transformSeconds(timestamp);
  }

  public getProperStepDurationValue(): any {
    return this.mixingSchemaStep.actionType !== MixingSchemaStepActionType.START_MIXING &&
      this.mixingSchemaStep.actionType !== MixingSchemaStepActionType.STOP_MIXING?
      this.transformDurationObjectToString(this.transformSecondsToDurationObject(this.mixingSchemaStep?.stepDuration?.seconds))
      : this.translateService.translate('production.lasts-negligibly-short');
  }


  public transformDurationObjectToString(duration: {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  }): string {
    return Utility.transformDurationObjectToUserFriendlyString(duration, this.translateService);
  }

  public editMixingSchemaStep(): void {
    this.toggleShowMixingSchemaStepForm();
    this.mixingSchemaService.tagMixingSchemaStepAsOpened(this.mixingSchemaStep.id);
  }

  public toggleShowMixingSchemaStepForm(): void {
    this.showMixingSchemaStepForm = !this.showMixingSchemaStepForm;
  }

  public reloadMixingSchemaSummarySteps(): void {
    this.schemaService.reloadSummaryStepsDuration(this.mixingSchemaService.getMixingSchemaSteps());
  }

  public updateMixingStepInData(mixingSchemaStep: MixingSchemaStep) {
    Utility.updateObjectInData(mixingSchemaStep, this.mixingSchemaService.getMixingSchemaSteps());
    this.mixingSchemaService.tagMixingSchemaStepAsClosed(mixingSchemaStep.id);
    this.reloadMixingSchemaSummarySteps();
    this.toggleShowMixingSchemaStepForm();
  }

  public closeFormView(): void {
    if (this.mixingSchemaService.isItNewStep(this.mixingSchemaStep)) {
      Utility.deleteObjectFromArray(this.mixingSchemaService.getMixingSchemaSteps(), this.mixingSchemaStep);
      this.reloadMixingSchemaSummarySteps();
    }
    this.mixingSchemaService.tagMixingSchemaStepAsClosed(this.mixingSchemaStep.id);
    this.toggleShowMixingSchemaStepForm();
  }

  protected readonly MixingSchemaStepActionType = MixingSchemaStepActionType;
  protected readonly TranslationKeyEnum = TranslationKeyEnum;
}
