import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductionSchemaStep, ProductionSchemaTemplate} from '../../core/sdk/model-productionschema';
import {FiringSchemaStep, FiringSchemaTemplate} from '../../core/sdk/model-firingschema';
import {ProductionSchemaService} from './production-schema.service';
import {FiringSchemaService} from './production/firing-schema.service';
import {MixingSchemaTemplateService} from './production/mixing-schema-template.service';
import {MixingSchemaStep, MixingSchemaTemplate} from '../../core/sdk/model-mixingschema';

@Injectable({
  providedIn: 'root'
})
export class SchemaTemplateService {

  constructor(private productionSchemaService: ProductionSchemaService,
              private firingSchemaService: FiringSchemaService,
              private mixingSchemaTemplateService: MixingSchemaTemplateService) {
  }

  public getAllTemplates(templateType: string): Observable<ProductionSchemaTemplate[] | FiringSchemaTemplate[] | MixingSchemaTemplate[]> {
    switch (true) {
      case templateType.includes('production'): {
        return this.productionSchemaService.getAllProductionSchemaTemplates();
      }
      case templateType.includes('firing'): {
        return this.firingSchemaService.getAllFiringSchemaTemplates();
      }
      case templateType.includes('mixing'): {
        return this.mixingSchemaTemplateService.getAllMixingSchemaTemplates();
      }
    }
  }

  public getEmptySchemaTemplate(templateType: string): ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate {
    switch (true) {
      case templateType.includes('production'): {
        return this.productionSchemaService.getEmptyProductionSchemaTemplate();
      }
      case templateType.includes('firing'): {
        return this.firingSchemaService.getEmptyFiringSchemaTemplate();
      }
      case templateType.includes('mixing'): {
        return this.mixingSchemaTemplateService.getEmptyMixingSchemaTemplate();
      }
    }
  }

  public getTemplateSteps(schemaTemplate: ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate):
    ProductionSchemaStep[] | FiringSchemaStep[] | MixingSchemaStep[] {
    return schemaTemplate.id.includes('firing') ? (schemaTemplate as FiringSchemaTemplate).firingSchemaStepList :
      schemaTemplate.id.includes('mixing') ? (schemaTemplate as MixingSchemaTemplate).mixingSchemaStepList :
        (schemaTemplate as ProductionSchemaTemplate).productionSchemaStepList;
  }

  public removeSchemaTemplate(schemaTemplateId: string): Observable<void> {
    switch (true) {
      case schemaTemplateId.includes('production'): {
        return this.productionSchemaService.removeProductionSchemaTemplate(schemaTemplateId);
      }
      case schemaTemplateId.includes('firing'): {
        return this.firingSchemaService.removeFiringSchemaTemplate(schemaTemplateId);
      }
      case schemaTemplateId.includes('mixing'): {
        return this.mixingSchemaTemplateService.removeMixingSchemaTemplate(schemaTemplateId);
      }
    }
  }

  public getAllTemplatesId(templateType: string): Observable<ProductionSchemaTemplate[] | FiringSchemaTemplate[] | MixingSchemaTemplate[]> {
    switch (true) {
      case templateType.includes('production'): {
        return this.productionSchemaService.getAllProductionSchemaTemplatesId();
      }
      case templateType.includes('firing'): {
        return this.firingSchemaService.getAllFiringSchemaTemplatesId();
      }
    }
  }

  public getCloneOfSchemaTemplate(schemaTemplateId: string): Observable<ProductionSchemaTemplate |
    FiringSchemaTemplate | MixingSchemaTemplate> {
    switch (true) {
      case schemaTemplateId.includes('production'): {
        return this.productionSchemaService.getCloneOfProductionSchemaTemplate(schemaTemplateId);
      }
      case schemaTemplateId.includes('firing'): {
        return this.firingSchemaService.getCloneOfFiringSchemaTemplate(schemaTemplateId);
      }
      case schemaTemplateId.includes('mixing'): {
        return this.mixingSchemaTemplateService.getCloneOfMixingSchemaTemplate(schemaTemplateId);
      }
    }
  }


}
