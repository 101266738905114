import {Directive, HostListener} from '@angular/core';
import {IgnoredKeyboardEventsService} from '../ignored-keyboard-events.service';

@Directive({
  selector: '[appIntegerInputValidation]'
})
export class IntegerInputValidationDirective {

  @HostListener('keydown', ['$event'])
  public doNotAllowIllegalSigns(e: KeyboardEvent): void {
    if (IgnoredKeyboardEventsService.shouldKeyboardEventBeIgnored(e)) {
      return;
    }
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }
}
