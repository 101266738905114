import {Injectable} from '@angular/core';
import {ProductionDeviceInstanceCtrl} from '../../../core/sdk/controllers-productiondevices';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {ProductionDeviceInstance} from '../../../core/sdk/model-productiondevices';
import {JsonScope} from '../../../core/sdk/jsonScope';

@Injectable({
  providedIn: 'root'
})
export class ProductionDeviceInstanceService {

  public productionDeviceInstanceData: ProductionDeviceInstance[];

  constructor(private productionDeviceInstanceCtrl: ProductionDeviceInstanceCtrl) {
  }

  public createProductionDeviceInstance(productionDeviceInstance: ProductionDeviceInstance): Observable<ProductionDeviceInstance> {
    return this.productionDeviceInstanceCtrl.createProductionDeviceInstance(productionDeviceInstance);
  }

  public updateProductionDeviceInstance(productionDeviceInstance: ProductionDeviceInstance): Observable<ProductionDeviceInstance> {
    return this.productionDeviceInstanceCtrl.updateProductionDeviceInstance(productionDeviceInstance,
      new JsonScope(false, ['production-device-model', 'production-device-group']));
  }

  getProductionDeviceInstances(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number)
    : Observable<ProductionDeviceInstance[]> {
    return this.productionDeviceInstanceCtrl.getProductionDeviceInstances(searchCriteria, pageNumber, pageSize);
  }

  countProductionDeviceInstances(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.productionDeviceInstanceCtrl.countProductionDeviceInstances(searchCriteria);
  }

  setWorkspaceGroupData(data: ProductionDeviceInstance[]): void {
    this.productionDeviceInstanceData = data;
  }

  getWorkspaceGroupData(): ProductionDeviceInstance[] {
    return this.productionDeviceInstanceData;
  }

}
