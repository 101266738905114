import {Injectable} from '@angular/core';
import {FilterTableField, SortTableField} from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class CustomFilterSortTableFieldsService {

  public defineFilteringFields(filteredFieldName: string, inClassPropertyName: string,
                               propertyClass?: string, deepFilterNestingList?: string[]): FilterTableField {
    return {
      filteredFieldName,
      inClassPropertyName,
      propertyClass,
      deepFilterNestingList,
    };
  }

  public defineSortingFields(columnName: string, isSortingStringNumeric?: boolean, inClassPropertyName?: string,
                             sortPropertyClass?: string, deepSortNestingList?: string[]): SortTableField {
    return {
      columnName,
      isSortingStringNumeric,
      inClassPropertyName,
      sortPropertyClass,
      deepSortNestingList
    };
  }

  public getDefinedFilteringFieldsForProductionBookedProductInProductType(): FilterTableField {
    return this.defineFilteringFields('name', 'productionBookedProduct',
      'ProductType', ['orderProduct', 'productType']);
  }

  public getDefinedFilteringFieldsForProductionBookedProductInProductForService(): FilterTableField {
    return this.defineFilteringFields('name', 'productionBookedProduct',
      'ProductForService', ['orderProduct', 'productForService']);
  }

  public getDefinedSortingFieldsForProductionBookedProductInProductType(): SortTableField {
    return this.defineSortingFields('name', false,
      'productionBookedProduct', 'ProductType',
      ['orderProduct', 'productType']);
  }

  public getDefinedSortingFieldsForProductionBookedProductInProductForService(): SortTableField {
    return this.defineSortingFields('name', false,
      'productionBookedProduct', 'ProductForService',
      ['orderProduct', 'productForService']);
  }

}
