import {Component, OnDestroy, OnInit} from '@angular/core';
import {FunctionalityService} from '../../shared/services/functionality.service';
import {TranslateService} from '../../core/translations/translate.service';
import {BaseSubNavbarService} from '../../shared/base-components/base-sub-navbar/base-sub-navbar.service';
import {BaseSubNavbarComponentFactoryService} from '../../shared/services/base-sub-navbar-component-factory.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
})
export class OffersComponent implements OnInit, OnDestroy {

  constructor(private functionalityService: FunctionalityService,
              private translateService: TranslateService,
              private baseSubNavbarService: BaseSubNavbarService,
              private baseSubNavbarComponentFactoryService: BaseSubNavbarComponentFactoryService) {
    this.functionalityService.flushSubFunctionality();
    this.baseSubNavbarComponentFactoryService.attachSubNavbar();
    this.baseSubNavbarService.redirectToPreferredFunctionalityIfAvailable(['offers-management']);
  }

  ngOnInit(): void {
    this.translateService.loadTranslationModule('offers');
  }

  ngOnDestroy(): void {
    this.baseSubNavbarComponentFactoryService.detachBaseSubNavbar();
  }
}
