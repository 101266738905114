import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {MixingSchemaStep} from '../../../../../core/sdk/model-mixingschema';
import {MixingSchemaService} from '../../../../services/production/mixing-schema.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Utility} from '../../../../utilities/utility';
import {ConstanceValues} from '../../../../constance-values/constance-values';
import {TranslationKeyEnum} from '../../../../enums/translation-key-enum';
import {EnumService} from '../../../../services/enum.service';
import {MixingSchemaStepActionType} from '../../../../../core/sdk/enums-types';
import {TranslateService} from '../../../../../core/translations/translate.service';

@Component({
  selector: 'app-mixing-schema-step-form',
  templateUrl: './mixing-schema-step-form.component.html',
})
export class MixingSchemaStepFormComponent implements OnInit, AfterViewInit, OnDestroy {

  public formGroup: FormGroup;
  public currentLocalValue: string;
  public showDurationControls: boolean = false;
  public showAssigningRawMaterialControls: boolean = false;
  public showWaterAdditionControl: boolean = false;
  public actionTypeSelectOption = this.enumService.transformEnumOptionsForPrimeDropdown(MixingSchemaStepActionType,
    TranslationKeyEnum.PRODUCTION);
  public isItNewStep = false;

  @Input() public mixingSchemaStep: MixingSchemaStep;
  @Output() public submitFormEmitter: EventEmitter<MixingSchemaStep> = new EventEmitter<MixingSchemaStep>();
  @Output() public closeFormEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private mixingSchemaService: MixingSchemaService,
              private formBuilder: FormBuilder,
              private enumService: EnumService,
              private  translateService:  TranslateService) {
  }

  public ngOnInit(): void {
    this.isItNewStep = this.mixingSchemaService.isItNewStep(this.mixingSchemaStep);
    this.initFormGroup();
    this.currentLocalValue = this.translateService.getCurrentLanguage()?.locale.split('_')[0];
  }

  public initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      index: [this.mixingSchemaStep.index,
        [Validators.required, Validators.min(ConstanceValues.MIN_POSITIVE_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
      actionType: [this.mixingSchemaStep.actionType ?? 0, [Validators.required]],
      description: [this.mixingSchemaStep.description ?? '',
        [Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]]
    });

    if (this.mixingSchemaStep.actionType) {
      this.addProperControlsBasedOnSelectedActionType(this.mixingSchemaStep.actionType);
    }
  }

  public ngAfterViewInit(): void {
    document.getElementById('stepForm').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }

  public submitForm(): void {
    this.mixingSchemaStep = {...this.mixingSchemaStep, ...this.formGroup.value};
    if (this.showDurationControls) {
      this.mixingSchemaStep.stepDuration = {
        nanos: 0,
        seconds: this.secondsControl.value + 60 * (this.minutesControl.value
          + 60 * (this.hoursControl.value + this.daysControl.value * 24) ?? 0)
      };
    } else {
      this.mixingSchemaStep.stepDuration = Utility.convertSecondsToDurationObject(0);
    }
    this.mixingSchemaService.tagMixingSchemaStepAsClosed(this.mixingSchemaStep.id);
    this.submitFormEmitter.emit(this.mixingSchemaStep);
  }

  public addControlsForAssigningRawMaterial(): void {
    this.formGroup.addControl('rawMaterial', this.formBuilder.control(this.mixingSchemaStep?.rawMaterial?.name ?? '',
      Validators.required));
    this.formGroup.addControl('tonnage', this.formBuilder.control(this.mixingSchemaStep.tonnage ?? 0,
      [Validators.required, Validators.max(ConstanceValues.MAX_NUMBER)]));
  }

  public addWaterAdditionControl(): void {
    this.formGroup.addControl('waterAdditionInLiters', this.formBuilder.control(
      this.mixingSchemaStep.waterAdditionInLiters ?? 0,
      [Validators.required, Validators.max(ConstanceValues.MAX_NUMBER)]));
  }

  public detachWaterAdditionControl(): void {
    this.formGroup.removeControl('waterAdditionInLiters');
  }

  public detachControlsForAssigningRawMaterial(): void {
    this.formGroup.removeControl('rawMaterial');
    this.formGroup.removeControl('tonnage');
  }

  public addProperControlsBasedOnSelectedActionType(selectedActionType: MixingSchemaStepActionType): void {
    switch (selectedActionType) {
      case MixingSchemaStepActionType.START_MIXING : {
        this.detachStepDurationControls();
        this.showDurationControls = false;
        this.detachControlsForAssigningRawMaterial();
        this.showAssigningRawMaterialControls = false;
        this.showWaterAdditionControl = false;
        this.detachWaterAdditionControl();
        break;
      }
      case MixingSchemaStepActionType.ADD_RAW_MATERIAL_INGREDIENT : {
        this.addStepDurationControls();
        this.showDurationControls = true;
        this.addControlsForAssigningRawMaterial();
        this.showAssigningRawMaterialControls = true;
        this.showWaterAdditionControl = false;
        this.detachWaterAdditionControl();
        break;
      }
      case  MixingSchemaStepActionType.MIXING_WITHOUT_INTERRUPTION : {
        this.addStepDurationControls();
        this.showDurationControls = true;
        this.detachControlsForAssigningRawMaterial();
        this.showAssigningRawMaterialControls = false;
        this.showWaterAdditionControl = false;
        this.detachWaterAdditionControl();
        break;
      }
      case  MixingSchemaStepActionType.PAUSE_MIXING : {
        this.addStepDurationControls();
        this.showDurationControls = true;
        this.detachControlsForAssigningRawMaterial();
        this.showAssigningRawMaterialControls = false;
        this.showWaterAdditionControl = false;
        this.detachWaterAdditionControl();
        break;
      }
      case  MixingSchemaStepActionType.STOP_MIXING : {
        this.detachStepDurationControls();
        this.showDurationControls = false;
        this.detachControlsForAssigningRawMaterial();
        this.showAssigningRawMaterialControls = false;
        this.showWaterAdditionControl = false;
        this.detachWaterAdditionControl();
        break;
      }
      case MixingSchemaStepActionType.ADD_WATER: {
        this.addWaterAdditionControl();
        this.showWaterAdditionControl = true;
        this.addStepDurationControls();
        this.showDurationControls = true;
        this.detachControlsForAssigningRawMaterial();
        this.showAssigningRawMaterialControls = false;
      }
    }
  }

  private addStepDurationControls(): void {
    const {
      days,
      hours,
      minutes,
      seconds
    } = Utility.convertSecondsToDurationObject(this.mixingSchemaStep.stepDuration.seconds);
    this.formGroup.addControl('days', this.formBuilder.control(days, Validators.max(999)));
    this.formGroup.addControl('hours', this.formBuilder.control(hours, Validators.max(ConstanceValues.MAX_NUMBER)));
    this.formGroup.addControl('minutes', this.formBuilder.control(minutes, Validators.max(ConstanceValues.MAX_NUMBER)));
    this.formGroup.addControl('seconds', this.formBuilder.control(seconds, Validators.max(ConstanceValues.MAX_NUMBER)));
  }

  private detachStepDurationControls(): void {
    this.formGroup.removeControl('days');
    this.formGroup.removeControl('hours');
    this.formGroup.removeControl('minutes');
    this.formGroup.removeControl('seconds');
  }

  public get indexControl(): AbstractControl {
    return this.formGroup.get('index');
  }

  public get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  public get daysControl(): AbstractControl {
    return this.formGroup.get('days');
  }

  public get hoursControl(): AbstractControl {
    return this.formGroup.get('hours');
  }

  public get minutesControl(): AbstractControl {
    return this.formGroup.get('minutes');
  }

  public get secondsControl(): AbstractControl {
    return this.formGroup.get('seconds');
  }

  public get actionTypeControl(): AbstractControl {
    return this.formGroup.get('actionType');
  }

  public get rawMaterialControl(): AbstractControl {
    return this.formGroup.get('rawMaterial');
  }

  public get tonnageControl(): AbstractControl {
    return this.formGroup.get('tonnage');
  }

  public get waterAdditionInLitersControl(): AbstractControl {
    return this.formGroup.get('waterAdditionInLiters');
  }

  public ngOnDestroy(): void {
    this.mixingSchemaService.clearListOfOpenedSteps();
  }

  public cancelForm(): void {
    this.closeFormEmitter.emit();
  }

}

