import {Injectable} from '@angular/core';
import {ProductionDeviceGroupCtrl, ProductionDeviceModelCtrl} from '../../../core/sdk/controllers-productiondevices';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {ProductionDeviceGroup, ProductionDeviceModel} from '../../../core/sdk/model-productiondevices';

@Injectable({
  providedIn: 'root'
})
export class ProductionDeviceService {

  constructor(private productionDeviceGroupCtrl: ProductionDeviceGroupCtrl,
              private productionDeviceModelCtrl: ProductionDeviceModelCtrl) {
  }


  /////////// GROUPS ////

  public countProductionDeviceGroups(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.productionDeviceGroupCtrl.countProductionDeviceGroups(searchCriteriaForClass);
  }

  public getProductionDeviceGroups(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number):
    Observable<ProductionDeviceGroup[]> {
    return this.productionDeviceGroupCtrl.getProductionDeviceGroups(searchCriteriaForClass, pageNumber, pageSize);
  }

  public findAllGroups(): Observable<ProductionDeviceGroup[]> {
    return this.productionDeviceGroupCtrl.findAll();
  }

  /// ALL_PRODUCTION_DEVICE_MODELS

  public countProductionDeviceModels(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.productionDeviceModelCtrl.countProductionDeviceModels(searchCriteriaForClass);
  }

  public getProductionDeviceModels(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number)
    : Observable<ProductionDeviceModel[]> {
    return this.productionDeviceModelCtrl.getProductionDeviceModels(searchCriteriaForClass, pageNumber, pageSize);
  }
}
