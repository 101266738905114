/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Declaration, DeclarationRequest, FaultyDeclarationReason} from './model-declarations';
import {DeclarationsSummaryDetails, SearchCriteriaForClass, TableDataJoiner} from './model-dto';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class DeclarationCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countDeclarationsForProcessStep(searchCriteria: SearchCriteriaForClass, processStepId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'processStepId', value: processStepId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/declaration/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countDeclarationsForSupervision(): Observable<number>  {
   const subject = new Subject<number>();
    this.httpService.get('/api/declaration/r/count-for-supervision', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createDeclarationForBurningProduct(burningProductID: string, burningTaskID: string, declaration: Declaration, jsonScope: JsonScope = new JsonScope(false, [])): Observable<TableDataJoiner>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'burningProductID', value: burningProductID});
  
    queryParamsList.push({name: 'burningTaskID', value: burningTaskID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<TableDataJoiner>();
    this.httpService.post('/api/declaration/w/burning-product-create', JsonScopedSerializer.stringify(declaration, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createDeclarationForWorkerTask(workerTaskID: string, declaration: Declaration, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Declaration>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'workerTaskID', value: workerTaskID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Declaration>();
    this.httpService.post('/api/declaration/w/worker-task-create', JsonScopedSerializer.stringify(declaration, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/declaration/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getDeclarationsForProcessStep(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, processStepId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Declaration[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    queryParamsList.push({name: 'processStepId', value: processStepId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Declaration[]>();
    this.httpService.post('/api/declaration/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getDeclarationsForSupervision(): Observable<Declaration[]>  {
   const subject = new Subject<Declaration[]>();
    this.httpService.get('/api/declaration/r/get-for-supervision', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getDeclarationsSummaryDetails(processStepId: string): Observable<DeclarationsSummaryDetails>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'processStepId', value: processStepId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<DeclarationsSummaryDetails>();
    this.httpService.get('/api/declaration/r/get-summary', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: Declaration, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Declaration>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Declaration>();
    this.httpService.put('/api/declaration/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class DeclarationRequestCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countRequestDeclarations(): Observable<number>  {
   const subject = new Subject<number>();
    this.httpService.get('/api/declaration-request/r/count', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: DeclarationRequest, jsonScope: JsonScope = new JsonScope(false, [])): Observable<DeclarationRequest>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<DeclarationRequest>();
    this.httpService.post('/api/declaration-request/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/declaration-request/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getRequestDeclarations(): Observable<DeclarationRequest[]>  {
   const subject = new Subject<DeclarationRequest[]>();
    this.httpService.get('/api/declaration-request/r/get', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public requestDeclarations(processStepID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'processStepID', value: processStepID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/declaration-request/w/request-new', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: DeclarationRequest, jsonScope: JsonScope = new JsonScope(false, [])): Observable<DeclarationRequest>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<DeclarationRequest>();
    this.httpService.put('/api/declaration-request/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class FaultyDeclarationReasonCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: FaultyDeclarationReason, jsonScope: JsonScope = new JsonScope(false, [])): Observable<FaultyDeclarationReason>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<FaultyDeclarationReason>();
    this.httpService.post('/api/faulty-declaration-reason/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/faulty-declaration-reason/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllDefaultReasons(): Observable<FaultyDeclarationReason[]>  {
   const subject = new Subject<FaultyDeclarationReason[]>();
    this.httpService.get('/api/faulty-declaration-reason/r/get-all-default', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: FaultyDeclarationReason, jsonScope: JsonScope = new JsonScope(false, [])): Observable<FaultyDeclarationReason>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<FaultyDeclarationReason>();
    this.httpService.put('/api/faulty-declaration-reason/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

