<div
  class="active-processes-management">
  <div class="active-processes-management__dropdown-container">
    <h2
      class="active-processes-management__main-title"
    >{{'products.filter-via-specific-norm-type'| translate}}</h2>
    <p-dropdown
      name="filtering-options"
      class="global-input"
      optionLabel="label"
      placeholder="{{'products.select-norm-type' | translate}}"
      [(ngModel)]="test"
      [options]="getAvailableFilteringOptions()"
      (onChange)="handleFilteringOptionChange($event)">
      <ng-template
        let-item
        pTemplate="selectedItem">
        {{item.label | translate}}
      </ng-template>
      <ng-template
        let-item
        pTemplate="item">
        {{item.label | translate}}
      </ng-template>
    </p-dropdown>
  </div>
</div>
