<div
  class="form-details">
  <div
    class="form-details__container form-details__container--background-color"
    data-cy="storing-unit-div">
    <form
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit()">
      <div
        class="form-details__field">
        <label for="piecesCapacity">{{'products.pieces-capacity' | translate}}</label>
        <input
          id="piecesCapacity"
          type="number"
          appIntegerInputValidation
          formControlName="piecesCapacity"
          placeholder="{{'products.pieces-capacity' | translate}}">
        <span
          [ngClass]="piecesCapacityControl.invalid && (piecesCapacityControl.dirty || piecesCapacityControl.touched) ? 'visibility--true' : 'visibility--false'"
          class="global-error-message"
        >{{'common.field-required' | translate}}</span>
      </div>
      <div
        formGroupName="type"
        class="form-details__field">
        <label for="name">{{'products.storing-unit-type-name' | translate}}</label>
        <input
          id="name"
          type="text"
          formControlName="name"
          class="global-input--inactive"
          placeholder="{{'products.storing-unit-type-name' | translate}}">
      </div>
      <div
        formGroupName="type"
        class="form-details__field">
        <label for="type">{{'products.storing-unit-type' | translate}}</label>
        <p-dropdown
          *ngIf="storingUnitTypeOptions"
          id="type"
          formControlName="type"
          optionValue="value"
          class="user-role__action-container__add-role__buttons__select  global-input--inactive"
          placeholder="{{'products.storing-unit-type' | translate}}"
          [options]="storingUnitTypeOptions">
        </p-dropdown>
      </div>
      <div
        formGroupName="type"
        class="form-details__field">
        <label for="width">{{'products.storing-unit-type-width' | translate}}</label>
        <p-inputNumber
          clas
          id="width"
          mode="decimal"
          [locale]="currentLocalValue"
          [minFractionDigits]="2"
          formControlName="width"
          class="global-input--inactive global-no-border-and-padding"
          placeholder="{{'products.storing-unit-type-width' | translate}}">
        </p-inputNumber>
      </div>
      <div
        formGroupName="type"
        class="form-details__field">
        <label for="height">{{'products.storing-unit-type-height' | translate}}</label>
        <p-inputNumber
          id="height"
          mode="decimal"
          [locale]="currentLocalValue"
          [minFractionDigits]="2"
          formControlName="height"
          class="global-input--inactive global-no-border-and-padding"
          placeholder="{{'products.storing-unit-type-height' | translate}}">
        </p-inputNumber>
      </div>
      <div
        formGroupName="type"
        class="form-details__field">
        <label for="nettoWeight">{{'products.storing-unit-type-netto-weight' | translate}}</label>
        <p-inputNumber
          id="nettoWeight"
          mode="decimal"
          [locale]="currentLocalValue"
          [minFractionDigits]="2"
          formControlName="nettoWeight"
          class="global-input--inactive global-no-border-and-padding"
          placeholder="{{'products.storing-unit-type-netto-weight' | translate}}">
        </p-inputNumber>
      </div>
      <div class="global-flex global-justify__center">
        <button
          type="submit"
          class="common-button"
          [disabled]="formGroup.invalid"
        >{{'common.submit' | translate}}</button>
      </div>
    </form>
  </div>

</div>


