import {Directive, HostListener} from '@angular/core';
import {IgnoredKeyboardEventsService} from '../ignored-keyboard-events.service';

@Directive({
  selector: '[appDoubleInputValidationDirective]'
})
export class DoubleInputValidationDirective {

  @HostListener('keydown', ['$event'])
  public doNotAllowIllegalSigns(e: KeyboardEvent): void {
    if (IgnoredKeyboardEventsService.shouldKeyboardEventBeIgnored(e)) {
      return;
    }
    if ((e.key === ' ' ||  e.key !== '.') && isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }
}
