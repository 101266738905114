<div
  class="global-flex global-justify__flex-end">
  <button
    type="button"
    class="common-button"
    (click)="closeComponent()"
  >{{'common.return' | translate}}</button>
</div>

<h3 class="global-color--white">{{('production.workspace-instances-for' | translate) + ' ' + selectedWorkspaceGroup.name}}</h3>

<p-table
  class="main-table-header"
  responsiveLayout="scroll"
  rowExpandMode="single"
  dataKey="id"
  [value]="getTableData()"
  (onPage)="paginate($event)"
  [paginator]="!shouldExportBeApplied"
  [lazy]="true"
  [rowHover]="true"
  [(first)]="pageable.pageNumber"
  [rows]="pageable.pageSize"
  [totalRecords]="pageable.count">

  <ng-template pTemplate="header">
    <tr>
      <th>{{'production.workspace-id' | translate}}</th>
      <th>{{'production.location' | translate}}</th>
      <th *ngIf="showAssignButton"
      >{{'common.action' | translate}}</th>
    </tr>

  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td
      [colSpan]="showAssignButton ? 3 : 2">
        {{'common.no-records-available' | translate}}
      </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-workspaceInstance
    class="global-pointer">
    <tr
      [pRowToggler]="workspaceInstance">
      <td>{{workspaceInstance.shorthand}}</td>
      <td>{{workspaceInstance.location}}</td>
      <td
        *ngIf="showAssignButton">
        <button
          type="button"
          class="common-button"
          (click)="emitChosenWorkspaceInstanceToAssign(workspaceInstance)"
        >{{'production.assign-instance'| translate}}</button>
      </td>
    </tr>
  </ng-template>
</p-table>
