<div
  class="global-flex global-flex-direction__column global-align__items-center">
  <label
    class="global-font-size__larger">
    {{('production.label-for' | translate) + ': ' + (this.objectNameToDisplay| translate)}}
  </label>
  <ngx-barcode
    [bc-value]="this.barcodeValue"
    [bc-element-type]="'canvas'"
    [bc-display-value]="true"></ngx-barcode>
</div>


