import {ElementRef, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {SelectItem} from 'primeng/api';
import {SearchCriteria} from '../../core/sdk/model-dto';
import {TableRefreshService} from './table-refresh.service';

@Injectable({
  providedIn: 'root'
})
export class ProductFilterService {
  private source: Subject<SelectItem> = new Subject<SelectItem>();

  public content$ = this.source.asObservable();

  public loadData(data: SelectItem): void {
    this.source.next(data);
  }

  public clearSearchDataIfNeeded(selectedFilteringOption: SelectItem, searchCriteriaList: SearchCriteria[],
                                 tableName: string, searchElementRefList: ElementRef[], tableRefreshService: TableRefreshService): void {
    if (selectedFilteringOption && !searchCriteriaList.isEmpty()) {
      tableRefreshService.onTableRefresh(tableName, true);
      searchElementRefList.forEach(elementRef => elementRef.nativeElement.value = null);
    }
  }
}
