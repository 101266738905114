import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {CrossFunctionalService} from '../../services/cross-functional.service';
import {BasePrimengTableDirective} from '../../base-primeng-table/base-primeng-table.directive';
import {Address} from '../../../core/sdk/model-address';
import {SpinnerService} from '../../services/spinner.service';
import {SearchCriteriaService} from '../../../core/search-criteria.service';
import {TableRefreshService} from '../../services/table-refresh.service';
import {CustomerService} from '../../services/customer.service';
import {DeliveryTerm, PaymentTerm} from '../../../core/sdk/model-enumentity';
import {Customer} from '../../../core/sdk/model-customer';
import {Offer} from '../../../core/sdk/model-offer';
import {HttpErrorResponse} from '@angular/common/http';
import {OfferService} from '../../services/offer.service';
import {NotificationService} from '../../services/notification.service';
import {PTableControlService} from '../../services/p-table-control.service';
import {TranslateService} from '../../../core/translations/translate.service';
import {Router} from '@angular/router';
import {BaseSubNavbarService} from '../base-sub-navbar/base-sub-navbar.service';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {OperationSign} from '../../../core/sdk/model-enums';

@Component({
  selector: 'app-assign-customer-table',
  templateUrl: './assign-customer-table.component.html',
})
export class AssignCustomerTableComponent extends BasePrimengTableDirective<Address> implements OnInit, AfterViewInit, OnDestroy {
  public customerList: Customer[];
  public deliveryTerms: DeliveryTerm[];
  public paymentTerms: PaymentTerm[];

  public companyNameFilterField: string;
  public datevIdFilterField: string;
  public taxIdFilterField: string;
  public salesRepresentativeFilterField: string;

  public isCrossFunctionalActive = false;
  public offer: Offer;

  @Output() submitCustomerEmitter: EventEmitter<Customer> = new EventEmitter<Customer>();

  constructor(private crossFunctionalService: CrossFunctionalService,
              private customerService: CustomerService,
              private offerService: OfferService,
              private translateService: TranslateService,
              private notificationService: NotificationService,
              private router: Router,
              protected pTableControlService: PTableControlService,
              protected spinnerService: SpinnerService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService,
              protected baseSubNavbarService: BaseSubNavbarService) {
    super(searchCriteriaService, tableRefreshService, spinnerService);
    this.assignClassName('Customer');
    this.assignTableName('customerForOrderTable');
    this.displayFilteringButtons = false;
    this.isCrossFunctionalActive = this.crossFunctionalService.isCrossFunctionalActive('offerToAssignCustomer');
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.loadTerms();
    this.showFilteringRow = true;
    if (this.isCrossFunctionalActive) {
      this.offer = this.crossFunctionalService.getGatheredData('offerToAssignCustomer');
    }
  }

  public loadTerms(): void {
    this.customerService.getDeliveryTerms().subscribe(response => {
      this.deliveryTerms = response;
    });
    this.customerService.getPaymentTerms().subscribe(response => {
      this.paymentTerms = response;
    });
  }

  public doCount(): void {
    this.customerService.countCustomers(this.searchCriteriaForClass).subscribe(response => {
      this.pageable.count = response;
    });
  }

  public doLoad(): void {
    this.customerService.getCustomers(this.searchCriteriaForClass, this.pageable.pageNumber, this.pageable.pageSize).subscribe(response => {
      this.customerList = response;
      this.spinnerService.deactivateSpinner();
    });
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public getTableData(): Customer[] {
    return this.customerList;
  }

  public onSubmit(customer: Customer): void {
    if (this.isCrossFunctionalActive) {
      this.cloneOffer(customer);
    } else {
      this.submitCustomerEmitter.emit(customer);
    }
  }

  public cloneOffer(customer: Customer): void {
    this.spinnerService.activateSpinner();
    this.offerService.cloneOffer(customer, this.offer.id).subscribe({
      next: (response: Offer) => {
        this.notificationService.displayNotificationToast('offers.offer-cloned-successfully',
          NotificationMessageType.SUCCESS);
        this.returnToOffers(response);
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public returnToOffers(offer: Offer): void {
    this.pTableControlService.pushExpandedRowToMap('offerTable', offer.id);
    this.pTableControlService.setOneTimeSelectedTab('offerTable',
      this.translateService.translate('offers.offer') + ' ' + offer.shorthand);
    this.router.navigate(['bh/offers/offers-management']);
  }

  public getTranslationOfDeliveryTerm(deliveryTerm: DeliveryTerm): string {
    return this.deliveryTerms.find(item => item.id === deliveryTerm.id).translations[0].translationValue;
  }

  public getTranslationOfPaymentTerm(paymentTerm: PaymentTerm): string {
    return this.paymentTerms.find(item => item.id === paymentTerm.id).translations[0].translationValue;
  }

  public resetFilters(): void {
    this.companyNameFilterField = null;
    this.datevIdFilterField = null;
    this.taxIdFilterField = null;
    this.salesRepresentativeFilterField = null;
    this.tableRefreshService.onTableRefresh(this.tableName, true);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.isCrossFunctionalActive) {
      this.crossFunctionalService.flushData('offerToAssignCustomer');
    }
    this.columnOperationSignMap.clear();
  }

  protected readonly OperationSign = OperationSign;
}
