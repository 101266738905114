import {Component, Input, OnChanges} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-secured-image',
  template: ` <img [src]="dataUrl$|async" alt="secured-image" [ngClass]="class"/> `
})
export class SecuredImageComponent implements OnChanges {

  constructor(private httpClient: HttpClient,
              private domSanitizer: DomSanitizer) {
  }

  @Input() public class: string;
  @Input() private src: string;
  // @ts-ignore
  private src$ = new BehaviorSubject(this.src);


  dataUrl$ = this.src$.pipe(switchMap(url => this.loadImage(url)));

  ngOnChanges(): void {
    this.src$.next(this.src);
  }

  private loadImage(url: string): Observable<any> {
    return this.httpClient
      .get(url, {responseType: 'blob'})
      // pass the url through the domSanitizer so angular knows he can parse it
      .pipe(map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))));
  }
}
