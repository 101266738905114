<div
  class="request-location-mark-list__body__location-mark-container"
  (click)="locationMarkEmitter.emit(locationMark)">
  <div class="request-location-mark-list__body__location-mark-container__info">
    <span
    >{{ ('mobile.storage-area' | translate) + ': ' }}</span>
    <span
      class="request-location-mark-list__body__location-mark-container__info__value"
    >{{ locationMark.storageArea?.name }}</span>
    <span
    >{{ ('mobile.storage-area-type' | translate) + ': ' }}</span>
    <span
      class="request-location-mark-list__body__location-mark-container__info__value"
    >{{ (locationMark.storageArea?.type | status: StorageAreaType : 'mobile') | translate }}</span>
    <span
    >{{ ('mobile.section' | translate) + ': ' }}</span>
    <span
      class="request-location-mark-list__body__location-mark-container__info__value"
    >{{ locationMark.storageArea?.section?.name }}</span>
    <span
    >{{ ('mobile.warehouse' | translate) + ': ' }}</span>
    <span
      class="request-location-mark-list__body__location-mark-container__info__value"
    >{{ locationMark.storageArea?.section.warehouse?.name }}</span>
    <span
    >{{ ('mobile.quantity' | translate) + ': ' }}</span>
    <span
      class="request-location-mark-list__body__location-mark-container__info__value"
    >{{ locationMark.quantity }}</span>
  </div>
</div>
