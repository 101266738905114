import {Injectable} from '@angular/core';
import {MixingMachineModelCtrl} from '../../core/sdk/controllers-productiondevices';
import {MixingMachineInstance, MixingMachineModel} from '../../core/sdk/model-productiondevices';
import {Utility} from '../utilities/utility';
import {SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MixingMachineModelService {

  public mixingMachineModelData: MixingMachineModel[];

  constructor(private mixingMachineModelCtrl: MixingMachineModelCtrl) {
  }

  public setMixingMachineModelData(data: MixingMachineModel[]): void {
    this.mixingMachineModelData = data;
  }

  public getMixingMachineModelData(): MixingMachineModel[] {
    return this.mixingMachineModelData;
  }

  public countMixingMachineModels(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.mixingMachineModelCtrl.countMixingMachineModels(searchCriteriaForClass);
  }

  public getMixingMachineModels(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number)
    : Observable<MixingMachineModel[]> {
    return this.mixingMachineModelCtrl.getMixingMachineModels(searchCriteriaForClass, pageNumber, pageSize);
  }

  public getMixingMachineModel(machineId: string): Observable<MixingMachineModel> {
    return this.mixingMachineModelCtrl.getObject(Utility.getObjectId(machineId));
  }

  // Instances

  public removeMixingMachineInstanceFromMixingMachineModelData(mixingMachineModelId: string, mixingMachineInstanceId: string): void {
    for (const index in this.mixingMachineModelData) {
      if (this.mixingMachineModelData[index].id === mixingMachineModelId) {
        Utility.deleteObjectFromArrayById(this.mixingMachineModelData[index].mixingMachineInstanceList, mixingMachineInstanceId);
        break;
      }
    }
  }

  public addNewMixingMachineInstanceToMixingMachineModelData(mixingMachineModelId: string, mixingMachineInstance: MixingMachineInstance)
    : void {
    for (const index in this.mixingMachineModelData) {
      if (this.mixingMachineModelData[index].id === mixingMachineModelId) {
        this.mixingMachineModelData[index].mixingMachineInstanceList.push(mixingMachineInstance);
        break;
      }
    }
  }

}
