/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {Functionality, GlobalApplicationConfig, Person, WorkspaceGroup, WorkspaceInstance} from './bighero-model';
import {WebsocketMessageType} from './enums-types';
import {Price} from './model-currency';
import {FaultyDeclarationReason} from './model-declarations';
import {Offer} from './model-offer';
import {Order, OrderProduct} from './model-order';
import {ProductForService, ServiceSchemaStep} from './model-productforservice';
import {ProductionCycleFaultyReasonCount, ProductionCycleHistory} from './model-productioncycle';
import {ProductionDeviceModel} from './model-productiondevices';
import {ProductionSchema, ProductionSchemaStep} from './model-productionschema';
import {ProductQuality, ProductType, ProductTypeRecipe} from './model-producttype';
import {Language} from './model-translations';
import {FunctionalityGroup} from './model-enums';

export interface CriteriaAndArgumentsList {
  predicateArguments: any[] | null;
  searchCriteriaForClass: SearchCriteriaForClass | null;
}

export interface CriteriaAndArgumentsMap {
  predicateArguments: {[key: string] :any} | null;
  searchCriteriaForClass: SearchCriteriaForClass | null;
}

export interface CriteriaValue {
  value: any | null;
  valueType: CriteriaValueType | null;
}

export enum CriteriaValueType {
STRING,
NUMBER,
DATE,
ENUM,
RANGE,
BOOLEAN
}

export interface DeclarationsSummaryDetails {
  summaryFaultyProductsQuantity: number | null;
  summaryGoodProductsQuantity: number | null;
}

export interface ErrorMessage {
  message: string | null;
}

export interface InventoryStatisticsSingleOccurrenceDataCollection {
  productQualities: ProductQuality[] | null;
  productTypes: ProductType[] | null;
  productionDeviceModels: ProductionDeviceModel[] | null;
  productionSchemaSteps: ProductionSchemaStep[] | null;
  productsForService: ProductForService[] | null;
  serviceSchemaSteps: ServiceSchemaStep[] | null;
  workers: Person[] | null;
  workspaceGroups: WorkspaceGroup[] | null;
}

export interface ManufacturingCostsData {
  activeApplicationConfig: GlobalApplicationConfig | null;
  productProductionSchema: ProductionSchema | null;
  productTypeRecipe: ProductTypeRecipe | null;
}

export interface NewPassword {
  password: string | null;
  token: string | null;
}

export interface OfferDetails {
  anyDummyProductInTheOfferProductList: boolean | null;
  isAnyDummyProductInTheOfferProductList: boolean | null;
  offer: Offer | null;
  totalManufacturingCost: number | null;
  totalPrice: Price | null;
}

export interface OrderInProduction {
  order: Order | null;
  productsInProduction: number | null;
  readyProducts: number | null;
}

export interface OrderProgressDetails {
  summaryOrderedProductsQuantity: number | null;
  summaryProducedProductsQuantity: number | null;
}

export interface PasswordChange {
  email: string | null;
  newPassword: string | null;
  oldPassword: string | null;
}

export interface PersonLogin {
  email: string | null;
  password: string | null;
}

export interface PersonLoginInfo {
  defaultUserLanguage: Language | null;
  functionalityMap: {[key: string] :Functionality[]} | {[key: string] :any} | null;
  mainFunctionalities: FunctionalityGroup[] | any[] | null;
  person: Person | null;
  token: string | null;
}

export interface ProductionCycleHistoryStatisticData {
  faultyDeclarationReasonCollected: FaultyDeclarationReason[] | null;
  monthlyProductionCycleHistory: {[key: string] :ProductionCycleHistory[]} | null;
  productForServiceCollected: ProductForService[] | null;
  productQualityCollected: ProductQuality[] | null;
  productTypesCollected: ProductType[] | null;
  theMostFaultyProductForServiceCycles: {[key: string] :ProductionCycleHistory[]} | null;
  theMostFaultyProductQualityCycles: {[key: string] :ProductionCycleHistory[]} | null;
  theMostFaultyProductTypeCycles: {[key: string] :ProductionCycleHistory[]} | null;
  theMostUsedFaultyDeclarationReasons: {[key: string] :number} | null;
  theMostUsedFaultyDeclarationReasonsByProductQuality: {[key: string] :ProductionCycleFaultyReasonCount[]} | null;
  theWorseDeclarationCreatorsByFaultyProductsCount: {[key: string] :number} | null;
  year: number | null;
}

export interface ProductionHomeOverview {
  freeDryers: number | null;
  freeMachines: number | null;
  freeOvens: number | null;
  notBookedPositions: number | null;
  orderReadyForProduction: number | null;
  ordersInProduction: number | null;
}

export interface ProductionPlanningInformation {
  orderProduct: OrderProduct | null;
  productionSchemaSteps: ProductionSchemaStep[] | null;
  serviceSchemaSteps: ServiceSchemaStep[] | null;
}

export interface SearchCriteria {
  criteriaMap: {[key: string] :CriteriaValue} | null;
  deepFilterNestingList: string[] | null;
  filterFieldName: string | null;
  inClassPropertyName: string | null;
  propertyClass: string | null;
}

export interface SearchCriteriaForClass {
  ascending: boolean | null;
  deepSortNestingList: string[] | null;
  filteredClass: string | null;
  isSortingStringNumeric: boolean | null;
  searchCriteriaList: SearchCriteria[] | null;
  sortBy: string | null;
  sortByInClassPropertyName: string | null;
  sortPropertyClass: string | null;
readonly   sortingStringNumeric: boolean | null;
}

export interface SearchCriteriaValuesRange {
  endValue: any | null;
  startValue: any | null;
}

export interface SupervisionNotification {
  messageType: WebsocketMessageType | null;
  notificationCounter: number | null;
  workerName: string | null;
}

export interface TableDataJoiner {
  joinedDataObject: any | null;
  tableDataRootObject: any | null;
}

export interface Version {
  artifact: string | null;
  buildTime: string | null;
  group: string | null;
  name: string | null;
  version: string | null;
}

export interface Violation {
  errorUIkey: string | null;
  param: undefined | ViolationParam[];
  paramList: ViolationParam[] | null;
}

export interface ViolationParam {
  name: string | null;
  value: any | null;
}

export interface WebsocketMessage {
  messageType: WebsocketMessageType | null;
  messageValue: any | null;
}

export interface WorkCalendarData {
  dateFrom: number | null;
  dateTo: number | null;
  worker: Person | null;
  workspaceInstance: WorkspaceInstance | null;
}

export interface WorkerProductionData {
  person: Person | null;
  summaryFaultyPieces: number | null;
  summaryGoodPieces: number | null;
}

