<div class="country-details">
  <div *ngIf="isCrossActive">
    <button
      type="button"
      class="global-button"
      (click)="assignCountry()"
    >{{'admin.assign-country' | translate}}</button>
    <button
      type="button"
      class="global-button"
      (click)="closeComponent()"
    >{{'admin.return' | translate}}</button>
  </div>
  <h3 class="country-details__title--color">{{'admin.edit-country' | translate}}</h3>
  <app-country-form
    [currentCountry]="currentCountry"
    [updateCountryFlag]="true"
    [hideReturnButton]="true"
    [shouldFormBeDisabled]="isCrossActive">
  </app-country-form>
</div>
