<ng-template [ngIf]="!showMixingSchemaStepForm">
  <div class="production-schema__record global-width--90vw">
    <div class="production-schema__record__action-column">
      <button class="production-schema__record__action-column__arrow-up-button"
              [disabled]="mixingSchemaService.someOfStepsIsOpen()"
              (click)="changeIndexHigher()">
        <i class="pi pi-arrow-up"></i>
      </button>
      <button class="production-schema__record__action-column__arrow-down-button"
              [disabled]="mixingSchemaService.someOfStepsIsOpen()"
              (click)="changeIndexLower()">
        <i class="pi pi-arrow-down"></i>
      </button>
    </div>
    <div class="production-schema__record__step-column">
      <ng-container
        *ngIf="mixingSchemaStep && transformSecondsToDurationObject(mixingSchemaStep?.stepDuration?.seconds) as stepDuration">
        <label class="global-subtitle global-flex-wrap">{{'production.step-duration' |translate}}</label>
        <span class="global-font-weight--bold">{{getProperStepDurationValue()}}</span>
      </ng-container>
    </div>
    <div class="production-schema__record__step-column">
      <label>{{'production.action-type' | translate}}</label>
      <span class="global-font-weight--bold">{{mixingSchemaStep.actionType | status: MixingSchemaStepActionType : TranslationKeyEnum.PRODUCTION}}</span>
    </div>
    <ng-template [ngIf]="mixingSchemaStep.rawMaterial">
      <div class="production-schema__record__step-column">
        <label>{{'production.raw-material' | translate}}</label>
        <span class="global-font-weight--bold">{{mixingSchemaStep.rawMaterial.name}}</span>
      </div>
      <div class="production-schema__record__step-column">
        <label>{{'production.tonnage' | translate}}</label>
        <span class="global-font-weight--bold">{{mixingSchemaStep.tonnage}}</span>
      </div>
    </ng-template>
    <ng-template [ngIf]="mixingSchemaStep.waterAdditionInLiters > 0">
      <div class="production-schema__record__step-column">
        <label>{{'production.water-addition-in-liters' | translate}}</label>
        <span class="global-font-weight--bold">{{mixingSchemaStep.waterAdditionInLiters}}</span>
      </div>
    </ng-template>
    <div class="production-schema__record__step-column">
      <label>{{'production.description' | translate}}</label>
      <textarea readonly class="global-schema-step-description">{{mixingSchemaStep.description.isEmpty() ? ('production.not-defined' | translate) : mixingSchemaStep.description}}</textarea>
    </div>
    <div class="production-schema__record__action-column">
      <button class="production-schema__record__action-column__edit-button"
              (click)="editMixingSchemaStep()">
        <i class="pi pi-pencil"></i></button>

      <button class="production-schema__record__action-column__remove-button"
              (click)="removeMixingSchemaStep()"><i class="pi pi-trash"></i></button>
    </div>
  </div>
</ng-template>


<ng-template [ngIf]="showMixingSchemaStepForm">
 <app-mixing-schema-step-form
 [mixingSchemaStep]="mixingSchemaStep"
 (submitFormEmitter)="updateMixingStepInData($event)"
 (closeFormEmitter)="closeFormView()">
 </app-mixing-schema-step-form>
</ng-template>
