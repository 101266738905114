import {Pipe, PipeTransform} from '@angular/core';
import {ProductionCycleHistory} from '../../core/sdk/model-productioncycle';

@Pipe({
  name: 'percentOfFaultyPieces',
  pure: true
})
export class PercentOfFaultyPiecesPipe implements PipeTransform {

  public transform(value: ProductionCycleHistory): number {
    return (value.summaryFaultyPieces * 100) / value.summaryPieces;
  }

}
