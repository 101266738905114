import {Component, Input} from '@angular/core';
import {FiringSchema, FiringSchemaStep} from '../../../core/sdk/model-firingschema';
import {MixingSchema, MixingSchemaStep} from '../../../core/sdk/model-mixingschema';
import {BaseObjectRecord} from '../../interfaces/interfaces';
import {Utility} from '../../utilities/utility';
import {TranslateService} from '../../../core/translations/translate.service';
import {SchemaService} from '../../services/schema.service';

@Component({
  selector: 'app-schema-details-view',
  templateUrl: './schema-details-view.component.html',
})
export class SchemaDetailsViewComponent {

  @Input() public currentSchema: FiringSchema | MixingSchema;

  constructor(private schemaService: SchemaService,
              private translateService: TranslateService) {
  }

  public initBaseObjectMainInfoRecords(): BaseObjectRecord [] {
    const records: BaseObjectRecord [] = [];
    const steps = this.currentSchema.id.includes('mixing') ? (this.currentSchema as MixingSchema).mixingSchemaStepList :
      (this.currentSchema as FiringSchema).firingSchemaStepList;
    records.push({label: 'name', value: this.currentSchema.name});
    records.push({
      label: 'steps-duration', value:
        this.transformDurationObjectToString(this.transformAllStepsDurationToDurationObject(
          steps))
    });
    records.push({label: 'last-update', value: this.convertTimestampToStringDate(this.currentSchema.timestamp)});
    return records;
  }

  public initBaseObjectCostsRecords(): BaseObjectRecord [] {
    const records: BaseObjectRecord [] = [];
    records.push({label: 'direct-labor-rate-cost', value: this.currentSchema.directLaborRateCost});
    records.push({label: 'energy-cost', value: this.currentSchema.energyCost});
    records.push({label: 'fixed-overhead-cost', value: this.currentSchema.fixedOverheadCost});
    records.push({label: 'indirect-labor-rate-cost', value: this.currentSchema.indirectLaborRateCost});
    records.push({label: 'variable-overhead-cost', value: this.currentSchema.variableOverheadCost});
    return records;
  }

  public transformDurationObjectToString(duration: {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  }): string {
    return Utility.transformDurationObjectToUserFriendlyString(duration, this.translateService);
  }

  public transformAllStepsDurationToDurationObject(steps: FiringSchemaStep[] | MixingSchemaStep[]):
    { days: number, hours: number, minutes: number, seconds: number } {
    const seconds = this.schemaService.getAllStepsTotalDuration(steps);
    return Utility.convertSecondsToDurationObject(seconds);
  }

  public convertTimestampToStringDate(timestamp: number): string {
    return Utility.convertTimestampToLocalDateString(timestamp);
  }


}
