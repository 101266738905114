import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-faulty-reason-tile',
  templateUrl: './faulty-reason-tile.component.html',
})
export class FaultyReasonTileComponent {

  public itemWithDisplayedDescription: string;
  @Input() public faultyReasons: any[];
  @Input() public faultyReasonCountTemplateRef : TemplateRef<any>;
  @Input() public faultyReasonDescriptionTemplateRef : TemplateRef<any>;

  public setItemForWhichDescriptionShouldBeShown(id: string): void {
    this.itemWithDisplayedDescription = id;
  }

  public resetChosenItemId(): void {
    this.itemWithDisplayedDescription = '';
  }

}
