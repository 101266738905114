import {Component, OnInit, ViewChild} from '@angular/core';
import {Person} from '../../core/sdk/bighero-model';
import {AuthService} from '../../core/auth/auth.service';
import {PersonCtrl} from '../../core/sdk/bighero-controllers';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '../../core/translations/translate.service';
import {NotificationService} from '../../shared/services/notification.service';
import {ImageFilesService} from '../../shared/services/image-files.service';
import {NotificationMessageType} from '../../shared/enums/NotificationMessageType';
import {SpinnerService} from '../../shared/services/spinner.service';
import {ConstanceValues} from '../../shared/constance-values/constance-values';
import {PersonFile} from '../../core/sdk/model-files';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-user-self-management',
  templateUrl: './user-self-management.component.html'
})
export class UserSelfManagementComponent implements OnInit {

  public formGroup: FormGroup;

  public showUserProfileForm = false;
  public user: Person;
  public selectedPathToFile: string;
  public file: File;
  public avatarStyleClass = 'user-profile__avatar';
  public userAvatarUrl: string;

  @ViewChild('fileInput') public fileInput;

  constructor(private authService: AuthService,
              private spinnerService: SpinnerService,
              private formBuilder: FormBuilder,
              private personService: PersonCtrl,
              private translateService: TranslateService,
              private notificationService: NotificationService,
              private imageFileService: ImageFilesService) {
  }

  public ngOnInit(): void {
    this.user = this.authService.getCurrentUser();
    this.imageFileService.avatarChanged
      .subscribe((imageUrl: string) => {
        this.userAvatarUrl = imageUrl;
      }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
    this.userAvatarUrl = this.imageFileService.getUserAvatar();
    this.translateService.loadTranslationModule('user-self-management');
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [this.user.name ?? '', [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
      email: [this.user.email ?? '', [Validators.required, Validators.email]],
      telephone: [this.user.telephone ?? '', [Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]{6,13}$/im)]]
    });
  }

  public onSubmit(): void {
    this.spinnerService.activateSpinner();
    this.user = {...this.user, ...this.formGroup.value};
    this.personService.selfUpdate(this.user).subscribe((response) => {
      this.authService.setLoggedPerson(response);
      this.user = response;
      this.notificationService.displayNotificationToast('common.updated-successfully', NotificationMessageType.SUCCESS);
      this.toggleUserProfileFormFlag();
      this.spinnerService.deactivateSpinner();
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public toggleUserProfileFormFlag(): void {
    if (this.showUserProfileForm) {
      delete this.formGroup;
    } else {
      this.initFormGroup();
    }
    this.showUserProfileForm = !this.showUserProfileForm;
  }

  public uploadAvatar(fileList?: FileList): void {
    const fileBrowser = this.fileInput.nativeElement;
    this.file = fileBrowser.files[0];
    if (fileList && fileList[0]) {
      // @ts-ignore
      this.file = fileList.item(0);
    }
    if (this.file) {
      this.spinnerService.activateSpinner();
      this.imageFileService.uploadAvatar(this.file, this.file.name)
        .subscribe({
          next: (file: PersonFile) => {
            this.authService.setLoggedPerson(file.person);
            this.user = file.person;
            this.notificationService.displayNotificationToast('user-self-management.avatar-upload-successful',
              NotificationMessageType.SUCCESS);
            this.spinnerService.deactivateSpinner();
          },
          error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error,
            this.spinnerService)
        });
    }
  }

  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get emailControl(): AbstractControl {
    return this.formGroup.get('email');
  }

  public get telephoneControl(): AbstractControl {
    return this.formGroup.get('telephone');
  }
}
