/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SearchCriteriaForClass} from './model-dto';
import {RawMaterial, RawMaterialMixture, RawMaterialMixtureIngredient, RawMaterialMixtureOrder} from './model-materials';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class RawMaterialCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: RawMaterial, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterial>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterial>();
    this.httpService.post('/api/raw-material/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteRawMaterials(rawMaterial: RawMaterial, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterial>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterial>();
    this.httpService.put('/api/raw-material/w/delete', JsonScopedSerializer.stringify(rawMaterial, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<RawMaterial>  {
   const subject = new Subject<RawMaterial>();
    this.httpService.get('/api/raw-material/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getRawMaterials(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterial[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterial[]>();
    this.httpService.post('/api/raw-material/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getRawMaterialsCount(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/raw-material/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: RawMaterial, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterial>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterial>();
    this.httpService.put('/api/raw-material/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class RawMaterialMixtureCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public activateRawMaterialMixture(rawMaterialMixtureId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterialMixture>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'rawMaterialMixtureId', value: rawMaterialMixtureId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterialMixture>();
    this.httpService.put('/api/raw-material-mixture/w/activate', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public checkPossibleTonnageToOrder(rawMaterialMixtureId: string): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'rawMaterialMixtureId', value: rawMaterialMixtureId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<number>();
    this.httpService.get('/api/raw-material-mixture/r/check-tonnage', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: RawMaterialMixture, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterialMixture>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterialMixture>();
    this.httpService.post('/api/raw-material-mixture/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deactivateRawMaterialMixture(rawMaterialMixtureId: string): Observable<void>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'rawMaterialMixtureId', value: rawMaterialMixtureId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/raw-material-mixture/w/deactivate', {params})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/raw-material-mixture/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<RawMaterialMixture>  {
   const subject = new Subject<RawMaterialMixture>();
    this.httpService.get('/api/raw-material-mixture/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getRawMaterialMixtures(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, deactivated: boolean | null, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterialMixture[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    if (deactivated !== undefined && deactivated !== null) {
      queryParamsList.push({name: 'deactivated', value: deactivated.toString()});
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterialMixture[]>();
    this.httpService.post('/api/raw-material-mixture/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getRawMaterialMixturesCount(searchCriteria: SearchCriteriaForClass, deactivated: boolean | null, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    if (deactivated !== undefined && deactivated !== null) {
      queryParamsList.push({name: 'deactivated', value: deactivated.toString()});
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/raw-material-mixture/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateIngredientList(rawMaterialMixture: RawMaterialMixture, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterialMixture>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterialMixture>();
    this.httpService.post('/api/raw-material-mixture/r/update-ingredient-list', JsonScopedSerializer.stringify(rawMaterialMixture, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: RawMaterialMixture, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterialMixture>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterialMixture>();
    this.httpService.put('/api/raw-material-mixture/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class RawMaterialMixtureIngredientCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countRawMaterialMixtureIngredient(searchCriteria: SearchCriteriaForClass, rawMaterialMixtureID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'rawMaterialMixtureID', value: rawMaterialMixtureID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/raw-material-mixture-ingredient/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: RawMaterialMixtureIngredient, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterialMixtureIngredient>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterialMixtureIngredient>();
    this.httpService.post('/api/raw-material-mixture-ingredient/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/raw-material-mixture-ingredient/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getRawMaterialMixtureIngredients(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, rawMaterialMixtureID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterialMixtureIngredient[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    queryParamsList.push({name: 'rawMaterialMixtureID', value: rawMaterialMixtureID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterialMixtureIngredient[]>();
    this.httpService.post('/api/raw-material-mixture-ingredient/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getTotalFixedAmountForRawMaterialIngredients(rawMaterialMixtureID: string): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'rawMaterialMixtureID', value: rawMaterialMixtureID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<number>();
    this.httpService.get('/api/raw-material-mixture-ingredient/r/get-total-fixed-amount-value', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: RawMaterialMixtureIngredient, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterialMixtureIngredient>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterialMixtureIngredient>();
    this.httpService.put('/api/raw-material-mixture-ingredient/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class RawMaterialMixtureOrderCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countRawMaterialMixtureOrders(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/raw-material-mixture-order/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: RawMaterialMixtureOrder, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterialMixtureOrder>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterialMixtureOrder>();
    this.httpService.post('/api/raw-material-mixture-order/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<RawMaterialMixtureOrder>  {
   const subject = new Subject<RawMaterialMixtureOrder>();
    this.httpService.get('/api/raw-material-mixture-order/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getRawMaterialMixtureOrders(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterialMixtureOrder[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterialMixtureOrder[]>();
    this.httpService.post('/api/raw-material-mixture-order/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: RawMaterialMixtureOrder, jsonScope: JsonScope = new JsonScope(false, [])): Observable<RawMaterialMixtureOrder>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<RawMaterialMixtureOrder>();
    this.httpService.put('/api/raw-material-mixture-order/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

