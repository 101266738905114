import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'calendarButtonLabel',
  pure: true
})
export class CalendarButtonLabelPipe implements PipeTransform{

  public transform(flagValue: boolean): string {
    return !flagValue ? 'common.single-selection-mode' : 'common.range-selection-mode';
  }


}
