<ng-template [ngIf]="!showWorkCalendarForWorkspaceInstanceFlag && !isOvenOperation()">
  <div class="production-planning-manual-table">
    <table class="production-planning-manual-table__table-container">
      <thead class="production-planning-manual-table__head">
      <tr class="production-planning-manual-table__row">
        <th class="production-planning-manual-table__header">{{'production.shorthand' | translate}}</th>
        <th class="production-planning-manual-table__header">{{'production.location' | translate}}</th>
        <th
          class="production-planning-manual-table__header">{{'production.production-device-instance' | translate}}</th>
        <th class="production-planning-manual-table__header">{{'production.action' | translate}}</th>
      </tr>
      </thead>
      <tbody>

      <ng-template [ngIf]="!currentProcessStep.workspaceGroup.workspaceInstanceList.isEmpty()">
        <tr
          *ngFor="let workspaceInstance of currentProcessStep.workspaceGroup.workspaceInstanceList"
          class="production-planning-manual-table__records">
          <td class="production-planning-manual-table__single-record">{{workspaceInstance.shorthand}}</td>
          <td class="production-planning-manual-table__single-record">{{workspaceInstance.location}}</td>
          <td class="production-planning-manual-table__single-record"
          >{{workspaceInstance.productionDeviceInstance ? workspaceInstance.productionDeviceInstance.shorthand : ('common.not-assigned' | translate)}}</td>
          <td class="production-planning-manual-table__single-record">
            <button
              type="button"
              class="global-button"
              (click)="enableWorkersForWorkspaceInstanceView(workspaceInstance)"
            >{{'production.see-work-calendar'| translate}}</button>
          </td>
        </tr>
      </ng-template>

      <ng-template [ngIf]="currentProcessStep.workspaceGroup.workspaceInstanceList.isEmpty()">
        <tr class="production-planning-manual-table__records">
          <td
            class="production-planning-manual-table__single-record"
            colspan="3"
          >{{'common.no-records' | translate}}</td>
        </tr>
      </ng-template>

      </tbody>
    </table>
  </div>
</ng-template>

<ng-template [ngIf]="isOvenOperation() || showWorkCalendarForWorkspaceInstanceFlag">
  <app-work-calendars
    [workspaceInstance]="currentlyChosenWorkspaceInstance"
    [mainWorkspaceGroup]="currentProcessStep.workspaceGroup"
    [processStep]="currentProcessStep"
    (closeViewEmitter)="toggleShowWorkersFlag()">
  </app-work-calendars>
</ng-template>
