/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SearchCriteriaForClass} from './model-dto';
import {AdditionalStepDuringStoringAtLocation, GroundStorageArea, PickUpProduct, ProductStoredInstance, ProductStoredInstanceRequest, ProductStoredInstanceRequestLocationMark, Section, SharedStorageUnitMedia, ShelfStorageArea, SplitProductStoredInstance, StorageArea, Warehouse, WastedProduct} from './model-warehouse';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class AdditionalStepDuringStoringAtLocationCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public completeAdditionalStep(additionalStepDuringStoringAtLocationID: string, toStoreBarcode: string, storageAreaBarcode: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<AdditionalStepDuringStoringAtLocation>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'additionalStepDuringStoringAtLocationID', value: additionalStepDuringStoringAtLocationID});
  
    queryParamsList.push({name: 'toStoreBarcode', value: toStoreBarcode});
  
    queryParamsList.push({name: 'storageAreaBarcode', value: storageAreaBarcode});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<AdditionalStepDuringStoringAtLocation>();
    this.httpService.post('/api/additional-step-during-storing-at-location/w/complete', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: AdditionalStepDuringStoringAtLocation, jsonScope: JsonScope = new JsonScope(false, [])): Observable<AdditionalStepDuringStoringAtLocation>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<AdditionalStepDuringStoringAtLocation>();
    this.httpService.post('/api/additional-step-during-storing-at-location/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/additional-step-during-storing-at-location/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<AdditionalStepDuringStoringAtLocation>  {
   const subject = new Subject<AdditionalStepDuringStoringAtLocation>();
    this.httpService.get('/api/additional-step-during-storing-at-location/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: AdditionalStepDuringStoringAtLocation, jsonScope: JsonScope = new JsonScope(false, [])): Observable<AdditionalStepDuringStoringAtLocation>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<AdditionalStepDuringStoringAtLocation>();
    this.httpService.put('/api/additional-step-during-storing-at-location/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class GroundStorageAreaCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countGroundStorageAreas(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/ground-storage-area/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: GroundStorageArea, jsonScope: JsonScope = new JsonScope(false, [])): Observable<GroundStorageArea>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<GroundStorageArea>();
    this.httpService.post('/api/ground-storage-area/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getGroundStorageAreas(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<GroundStorageArea[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<GroundStorageArea[]>();
    this.httpService.post('/api/ground-storage-area/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: GroundStorageArea, jsonScope: JsonScope = new JsonScope(false, [])): Observable<GroundStorageArea>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<GroundStorageArea>();
    this.httpService.put('/api/ground-storage-area/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class PickUpProductCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: PickUpProduct, jsonScope: JsonScope = new JsonScope(false, [])): Observable<PickUpProduct>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<PickUpProduct>();
    this.httpService.post('/api/pick-up-product/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createPickUpProductsFromProductStoredInstanceList(productStoredInstanceList: ProductStoredInstance[], orderPackageRequestID: string, orderPackageProductID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<PickUpProduct[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'orderPackageRequestID', value: orderPackageRequestID});
  
    queryParamsList.push({name: 'orderPackageProductID', value: orderPackageProductID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<PickUpProduct[]>();
    this.httpService.post('/api/pick-up-product/w/create-from-list', JsonScopedSerializer.stringify(productStoredInstanceList, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/pick-up-product/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getPickUpProductsForMobile(packageProductID: string): Observable<PickUpProduct[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'packageProductID', value: packageProductID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<PickUpProduct[]>();
    this.httpService.get('/api/pick-up-product/r/get-for-mobile', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getPickUpProductsForOrderPackageProduct(packageProductID: string): Observable<PickUpProduct[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'packageProductID', value: packageProductID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<PickUpProduct[]>();
    this.httpService.get('/api/pick-up-product/r/get-for-order-package-product', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public packPickUpProduct(pickUpProductID: string, storageAreaBarcode: string, productStoredInstanceBarcode: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<PickUpProduct>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pickUpProductID', value: pickUpProductID});
  
    queryParamsList.push({name: 'storageAreaBarcode', value: storageAreaBarcode});
  
    queryParamsList.push({name: 'productStoredInstanceBarcode', value: productStoredInstanceBarcode});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<PickUpProduct>();
    this.httpService.post('/api/pick-up-product/w/pack', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: PickUpProduct, jsonScope: JsonScope = new JsonScope(false, [])): Observable<PickUpProduct>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<PickUpProduct>();
    this.httpService.put('/api/pick-up-product/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductStoredInstanceCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countProductStoredInstance(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/product-stored-instance/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createFromProductStoredInstanceRequest(productStoredInstanceRequestID: string): Observable<ProductStoredInstanceRequestLocationMark[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productStoredInstanceRequestID', value: productStoredInstanceRequestID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductStoredInstanceRequestLocationMark[]>();
    this.httpService.get('/api/product-stored-instance/w/create-from-request', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<ProductStoredInstance>  {
   const subject = new Subject<ProductStoredInstance>();
    this.httpService.get('/api/product-stored-instance/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductStoredInstanceMFC(productStoredInstanceID: string): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productStoredInstanceID', value: productStoredInstanceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<number>();
    this.httpService.get('/api/product-stored-instance/r/get-mfc', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductStoredInstances(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductStoredInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductStoredInstance[]>();
    this.httpService.post('/api/product-stored-instance/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductStoredInstancesForStorageArea(storageAreaID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductStoredInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'storageAreaID', value: storageAreaID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductStoredInstance[]>();
    this.httpService.post('/api/product-stored-instance/r/get-for-storage-area', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public storeNewProductStoredInstance(locationMarkID: string, storageAreaBarcode: string, productStoredInstanceBarcode: string): Observable<ProductStoredInstanceRequestLocationMark>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'locationMarkID', value: locationMarkID});
  
    queryParamsList.push({name: 'storageAreaBarcode', value: storageAreaBarcode});
  
    queryParamsList.push({name: 'productStoredInstanceBarcode', value: productStoredInstanceBarcode});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductStoredInstanceRequestLocationMark>();
    this.httpService.get('/api/product-stored-instance/w/store-new', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public takeOutOfStock(description: string, productStoredInstanceID: string, quantity: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductStoredInstance>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productStoredInstanceID', value: productStoredInstanceID});
  
    queryParamsList.push({name: 'quantity', value: quantity.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductStoredInstance>();
    this.httpService.post('/api/product-stored-instance/w/take-out-of-stock', JsonScopedSerializer.stringify(description, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ProductStoredInstance, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductStoredInstance>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductStoredInstance>();
    this.httpService.put('/api/product-stored-instance/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductStoredInstanceRequestCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public assignWarehouseWorkerForStorage(productStoredInstanceRequestID: string): Observable<ProductStoredInstanceRequest>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productStoredInstanceRequestID', value: productStoredInstanceRequestID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductStoredInstanceRequest>();
    this.httpService.get('/api/product-stored-instance-request/w/assign-worker-to-storage', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countProductStoredInstanceRequests(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/product-stored-instance-request/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<ProductStoredInstanceRequest>  {
   const subject = new Subject<ProductStoredInstanceRequest>();
    this.httpService.get('/api/product-stored-instance-request/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductStoredInstanceRequests(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductStoredInstanceRequest[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductStoredInstanceRequest[]>();
    this.httpService.post('/api/product-stored-instance-request/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkerReadyForStorageProductStoredInstanceRequests(): Observable<ProductStoredInstanceRequest[]>  {
   const subject = new Subject<ProductStoredInstanceRequest[]>();
    this.httpService.get('/api/product-stored-instance-request/r/get-ready-for-storage', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ProductStoredInstanceRequest, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductStoredInstanceRequest>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductStoredInstanceRequest>();
    this.httpService.put('/api/product-stored-instance-request/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductStoredInstanceRequestLocationMarkCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: ProductStoredInstanceRequestLocationMark, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductStoredInstanceRequestLocationMark>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductStoredInstanceRequestLocationMark>();
    this.httpService.post('/api/product-stored-instance-request-location-mark/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createProductStoredInstanceRequestLocationMarksFromList(locationMarkList: ProductStoredInstanceRequestLocationMark[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductStoredInstanceRequestLocationMark[]>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductStoredInstanceRequestLocationMark[]>();
    this.httpService.post('/api/product-stored-instance-request-location-mark/w/create-from-list', JsonScopedSerializer.stringify(locationMarkList, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/product-stored-instance-request-location-mark/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductStoredInstanceRequestLocationMarks(productStoredInstanceRequestID: string): Observable<ProductStoredInstanceRequestLocationMark[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productStoredInstanceRequestID', value: productStoredInstanceRequestID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductStoredInstanceRequestLocationMark[]>();
    this.httpService.get('/api/product-stored-instance-request-location-mark/r/get', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductStoredInstanceRequestLocationMarksLeftForStorage(productStoredInstanceRequestID: string): Observable<ProductStoredInstanceRequestLocationMark[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productStoredInstanceRequestID', value: productStoredInstanceRequestID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductStoredInstanceRequestLocationMark[]>();
    this.httpService.get('/api/product-stored-instance-request-location-mark/r/get-locations-for-storage', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ProductStoredInstanceRequestLocationMark, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductStoredInstanceRequestLocationMark>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductStoredInstanceRequestLocationMark>();
    this.httpService.put('/api/product-stored-instance-request-location-mark/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class SectionCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countWarehouseSections(searchCriteria: SearchCriteriaForClass, warehouseID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'warehouseID', value: warehouseID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/section/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: Section, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Section>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Section>();
    this.httpService.post('/api/section/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/section/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWarehouseSections(searchCriteria: SearchCriteriaForClass, warehouseID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Section[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'warehouseID', value: warehouseID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Section[]>();
    this.httpService.post('/api/section/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: Section, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Section>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Section>();
    this.httpService.put('/api/section/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class SharedStorageUnitMediaCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public assignProductStoredInstanceList(productStoredInstanceList: ProductStoredInstance[], sharedStorageUnitMediaID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<SharedStorageUnitMedia>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'sharedStorageUnitMediaID', value: sharedStorageUnitMediaID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<SharedStorageUnitMedia>();
    this.httpService.put('/api/shared-storage-unit-media/w/assign-products-from-list', JsonScopedSerializer.stringify(productStoredInstanceList, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: SharedStorageUnitMedia, jsonScope: JsonScope = new JsonScope(false, [])): Observable<SharedStorageUnitMedia>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<SharedStorageUnitMedia>();
    this.httpService.post('/api/shared-storage-unit-media/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<SharedStorageUnitMedia>  {
   const subject = new Subject<SharedStorageUnitMedia>();
    this.httpService.get('/api/shared-storage-unit-media/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getSharedStorageUnitMediaForStorageArea(storageAreaID: string): Observable<SharedStorageUnitMedia[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'storageAreaID', value: storageAreaID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<SharedStorageUnitMedia[]>();
    this.httpService.get('/api/shared-storage-unit-media/r/get-assigned-to-storage-area', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public unpackAndDisposeSharedStorageUnitMedia(sharedStorageUnitMediaID: string): Observable<ProductStoredInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'sharedStorageUnitMediaID', value: sharedStorageUnitMediaID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductStoredInstance[]>();
    this.httpService.delete('/api/shared-storage-unit-media/w/unpack-and-dispose', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: SharedStorageUnitMedia, jsonScope: JsonScope = new JsonScope(false, [])): Observable<SharedStorageUnitMedia>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<SharedStorageUnitMedia>();
    this.httpService.put('/api/shared-storage-unit-media/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ShelfStorageAreaCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countStorageAreas(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/shelf-storage-area/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: ShelfStorageArea, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ShelfStorageArea>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ShelfStorageArea>();
    this.httpService.post('/api/shelf-storage-area/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getStorageAreas(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ShelfStorageArea[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ShelfStorageArea[]>();
    this.httpService.post('/api/shelf-storage-area/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: ShelfStorageArea, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ShelfStorageArea>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ShelfStorageArea>();
    this.httpService.put('/api/shelf-storage-area/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class SplitProductStoredInstanceCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public assignDestinationStorageArea(storageAreaID: string, splitProductStoredInstanceID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<SplitProductStoredInstance>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'storageAreaID', value: storageAreaID});
  
    queryParamsList.push({name: 'splitProductStoredInstanceID', value: splitProductStoredInstanceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<SplitProductStoredInstance>();
    this.httpService.put('/api/split-product-stored-instance/w/assign-destination-storage-area', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public assignWarehouseWorker(splitProductStoredInstanceID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<SplitProductStoredInstance>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'splitProductStoredInstanceID', value: splitProductStoredInstanceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<SplitProductStoredInstance>();
    this.httpService.put('/api/split-product-stored-instance/w/assign-warehouse-worker', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public confirmMoveToDestinationArea(splitProductStoredInstanceBarcode: string, destinationAreaBarcode: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<SplitProductStoredInstance>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'splitProductStoredInstanceBarcode', value: splitProductStoredInstanceBarcode});
  
    queryParamsList.push({name: 'destinationAreaBarcode', value: destinationAreaBarcode});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<SplitProductStoredInstance>();
    this.httpService.put('/api/split-product-stored-instance/w/confirm-move-to-destination-area', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public confirmRepack(splitProductStoredInstanceID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<SplitProductStoredInstance>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'splitProductStoredInstanceID', value: splitProductStoredInstanceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<SplitProductStoredInstance>();
    this.httpService.put('/api/split-product-stored-instance/w/confirm-repack', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countSplitProductStoredInstances(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/split-product-stored-instance/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public create(splitProductStoredInstance: SplitProductStoredInstance, jsonScope: JsonScope = new JsonScope(false, [])): Observable<SplitProductStoredInstance>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<SplitProductStoredInstance>();
    this.httpService.post('/api/split-product-stored-instance/w/create', JsonScopedSerializer.stringify(splitProductStoredInstance, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<SplitProductStoredInstance>  {
   const subject = new Subject<SplitProductStoredInstance>();
    this.httpService.get('/api/split-product-stored-instance/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getSplitProductStoredInstances(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<SplitProductStoredInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<SplitProductStoredInstance[]>();
    this.httpService.post('/api/split-product-stored-instance/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkerReadyForMoveSplitProductStoredInstances(): Observable<SplitProductStoredInstance[]>  {
   const subject = new Subject<SplitProductStoredInstance[]>();
    this.httpService.get('/api/split-product-stored-instance/r/get-for-worker-to-move', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkerReadyForRepackSplitProductStoredInstances(): Observable<SplitProductStoredInstance[]>  {
   const subject = new Subject<SplitProductStoredInstance[]>();
    this.httpService.get('/api/split-product-stored-instance/r/get-for-worker-to-repack', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: SplitProductStoredInstance, jsonScope: JsonScope = new JsonScope(false, [])): Observable<SplitProductStoredInstance>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<SplitProductStoredInstance>();
    this.httpService.put('/api/split-product-stored-instance/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class StorageAreaCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countStorageAreas(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/storage-area/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: StorageArea, jsonScope: JsonScope = new JsonScope(false, [])): Observable<StorageArea>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<StorageArea>();
    this.httpService.post('/api/storage-area/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<StorageArea>  {
   const subject = new Subject<StorageArea>();
    this.httpService.get('/api/storage-area/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getStorageAreas(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<StorageArea[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<StorageArea[]>();
    this.httpService.post('/api/storage-area/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: StorageArea, jsonScope: JsonScope = new JsonScope(false, [])): Observable<StorageArea>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<StorageArea>();
    this.httpService.put('/api/storage-area/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class WarehouseCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countWarehouses(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/warehouse/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: Warehouse, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Warehouse>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Warehouse>();
    this.httpService.post('/api/warehouse/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<Warehouse>  {
   const subject = new Subject<Warehouse>();
    this.httpService.get('/api/warehouse/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWarehouses(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Warehouse[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Warehouse[]>();
    this.httpService.post('/api/warehouse/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: Warehouse, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Warehouse>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Warehouse>();
    this.httpService.put('/api/warehouse/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class WastedProductCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countWastedProducts(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/wasted-products/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<WastedProduct>  {
   const subject = new Subject<WastedProduct>();
    this.httpService.get('/api/wasted-products/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWastedProducts(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<WastedProduct[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<WastedProduct[]>();
    this.httpService.post('/api/wasted-products/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

