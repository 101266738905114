import {Injectable} from '@angular/core';
import {Functionality} from '../../core/sdk/bighero-model';
import {FunctionalityGroup} from '../../core/sdk/model-enums';
import {SessionService} from './session.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FunctionalityService {
  readonly functionalityGroupNameIndex = 2;

  public functionalityMap: Map<string, Functionality[]> | null;
  public functionalityGroups: FunctionalityGroup[] = [];
  public currentSubFunctionalities: Functionality[] = [];
  public currentFunctionality: Functionality;
  public currentFunctionalityGroupName: string;
  public currentFunctionalityName: string;

  constructor(private router: Router,
              private sessionService: SessionService) {
  }

  public isFunctionalityAvailable(functionalityName: string): boolean {
    return this.getCurrentSubFunctionalities().findIndex(functionality => functionality.name === functionalityName) !== -1;
  }

  public setFunctionalityMap(functionalityMap: { [key: string]: Functionality[] }): void {
    this.sessionService.setFunctionalityMap(functionalityMap);
  }

  public setFunctionalityGroups(functionalityGroups: FunctionalityGroup[]): void {
    this.sessionService.setFunctionalityGroups(functionalityGroups);
    this.functionalityGroups = functionalityGroups;
  }

  public getUserFunctionalityGroups(): FunctionalityGroup[] {
    return this.functionalityGroups.length ? this.functionalityGroups : this.sessionService.getFunctionalityGroups();
  }

  public getUserFunctionalityMap(): Map<string, Functionality[]> {
    return this.functionalityMap ? this.functionalityMap : this.sessionService.getFunctionalityMap();
  }

  public getCurrentSubFunctionalities(): Functionality[] {
    return this.currentSubFunctionalities.length ? this.currentSubFunctionalities :
      this.sessionService.getFunctionalityMap()[FunctionalityGroup[FunctionalityGroup[this.getActiveFunctionalityGroupName()
        .split('-').join('_')?.toUpperCase()]]];
  }


  public getActiveFunctionalityGroupName(): string {
    const urlArray: string[] = this.splitUrl(this.router.url);
    this.currentFunctionalityGroupName = urlArray[this.functionalityGroupNameIndex];
    return this.currentFunctionalityGroupName;
  }

  public getActiveFunctionalityName(): string {
    const urlArray: string[] = this.splitUrl(this.router.url);
    this.currentFunctionalityName = urlArray.length === 4 ? urlArray[3] : urlArray[4];
    return this.currentFunctionalityName;
  }

  public splitUrl(url: string): string[] {
    return url.split('/');
  }

  public cutMainSubFunctionalityUrl(url: string): string {
    return url.includes('/') ? url.split('/')[1] : url;
  }

  public flushSubFunctionality(): void {
    this.currentSubFunctionalities = [];
    this.currentFunctionality = null;
  }

  public getUserFunctionalityName(functionality: Functionality): string {
    return 'common.' + this.cutMainSubFunctionalityUrl(functionality.name);
  }

}
