<div class="printable-order-package__main-container">

  <p
    class="printable-order-package__main-container__title"
  >{{'warehouse.package-summary' | translate}}</p>

  <div class="printable-order-package__item-info">
    <label
      for="orderId"
    >{{'warehouse.order-id' | translate}}:</label>
    <p
      id="orderId"
    >{{currentOrderPackageRequest.orderPackage.order.shorthand}}</p>
  </div>

  <div class="printable-order-package__item-info">
    <label
      for="packageId"
    >{{'warehouse.package-id' | translate}}:</label>
    <p
      id="packageId"
    >{{currentOrderPackageRequest.orderPackage.shorthand}}</p>
  </div>
</div>

<div class="global-flex global-flex-direction__row">
  <div class="printable-order-package__delivery-address">
    <h4>{{'warehouse.delivery-address' | translate}}</h4>

    <div class="printable-order-package__item-info global-flex-direction__row">
      <label
        for="flatNumber"
      >{{'warehouse.flat-number' | translate}}:</label>
      <p
        id="flatNumber"
      >{{currentOrderPackageRequest.orderPackage.deliveryAddress.flatNumber}}</p>
    </div>

    <div class="printable-order-package__item-info global-flex-direction__row">
      <label
        for="buildingNumber"
      >{{'warehouse.building-number' | translate}}:</label>
      <p id="buildingNumber"
      >{{currentOrderPackageRequest.orderPackage.deliveryAddress.buildingNumber}}</p>
    </div>

    <div class="printable-order-package__item-info global-flex-direction__row">
      <label for="street">{{'warehouse.street' | translate}}:</label>
      <p id="street">{{currentOrderPackageRequest.orderPackage.deliveryAddress.street}}</p>
    </div>

    <div class="printable-order-package__item-info global-flex-direction__row">
      <label
        for="city"
      >{{'warehouse.city' | translate}}:</label>
      <p
        id="city"
      >{{currentOrderPackageRequest.orderPackage.deliveryAddress.city}}</p>
    </div>

    <div class="printable-order-package__item-info global-flex-direction__row">
      <label
        for="postalCode"
      >{{'warehouse.postal-code' | translate}}:</label>
      <p
        id="postalCode"
      >{{currentOrderPackageRequest.orderPackage.deliveryAddress.postalCode}}</p>
    </div>


    <div class="printable-order-package__item-info global-flex-direction__row">
      <label
        for="country"
      >{{'warehouse.country' | translate}}:</label>
      <p
        id="country"
      >{{currentOrderPackageRequest.orderPackage.deliveryAddress.country.name}}</p>
    </div>
  </div>

  <div class="printable-order-package__customer">
    <div class="printable-order-package__item-info global-flex-direction__column">
      <label
        for="companyName"
      >{{'warehouse.company-name' | translate}}:</label>
      <p
        id="companyName"
      >{{currentOrderPackageRequest.orderPackage.deliveryAddress.customer.companyName}}</p>
    </div>

    <div class="printable-order-package__item-info global-flex-direction__column">
      <label
        for="deliveryTerm"
      >{{'warehouse.delivery-term' | translate}}:</label>
      <p
        id="deliveryTerm"
      >{{currentOrderPackageRequest.orderPackage.deliveryAddress.customer.deliveryTerm.name}}</p>
    </div>

    <div class="printable-order-package__item-info global-flex-direction__column">
      <label
        for="salesManager"
      >{{'warehouse.sales-manager' | translate}}:</label>
      <p
        id="salesManager"
      >{{currentOrderPackageRequest.orderPackage.deliveryAddress.customer.salesRepresentative.name}}</p>
    </div>

  </div>
</div>

<div>
  <h5
    class="printable-order-package__table-title"
  >{{'warehouse.products-in-package' | translate}}</h5>
  <p-table
    class="main-table-header"
    dataKey="id"
    editMode="row"
    [value]="orderPackageProducts"
    [lazy]="true"
    [rows]="orderPackageProducts?.length">
    <ng-template pTemplate="header">
      <tr class="additional-address-line-table__headers additional-address-line-table__headers--border">
        <th
        >{{'warehouse.name' | translate}}
        </th>
        <th
        >{{'warehouse.article-number' | translate}}
        </th>
        <th
        >{{'warehouse.quality' | translate}}
        </th>
        <th
        >{{'warehouse.storing-keeping-unit' | translate}}
        </th>
        <th>
          {{'warehouse.quantity' | translate}}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">
          {{'warehouse.package-is-empty' | translate}}
        </td>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-packageProduct>
      <tr class="additional-address-line-table__body">
        <td>{{packageProduct.orderProduct.productType.name}}</td>
        <td>{{packageProduct.orderProduct.productType.articleNumber}}</td>
        <td>{{packageProduct.orderProduct.productType.quality.name}}</td>
        <td>{{packageProduct.orderProduct.productType.storingUnitInstance.type.name + '\t(' + packageProduct.orderProduct.productType.storingUnitInstance.piecesCapacity + ')'}}</td>
        <td>{{packageProduct.quantity}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>


