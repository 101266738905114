
export enum AdditionalStepDuringStoringAtLocationType {
CREATE_SPLIT,
CREATE_SHARED_STORAGE_UNIT_MEDIA,
ADD_TO_EXISTING_SHARED_STORAGE_UNIT_MEDIA
}

export enum AddressType {
DELIVERY_ADDRESS,
BILLING_ADDRESS,
MAIN_ADDRESS
}

export enum BatchHistoryType {
}

export enum FileType {
DOCUMENT,
IMAGE,
INVOICE,
INVENTORY_REPORT
}

export enum FurnaceModelType {
ELECTRIC,
GAS
}

export enum InvoiceApplicableTaxType {
USE_CLIENT_MAIN_ADDRESS_COUNTRY_TAX_RATE,
USE_CLIENT_SHIPPING_ADDRESS_COUNTRY_TAX_RATE,
USE_GERMAN_PRODUCTS_TAX_RATE
}

export enum InvoiceInfoLineType {
FOOTER_LINE,
MAIN_BODY_LINE_COMMON,
MAIN_BODY_LINE_UNIQUE
}

export enum MixingSchemaStepActionType {
START_MIXING,
ADD_RAW_MATERIAL_INGREDIENT,
MIXING_WITHOUT_INTERRUPTION,
PAUSE_MIXING,
STOP_MIXING,
ADD_WATER
}

export enum MoldPartTypeEnum {
FRAME,
INLAY
}

export enum OfferLockType {
ONE_OF_THE_PRODUCTS_MFC_WAS_UPDATED
}

export enum OrderInvoiceType {
PROFORMA,
FINAL,
DEPOSIT
}

export enum ProductOriginType {
PRODUCT_TYPE,
PRODUCT_FOR_SERVICE
}

export enum ProductQualityNormProgressionType {
LINEAR,
NON_LINEAR
}

export enum ProductQualityNormReversibilityType {
REVERSIBLE,
IRREVERSIBLE
}

export enum ProductQualityNormType {
STRENGTH_TEST,
THERMAL_EXPANSION,
CHANGE_IN_LENGTH
}

export enum ProductionDeviceModelType {
DRYER_MODEL,
FURNACE_MODEL,
MACHINE_MODEL,
MIXING_MACHINE_MODEL
}

export enum ProductionLogActionType {
ORDER_MARKED_FOR_PRODUCTION,
PRODUCTION_BOOKED,
WORKER_TASK_STARTED,
NEW_BATCH_CREATED,
NEW_PRODUCTS_PRODUCED,
PRODUCED_PRODUCTS_HANDED_FOR_STORAGE,
NEW_UNPLANNED_BATCH_CREATED,
NEW_UNPLANNED_STEP_CREATED,
PRODUCED_QUANTITY_REDUCED_FOR_UNPLANNED_REWORK
}

export enum ProductionSchemaStepType {
PRODUCTION_SCHEMA_STEP,
BURNING_SCHEMA_STEP,
MOLDING_SCHEMA_STEP,
DRYING_SCHEMA_STEP,
POST_PROCESSING_SCHEMA_STEP,
MIXING_MASSES_SCHEMA_STEP
}

export enum SizeChangeType {
GROW,
SHRINK,
NO_CHANGE
}

export enum StorageAreaType {
SHELF,
GROUND
}

export enum StoringUnitTypeEnum {
PALETTE,
BOX
}

export enum StrengthNormType {
CRUSHING,
BENDING
}

export enum SupplierArticleTypeEnum {
}

export enum SupplyOrderType {
}

export enum TaskType {
WORKER_TASK,
BURNING_TASK
}

export enum TaxRateType {
FOR_PRODUCTS,
FOR_SERVICES
}

export enum UnplannedStepType {
CUTTING,
GRINDING,
DRILLING,
PATCHING,
STAMPING,
OTHER
}

export enum WebsocketMessageType {
CREATE_NOTIFICATION,
UPDATE_NOTIFICATION
}

export enum WorkspaceGroupType {
DRYING_AREA,
OVEN_OPERATION,
MACHINE_OPERATION,
WITHOUT_MACHINE,
POST_PROCESSING_OPERATION,
MIXING_STAND_OPERATION,
WITH_OTHER_MACHINE
}

