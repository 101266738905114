import {Component, inject, Input} from '@angular/core';
import {FiringSchema} from '../../../core/sdk/model-firingschema';
import {FiringSchemaTemperatureFluctuation} from '../../../core/sdk/model-enums';
import {ProductionFiringSchemaService} from '../../services/production-firing-schema.service';
import {Utility} from '../../utilities/utility';
import {TranslateService} from '../../../core/translations/translate.service';
import {SchemaService} from '../../services/schema.service';

@Component({
  selector: 'app-firing-schema-steps-tile',
  templateUrl: './firing-schema-steps-tile.component.html',
})
export class FiringSchemaStepsTileComponent {

  private productionFiringSchemaService: ProductionFiringSchemaService = inject(ProductionFiringSchemaService);
  private schemaService: SchemaService = inject(SchemaService);
  private translateService: TranslateService = inject(TranslateService);

  @Input() public firingSchema: FiringSchema;

  public getEnumValue(value: FiringSchemaTemperatureFluctuation): string {
    return this.productionFiringSchemaService.getTranslatedValueOfStepType(value, FiringSchemaTemperatureFluctuation);
  }

  public transformSecondsToDurationObject(timestamp: number): {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  } {
    return this.schemaService.transformSeconds(timestamp);
  }

  public transformDurationObjectToString(duration: {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  }): string {
    return Utility.transformDurationObjectToUserFriendlyString(duration, this.translateService);
  }

}
