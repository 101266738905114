<div class="form-details">

  <div class="form-details__container form-details__container--background-color">
    <form
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit()">
      <div class="form-details__field">
        <label for="temperature">{{ 'products.temperature' | translate }} [℃]</label>
        <input
          id="temperature"
          type="number"
          formControlName="temperature"
          placeholder="{{'products.temperature' | translate}}">
        <span
          [ngClass]="temperatureControl.invalid && (temperatureControl.dirty || temperatureControl.touched) ? 'visibility--true' : 'visibility--false'"
          class="global-error-message"
        >{{ 'common.field-required' | translate }}</span>
      </div>
      <div class="form-details__field">
        <label *ngIf="normType === ProductQualityNormType.STRENGTH_TEST" for="value">{{ 'products.force' | translate }} [N/mm²]</label>
        <label *ngIf="normType === ProductQualityNormType.THERMAL_EXPANSION ||
         normType === ProductQualityNormType.CHANGE_IN_LENGTH" for="value">{{ 'products.percentage' | translate }} [%]</label>
        <input
          id="value"
          type="number"
          formControlName="value"
          placeholder="{{'products.force' | translate}}">
        <span
          [ngClass]="valueControl.invalid && (valueControl.dirty || valueControl.touched) ? 'visibility--true' : 'visibility--false'"
          class="global-error-message"
        >{{ 'common.field-required' | translate }}</span>
      </div>
      <div class="form-details__field global-p-calendar-icon">
        <label
          for="dateOfPerformingTest"
        >{{ 'products.date-of-performing-test' | translate }}</label>
        <p-calendar
          appendTo="body"
          formControlName="dateOfPerformingTest"
          id="dateOfPerformingTest"
          dateFormat="dd.mm.yy"
          [styleClass]="'global-width--100'"
          [showButtonBar]="true"
          [readonlyInput]="true"
          [clearButtonStyleClass]="'display--false'"
          [showIcon]="true"
          [showOnFocus]="false"
          placeholder="{{ 'products.set-date' | translate }}"
          (onSelect)="assignDate($event)">
        </p-calendar>
      </div>

      <div class="global-flex global-justify__center">
        <button
          type="submit"
          [disabled]="formGroup.invalid"
        >{{ 'common.submit' | translate }}
        </button>
        <button
          type="button"
          (click)="onCancel()"
        >{{ 'common.close' | translate }}
        </button>
      </div>

    </form>
  </div>

</div>
