/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Declaration} from './model-declarations';
import {ProductionCycleHistoryStatisticData, SearchCriteriaForClass, WorkerProductionData} from './model-dto';
import {ProductionCycleHistory, ProductionCycleStepDetails} from './model-productioncycle';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ProductionCycleHistoryCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countProductionCycleHistory(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/production-cycle-history/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<ProductionCycleHistory>  {
   const subject = new Subject<ProductionCycleHistory>();
    this.httpService.get('/api/production-cycle-history/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductionCycleHistory(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<ProductionCycleHistory[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<ProductionCycleHistory[]>();
    this.httpService.post('/api/production-cycle-history/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkerDataForProductionCycleHistory(productionCycleHistoryID: string): Observable<WorkerProductionData[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productionCycleHistoryID', value: productionCycleHistoryID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<WorkerProductionData[]>();
    this.httpService.get('/api/production-cycle-history/r/get-workers-data', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getYearMonthProductionCycleHistoryStatistics(year: number, month: number | null): Observable<ProductionCycleHistoryStatisticData>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'year', value: year.toString()});
  
    if (month !== undefined && month !== null) {
      queryParamsList.push({name: 'month', value: month.toString()});
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductionCycleHistoryStatisticData>();
    this.httpService.get('/api/production-cycle-history/r/get-year-month-statistics', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductionCycleStepDetailsCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public getDeclarationsForProductionCycleStepDetails(productionCycleStepDetailsID: string): Observable<Declaration[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productionCycleStepDetailsID', value: productionCycleStepDetailsID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<Declaration[]>();
    this.httpService.get('/api/production-cycle-step-details/r/get-declarations', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<ProductionCycleStepDetails>  {
   const subject = new Subject<ProductionCycleStepDetails>();
    this.httpService.get('/api/production-cycle-step-details/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductionCycleHistoryStepDetails(productionCycleHistoryID: string): Observable<ProductionCycleStepDetails[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productionCycleHistoryID', value: productionCycleHistoryID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductionCycleStepDetails[]>();
    this.httpService.get('/api/production-cycle-step-details/r/get', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

