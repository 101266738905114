<div class="form-container">
  <div class="form-center form-container__card">
    <app-secured-image
      [class]="avatarStyleClass"
      [src]="userAvatarUrl"
    ></app-secured-image>
    <div class="global-flex-direction__column">
      <div class="form-container__title form-container__title--color">

        <span>{{'user-self-management.welcome' | translate}} {{user.name}}</span>
      </div>

      <div
        *ngIf="!showUserProfileForm"
        class="global-container--flex global-container--flex--column global-padding__small-top">
        <app-language-selector></app-language-selector>
        <label
          for="fileInput"
          class="button button__submit-button button__submit-button--color button__submit-button--fit-content global-pointer global-text-align__center global-width--fill-available"
        >{{'user-self-management.upload-avatar'| translate}}</label>
        <input
          #fileInput
          id="fileInput"
          type="file"
          class="button button__submit-button global-pointer"
          [(ngModel)]="selectedPathToFile"
          (change)="uploadAvatar()">
        <button
          class="button button__submit-button global-text-align__center global-width--fill-available"
          (click)="toggleUserProfileFormFlag()"
        >{{'user-self-management.edit-user-profile' | translate}}
        </button>
        <button
          class="button button__submit-button global-text-align__center global-width--fill-available"
          [routerLink]="['/bh/password-change']"
        >{{"common.password-change" | translate}}</button>
      </div>

      <form
        *ngIf="showUserProfileForm"
        class="user-self-management__form"
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit()">
        <div class="user-self-management__form__fields">
          <div class="user-self-management__form__fields__record">
            <label for="name">{{'user-self-management.user-name' | translate}}:</label>
            <input
              id="name"
              type="text"
              placeholder="{{'user-self-management.user-name' | translate}}"
              formControlName="name">
            <span
              [ngClass]="nameControl.invalid && (nameControl.dirty || nameControl.touched) ? 'visibility--true' : 'visibility--false'"
              class="global-error-message"
            >{{'common.valid-value-required' | translate}}</span>
          </div>
          <div class="user-self-management__form__fields__record">
            <label for="email">{{'user-self-management.email' | translate}}:</label>
            <input
              id="email"
              type="text"
              placeholder="{{'user-self-management.email' | translate}}"
              formControlName="email">
            <span
              [ngClass]="emailControl.invalid && (emailControl.dirty || emailControl.touched) ? 'visibility--true' : 'visibility--false'"
              class="global-error-message"
            >{{'common.valid-value-required' | translate}}</span>
          </div>
          <div class="user-self-management__form__fields__record">
            <label for="telephone">{{'user-self-management.user-telephone' | translate}}:</label>
            <input
              id="telephone"
              type="text"
              placeholder="{{'user-self-management.telephone' | translate}}"
              formControlName="telephone">
            <span
              [ngClass]="telephoneControl.invalid && (telephoneControl.dirty || telephoneControl.touched) ? 'visibility--true' : 'visibility--false'"
              class="global-error-message"
            >{{'common.valid-value-required' | translate}}</span>
          </div>
        </div>
        <div class="user-self-management__form__buttons">
          <button
            type="submit"
            [disabled]="formGroup.invalid"
          >{{'common.submit' | translate}}</button>
          <button
            type="button"
            (click)="toggleUserProfileFormFlag()"
          >{{'common.return' | translate}}</button>
        </div>
      </form>
    </div>

  </div>
</div>
