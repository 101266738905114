import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SplitProductStoredInstance} from '../../../../../core/sdk/model-warehouse';
import {StoringUnitInstance} from '../../../../../core/sdk/bighero-model';
import {SplitProductStoredInstanceService} from '../../../../services/warehouse/split-product-stored-instance.service';
import {SpinnerService} from '../../../../services/spinner.service';
import {NotificationService} from '../../../../services/notification.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationMessageType} from '../../../../enums/NotificationMessageType';
import {HttpErrorResponse} from '@angular/common/http';
import {StoringUnitTypeEnum} from '../../../../../core/sdk/enums-types';
import {TranslationKeyEnum} from '../../../../enums/translation-key-enum';
import {EnumService} from '../../../../services/enum.service';
import {ConstanceValues} from '../../../../constance-values/constance-values';

@Component({
  selector: 'app-mobile-split-product-stored-instance-details-tile',
  templateUrl: './mobile-split-product-stored-instance-details-tile.component.html',
})
export class MobileSplitProductStoredInstanceDetailsTileComponent implements OnInit, AfterViewInit {

  public storingUnitInstance: StoringUnitInstance;
  public formGroup: FormGroup;
  private readonly stringValidators = [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)];

  @Input() public splitProductStoredInstance: SplitProductStoredInstance;
  @Input() public forRepack: boolean;
  @Output() public closeViewEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() public submitEmitter: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('areaBarcodeInput') areaBarcodeInput: ElementRef;

  constructor(private splitProductStoredInstanceService: SplitProductStoredInstanceService,
              private spinnerService: SpinnerService,
              private notificationService: NotificationService,
              private formBuilder: FormBuilder,
              private enumService: EnumService) {
  }

  public ngOnInit(): void {
    this.splitProductStoredInstance.productType ?
      this.storingUnitInstance = this.splitProductStoredInstance.productType.storingUnitInstance :
      this.storingUnitInstance = this.splitProductStoredInstance.productForService.storingUnitInstance;
    if (!this.forRepack) {
      this.initFormGroup();
    }
  }

  public ngAfterViewInit(): void {
    if (!this.forRepack && this.splitProductStoredInstance.destinationStorageArea) {
      this.areaBarcodeInput.nativeElement.focus();
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      areaBarcode: ['', this.stringValidators],
      splitProductStoredInstance: ['', this.stringValidators]
    });
  }

  public confirmRepack(): void {
    this.spinnerService.activateSpinner();
    this.splitProductStoredInstanceService.confirmRepack(this.splitProductStoredInstance.id).subscribe({
      next: () => {
        this.notificationService.displayNotificationToast('mobile.confirmed-for-repack-successfully',
          NotificationMessageType.SUCCESS);
        this.submitEmitter.emit();
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public confirmMoveToDestinationArea(): void {
    this.spinnerService.activateSpinner();
    this.splitProductStoredInstanceService.confirmMoveToDestinationArea(this.splitProductStoredInstanceControl.value,
      this.areaBarcodeControl.value).subscribe({
      next: () => {
        this.notificationService.displayNotificationToast('mobile.moved-to-destination-area-successfully',
          NotificationMessageType.SUCCESS);
        this.submitEmitter.emit();
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public displayType(enumItem: number): string {
    return this.enumService.getEnumValueWithModule(enumItem, StoringUnitTypeEnum, TranslationKeyEnum.MOBILE);
  }

  public get areaBarcodeControl(): AbstractControl {
    return this.formGroup.get('areaBarcode');
  }

  public get splitProductStoredInstanceControl(): AbstractControl {
    return this.formGroup.get('splitProductStoredInstance');
  }

  public onBack(): void {
    this.closeViewEmitter.emit();
  }
}
