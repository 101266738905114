import {Injectable} from '@angular/core';
import {CurrencyCtrl} from '../../core/sdk/controllers-currency';
import {SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {Currency, CurrencyRate, Price} from '../../core/sdk/model-currency';
import {Utility} from '../utilities/utility';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  public currencyData: Currency[];
  public euroCurrency: Currency;

  constructor(private currencyCtrl: CurrencyCtrl) {
    this.currencyCtrl.getEuroCurrency().subscribe(response => {
      this.euroCurrency = response;
    });
  }

  public getCurrency(objectId: string): Observable<Currency> {
    return this.currencyCtrl.getObject(Utility.getObjectId(objectId));
  }

  public setCurrencyData(data: Currency[]): void {
    this.currencyData = data;
  }

  public getCurrencyData(): Currency[] {
    return this.currencyData;
  }

  public getCurrencies(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Currency[]> {
    return this.currencyCtrl.getCurrencies(searchCriteriaForClass, pageNumber, pageSize);
  }

  public countCurrencies(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.currencyCtrl.countCurrencies(searchCriteriaForClass);
  }

  public createCurrency(currency: Currency): Observable<Currency> {
    return this.currencyCtrl.createCurrency(currency);
  }

  public getDefaultEuroCurrency(): Currency {
    return this.euroCurrency;
  }

  public getNewEmptyPrice(currency: Currency, value: number, discountPercentage?: number): Price {
    return {
      id: 'price/' + Utility.getUUID(),
      currency,
      value,
      discountPercentage: discountPercentage ?? 0,
      version: 0,
      timestamp: Date.now(),
    };
  }

  public getNewEmptyCurrency(name: string, shortname: string, activeCurrencyRate: CurrencyRate): Currency {
    return {
      id: 'currency/' + Utility.getUUID(),
      name,
      shortname,
      activeCurrencyRate,
      archivedCurrencyRates: [],
      isDeleted: false,
      timestamp: Date.now(),
      version: 0,
      deleted: false,
      entityVersion: 0,
    };
  }

  public updateActiveCurrencyRateInCurrency(currencyRate: CurrencyRate): void {
    for (const index in this.currencyData) {
      if (this.currencyData[index].id === currencyRate.currency.id) {
        this.currencyData[index].activeCurrencyRate = currencyRate;
        break;
      }
    }
  }


}
