<ng-container *ngIf="stepFormFlag; then stepForm; else: stepDisplayed"></ng-container>
<ng-template
  #stepDisplayed>
  <form
    [formGroup]="formGroup"
    (ngSubmit)="updateProductionSchemaStepDescription()">
    <div
      class="production-schema__record"
      [ngClass]="{'production-schema__record--half-round-borders' : !indexColumnApplied}">
      <div
        *ngIf="indexColumnApplied"
        class="production-schema__record__action-column">
        <button
          type="button"
          class="production-schema__record__action-column__arrow-up-button"
          data-cy="change-index-higher-button"
          [disabled]="shouldArrowButtonsInSchemaStepBeDisable"
          (click)="changeIndexHigher()">
          <i class="pi pi-arrow-up"></i>
        </button>
        <button
          type="button"
          class="production-schema__record__action-column__arrow-down-button"
          data-cy="change-index-lower-button"
          [disabled]="shouldArrowButtonsInSchemaStepBeDisable"
          (click)="changeIndexLower()">
          <i class="pi pi-arrow-down"></i>
        </button>
      </div>

      <div class="production-schema__record-container">
        <div class="production-schema__record__step-column">
          <label
            class="production-schema__record__step-column__box-label"
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}"
          >{{(step.type | status:ProductionSchemaStepType : TranslationKeyEnum.PRODUCTS) | translate}}</label>
          <ng-container
            *ngIf="step && transformSecondsToDurationObject(step?.stepDuration?.seconds) as stepDuration">
            <label
              class="global-subtitle global-flex-wrap"
              [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}"
            >{{'products.step-duration' |translate}}</label>
            <label [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">
              {{transformDurationObjectToString(stepDuration)}}
            </label>
          </ng-container>
        </div>

        <div class="production-schema__record__step-column">
          <label
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{'products.workspace-group' | translate}}</label>
          <p [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{step.workspaceGroup?.name}}</p>
        </div>

        <div
          *ngIf="moldType"
          class="production-schema__record__step-column">
          <label [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{'products.mold-type' | translate}}</label>
          <p [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{moldType.name}}</p>
        </div>

        <div
          *ngIf="weightReduction != null"
          class="production-schema__record__step-column">
          <label
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{'products.weight-reduction' | translate}}</label>
          <p [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{weightReduction}}</p>
        </div>

        <div
          *ngIf="quantityOfMiddleProductsAfterPostProcessing"
          class="production-schema__record__step-column">
          <label
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{'products.quantity-of-middle-products-after-molding' | translate}}</label>
          <p
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{quantityOfMiddleProductsAfterPostProcessing}}</p>
        </div>

        <div
          *ngIf="firingSchema"
          class="production-schema__record__step-column">
          <label
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{'products.firing-schema' | translate}}</label>
          <p
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{firingSchema.name}}</p>
        </div>

        <div
          *ngIf="mixingSchema"
          class="production-schema__record__step-column">
          <label
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{'products.mixing-schema' | translate}}</label>
          <p
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{mixingSchema.name}}</p>
        </div>

        <div class="production-schema__record__step-column">
          <label
            for="name"
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{'products.name' | translate}}</label>
          <ng-container
            *ngIf="!(editStepDescriptionFlag); then nameOutput; else nameInput"
            id="name"
          ></ng-container>
          <ng-template #nameOutput>
            <p [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{step.name}}</p>
          </ng-template>
          <ng-template #nameInput>
            <input
              type="text"
              class="global-input"
              placeholder="{{'products.name' | translate}}"
              formControlName="name"
              appVirtualKeyboard
              [ngClass]="nameControl.invalid ? 'global-invalid-input-box-shadow' : ''">
          </ng-template>
          <label
            for="description"
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}">{{'products.description' | translate}}</label>
          <ng-container
            *ngIf="!(editStepDescriptionFlag); then currentInput; else descriptionInput"
            id="description"
          ></ng-container>
          <ng-template #currentInput>
          <textarea
            readonly
            class="global-schema-step-description"
            [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem': ''}"
          >{{step.description.isEmpty() ? ('products.not-defined' | translate) : step.description}}</textarea>
          </ng-template>
          <ng-template #descriptionInput>
            <input
              type="text"
              class="global-input"
              formControlName="description"
              appVirtualKeyboard
              [ngClass]="descriptionControl.invalid ? 'global-invalid-input-box-shadow' : ''">
          </ng-template>
        </div>
      </div>

      <div class="production-schema__record__action-column">
        <ng-container
          *ngIf="!(editStepDescriptionFlag); then editDescription; else submitEditedDescription"></ng-container>
        <ng-template #editDescription>
          <button
            type="button"
            class="production-schema__record__action-column__edit-button"
            (click)="indexColumnApplied? toggleStepFormFlag() : toggleEditDescriptionFlag()">
            <i class="pi pi-pencil"></i>
          </button>
        </ng-template>
        <ng-template #submitEditedDescription>
          <button
            type="submit"
            class="production-schema__record__action-column__edit-button"
            data-cy="edit-button"
            [disabled]="formGroup.invalid">
            <i class="pi pi-check"></i>
          </button>
        </ng-template>
        <button
          type="button"
          class="production-schema__record__action-column__remove-button"
          data-cy="remove-button"
          (click)="removeProductionSchemaStep()">
          <i class="pi pi-trash"></i>
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #stepForm>
  <app-production-schema-step-form
    [step]="step"
    [isSchemaTemplateApplied]="isSchemaTemplateApplied"
    [closeAllStepEditionEmitter]="closeAllStepEditionEmitter"
    (submitNewStepEmitter)="updateStep($event)"
  ></app-production-schema-step-form>
</ng-template>
