<form
  *ngIf="displayForm"
  class="global-flex global-flex-direction__column"
  [formGroup]="formGroup"
  (ngSubmit)="submitForm()">

  <div
    class="production-schema-step-form"
    data-cy="production-schema-step-form-div">
    <div class="production-schema-step-form__details">
      <label class="global-title margin--top">{{"schemas.basic-information" | translate}}</label>
      <div class="production-schema-step-form__details__inputs">
        <div class="production-schema-step-form__details__inputs__base-info">

          <div class="production-schema-step-form__details__inputs__base-info__index">
            <label>{{"schemas.index" | translate}}</label>
            <input
              type="number"
              readonly
              formControlName="index">
            <span
              *ngIf="indexControl.invalid && (indexControl.dirty || indexControl.touched)"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>

          </div>
          <div class="production-schema-step-form__details__inputs__base-info__type">
            <label>{{"schemas.type" | translate}}</label>
            <p-dropdown
              id="type"
              optionLabel="label"
              optionValue="value"
              formControlName="type"
              [options]="stepTypeSelectOption"
              [readonly]="!isItNewStep"
              [ngClass]="isItNewStep ? 'global-input' : 'global-input--inactive'"
              (onChange)="updateStepTypeValue()"
            ></p-dropdown>
            <span
              *ngIf="typeControl.invalid && (typeControl.dirty || typeControl.touched)"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>

          </div>

        </div>
        <div class="production-schema-step-form__details__inputs__assigning"
             [id]="'assigningContainer' + step.id">

          <div>
            <label for="workspaceInput">{{'schemas.workspace-group' | translate}}</label>
            <div id="workspaceInput">
              <input
                readonly
                type="text"
                class="global-input"
                value="{{workspaceGroup?.name}}"
                placeholder="{{'schemas.select-workspace-group' | translate}}"
                formControlName="workspaceGroupName">
              <button
                type="button"
                data-cy="assign-workspace-group-button"
                (click)="assignWorkspaceGroup()"
              >{{'schemas.assign-workspace-group' | translate}}</button>
            </div>
            <span
              *ngIf="workspaceGroupNameControl.invalid && (workspaceGroupNameControl.dirty || workspaceGroupNameControl.touched)"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>

          </div>

          <div *ngIf="isBurningStepType">
            <label for="firingSchemaInput">{{'schemas.firing-schema' | translate}}</label>
            <div id="firingSchemaInput">
              <input
                readonly
                id="firingSchemaName"
                type="text"
                class="global-input"
                value="{{firingSchema?.name}}"
                placeholder="{{'schemas.select-firing-schema' | translate}}"
                formControlName="firingSchemaName">
              <button
                type="button"
                data-cy="assign-firing-schema-button"
                (click)="assignFiringSchema()"
              >{{'schemas.assign-firing-schema' | translate}}</button>
            </div>
            <span
              *ngIf="firingSchemaNameControl.invalid && (firingSchemaNameControl.dirty || firingSchemaNameControl.touched)"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>
          </div>

          <div *ngIf="isMoldingStepType">
            <label for="moldTypeInput">{{'schemas.mold-type' | translate}}</label>
            <div id="moldTypeInput">
              <input
                readonly
                id="moldTypeName"
                type="text"
                class="global-input"
                value="{{moldType?.name}}"
                placeholder="{{'schemas.select-mold-type' | translate}}"
                formControlName="moldTypeName">
              <button
                type="button"
                data-cy="assign-mold-type-button"
                (click)="assignMoldType()"
              >{{'schemas.assign-mold-type' | translate}}</button>
            </div>
            <span
              *ngIf="moldTypeNameControl.invalid && (moldTypeNameControl.dirty || moldTypeNameControl.touched)"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>
          </div>

          <div *ngIf="isMixingMassesSchemaStepType">
            <label for="mixingSchemaInput">{{'schemas.mixing-schema' | translate}}</label>
            <div id="mixingSchemaInput">
              <input
                readonly
                type="text"
                class="global-input"
                value="{{mixingSchema?.name}}"
                placeholder="{{'schemas.select-mixing-schema' | translate}}"
                formControlName="mixingSchemaName">
              <button
                type="button"
                (click)="assignMixingSchema()"
              >{{'schemas.assign-mixing-schema' | translate}}</button>
            </div>
            <span
              *ngIf="mixingSchemaNameControl.invalid && (mixingSchemaNameControl.dirty || mixingSchemaNameControl.touched)"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>
          </div>

        </div>

        <div class="production-schema-step-form__details__inputs__description">
          <label
            class="global-subtitle"
            for="name"
          >{{'schemas.name' | translate}}</label>
          <input
            id="name"
            type="text"
            class="global-input global-margin__small-bottom"
            formControlName="name">
          <span
            *ngIf="nameControl.invalid && (nameControl.dirty || nameControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>

          <label
            for="description"
            class="global-subtitle"
          >{{'schemas.description' | translate}}</label>
          <textarea
            id="description"
            class="global-input"
            formControlName="description"
          ></textarea>
          <span
            *ngIf="descriptionControl.invalid && (descriptionControl.dirty || descriptionControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>

          <div *ngIf="isDryingStepType">
            <label for="weightReductionInput">{{'schemas.weight-reduction' | translate}}</label>
            <p-inputNumber
              id="weightReductionInput"
              class="global-input global-margin__small-bottom global-no-border-and-padding"
              formControlName="weightReduction"
              mode="decimal"
              [locale]="currentLocalValue"
              [minFractionDigits]="2"
            ></p-inputNumber>
            <span
              *ngIf="weightReductionControl.invalid && (weightReductionControl.dirty || weightReductionControl.touched)"
              class="global-error-message"
            >{{'common.valid-value-required' | translate}}</span>
          </div>

          <div *ngIf="isPostProcessingStepType">
            <label
              for="quantityOfMiddleProductsAfterPostProcessing">{{'schemas.quantity-of-middle-products-after-post-processing' | translate}}</label>
            <input
              id="quantityOfMiddleProductsAfterPostProcessing"
              type="number"
              appIntegerInputValidation
              class="global-input global-margin__small-bottom"
              formControlName="quantityOfMiddleProductsAfterPostProcessing">
            <span
              *ngIf="quantityOfMiddleProductsAfterPostProcessingControl.invalid && (quantityOfMiddleProductsAfterPostProcessingControl.dirty || quantityOfMiddleProductsAfterPostProcessingControl.touched)"
              class="global-error-message"
            >{{'common.valid-value-required' | translate}}</span>
          </div>

        </div>

      </div>
      <div class="production-schema-step-form__details__numbers">
        <div class="production-schema-step-form__details__numbers__time">
          <label class="global-subtitle global-margin__small-bottom">{{"schemas.step-duration" | translate}}</label>
          <label for="days">{{'common.days' | translate}}</label>
          <input
            id="days"
            type="number"
            appIntegerInputValidation
            formControlName="days">
          <span
            *ngIf="daysControl.invalid && (daysControl.dirty || daysControl.touched)"
            class="global-error-message"
          >{{'common.value-cannot-be-negative-or-greater-than-999' | translate}}</span>

          <label for="hours">{{"common.hours" | translate}}</label>
          <input
            id="hours"
            type="number"
            appIntegerInputValidation
            formControlName="hours">
          <span
            *ngIf="hoursControl.invalid && (hoursControl.dirty || hoursControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label for="minutes">{{"common.minutes" |translate}}</label>
          <input
            id="minutes"
            type="number"
            appIntegerInputValidation
            formControlName="minutes">
          <span
            *ngIf="minutesControl.invalid && (minutesControl.dirty || minutesControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label for="seconds">{{"common.seconds" | translate}}</label>
          <input
            id="seconds"
            type="number"
            appIntegerInputValidation
            formControlName="seconds">
          <span
            *ngIf="secondsControl.invalid && (secondsControl.dirty || secondsControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
        </div>

        <div class="production-schema-step-form__details__numbers__costs">
          <label class="global-subtitle global-margin__small-bottom">{{"schemas.costs" | translate}}</label>
          <label>{{"schemas.direct-labor-rate-cost" | translate}}</label>
          <p-inputNumber
            class="global-no-border-and-padding"
            id="directLaborRateCost"
            formControlName="directLaborRateCost"
            mode="decimal"
            [locale]="currentLocalValue"
            [minFractionDigits]="2"
          ></p-inputNumber>
          <span
            *ngIf="directLaborRateCostControl.invalid && (directLaborRateCostControl.dirty || directLaborRateCostControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label>{{"schemas.indirect-labor-rate-cost" | translate}}</label>
          <p-inputNumber
            class="global-no-border-and-padding"
            id="indirectLaborRateCost"
            formControlName="indirectLaborRateCost"
            mode="decimal"
            [locale]="currentLocalValue"
            [minFractionDigits]="2"
          ></p-inputNumber>
          <span
            *ngIf="indirectLaborRateCostControl.invalid && (indirectLaborRateCostControl.dirty || indirectLaborRateCostControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label>{{"schemas.energy-cost" | translate}}</label>
          <p-inputNumber
            class="global-no-border-and-padding"
            id="energyCost"
            formControlName="energyCost"
            mode="decimal"
            [locale]="currentLocalValue"
            [minFractionDigits]="2"
          ></p-inputNumber>
          <span
            *ngIf="energyCostControl.invalid && (energyCostControl.dirty || energyCostControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label>{{"schemas.fixed-overhead-cost" | translate}}</label>
          <p-inputNumber
            class="global-no-border-and-padding"
            id="fixedOverheadCost"
            formControlName="fixedOverheadCost"
            mode="decimal"
            [locale]="currentLocalValue"
            [minFractionDigits]="2"
          ></p-inputNumber>
          <span
            *ngIf="fixedOverheadCostControl.invalid && (fixedOverheadCostControl.dirty || fixedOverheadCostControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label>{{"schemas.variable-overhead-cost" | translate}}</label>
          <p-inputNumber
            class="global-no-border-and-padding"
            id="variableOverheadCost"
            formControlName="variableOverheadCost"
            mode="decimal"
            [locale]="currentLocalValue"
            [minFractionDigits]="2"
          ></p-inputNumber>
          <span
            *ngIf="variableOverheadCostControl.invalid && (variableOverheadCostControl.dirty || variableOverheadCostControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="production-schema-step-form__footer">
    <button
      type="submit"
      class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
      data-cy="save-button"
      [disabled]="this.formGroup.invalid"
    >{{'common.save' | translate}}</button>
    <button
      type="button"
      class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
      data-cy="delete-button"
      (click)="closeComponent()"
    >{{(isItNewStep ? 'schemas.delete' : 'common.cancel') | translate}}</button>
  </div>
</form>
