import {Component, Input, OnInit} from '@angular/core';
import {Utility} from '../../utilities/utility';
import {BaseObjectRecord} from '../../interfaces/interfaces';

@Component({
  selector: 'app-base-details-display',
  templateUrl: './base-details-display.component.html',
})
export class BaseDetailsDisplayComponent implements OnInit {

  public isTerminalDevice: boolean;

  @Input() public baseDetailsRecords: BaseObjectRecord[];
  @Input() public detailsTitle: string;
  @Input() public translationModule: string;

  public ngOnInit(): void {
    this.isTerminalDevice = Utility.isTerminalDevice();
  }

  public getBaseRecordUnit(unit: string): string {
    return unit ? ` [${unit}]` : '';
  }

}
