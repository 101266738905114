import {Injectable} from '@angular/core';
import {
  BurningSchemaStep,
  DryingSchemaStep, MixingMassesSchemaStep,
  MoldingSchemaStep,
  PostProcessingSchemaStep,
  ProductionSchema,
  ProductionSchemaStep,
  ProductionSchemaTemplate
} from '../../core/sdk/model-productionschema';
import {FiringSchema, FiringSchemaStep, FiringSchemaTemplate} from '../../core/sdk/model-firingschema';
import {Utility} from '../utilities/utility';
import {TranslateService} from '../../core/translations/translate.service';
import {ProductionSchemaService} from './production-schema.service';
import {FiringSchemaService} from './production/firing-schema.service';
import {Observable, Subject} from 'rxjs';
import {ProductType} from '../../core/sdk/model-producttype';
import {FurnaceModel} from '../../core/sdk/model-productiondevices';
import {Router} from '@angular/router';
import {ProductionSchemaStepType, WorkspaceGroupType} from '../../core/sdk/enums-types';
import {SchemaService} from './schema.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionFiringSchemaService {

  originalSchemaSteps: ProductionSchemaStep[] | FiringSchemaStep[];
  schemaSteps: ProductionSchemaStep[] | FiringSchemaStep[];

  public currentlyEditedStepIDs: string[] = [];

  private shouldArrowButtonsBeDisabledSource = new Subject<string[]>();

  public shouldArrowButtonsBeDisabledSourceContent = this.shouldArrowButtonsBeDisabledSource.asObservable();

  constructor(private translateService: TranslateService,
              private productionSchemaService: ProductionSchemaService,
              private firingSchemaService: FiringSchemaService,
              private schemaService: SchemaService) {
  }

  public shouldArrowButtonsBeDisabled(): void {
    this.shouldArrowButtonsBeDisabledSource.next(this.currentlyEditedStepIDs);
  }

  public addToCurrentlyEditedSteps(schemaStepId: string): void {
    this.currentlyEditedStepIDs.push(schemaStepId);
    this.shouldArrowButtonsBeDisabled();
  }

  public removeFromCurrentlyEditedSteps(schemaStepId: string): void {
    this.currentlyEditedStepIDs.splice(this.currentlyEditedStepIDs.indexOf(schemaStepId), 1);
    this.shouldArrowButtonsBeDisabled();
  }

  public flushAllOpenedSteps(): void {
    this.currentlyEditedStepIDs = [];
  }

  public isAnyStepOpened(): boolean {
    return this.currentlyEditedStepIDs.length > 0;
  }

  // SCHEMAS

  public getEmptySchema(object: ProductType | FurnaceModel): ProductionSchema | FiringSchema {
    switch (true) {
      case object.id.includes('product'): {
        return this.productionSchemaService.getEmptyProductionSchema(object as ProductType);
      }
      case object.id.includes('furnace'): {
        return this.firingSchemaService.getEmptyFiringSchema();
      }
    }
  }

  public updateSchema(schema: ProductionSchema | FiringSchema): Observable<ProductionSchema | FiringSchema> {
    switch (true) {
      case schema.id.includes('production'): {
        return this.productionSchemaService.updateProductionSchema(schema as ProductionSchema);
      }
      case schema.id.includes('firing'): {
        return this.firingSchemaService.updateFiringSchema(schema as FiringSchema);
      }
    }
  }


  // STEPS

  public isItNewStep(stepId: string): boolean {
    for (const step of this.originalSchemaSteps) {
      if (step.id === stepId) {
        return false;
      }
    }
    return true;
  }

  public updateEditedStepInSchemaSteps(step: ProductionSchemaStep): void {
    for (const index in this.getSchemaSteps()) {
      if (this.getSchemaSteps()[index].id === step.id) {
        if (this.isItNewStep(step.id)) {
          step.id = this.getValidId(step);
        }
        this.getSchemaSteps().splice(parseInt(index, 10), 1, step);
        break;

      }
    }
  }

  public getEmptySchemaStep(schemaStepType: string): ProductionSchemaStep | FiringSchemaStep |
    BurningSchemaStep | MoldingSchemaStep | MixingMassesSchemaStep {
    switch (true) {
      case schemaStepType.includes('production'):
        return this.productionSchemaService.getEmptyProductionSchemaStep();
      case schemaStepType.includes('firing'):
        return this.firingSchemaService.getEmptyFiringSchemaStep();
      case schemaStepType.includes('burning'):
        return this.productionSchemaService.getEmptyBurningSchemaStep();
      case schemaStepType.includes('molding'):
        return this.productionSchemaService.getEmptyMoldingSchemaStep();
      case schemaStepType.includes('drying'):
        return this.productionSchemaService.getEmptyDryingSchemaStep();
      case schemaStepType.includes('postProcessing'):
        return this.productionSchemaService.getEmptyPostProcessingSchemaStep();
      case schemaStepType.includes('mixingMasses'):
        return this.productionSchemaService.getEmptyMixingMassesSchemaStep();
    }
  }

  public pushNewStepToData(step: ProductionSchemaStep | FiringSchemaStep): void {
    if (step.id.includes('firing')) {
      if (this.schemaSteps) {
        (this.schemaSteps as FiringSchemaStep[]).push(step as FiringSchemaStep);
      } else {
        this.schemaSteps = [step as FiringSchemaStep];
      }
    } else {
      if (this.schemaSteps) {
        (this.schemaSteps as ProductionSchemaStep[]).push(step as ProductionSchemaStep);
      } else {
        this.schemaSteps = [step as ProductionSchemaStep];
      }
    }
  }

  public getValidId(step: ProductionSchemaStep | BurningSchemaStep | MoldingSchemaStep): string {
    switch (step.type) {
      case ProductionSchemaStepType.PRODUCTION_SCHEMA_STEP:
        return 'production-schema-step/' + Utility.getUUID();
      case ProductionSchemaStepType.BURNING_SCHEMA_STEP:
        return 'burning-schema-step/' + Utility.getUUID();
      case ProductionSchemaStepType.MOLDING_SCHEMA_STEP:
        return 'molding-schema-step/' + Utility.getUUID();
      case ProductionSchemaStepType.DRYING_SCHEMA_STEP:
        return 'drying-schema-step/' + Utility.getUUID();
      case ProductionSchemaStepType.POST_PROCESSING_SCHEMA_STEP:
        return 'post-processing-schema-step/' + Utility.getUUID();
      case ProductionSchemaStepType.MIXING_MASSES_SCHEMA_STEP:
        return 'mixing-masses-schema-step/' + Utility.getUUID();
    }
  }

  public joinStepsToData(steps: ProductionSchemaStep[] | FiringSchemaStep[]): void {
    if (!this.schemaSteps) {
      this.assignSchemaStepsToData(steps);
    } else {
      if (steps && steps.length) {
        const temporaryData = this.schemaSteps;
        if (steps[0].id.includes('firing')) {
          (temporaryData as FiringSchemaStep[]).push(...steps as FiringSchemaStep[]);
        } else {
          (temporaryData as ProductionSchemaStep[]).push(...steps as ProductionSchemaStep[]);
        }
        this.assignSchemaStepsToData(temporaryData);
        this.updateIndexAndPropertiesInSchemaSteps();
      }
    }
  }

  public updateSchemaStep(schemaStep: ProductionSchemaStep | FiringSchemaStep | DryingSchemaStep)
    : Observable<ProductionSchemaStep | FiringSchemaStep | DryingSchemaStep | MoldingSchemaStep> {
    switch (true) {
      case schemaStep.id.includes('production'): {
        return this.productionSchemaService.updateProductionSchemaStep(schemaStep as ProductionSchemaStep);
      }
      case schemaStep.id.includes('firing'):
        return this.firingSchemaService.updateFiringSchemaStep(schemaStep as FiringSchemaStep);
      case schemaStep.id.includes('burning'):
        return this.productionSchemaService.updateBurningSchemaStep(schemaStep as BurningSchemaStep);
      case schemaStep.id.includes('molding'):
        return this.productionSchemaService.updateMoldingSchemaStep(schemaStep as MoldingSchemaStep);
      case schemaStep.id.includes('drying'):
        return this.productionSchemaService.updateDryingSchemaStep(schemaStep as DryingSchemaStep);
      case schemaStep.id.includes('post-processing'):
        return this.productionSchemaService.updatePostProcessingSchemaStep(schemaStep as PostProcessingSchemaStep);
      case schemaStep.id.includes('mixing'):
        return this.productionSchemaService.updateMixingMassesSchemaStep(schemaStep as MixingMassesSchemaStep);
    }
  }

  public removeSchemaStep(schemaStepId: string): Observable<ProductionSchema> {
    return this.productionSchemaService.removeProductionSchemaStep(schemaStepId);
  }

  public assignSchemaSteps(stepList: ProductionSchemaStep[] | FiringSchemaStep[]): void {
    this.assignSchemaStepsToData(stepList);
    this.originalSchemaSteps = Object.assign([], stepList);
    this.sortProductionSchemaSteps();
  }

  public assignSchemaStepsToData(stepList: ProductionSchemaStep[] | FiringSchemaStep[]): void {
    this.schemaSteps = stepList;
  }

  public getSchemaSteps(): FiringSchemaStep[] | ProductionSchemaStep[] {
    return this.schemaSteps;
  }

  public flushSchemaSteps(): void {
    this.flushSchemaStepsFromData();
    this.originalSchemaSteps = undefined;
  }

  public flushSchemaStepsFromData(): void {
    this.schemaSteps = undefined;
  }

  public clearSchemaStepsFromData(): void {
    this.schemaSteps = [];
  }

  public sortProductionSchemaSteps(): void {
    this.schemaSteps?.sort((a, b) => Utility.compareNumbers(a.index, b.index));
  }

  public getTranslatedValueOfStepType(enumValue: number, schemaStepType: any): string {
    return this.translateService.translate(schemaStepType[enumValue]);
  }

  public changeIndexHigher(step: ProductionSchemaStep | FiringSchemaStep): void {
    this.schemaSteps = this.schemaService.changeIndexHigher(step, this.schemaSteps);
  }

  public changeIndexLower(step: ProductionSchemaStep | FiringSchemaStep): void {
    this.schemaSteps = this.schemaService.changeIndexLower(step, this.schemaSteps);
  }

  public findStepIndexInArray(step: ProductionSchemaStep | FiringSchemaStep): number {
    return this.schemaSteps ? this.schemaSteps.findIndex(item => item.id === step.id) : -1;
  }

  public updateIndexAndPropertiesInSchemaSteps(validSchema?: ProductionSchema): void {
    if (this.schemaSteps) {
      this.schemaSteps.forEach((item, index) => {
        this.schemaSteps[index].index = index + 1;
        if (validSchema) {
          (this.schemaSteps[index] as ProductionSchemaStep).productionSchema = validSchema as ProductionSchema;
        }
      });
    }
  }

  public removeStepFromData(step: ProductionSchemaStep | FiringSchemaStep): void {
    this.schemaSteps.splice(this.findStepIndexInArray(step), 1);
  }

  public getWorkspaceGroupTypeForCurrentStep(stepType: ProductionSchemaStepType): WorkspaceGroupType {
    switch (stepType) {
      case ProductionSchemaStepType.PRODUCTION_SCHEMA_STEP:
        return WorkspaceGroupType.WITHOUT_MACHINE;
      case ProductionSchemaStepType.BURNING_SCHEMA_STEP:
        return WorkspaceGroupType.OVEN_OPERATION;
      case ProductionSchemaStepType.DRYING_SCHEMA_STEP:
        return WorkspaceGroupType.DRYING_AREA;
      case ProductionSchemaStepType.MOLDING_SCHEMA_STEP:
        return WorkspaceGroupType.MACHINE_OPERATION;
      case ProductionSchemaStepType.POST_PROCESSING_SCHEMA_STEP:
        return WorkspaceGroupType.POST_PROCESSING_OPERATION;
      case ProductionSchemaStepType.MIXING_MASSES_SCHEMA_STEP:
        return WorkspaceGroupType.MIXING_STAND_OPERATION;
    }
  }

  public getDeletedStepIdArray(): string[] {
    const deletedSteps: string[] = [];
    this.originalSchemaSteps.forEach(step => {
      if (this.findStepIndexInArray(step) === -1) {
        deletedSteps.push(Utility.getObjectId(step.id));
      }
    });
    return deletedSteps;
  }

  public deleteRemovedSteps(deletedStepsIdList: string[]): Observable<void> {
    return this.productionSchemaService.removeProductionSchemaStepFromList(deletedStepsIdList);
  }

  // SCHEMA TEMPLATES

  public importProductionSchemaTemplateSteps(templateId: string): Observable<ProductionSchemaStep[]> {
    return this.productionSchemaService.importProductionSchemaTemplate(Utility.getObjectId(templateId));
  }

  public importFiringSchemaTemplate(templateId: string): Observable<FiringSchemaTemplate> {
    return this.firingSchemaService.getFiringSchemaTemplateWithDuplicatedStepList(Utility.getObjectId(templateId));
  }

  public updateSchemaTemplate(schemaTemplate: ProductionSchemaTemplate | FiringSchemaTemplate)
    : Observable<ProductionSchemaTemplate | FiringSchemaTemplate> {
    switch (true) {
      case schemaTemplate.id.includes('production'): {
        return this.productionSchemaService.updateProductionSchemaTemplate(schemaTemplate as ProductionSchemaTemplate);
      }
      case schemaTemplate.id.includes('firing'): {
        return this.firingSchemaService.updateFiringSchemaTemplate(schemaTemplate as FiringSchemaTemplate);
      }
    }
  }

  public getCurrentPathName(router: Router): string {
    const url = router.url.split('/');
    return url[url.length - 1];
  }
}
