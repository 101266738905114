<div class="document-file">
  <button
    *ngIf="showReturnButton"
    type="button"
    class="common-button"
    (click)="closeEmitter.emit()"
  >{{'common.return' | translate}}</button>

  <h3>{{'order-management.document-files' | translate}}</h3>

  <div
    class="invoice-details__invoice-files-container"
    [ngClass]="containerStyle">
      <span
        [ngStyle]="{'color': showReturnButton ? 'black' : 'white'}"
        style="color: white; font-size: 1.5rem; margin-top: 1.5rem">{{('order-management.applicable-tax' | translate) + ' : ' + (getApplicableTaxEnumValue(currentInvoice.applicableTax)| translate)}}</span>

    <div class="invoice-details__invoice-files-container__file">
      <img
        class="global-logo"
        alt="german"
        src="../../../../assets/german_flag.png">
      <app-invoice-action
        [fileInfo]="germanFileInfo"
        [currentInvoice]="currentInvoice"
        [locale]="germanLocale"
        (generateFileEmitter)="loadDocumentFiles()">
      </app-invoice-action>
    </div>
    <div class="invoice-details__invoice-files-container__file">
      <img
        class="global-logo"
        alt="english"
        src="../../../../assets/uk_flag.png">
      <app-invoice-action
        [fileInfo]="englishFileInfo"
        [currentInvoice]="currentInvoice"
        [locale]="englishLocale"
        (generateFileEmitter)="loadDocumentFiles()">
      </app-invoice-action>
    </div>
  </div>
</div>
