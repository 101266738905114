import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdditionalAddressLine, Address} from '../../../core/sdk/model-address';
import {BaseFormField} from '../../../shared/components/base-form/base-form-field';
import {AddressService} from '../../../shared/services/address.service';
import {StringInputField} from '../../../shared/components/base-form/string-input-field';
import {SelectField} from '../../../shared/components/base-form/select-field';
import {FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../../shared/services/notification.service';
import {TranslateService} from '../../../core/translations/translate.service';
import {EnumTranslationsComponent} from '../../../shared/enum-translations/enum-translations.component';
import {NotificationMessageType} from '../../../shared/enums/NotificationMessageType';
import {EnumService} from '../../../shared/services/enum.service';
import {Utility} from '../../../shared/utilities/utility';
import {Router} from '@angular/router';
import {AdditionalAddressLineType} from '../../../core/sdk/model-enumentity';

@Component({
  selector: 'app-customer-additional-address-line',
  templateUrl: './customer-additional-address-line.component.html'
})
export class CustomerAdditionalAddressLineComponent implements OnInit {

  private additionalAddressLineList: AdditionalAddressLine[];

  public formFields: BaseFormField<string | AdditionalAddressLineType>[] = [];
  public newTypeFormFields: BaseFormField<string | AdditionalAddressLineType>[] = [];
  public addNewTypeFlag = false;
  public editedLine: AdditionalAddressLine;

  @Input() public currentAddress: Address;
  @Input() public additionalAddressLineTypeList: AdditionalAddressLineType[];

  @Output() public returnValidAdditionalAddressLineList: EventEmitter<AdditionalAddressLine[] | void>
    = new EventEmitter<AdditionalAddressLine[] | void>();
  @Output() public returnValidAdditionalAddressLineTypeList: EventEmitter<AdditionalAddressLineType[] | void>
    = new EventEmitter<AdditionalAddressLineType[] | void>();

  @ViewChild('enumTranslationComponent') public enumTranslationsComponent: EnumTranslationsComponent;

  constructor(public addressService: AddressService,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private router: Router,
              private enumService: EnumService) {
  }

  public ngOnInit(): void {
    this.initiateLists();
  }

  private initiateLists(): void {
    this.additionalAddressLineList = this.currentAddress?.additionalAddressLineList ?? [];
    if (this.additionalAddressLineTypeList) {
      this.newFormInit();
    } else {
      this.addressService.getAdditionalAddressLineType().subscribe(response => {
        this.additionalAddressLineTypeList = response;
        this.newFormInit();
      }, (error) => {
        this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
      });
    }
  }

  private newFormInit(): void {
    this.formFields = [
      new StringInputField({
        key: 'additionalAddressLine',
        label: 'customers.additional-address-line',
        value: this.editedLine ? this.editedLine.value : '',
        placeholder: 'customers.type-here',
        order: 1,
        alert: 'common.field-required',
        validators: [Validators.required]
      }),
      new SelectField({
        key: 'additionalAddressLineType',
        label: 'customers.additional-address-line-type',
        value: this.getAdditionalAddressLineTypeInArrayForEditedObject(),
        options: {objects: this.additionalAddressLineTypeList, field: 'name'},
        order: 2,
        placeholder: 'customers.select-one',
        inputStyle: 'global-input primeng-dropdown-override',
        alert: 'common.field-required',
        validators: [Validators.required]
      }),
    ];
  }

  private getAdditionalAddressLineTypeInArrayForEditedObject(): AdditionalAddressLineType | '' {
    if (this.editedLine && this.editedLine?.type) {
      const indexInArray = this.additionalAddressLineTypeList.findIndex(item => item.id === this.editedLine.type.id);
      if (indexInArray !== -1) {
        return this.additionalAddressLineTypeList[indexInArray];
      }
    }
    return '';
  }

  private newTypeFormInit(): void {
    this.newTypeFormFields = [
      new StringInputField({
        key: 'additionalAddressLineType',
        label: 'customers.additional-address-line-type',
        value: '',
        placeholder: 'customers.type-here',
        alert: 'common.field-required',
        validators: [Validators.required]
      })
    ];
  }

  public submitAdditionalAddressLine(form: FormGroup): void {
    const {additionalAddressLine, additionalAddressLineType} = form.value;
    this.editedLine.value = additionalAddressLine;
    this.editedLine.type = additionalAddressLineType;
    if (this.additionalAddressLineList.includes(this.editedLine)) {
      Utility.updateObjectInData(this.editedLine, this.additionalAddressLineList);
      this.flushLineFlagAndObject();
      this.newFormInit();
    } else {
      this.additionalAddressLineList.push(this.editedLine);
      this.flushLineFlagAndObject();
      this.newFormInit();
    }
    this.returnValidAdditionalAddressLineList.emit(this.additionalAddressLineList);
  }

  private flushLineFlagAndObject(): void {
    this.editedLine = undefined;
  }

  private flushTypeFlagAndObject(): void {
    this.addNewTypeFlag = false;
  }

  public addNewLine(): void {
    this.editedLine = this.addressService.getNewAdditionalAddressLine();
    this.editedLine.address = this.currentAddress;
    this.newFormInit();
  }

  public editLine(addressLine: AdditionalAddressLine): void {
    this.editedLine = addressLine;
    this.newFormInit();
  }

  public addNewType(): void {
    this.addNewTypeFlag = true;
    this.newTypeFormInit();
  }

  public submitNewType(form: FormGroup): void {
    const {additionalAddressLineType} = form.value;
    const translations = this.enumTranslationsComponent.getIntroducedTranslations();
    if (!this.enumTranslationsComponent.checkIfTranslationsAreValid(translations)) {
      this.notificationService.displayNotificationToast('customers.translation-value-must-be-present', NotificationMessageType.ERROR);
    } else if (this.checkIfTypeExist(additionalAddressLineType)) {
      this.notificationService.displayNotificationToast('customers.type-already-exist', NotificationMessageType.ERROR);

    } else {
      this.addressService.createAdditionalAddressLineType(additionalAddressLineType).subscribe(response => {
        translations.forEach(item => {
          item.enumEntity = response;
        });
        this.enumService.addEnumTranslationList(translations).subscribe(translationsResponse => {
          this.notificationService.displayNotificationToast(
            'customers.additional-address-line-type-created-successfully', NotificationMessageType.SUCCESS);
          response.translations = translationsResponse;
          this.additionalAddressLineTypeList.push(response);
          this.returnValidAdditionalAddressLineTypeList.emit(this.additionalAddressLineTypeList);
          this.flushTypeFlagAndObject();
          this.newFormInit();
          this.newTypeFormInit();
        });
      }, (error) => this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error),
        NotificationMessageType.ERROR));
    }
  }

  public checkIfTypeExist(additionalAddressLineTypeName: string): boolean {
    return !!(this.additionalAddressLineTypeList.find(item => item.name === additionalAddressLineTypeName));
  }


  public removeLine(addressLine: AdditionalAddressLine): void {
    this.additionalAddressLineList.splice(this.additionalAddressLineList.indexOf(addressLine), 1);
    this.returnValidAdditionalAddressLineList.emit(this.additionalAddressLineList);
    this.cancelAdditionalAddressLineFields();
    this.newFormInit();
  }

  public cancelAdditionalAddressLineFields(): void {
    this.editedLine = undefined;
    this.cancelAdditionalAddressLineTypeFields();
    this.newFormInit();
  }

  public cancelAdditionalAddressLineTypeFields(): void {
    this.addNewTypeFlag = false;
    this.newTypeFormInit();
  }

  public returnTypeName(additionalAddressLineTypeId: string): string {
    return this.additionalAddressLineTypeList.find(record => record.id === additionalAddressLineTypeId)?.translations[0].translationValue;
  }

  public getAdditionalAddressLines(): AdditionalAddressLine[] {
    return this.additionalAddressLineList ?? [];
  }

}
