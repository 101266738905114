import {Component, Input} from '@angular/core';
import {InventoryInStoringProduct} from '../../../../core/sdk/model-inventory';
import {InventoryProductStatus, ProductStoredInstanceRequestStatus} from '../../../../core/sdk/enums-statuses';

@Component({
  selector: 'app-mobile-inventory-in-storing-product-info',
  templateUrl: './mobile-inventory-in-storing-product-info.component.html',
})
export class MobileInventoryInStoringProductInfoComponent {

  @Input() public inventoryInStoringProduct: InventoryInStoringProduct;
  @Input() public backgroundColor: string;
  @Input() public displayBorder: boolean = true;
  @Input() public title: string;

  protected readonly InventoryProductStatus = InventoryProductStatus;
  protected readonly ProductStoredInstanceRequestStatus = ProductStoredInstanceRequestStatus;
}
