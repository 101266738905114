<form
  id="stepForm"
  class="global-flex global-flex-direction__column"
  [formGroup]="this.formGroup"
  (ngSubmit)="submitForm()">
  <div
    class="production-schema-step-form">
    <div class="production-schema-step-form__details">
      <label class="global-title margin--top">{{"production.basic-information" | translate}}</label>
      <div class="production-schema-step-form__details__inputs">
        <div class="production-schema-step-form__details__inputs__base-info">

          <div class="production-schema-step-form__details__inputs__base-info__index">
            <label>{{"production.index" | translate}}</label>
            <input
              readonly
              id="index"
              type="number"
              appIntegerInputValidation
              formControlName="index">
            <span
              *ngIf="indexControl.invalid && (indexControl.dirty || indexControl.touched)"
              class="global-error-message"
            >{{'common.valid-value-required' | translate}}</span>
          </div>
          <div class="production-schema-step-form__details__inputs__base-info__type">
            <label>{{"production.action-type" | translate}}</label>
            <p-dropdown
              optionValue="value"
              optionLabel="label"
              formControlName="actionType"
              [options]="actionTypeSelectOption"
              (onChange)="addProperControlsBasedOnSelectedActionType($event.value)"
            ></p-dropdown>
            <span
              *ngIf="actionTypeControl.invalid && (actionTypeControl.dirty || actionTypeControl.touched)"
              class="global-error-message"
            >{{'common.valid-value-required' | translate}}</span>
          </div>
        </div>

        <div
          *ngIf="showAssigningRawMaterialControls"
          class="production-schema-step-form__details__inputs__assigning global-align__items-center">

          <div>
            <label for="rawMaterialInput">{{'schemas.raw-material' | translate}}</label>
            <div id="rawMaterialInput">
              <input
                readonly
                type="text"
                class="global-input"
                formControlName="rawMaterial">
              <button
                type="button"
                [disabled]="true"
              >{{'schemas.assign-raw-material' | translate}}</button>
            </div>
            <span
              *ngIf="rawMaterialControl.invalid && (rawMaterialControl.dirty || rawMaterialControl.touched)"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>

          </div>

          <div class="global-align__items-center">
            <label for="tonnage">{{'common.tonnage' | translate}}</label>
            <input
              class="global-width--7vw"
              id="tonnage"
              type="number"
              appIntegerInputValidation
              formControlName="tonnage">
            <span
              *ngIf="tonnageControl.invalid && (tonnageControl.dirty || tonnageControl.touched)"
              class="global-error-message"
            >{{'common.value-cannot-be-negative-or-greater-than-999' | translate}}</span>
          </div>
        </div>

        <div
          *ngIf="showWaterAdditionControl"
        class="global-flex global-flex-direction__column global-align__items-center">
          <label for="waterAdditionInLiters"
          >{{'production.water-addition-in-liters' | translate}}</label>
          <p-inputNumber
            id="waterAdditionInLiters"
            class="global-input global-width--50 global-no-border-and-padding"
            formControlName="waterAdditionInLiters"
            mode="decimal"
            [locale]="currentLocalValue"
            [minFractionDigits]="2"
            ></p-inputNumber>
          <span
            *ngIf="waterAdditionInLitersControl.invalid && (waterAdditionInLitersControl.dirty || waterAdditionInLitersControl.touched)"
            class="global-error-message"
          >{{'common.value-cannot-be-negative-or-greater-than-999' | translate}}</span>
        </div>

        <div class="production-schema-step-form__details__inputs__description">
          <label class="global-subtitle">{{'production.description' | translate}}</label>
          <textarea
            id="description"
            class="global-input"
            formControlName="description"
          ></textarea>
          <span
            *ngIf="descriptionControl.invalid && (descriptionControl.dirty || descriptionControl.touched)"
            class="global-error-message"
          >{{'common.max-length-for-this-field-is-255-signs' | translate}}</span>
        </div>

      </div>

      <div
        *ngIf="showDurationControls"
        class="production-schema-step-form__details__numbers">
        <div class="production-schema-step-form__details__numbers__time">
          <label class="global-subtitle global-margin__small-bottom">{{"production.step-duration" | translate}}</label>
          <label for="days">{{'common.days' | translate}}</label>
          <input
            id="days"
            type="number"
            appIntegerInputValidation
            formControlName="days">
          <span
            *ngIf="daysControl.invalid && (daysControl.dirty || daysControl.touched)"
            class="global-error-message"
          >{{'common.value-cannot-be-negative-or-greater-than-999' | translate}}</span>

          <label for="hours">{{"common.hours" | translate}}</label>
          <input
            id="hours"
            type="number"
            appIntegerInputValidation
            formControlName="hours">
          <span
            *ngIf="hoursControl.invalid && (hoursControl.dirty || hoursControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label for="minutes">{{"common.minutes" |translate}}</label>
          <input
            id="minutes"
            type="number"
            appIntegerInputValidation
            formControlName="minutes">
          <span
            *ngIf="minutesControl.invalid && (minutesControl.dirty || minutesControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label for="seconds">{{"common.seconds" | translate}}</label>
          <input
            id="seconds"
            type="number"
            appIntegerInputValidation
            formControlName="seconds">
          <span
            *ngIf="secondsControl.invalid && (secondsControl.dirty || secondsControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
        </div>
      </div>

    </div>
  </div>

  <div class="production-schema-step-form__footer">
    <button
      type="submit"
      class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
      [disabled]="this.formGroup.invalid"
    >{{'common.save' | translate}}</button>
    <button
      type="button"
      class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
      (click)="cancelForm()"
    >{{(isItNewStep ? 'schemas.delete' : 'common.cancel') | translate}}</button>
  </div>
</form>

