import {Injectable} from '@angular/core';
import {OfferDetails, SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {Utility} from '../utilities/utility';
import {ProductType} from '../../core/sdk/model-producttype';
import {OfferStatus} from '../../core/sdk/enums-statuses';
import {JsonScope} from '../../core/sdk/jsonScope';
import {DummyOfferProduct, Offer, OfferProduct} from '../../core/sdk/model-offer';
import {DummyOfferProductCtrl, OfferCtrl, OfferProductCtrl} from '../../core/sdk/controllers-offer';
import {OfferFile} from '../../core/sdk/model-files';
import {Price} from '../../core/sdk/model-currency';
import {Customer} from '../../core/sdk/model-customer';
import {NotificationMessageType} from '../enums/NotificationMessageType';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationService} from './notification.service';
import {SpinnerService} from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  public data: Offer[];

  constructor(private offerCtrl: OfferCtrl,
              private offerProductCtrl: OfferProductCtrl,
              private dummyOfferProductCtrl: DummyOfferProductCtrl) {
  }

  public getEmptyOfferForCustomer(customer: Customer): Offer {
    return {
      id: 'offer/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      customer,
      creationDate: Date.now(),
      shorthand: null,
      isDeleted: false,
      estimatedDeliveryDate: null,
      estimatedProductionStartDate: null,
      offerProductList: [],
      status: 0,
      estimatedValue: null,
      deleted: false,
      isLocked: false, lockReason: null, locked: false
    };
  }

  public getOfferData(): Offer[] {
    return this.data;
  }

  public getOffers(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Offer[]> {
    return this.offerCtrl.getOffers(searchCriteria, pageNumber, pageSize);
  }

  public countOffers(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.offerCtrl.countOffers(searchCriteria);
  }

  // TODO
  public countOffersForProductType(searchCriteriaForClass: SearchCriteriaForClass, productTypeId: string): Observable<number> {
    return this.offerCtrl.countOffersWithGivenProductType(searchCriteriaForClass, Utility.getObjectId(productTypeId));
  }

  public getOffersForProductType(searchCriteriaForClass: SearchCriteriaForClass, productTypeId: string, pageNumber: number,
                                 pageSize: number): Observable<Offer[]> {
    return this.offerCtrl.getOffersWithGivenProductType(searchCriteriaForClass, pageNumber, pageSize, Utility.getObjectId(productTypeId));
  }

  public getFiles(customerId: string, pageNumber: number, pageSize: number): Observable<OfferFile[]> {
    return this.offerCtrl.getOfferFiles(customerId, pageNumber, pageSize);
  }

  public countFiles(customerId): Observable<number> {
    return this.offerCtrl.countOfferFiles(customerId);
  }

  public createOffer(offer: Offer): Observable<Offer> {
    return this.offerCtrl.createObject(offer);
  }

  public getOffer(objectId: string): Observable<Offer> {
    return this.offerCtrl.getObject(Utility.getObjectId(objectId));
  }

  public updateOffer(offer: Offer): Observable<Offer> {
    return this.offerCtrl.updateObject(offer);
  }

  public getOfferDetails(offerId: string): Observable<OfferDetails> {
    return this.offerCtrl.getOfferDetails(Utility.getObjectId(offerId));
  }

  public updateOfferStatus(offerId: string, offerStatus: OfferStatus): Observable<Offer> {
    return this.offerCtrl.updateOfferStatus(Utility.getObjectId(offerId), offerStatus);
  }

  public isOfferWithProductsThatHaveMFCOrPriceEqualZero(offerID: string): Observable<boolean> {
    return this.offerCtrl.isOfferWithProductsThatHaveMFCOrPriceEqualZero(Utility.getObjectId(offerID));
  }

  public changeOfferStatus(isSomeDummyProduct: boolean, notificationService: NotificationService,
                           spinnerService: SpinnerService, offerId: string, offerStatus: OfferStatus): void {
    if (isSomeDummyProduct) {
      notificationService.displayNotificationToast('offers.create-product-types-for-unknown-products-first',
        NotificationMessageType.WARNING);
    } else {
      spinnerService.activateSpinner();
      this.updateOfferStatus(offerId, offerStatus).subscribe({
        next: (response) => {
          Utility.updateObjectInData(response, this.data);
          notificationService.displayNotificationToast('offers.offer-status-updated',
            NotificationMessageType.SUCCESS);
          spinnerService.deactivateSpinner();
        },
        error: (error: HttpErrorResponse) => notificationService.handleErrorResponseWithMessage(error, spinnerService)
      });
    }
  }


  public cloneOffer(customer: Customer, offerID: string): Observable<Offer> {
    return this.offerCtrl.cloneOffer(customer, Utility.getObjectId(offerID));
  }


  /////// OFFER_PRODUCT

  public createNewOfferProduct(offer: Offer, productType: ProductType, price: Price, manufacturingCost?: number): Observable<OfferProduct> {
    return this.offerProductCtrl.createObject({
      id: 'offer-product/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      offer,
      productType,
      deleted: false,
      isDeleted: false,
      price,
      quantity: 0,
      manufacturingCost: manufacturingCost ? manufacturingCost : 0
    }, new JsonScope(false, ['offer']));
  }

  public getNewOfferProduct(offer: Offer, productType: ProductType, price: Price, manufacturingCost?: number): OfferProduct {
    return {
      id: 'offer-product/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      offer,
      productType,
      deleted: false,
      isDeleted: false,
      price,
      quantity: 0,
      manufacturingCost: manufacturingCost ? manufacturingCost : 0
    };
  }

  public removeOfferProduct(offerProductId: string): Observable<void> {
    return this.offerProductCtrl.deleteObject(Utility.getObjectId(offerProductId));
  }

  public updateOfferProduct(offerProduct: OfferProduct): Observable<OfferProduct> {
    return this.offerProductCtrl.updateObject(offerProduct, new JsonScope(false, ['product-type']));
  }

  public updateOfferProductList(offerProductList: OfferProduct[]): Observable<OfferProduct[]> {
    return this.offerProductCtrl.updateProductOfferList(offerProductList);
  }

  public createOfferProductsFromList(offerProductList: OfferProduct[]): Observable<OfferProduct[]> {
    return this.offerProductCtrl.createOfferProductsFromList(offerProductList, new JsonScope(false,
      ['offer', 'product-type']));
  }

  public calculateOffer(offerID: string, priority: boolean): Observable<OfferDetails> {
    return this.offerCtrl.calculateOffer(Utility.getObjectId(offerID), priority);
  }

  public calculateAllActiveOffers(): Observable<number> {
    return this.offerCtrl.calculateAllActiveOffers();
  }

  public setMarginForAllOfferProductInOffer(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                                            offerId: string, margin: number): Observable<OfferProduct[]> {
    return this.offerProductCtrl.setMarginForAllOfferProductsInOffer(searchCriteriaForClass, pageNumber, pageSize,
      Utility.getObjectId(offerId), margin);
  }

  public countOfferProductsForOffer(searchCriteriaForClass: SearchCriteriaForClass, offerId: string): Observable<number> {
    return this.offerProductCtrl.countOfferProductsForOffer(searchCriteriaForClass, Utility.getObjectId(offerId));
  }

  public getOfferProductsForOfferWithPagination(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number,
                                                pageSize: number, offerId: string): Observable<OfferProduct[]> {
    return this.offerProductCtrl.getOfferProductsForOffer(searchCriteriaForClass, pageNumber, pageSize,
      Utility.getObjectId(offerId));
  }


  ////// DUMMY_OFFER_PRODUCT

  public getNewDummyOfferProduct(price: Price, offer?: Offer): DummyOfferProduct {
    return {
      id: 'dummy-offer-product/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      timestamp: Date.now(),
      deleted: false,
      isDeleted: false,
      productType: null,
      createdProductTypeFromDummy: null,
      offer: offer ?? null,
      price,
      quantity: 0,
      manufacturingCost: 0,
      additionalCosts: 0,
      height: 0,
      length: 0,
      weight: 0,
      width: 0,
      description: '',
      shape: null
    };
  }

  public createDummyOfferProduct(dummyOfferProduct: DummyOfferProduct): Observable<DummyOfferProduct> {
    return this.dummyOfferProductCtrl.createObject(dummyOfferProduct, new JsonScope(false, ['product-type', 'offer']));
  }
}
