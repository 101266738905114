import {Injectable} from '@angular/core';
import {ProductionFiringSchemaService} from './production-firing-schema.service';

@Injectable({
  providedIn: 'root'
})
export class CrossFunctionalService {
  private functionalMap: Map<string, any> = new Map<string, any>();
  private registeredCrossActionKeys: CrossActionKeys;

  constructor(private productionFiringSchemaService: ProductionFiringSchemaService) {
  }

  public isCrossFunctionalActive(functionalKey: string): boolean {
    return this.functionalMap.has(functionalKey);
  }

  public setGatheredData(functionalKey: string, data: any): void {
    this.functionalMap.set(functionalKey, data);
  }

  public getGatheredData(functionalKey: string): any {
    return this.functionalMap.get(functionalKey);
  }

  public flushData(functionalKey: string): void {
    this.functionalMap.delete(functionalKey);
  }

  public flushStepFromKeysIfNecessary(): void {
    if (!this.functionalMap.has('returningFlag')) {
      this.flushData('currentlyEditedStepId');
      this.flushData('currentlyEditedStepValues');
      this.flushData('passedProductionSchema');
      if (this.productionFiringSchemaService.isAnyStepOpened()) {
        this.productionFiringSchemaService.flushAllOpenedSteps();
      }
    }
  }

  public registerCrossActionKeys(keys: string[]): void {
    this.registeredCrossActionKeys = new CrossActionKeys(true, keys);
  }

  public setFlushingFlag(flagValue: boolean): void {
    this.registeredCrossActionKeys.flushingFlag = flagValue;
  }

  public closeOrReturnFromCrossAction(): void {
    if (this.registeredCrossActionKeys && this.registeredCrossActionKeys.flushingFlag) {
      this.registeredCrossActionKeys.keysList.forEach(key =>
        this.flushData(key)
      );
      delete this.registeredCrossActionKeys;
    }
  }
}

class CrossActionKeys {
  flushingFlag: boolean;
  keysList: string[];

  constructor(flag: boolean, keyList: string[]) {
    this.flushingFlag = flag;
    this.keysList = keyList;
  }
}
