<div class="mobile-inventory-in-storing-product-info"
     [style.background-color]="backgroundColor"
     [style.border]="displayBorder ? ' 5px solid red' : 'none'">
  <ng-template [ngIf]="title">
    <h2 class="global-font-weight--bold"
    >{{ title | translate }}</h2>
  </ng-template>
  <div
    class="global-grid grid-column-template--3">
    <div class="global-flex global-flex-direction__column global-text-align__center">
      <span class="global-font-weight--bold"
      >{{ 'mobile.status' }}</span>
      <span
      >{{ inventoryInStoringProduct.productStoredInstanceRequest.status | status: ProductStoredInstanceRequestStatus: 'mobile' }}</span>
    </div>
    <div class="global-flex global-flex-direction__column global-text-align__center">
      <span class="global-font-weight--bold"
      >{{ 'mobile.inventory-status' }}</span>
      <span
      >{{ inventoryInStoringProduct.status | status: InventoryProductStatus: 'mobile' }}</span>
    </div>
    <div class="global-flex global-flex-direction__column global-text-align__center">
      <span class="global-font-weight--bold"
      >{{ 'mobile.product-name'| translate }}</span>
      <span
      >{{ inventoryInStoringProduct.productStoredInstanceRequest.productType ? inventoryInStoringProduct.productStoredInstanceRequest.productType.name : inventoryInStoringProduct.productStoredInstanceRequest.productForService.name }}</span>
    </div>
    <div class="global-flex global-flex-direction__column global-text-align__center">
      <span class="global-font-weight--bold"
      >{{ 'mobile.article-number'| translate }}</span>
      <span
      >{{ inventoryInStoringProduct.productStoredInstanceRequest.productType ? inventoryInStoringProduct.productStoredInstanceRequest.productType.articleNumber : ('mobile.not-applicable' | translate) }}</span>
    </div>
    <div class="global-flex global-flex-direction__column global-text-align__center">
      <span class="global-font-weight--bold"
      >{{ 'mobile.quality'| translate }}</span>
      <span
      >{{ inventoryInStoringProduct.productStoredInstanceRequest.productType ? inventoryInStoringProduct.productStoredInstanceRequest.productType.quality.name : ('mobile.not-applicable' | translate) }}</span>
    </div>
    <div class="global-flex global-flex-direction__column global-text-align__center">
      <span class="global-font-weight--bold"
      >{{ 'mobile.booking-series'| translate }}</span>
      <span
      >{{ inventoryInStoringProduct.productStoredInstanceRequest.processStep.bookingSeries }}</span>
    </div>
  </div>
</div>
