import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WorkspaceGroup, WorkspaceInstance} from '../../../../../core/sdk/bighero-model';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SpinnerService} from '../../../../services/spinner.service';
import {NotificationService} from '../../../../services/notification.service';
import {WorkerService} from '../../../../services/worker.service';
import {WorkCalendarData} from '../../../../../core/sdk/model-dto';
import {HttpErrorResponse} from '@angular/common/http';
import {SelectItem} from 'primeng/api';
import {EnumService} from '../../../../services/enum.service';
import {Month} from '../../../../enums/enums';
import {TranslationKeyEnum} from '../../../../enums/translation-key-enum';
import {Utility} from '../../../../utilities/utility';
import {WorkspaceGroupWorkCalendarData} from '../../../../interfaces/interfaces';
import {BaseSubNavbarService} from '../../../base-sub-navbar/base-sub-navbar.service';

@Component({
  selector: 'app-monthly-calendar-view-for-workers',
  templateUrl: './monthly-calendar-view-for-workers.component.html',
})
export class MonthlyCalendarViewForWorkersComponent implements OnInit {

  public formGroup: FormGroup;
  public showMonthlyCalendarViewFlag = false;
  public workCalendarData: WorkCalendarData[] = [];
  public tableRows: WorkspaceGroupWorkCalendarData[] = [];

  public availableMonthOptions: SelectItem[];
  public numberOfDaysInMonth: number[] = [];
  public rowColors: string[] = [];

  @Input() public workspaceInstance: WorkspaceInstance;
  @Input() public workspaceGroups: WorkspaceGroup[];
  @Input() public loadDataForGroupLeader = false;
  @Input() public showAllGroups = false;
  @Output() public closeViewEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private formBuilder: FormBuilder,
              private spinnerService: SpinnerService,
              private notificationService: NotificationService,
              private enumService: EnumService,
              private workerService: WorkerService,
              private baseSubNavbarService: BaseSubNavbarService) {
  }

  public ngOnInit(): void {
    this.availableMonthOptions = this.enumService.transformEnumOptionsForPrimeDropdown(Month, TranslationKeyEnum.PRODUCTION);
    this.initFormGroup();
    this.baseSubNavbarService.displayFilteringButtons(false);
  }

  public getAvailableMonthOptions(): SelectItem[] {
    return this.availableMonthOptions;
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      month: [0, Validators.required],
      year: [0, [Validators.required, Validators.min(2019)]]
    });
  }

  public closeView(): void {
    this.closeViewEmitter.emit();
  }

  private loadWorkCalendarDataForWorkspaceInstance(): void {
    this.spinnerService.activateSpinner();
    this.workerService.getWorkCalenderDataForWorkspaceInstance(this.workspaceInstance.id, this.yearControl.value,
      this.monthControl.value + 1).subscribe({
      next: (response: WorkCalendarData[]) => {
        this.workCalendarData = response;
        this.loadNumberOfDaysInSelectedMonth();
        this.toggleShowMonthlyCalendarViewFlag();
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public prepareTableRowColorList(): void {
    this.tableRows.forEach((element, index) => {
      if (index === 0) {
        this.rowColors.push('white');
      } else {
        if (this.tableRows[index - 1].workspaceGroupName === this.tableRows[index].workspaceGroupName) {
          this.rowColors.push(this.rowColors[index - 1]);
        } else {
          if (this.rowColors[index - 1] === 'white') {
            this.rowColors.push('#d9d9d9');
          } else {
            this.rowColors.push('white');
          }
        }
      }
    });
  }

  private loadWorkCalendarDataForWorkspaceGroups(): void {
    this.spinnerService.activateSpinner();
    this.workerService.getWorkCalendarDataForWorkspaceGroups(this.retrieveWorkspaceGroupsIds(), this.yearControl.value,
      this.monthControl.value + 1).subscribe({
      next: (response: { [key: string]: WorkCalendarData[] }) => {
        this.handleSuccessResponseWorkCalendarDataForWorkspaceGroups(response);
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadTableRows(data: { [key: string]: WorkCalendarData[] }): void {
    Object.keys(data).map(key => {
      data[key].forEach(workCalendarData => {
        this.tableRows.push({workspaceGroupName: key, workCalendarData: workCalendarData});
      });
    });
    this.tableRows.sort((a, b) => {
      return a.workspaceGroupName.localeCompare(b.workspaceGroupName);
    });
    this.prepareTableRowColorList();
  }

  private retrieveWorkspaceGroupsIds(): string[] {
    return this.workspaceGroups ? this.workspaceGroups
      .map(workspaceGroup => Utility.getObjectId(workspaceGroup.id)) : null;
  }

  public loadWorkCalendarDataForGroupLeader(): void {
    this.spinnerService.activateSpinner();
    this.workerService.getWorkCalendarDataForLoggedInGroupLeader(this.yearControl.value,
      this.monthControl.value + 1).subscribe({
      next: (response: { [key: string]: WorkCalendarData[] }) => {
        this.handleSuccessResponseWorkCalendarDataForWorkspaceGroups(response);
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private handleSuccessResponseWorkCalendarDataForWorkspaceGroups(response: {
    [key: string]: WorkCalendarData[]
  }): void {
    this.loadTableRows(response);
    this.loadNumberOfDaysInSelectedMonth();
    this.toggleShowMonthlyCalendarViewFlag();
    this.spinnerService.deactivateSpinner();
  }

  public onSubmit(): void {
    if (this.workspaceInstance) {
      this.loadWorkCalendarDataForWorkspaceInstance();
    } else if (this.loadDataForGroupLeader) {
      this.loadWorkCalendarDataForGroupLeader();
    } else {
      this.loadWorkCalendarDataForWorkspaceGroups();
    }
  }

  public isDayBetweenRange(currentDay: number, dateFrom: number, dateTo: number): boolean {
    const fromDay = new Date(dateFrom);
    const toDay = new Date(dateTo);
    const currentDate = new Date((this.yearControl.value + '-' + (this.monthControl.value + 1) + '-' + currentDay).toString());
    return currentDate >= fromDay && currentDate <= toDay;
  }

  private loadNumberOfDaysInSelectedMonth(): void {
    const tmp = new Date((this.yearControl.value + '-' + (this.monthControl.value + 1)).toString());
    tmp.setMonth(tmp.getMonth() + 1);
    tmp.setDate(0);
    this.numberOfDaysInMonth = Array.from({length: tmp.getDate()}, (v, k) => k + 1);
  }

  public getEnumMonthValue(enumItem: number): string {
    return this.enumService.getEnumValueWithModule(enumItem, Month, TranslationKeyEnum.PRODUCTION);
  }

  public get monthControl(): AbstractControl {
    return this.formGroup.get('month');
  }

  public get yearControl(): AbstractControl {
    return this.formGroup.get('year');
  }

  public toggleShowMonthlyCalendarViewFlag(): void {
    this.showMonthlyCalendarViewFlag = !this.showMonthlyCalendarViewFlag;
  }

}
