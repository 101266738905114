import {EventEmitter, Injectable} from '@angular/core';
import {AuthService} from '../../core/auth/auth.service';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  webSocket: WebSocket;
  stompClient: Stomp;

  websocketMessageEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private authService: AuthService) {
  }

  connectToWebsocket(webSocketUrl: string): Stomp {
    const authorizationToken = '?authorization=' + this.authService.getCurrentAuthToken();
    this.webSocket = new SockJS(webSocketUrl + authorizationToken, {transports: ['websocket']});
    this.stompClient = Stomp.over(this.webSocket);
    return this.stompClient;
  }

  getWebSocketHeaders(): {Authorization: number} {
    return {
      Authorization: +this.authService.getCurrentAuthToken()
    };
  }

  openWebSocketConnection(websocketPath: string): void {
    this.stompClient = this.connectToWebsocket('/ws/' + websocketPath + '/');
    this.stompClient.debug = null;
    const headers = this.getWebSocketHeaders();
    this.stompClient.connect({headers}, () => {
      this.stompClient.subscribe('/user/channel/ws/' + websocketPath, msg => {
        this.websocketMessageEmitter.emit(msg.body);

      });
    });
  }

  closeWebSocketConnection(): void {
    this.stompClient.unsubscribe();
    this.stompClient.disconnect();
  }

}
