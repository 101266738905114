import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-custom-expand-panel',
  templateUrl: './custom-expand-panel.component.html',

})
export class CustomExpandPanelComponent implements OnInit, OnDestroy {

  public activeTileFlag = false;
  public subscription: Subscription;

  @Input() public headerTitle: string;
  @Input() public headerValue: number;
  @Input() public shouldSetGlobalWidth = true;
  @Input() public backgroundColor: string;
  @Input() public shouldSetContentWidth: boolean;
  @Input() public shouldSetContentPadding: boolean;
  @Input() public displayRightBorder = true;
  @Input() public tileEvent: Observable<boolean>;

  public ngOnInit() {
    if (this.tileEvent) {
      this.subscription = this.tileEvent.subscribe({
        next: (response: boolean) => this.activeTileFlag = response
      });
    }
  }

  public toggleActiveTileFlag(): void {
    this.activeTileFlag = !this.activeTileFlag;
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
