import {Injectable} from '@angular/core';
import {Person} from '../../core/sdk/bighero-model';
import {SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {TranslateService} from '../../core/translations/translate.service';
import {Utility} from '../utilities/utility';
import {CustomerFile} from '../../core/sdk/model-files';
import {DeliveryTerm, PaymentTerm} from '../../core/sdk/model-enumentity';
import {DeliveryTermCtrl, PaymentTermCtrl} from '../../core/sdk/controllers-enumentity';
import {Address} from '../../core/sdk/model-address';
import {Customer} from '../../core/sdk/model-customer';
import {CustomerCtrl} from '../../core/sdk/controllers-customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  public customersData: Customer[];

  constructor(private customerCtrl: CustomerCtrl,
              private translateService: TranslateService,
              private paymentTermCtrl: PaymentTermCtrl,
              private deliveryTermCtrl: DeliveryTermCtrl) {
  }

  public getEmptyCustomer(): Customer {
    return {
      id: 'customer/' + Utility.getUUID(),
      timestamp: Date.now(),
      version: 0,
      entityVersion: 0,
      companyName: null,
      mainPhoneNumber: null,
      paymentTerm: null,
      deliveryTerm: null,
      datevID: null,
      taxID: null,
      salesRepresentative: null,
      mainAddress: null,
      addressList: [],
      contactList: [],
      deleted: false,
      fileList: null,
      inHouseManager: null,
      notesList: [],
      isDeleted: false
    };
  }

  public createNewCustomer(customer: Customer): Observable<Customer> {
    return this.customerCtrl.createObject(customer);
  }

  public setCustomersData(data: Customer[]): void {
    this.customersData = data;
  }

  public getCustomersData(): Customer[] {
    return this.customersData;
  }

  public countCustomers(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.customerCtrl.getCustomersCount(searchCriteriaForClass);
  }

  public getCustomers(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Customer[]> {
    return this.customerCtrl.getCustomers(searchCriteriaForClass, pageNumber, pageSize);
  }

  public getFiles(customerId: string, pageNumber: number, pageSize: number): Observable<CustomerFile[]> {
    return this.customerCtrl.getCustomerFiles(customerId, pageNumber, pageSize);
  }

  public countFiles(customerId): Observable<number> {
    return this.customerCtrl.countCustomerFiles(customerId);
  }

  public getCustomer(customerId: string): Observable<Customer> {
    return this.customerCtrl.getObject(Utility.getObjectId(customerId));
  }

  public updateCustomer(customer: Customer): Observable<Customer> {
    return this.customerCtrl.updateObject(customer);
  }

  public updateSalesManagerForCustomer(customerId: string, salesManager: Person): Observable<Customer> {
    return this.customerCtrl.updateCustomerSalesManager(customerId, Utility.getObjectId(salesManager.id));
  }

  public getDeliveryTerms(): Observable<DeliveryTerm[]> {
    return this.deliveryTermCtrl.findAllEnumObjects();
  }

  public getPaymentTerms(): Observable<PaymentTerm[]> {
    return this.paymentTermCtrl.findAllEnumObjects();
  }

  public updateInHouseManager(customerId: string, inHouseManagerId: string): Observable<Customer> {
    return this.customerCtrl.updateInHouseManager(Utility.getObjectId(customerId), Utility.getObjectId(inHouseManagerId));
  }

  public updateAddressInCustomersData(address: Address): void {
    topLoop:
      for (const i in this.getCustomersData()) {
        if (this.getCustomersData()[i].id === address.customer.id) {
          for (const j in this.getCustomersData()[i].addressList) {
            if (this.getCustomersData()[i].addressList[j].id === address.id) {
              this.getCustomersData()[i].addressList[j] = address;
              break topLoop;
            }
          }
        }
      }
  }

}
