import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Utility} from '../../utilities/utility';
import {MixingSchema, MixingSchemaStep, MixingSchemaTemplate} from '../../../core/sdk/model-mixingschema';
import {MixingSchemaService} from '../../services/production/mixing-schema.service';
import {TranslateService} from '../../../core/translations/translate.service';
import {ConstanceValues} from '../../constance-values/constance-values';
import {PTableControlService} from '../../services/p-table-control.service';
import {Router} from '@angular/router';
import {BaseSubNavbarService} from '../../base-components/base-sub-navbar/base-sub-navbar.service';
import {NotificationService} from '../../services/notification.service';
import {SpinnerService} from '../../services/spinner.service';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {HttpErrorResponse} from '@angular/common/http';
import {CrossFunctionalService} from '../../services/cross-functional.service';
import {SchemaService} from '../../services/schema.service';
import {MixingSchemaTemplateService} from '../../services/production/mixing-schema-template.service';

@Component({
  selector: 'app-full-mixing-schema-edition',
  templateUrl: './full-mixing-schema-edition.component.html',
})
export class FullMixingSchemaEditionComponent implements OnInit, AfterViewInit, OnDestroy {

  public formGroup: FormGroup;
  public currentMixingSchema: MixingSchema | MixingSchemaTemplate;
  private totalStepsDuration = {days: 0, hours: 0, minutes: 0, seconds: 0};
  public isUpdateModeApplied: boolean = false;
  public isCrossFunctionalActive: boolean;
  public importTemplateFlag: boolean = false;
  public templatesOptions: MixingSchemaTemplate[];
  public selectedTemplate: MixingSchemaTemplate;
  public shouldDescriptionBeDisplayed = false;
  public currentLocalValue: string;

  constructor(protected mixingSchemaService: MixingSchemaService,
              private translateService: TranslateService,
              private formBuilder: FormBuilder,
              private pTableControlService: PTableControlService,
              private router: Router,
              private baseSubNavbarService: BaseSubNavbarService,
              private notificationService: NotificationService,
              private spinnerService: SpinnerService,
              private crossFunctionalService: CrossFunctionalService,
              private schemaService: SchemaService,
              private mixingSchemaTemplateService: MixingSchemaTemplateService) {
    this.isCrossFunctionalActive = this.crossFunctionalService.isCrossFunctionalActive('editMixingSchema');
  }

  public ngOnInit(): void {
    this.initMixingSchema();
    this.initTotalDuration(this.currentMixingSchema.mixingSchemaStepList);
    this.initFormGroup();
    if (this.isUpdateModeApplied) {
      this.mixingSchemaService.assignMixingSchemaSteps(this.currentMixingSchema.mixingSchemaStepList);
    }
    this.baseSubNavbarService.displayFilteringButtons(false);
    this.schemaService.reloadSummaryStepDurationSourceContent.subscribe(steps => {
      this.initTotalDuration(steps);
    });
    this.currentLocalValue = this.translateService.getCurrentLanguage()?.locale.split('_')[0];
  }

  public ngAfterViewInit(): void {
    this.fetchMixingSchemaTemplates();
  }

  private fetchMixingSchemaTemplates(): void {
    this.spinnerService.activateSpinner();
    this.mixingSchemaTemplateService.getAllMixingSchemaTemplatesId().subscribe({
      next: (response: MixingSchemaTemplate[]) => {
        this.templatesOptions = response;
        this.spinnerService.deactivateSpinner();
        this.templatesOptions.sort((a, b) => Utility.compareNumbers(a.name, b.name));
        this.selectedTemplate = this.templatesOptions[0];
      }
    });
  }

  public someOfMixingSchemaStepsAreOpened(): boolean {
    return this.mixingSchemaService.someOfStepsIsOpen();
  }

  private initMixingSchema(): void {
    if (this.isCrossFunctionalActive) {
      this.isUpdateModeApplied = true;
      this.currentMixingSchema = this.crossFunctionalService.getGatheredData('editMixingSchema').mixingSchema;
      this.crossFunctionalService.flushData('editMixingSchema');
    } else if (this.crossFunctionalService.isCrossFunctionalActive('passedMixingSchema')) {
      this.currentMixingSchema = this.crossFunctionalService.getGatheredData('passedMixingSchema');
      this.mixingSchemaService.assignMixingSchemaSteps((this.currentMixingSchema as MixingSchemaTemplate).mixingSchemaStepList);
      this.shouldDescriptionBeDisplayed = true;
      this.crossFunctionalService.flushData('passedMixingSchema');
    } else {
      this.currentMixingSchema = this.mixingSchemaService.getNewEmptyMixingSchema();
    }
  }

  private attachDescriptionControl(): void {
    if ('description' in this.currentMixingSchema) {
      this.formGroup.addControl('description', this.formBuilder.control(this.currentMixingSchema.description,
        [Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]));
    }
  }

  public onSubmit(): void {
    if (this.mixingSchemaService.getMixingSchemaSteps().isEmpty()) {
      this.notificationService.displayNotificationToast('production.there-is-no-defined-mixing-schema-steps',
        NotificationMessageType.WARNING);
    } else {
      this.submitMixingSchema();
    }
  }

  private submitMixingSchema(): void {
    this.spinnerService.activateSpinner();
    this.currentMixingSchema = {...this.currentMixingSchema, ...this.formGroup.value};
    this.currentMixingSchema.mixingSchemaStepList = this.mixingSchemaService.getMixingSchemaSteps();
    this.mixingSchemaService.updateIndexAndPropertiesInSchemaSteps();
    this.isTemplate(this.currentMixingSchema.id) ? this.updateMixingSchemaTemplate() : this.updateMixingSchema();
  }

  private updateMixingSchemaTemplate(): void {
    this.mixingSchemaTemplateService.updateMixingSchemaTemplate(this.currentMixingSchema as MixingSchemaTemplate)
      .subscribe({
        next: (): void => {
          this.notificationService.displayNotificationToast('production.mixing-schema-template-created-successfully',
            NotificationMessageType.SUCCESS);
          this.spinnerService.deactivateSpinner();
          this.returnToMixingSchemaTemplateView();
        }
      });
  }

  private updateMixingSchema(): void {
    this.mixingSchemaService.updateMixingSchema(this.currentMixingSchema as MixingSchema).subscribe({
      next: () => {
        this.notificationService.displayNotificationToast('production.mixing-schema-created-successfully',
          NotificationMessageType.SUCCESS);
        this.spinnerService.deactivateSpinner();
        this.returnToMixingSchemaView();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public isTemplate(templateId: string): boolean {
    return templateId.includes('template');
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [this.currentMixingSchema.name ?? '', [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
      directLaborRateCost: [this.currentMixingSchema.directLaborRateCost ?? 0],
      energyCost: [this.currentMixingSchema.energyCost ?? 0],
      fixedOverheadCost: [this.currentMixingSchema.fixedOverheadCost ?? 0],
      indirectLaborRateCost: [this.currentMixingSchema.indirectLaborRateCost ?? 0],
      variableOverheadCost: [this.currentMixingSchema.variableOverheadCost ?? 0]
    });
    if (this.isTemplate(this.currentMixingSchema.id)) {
      this.attachDescriptionControl();
    }
  }

  public importSelectedTemplate(id: string): void {
    this.mixingSchemaTemplateService.importMixingSchemaTemplate(id).subscribe({
      next: (response: MixingSchemaTemplate) => {
        response.mixingSchemaStepList.sort((a, b) => Utility.compareNumbers(a.index, b.index));
        this.mixingSchemaService.joinStepsFromImportedMixingSchemaTemplate(response.mixingSchemaStepList);
        this.assignCostsFromImportedMixingSchemaTemplate(response);
        this.notificationService.displayNotificationToast('production.template-imported-successfully',
          NotificationMessageType.SUCCESS);
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private assignCostsFromImportedMixingSchemaTemplate(mixingSchemaTemplate: MixingSchemaTemplate): void {
    this.energyCostControl.patchValue(mixingSchemaTemplate.energyCost);
    this.directLaborRateCostControl.patchValue(mixingSchemaTemplate.directLaborRateCost);
    this.indirectLaborRateCostControl.patchValue(mixingSchemaTemplate.indirectLaborRateCost);
    this.fixedOverheadCostControl.patchValue(mixingSchemaTemplate.fixedOverheadCost);
    this.variableOverheadCostControl.patchValue(mixingSchemaTemplate.variableOverheadCost);
  }

  public closeView(): void {
    this.mixingSchemaService.clearListOfOpenedSteps();
    this.isTemplate(this.currentMixingSchema.id) ? this.returnToMixingSchemaTemplateView() : this.returnToMixingSchemaView();
  }

  public toggleImportFlag(): void {
    this.importTemplateFlag = !this.importTemplateFlag;
  }

  public returnToMixingSchemaView(): void {
    this.pTableControlService.pushExpandedRowToMap('mixingSchemaManagementTable', this.currentMixingSchema.id);
    this.pTableControlService.setOneTimeSelectedTab('mixingSchemaManagementTable',
      this.translateService.translate('production.mixing-schema-steps'));
    this.router.navigate(['bh/production/mixing-schema-management']);
  }

  private returnToMixingSchemaTemplateView(): void {
    this.router.navigate(['bh/production/mixing-schema-templates']);
  }

  public initTotalDuration(steps: MixingSchemaStep[]): void {
    this.totalStepsDuration = this.getTotalDurationOfSteps(steps);
  }

  private getTotalDurationOfSteps(steps: MixingSchemaStep[]): {
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  } {
    const seconds = this.schemaService.getAllStepsTotalDuration(steps);
    return Utility.convertSecondsToDurationObject(seconds ?? 0);
  }

  public transformDurationToString(): string {
    return Utility.transformDurationObjectToUserFriendlyString(this.totalStepsDuration, this.translateService);
  }


  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get directLaborRateCostControl(): AbstractControl {
    return this.formGroup.get('directLaborRateCost');
  }

  public get energyCostControl(): AbstractControl {
    return this.formGroup.get('energyCost');
  }

  public get fixedOverheadCostControl(): AbstractControl {
    return this.formGroup.get('fixedOverheadCost');
  }

  public get indirectLaborRateCostControl(): AbstractControl {
    return this.formGroup.get('indirectLaborRateCost');
  }

  public get variableOverheadCostControl(): AbstractControl {
    return this.formGroup.get('variableOverheadCost');
  }

  public get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  public clearAllStep(): void {
    this.mixingSchemaService.clearAllSteps();
    this.mixingSchemaService.clearListOfOpenedSteps();
    this.initTotalDuration([]);
  }

  public createNewStep(): void {
    const newMixingSchemaStep: MixingSchemaStep = this.mixingSchemaService.getNewEmptyMixingSchemaStep();
    newMixingSchemaStep.index = !this.mixingSchemaService.getMixingSchemaSteps().isEmpty() ?
      this.mixingSchemaService.getMixingSchemaSteps().length + 1 : 1;
    this.mixingSchemaService.tagMixingSchemaStepAsOpened(newMixingSchemaStep.id);
    this.mixingSchemaService.addNewMixingSchemaStep(newMixingSchemaStep);
  }

  public ngOnDestroy(): void {
    this.mixingSchemaService.flushNewlyAddedMixingSchemaSteps();
  }
}
