<div class="workload-tile">
  <div class="workload-tile__content">

    <ng-container *ngIf="isWorkspaceGroup then workspaceGroupInfo else machineModelInfo"></ng-container>

    <ng-template #workspaceGroupInfo>
      <div class="global-flex global-flex-direction__column">

        <div class="workload-tile__content__workspace-info">
          <p>{{'production.worker-name' | translate}}</p>
          <span>{{workload.worker?.name}}</span>
        </div>

        <div class="workload-tile__content__workspace-info">
          <p>{{'production.workspace-instance' | translate}}</p>
          <span>{{workload.workspaceInstance.shorthand}}</span>
        </div>

      </div>
    </ng-template>

    <ng-template #machineModelInfo>

      <div class="global-flex global-flex-direction__column">

        <div class="workload-tile__content__machine-info">
          <p>{{'production.booked-for' | translate}}</p>
          <span
          >{{workload.productType.name}}</span>
          <p>{{'production.in-order' | translate}}</p>
          <span>{{workload.order.shorthand}}</span>
        </div>

        <div class="workload-tile__content__instance-info">
          <p>{{'production.machine-instance'| translate}}</p>
          <span>{{workload.workspaceInstance.productionDeviceInstance.shorthand}}</span>
        </div>
      </div>

    </ng-template>


  </div>

  <div class="workload-tile__content__duration" style="width: max-content">
    <span>{{transformTimestampToUserFriendlyString(workload.duration?.seconds)}}</span>
  </div>



</div>
