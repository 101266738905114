import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appPlaceholderNotificationModalDirective]'
})
export class PlaceholderNotificationModalDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }
}
