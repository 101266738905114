import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {StoringUnitType} from '../../../../core/sdk/bighero-model';
import {SearchCriteriaService} from '../../../../core/search-criteria.service';
import {TableRefreshService} from '../../../services/table-refresh.service';
import {ProductStoringUnitTypeService} from '../../../services/product-storing-unit-type.service';
import {SpinnerService} from '../../../services/spinner.service';
import {BaseSubNavbarService} from '../../base-sub-navbar/base-sub-navbar.service';
import {BasePrimengTableDirective} from '../../../base-primeng-table/base-primeng-table.directive';

@Component({
  selector: 'app-storing-unit-type-table',
  templateUrl: './storing-unit-type-table.component.html'
})
export class StoringUnitTypeTableComponent extends BasePrimengTableDirective<StoringUnitType> implements OnInit,
  AfterViewInit, OnDestroy {

  public data: StoringUnitType[] = [];
  @Output() public createClicked: EventEmitter<StoringUnitType> = new EventEmitter<StoringUnitType>();

  constructor(private productStoringUnitTypeService: ProductStoringUnitTypeService,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected spinnerService: SpinnerService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService);
    this.assignClassName('StoringUnitType');
    this.displayFilteringButtons = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.pageable.pageSize = 3;
  }

  public ngAfterViewInit(): void {
    this.refreshTable();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public getTableData(): StoringUnitType[] {
    return this.data;
  }

  public doCount(): void {
    this.productStoringUnitTypeService.countStoringUnitTypes(this.searchCriteriaForClass).subscribe(countedStoringUnitTypes => {
      this.pageable.count = countedStoringUnitTypes;
    });
  }

  public doLoad(): void {
    this.productStoringUnitTypeService.getStoringUnitTypes(this.searchCriteriaForClass, this.pageable.pageNumber, this.pageable.pageSize)
      .subscribe(storingUnitType => {
        this.data = storingUnitType;
        this.spinnerService.deactivateSpinner();
      });
  }

  public emitStoringUnitType(item: StoringUnitType): void {
    this.createClicked.emit(item);
  }

}
