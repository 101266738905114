import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NotificationService} from '../../services/notification.service';
import {NotificationMessageType} from '../../enums/NotificationMessageType';

@Component({
  selector: 'app-image-terminal-preview',
  templateUrl: './image-terminal-preview.component.html'
})
export class ImageTerminalPreviewComponent {

  private currentZoomStyle = {zoom: 1};
  private currentZoom = 1;

  @Input() public imgUrl: string;
  @Input() public customClass = '';

  @Output() public closeComponentEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private notificationService: NotificationService) {
  }


  public closeComponent(): void {
    this.closeComponentEmitter.emit();
  }

  public zoomIn(): void {
    if (this.currentZoom >= 1) {
      this.currentZoom++;
    } else {
      this.currentZoom = this.currentZoom + 0.1;
    }
    this.updateCurrentZoomStyle();
  }

  public zoomOut(): void {
    if (this.currentZoom >= 2) {
      this.currentZoom--;
    } else {
      if (this.currentZoom >= 0.2) {
        this.currentZoom = this.currentZoom - 0.1;
      } else {
        this.notificationService.displayNotificationToast('production.can-not-zoom-out-more', NotificationMessageType.WARNING);
      }
    }
    this.updateCurrentZoomStyle();
  }

  private updateCurrentZoomStyle(): void {
    this.currentZoomStyle = {zoom: this.currentZoom};
  }

  public get zoomStyle(): { [p: string]: any } {
    return this.currentZoomStyle;
  }


}
