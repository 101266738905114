import {Component, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {PasswordResetService} from './password-reset.service';
import {Router} from '@angular/router';
import {BaseFormField} from '../../shared/components/base-form/base-form-field';
import {StringInputField} from '../../shared/components/base-form/string-input-field';
import {NotificationService} from '../../shared/services/notification.service';
import {NotificationMessageType} from '../../shared/enums/NotificationMessageType';

@Component({
  selector: 'app-password-reset',
  templateUrl: 'password-reset.component.html'
})
export class PasswordResetComponent implements OnInit {
  public formFields: BaseFormField<string>[];

  constructor(private passwordResetService: PasswordResetService,
              private router: Router,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.formInit();
  }

  private formInit(): void {
    this.formFields = [
      new StringInputField({
        key: 'email',
        label: 'common.provide-email-for-reset',
        alert: 'common.valid-email-required',
        labelStyle: 'form-container__title--color',
        order: 1,
        validators: [Validators.required, Validators.email]
      })
    ];
  }


  onReset(form: FormGroup): void {
    this.passwordResetService.passwordReset(form.value.email);
    this.router.navigate(['/login']).then(() => {
      this.notificationService.displayNotificationToast('common.password-reset-request-successful', NotificationMessageType.SUCCESS);
    });
    this.goBack();
  }

  goBack(): void {
    this.router.navigate(['/login']);
  }
}
