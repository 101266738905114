<div class="base-display-details-container">
  <h3 class="base-display-details-container__title">{{detailsTitle | translate}}</h3>
  <div class="base-display-details-container__content">
    <div
      *ngFor="let baseDetailsRecord of baseDetailsRecords"
      class="base-display-details-container__single-item">
      <label
        class="base-display-details-container__single-item-label"
        [ngStyle]="{'font-size': isTerminalDevice ? '1.75rem' : ''}"
      >{{(translationModule + '.' + baseDetailsRecord.label | translate) + getBaseRecordUnit(baseDetailsRecord.unit)}}:
      </label>
      <p
        class="base-display-details-container__single-item-value"
        [ngStyle]="{'font-size': isTerminalDevice ? '1.5rem' : ''}"
      >{{baseDetailsRecord.value != null ? baseDetailsRecord.value : ('common.no-data'| translate)}}</p>
    </div>
  </div>
</div>


