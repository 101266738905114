import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {Button} from 'primeng/button';
import {Utility} from '../../utilities/utility';

@Component({
  selector: 'app-rocker-tab-navbar',
  templateUrl: './rocker-tab-navbar.component.html',
})
export class RockerTabNavbarComponent implements OnInit {

  public leftSideStyleButtonName: string;
  public activeTab: string;
  public isTerminalDevice: boolean = false;
  public isMobileDevice: boolean = false;

  @Input()
  public set setDefaultTabName(tabName: string) {
    if (tabName) {
      this.activeTab = tabName;
    } else {
      this.activeTab = this.tabsNames[0];
    }
  }

  @Input() tabsNames: string[];
  @Input() rightSideActionButtonNames: Map<string, string[]>;
  @Input() leftSideSwitchStyleButtonNames: Map<string, string[]>;
  @Input() customButtonsList: TemplateRef<Button>[];

  @Input()
  public set defaultSwitchLeftSideButtonName(buttonName: string) {
    if (buttonName) {
      this.leftSideStyleButtonName = buttonName;
      this.switchLeftSideButtonActionEmitter.emit(this.leftSideStyleButtonName);
    }
  }

  @Output() switchActiveTabEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() makeButtonActionEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() switchLeftSideButtonActionEmitter: EventEmitter<string> = new EventEmitter<string>();

  public ngOnInit(): void {
    this.isTerminalDevice = Utility.isTerminalDevice();
    this.isMobileDevice = Utility.isMobileDevice();
  }

  public getButtonsForActiveTab(): string[] {
    return this.rightSideActionButtonNames.get(this.activeTab);
  }

  public getLeftSideStyleButtonsNames(): string[] {
    return this.leftSideSwitchStyleButtonNames?.get(this.activeTab);
  }

  public switchTab(tabName: string): void {
    this.activeTab = tabName;
    this.switchActiveTabEmitter.emit(this.activeTab);
  }

  public makeButtonAction(buttonName: string): void {
    this.makeButtonActionEmitter.emit(buttonName);
  }

  public getCustomButtons(): TemplateRef<Button>[] {
    return this.customButtonsList;
  }

  public switchLeftSideButtonName(leftSideStyleButtonName: string): void {
    this.leftSideStyleButtonName = leftSideStyleButtonName;
    this.switchLeftSideButtonActionEmitter.emit(leftSideStyleButtonName);
  }

  public isSwitchLeftSideButtonActive(buttonName: string): boolean {
    return buttonName === this.leftSideStyleButtonName;
  }

}
