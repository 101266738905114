import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../core/auth/auth.service';
import {TranslateService} from '../../core/translations/translate.service';
import {Router} from '@angular/router';
import {AboutComponent} from './about/about.component';
import {environment} from '../../../environments/environment';
import {FunctionalityGroup} from '../../core/sdk/model-enums';
import {BaseSubNavbarService} from '../../shared/base-components/base-sub-navbar/base-sub-navbar.service';
import {ImageFilesService} from '../../shared/services/image-files.service';
import {FunctionalityService} from '../../shared/services/functionality.service';
import {Utility} from '../../shared/utilities/utility';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  public isLoginInfoPresent = false;
  public appTitle = environment.appName;
  public userAvatarUrl: string;
  public avatarStyleClass = 'navbar-bh__user-logo';
  public isTerminalDevice = false;
  public collapsedFunctionalities = false;
  public isMobileDevice = false;

  @ViewChild('aboutModal') aboutModal: AboutComponent;

  constructor(private authService: AuthService,
              private translateService: TranslateService,
              private router: Router,
              private baseSubNavbarService: BaseSubNavbarService,
              private imageFileService: ImageFilesService,
              private functionalityService: FunctionalityService) {
    if (Utility.isTerminalDevice()) {
      this.avatarStyleClass += ' navbar-bh__user-logo--terminal';
      this.isTerminalDevice = true;
    } else {
      this.avatarStyleClass += ' navbar-bh__user-logo--desktop';
    }
    this.isMobileDevice = Utility.isMobileDevice();
  }

  public ngOnInit(): void {
    this.translateService.translationsLoaded.asObservable().subscribe(() => {
      if (!this.isLoginInfoPresent && this.translateService.isTranslationKeyPresent('common.logged-in')
        && this.authService.isAuthenticated()) {
        this.isLoginInfoPresent = true;
      }
    });
    this.imageFileService.avatarChanged
      .subscribe((imageUrl: string) => {
        this.userAvatarUrl = imageUrl;
      });
    this.userAvatarUrl = this.imageFileService.getUserAvatar();
    if (this.isMobileFunctionalityGroupPresent()) {
      this.collapsedFunctionalities = true;
    }
  }

  private isMobileFunctionalityGroupPresent(): boolean {
    return this.functionalityService.getUserFunctionalityGroups()
      .find(functionalityGroup => functionalityGroup === FunctionalityGroup.MOBILE) !== null;
  }

  public logout(): void {
    this.authService.logout();
    this.isLoginInfoPresent = false;
  }

  public goToHomePage(): void {
    this.router.navigate(['/bh']);
  }

  public getCurrentFunctionalityGroups(): FunctionalityGroup[] {
    return this.functionalityService.getUserFunctionalityGroups().sort((a, b) => a - b);
  }

  public getFunctionalityGroupName(functionalityGroup: FunctionalityGroup): string {
    return 'common.' + FunctionalityGroup[functionalityGroup].toString().toLowerCase().split('_').join('-');
  }

  public isActiveFunctionalityGroup(functionalityGroup: FunctionalityGroup): boolean {
    return this.functionalityService.getActiveFunctionalityGroupName() === FunctionalityGroup[functionalityGroup]
      .toString().toLowerCase().split('_').join('-');
  }

  public redirectTo(functionalityGroup: FunctionalityGroup): void {
    if (!this.isActiveFunctionalityGroup(functionalityGroup)) {
      this.baseSubNavbarService.redirectToCurrentSubFunctionality('', functionalityGroup);
    }
    this.collapsedFunctionalities = true;
  }

}
