import {Injectable} from '@angular/core';
import {BurningTaskCtrl, TaskCtrl, WorkerTaskCtrl} from '../../../core/sdk/controllers-tasks';
import {FurnaceInstance} from '../../../core/sdk/model-productiondevices';
import {Worker} from '../../../core/sdk/model-roles';
import {BurningProduct, ProcessStep, WorkspaceInstance} from '../../../core/sdk/bighero-model';
import {BurningTask, Task, WorkerTask} from '../../../core/sdk/model-tasks';
import {Utility} from '../../utilities/utility';
import {TaskType} from '../../../core/sdk/enums-types';
import {BurningTaskStatus, TaskStatus} from '../../../core/sdk/enums-statuses';
import {Observable} from 'rxjs';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {JsonScope} from '../../../core/sdk/jsonScope';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  taskData: Task[];

  workerTaskData: WorkerTask[];

  dismissedTasksData: Task[];

  constructor(private burningTaskCtrl: BurningTaskCtrl,
              private workerTaskCtrl: WorkerTaskCtrl,
              private taskCtrl: TaskCtrl) {
  }

  public getNewBurningTask(name: string, furnaceInstance: FurnaceInstance, workerList: Worker[],
                           burningProductList: BurningProduct[]): BurningTask {
    return {
      id: 'burning-task/' + Utility.getUUID(),
      version: 0,
      creationDate: Date.now(),
      startDate: null,
      timestamp: Date.now(),
      type: TaskType.BURNING_TASK,
      progressStatus: TaskStatus.ACTIVE,
      shorthand: null,
      status: BurningTaskStatus.CREATED,
      estimatedDuration: null,
      name,
      burningProductList,
      furnaceInstance,
      personRole: null,
      workerList
    };
  }

  public getBurningProduct(processStep: ProcessStep): BurningProduct {
    return {
      id: 'burning-product/' + Utility.getUUID(),
      version: 0,
      timestamp: Date.now(),
      processStep,
      quantity: 0,
      burnedFaultyQuantity: 0,
      burnedGoodQuantity: 0
    };
  }

  public createBurningTask(task: BurningTask): Observable<BurningTask> {
    return this.burningTaskCtrl.createObject(task);
  }


  /////// WORKER_TASK
  public getNewWorkerTask(processStep: ProcessStep, workspaceInstance: WorkspaceInstance, worker: Worker, goal: number): WorkerTask {
    return {
      id: 'worker-task/' + Utility.getUUID(),
      version: 0,
      timestamp: Date.now(),
      creationDate: Date.now(),
      name: null,
      description: null,
      processStep,
      personRole: worker,
      estimatedDuration: processStep.estimatedTime,
      workspaceInstance,
      startDate: null,
      type: TaskType.WORKER_TASK,
      progressStatus: TaskStatus.ACTIVE,
      goal,
      doneFaultyQuantity: 0,
      doneGoodQuantity: 0,
      workerSubmittedLackOfAwaitingProductsForTask: false,
      moldInstance: null,
    };
  }

  public getWorkerTask(objectId: string): Observable<WorkerTask> {
    return this.workerTaskCtrl.getObject(Utility.getObjectId(objectId));
  }

  public createWorkerTask(task: WorkerTask): Observable<WorkerTask> {
    return this.workerTaskCtrl.createWorkerTask(task, new JsonScope(false, ['process-step']));
  }

  public createWorkerTasksAutomatically(processStepId: string): Observable<WorkerTask[]> {
    return this.workerTaskCtrl.createWorkerTasksAutomatically(Utility.getObjectId(processStepId));
  }

  public setWorkerTaskData(data: WorkerTask[]): void {
    this.workerTaskData = data;
  }

  public getWorkerTaskData(): WorkerTask[] {
    return this.workerTaskData;
  }

  public deleteAllNotStartedWorkerTasksForProcessStep(processStepID: string): Observable<number> {
    return this.workerTaskCtrl.deleteAllNotStartedWorkerTasksForProcessStep(Utility.getObjectId(processStepID));
  }

  public submitLackOfAwaitingProductsForWorkerTask(workerTaskID: string): Observable<WorkerTask> {
    return this.workerTaskCtrl.submitLackOfAwaitingProductsForWorkerTask(Utility.getObjectId(workerTaskID));
  }

  public finishWorkerTasksForProcessStepWithLackOfAwaitingProducts(processStepID: string): Observable<number> {
    return this.workerTaskCtrl.finishWorkerTasksForProcessStepWithLackOfAwaitingProducts(Utility.getObjectId(processStepID));
  }

  public finishWorkerTaskWithLackOfAwaitingProducts(workerTaskID: string): Observable<WorkerTask> {
    return this.workerTaskCtrl.finishWorkerTaskWithLackOfProducts(Utility.getObjectId(workerTaskID));
  }

  public getAllWorkerTasksForProcessStep(searchCriteria: SearchCriteriaForClass,
                                         processStepID: string, pageNumber: number,
                                         pageSize: number): Observable<WorkerTask[]> {
    return this.workerTaskCtrl.getAllWorkerTasksForProcessStep(
      searchCriteria, Utility.getObjectId(processStepID), pageNumber, pageSize);
  }

  public countAllWorkerTasksForProcessStep(searchCriteria: SearchCriteriaForClass, processStepID: string): Observable<number> {
    return this.workerTaskCtrl.countAllWorkerTasksForProcessStep(searchCriteria, Utility.getObjectId(processStepID));
  }

  public changeUsedMoldInstanceForTask(workerTaskId: string, moldInstanceBarcode: string, isPreviousMoldInstanceDamaged: boolean | null)
    : Observable<WorkerTask> {
    return this.workerTaskCtrl.changeUsedMoldInstanceForTask(Utility.getObjectId(workerTaskId), moldInstanceBarcode,
      isPreviousMoldInstanceDamaged);
  }

  ////// TASK

  public setTaskData(tasks: Task[]): void {
    this.taskData = tasks;
  }

  public getTaskData(): Task[] {
    return this.taskData;
  }

  public updateTask(task: Task): Observable<Task> {
    return this.taskCtrl.updateObject(task, new JsonScope(true, ['task', 'worker-task']));
  }

  public getDailyWorkerTasksForWorker(): Observable<WorkerTask[]> {
    return this.workerTaskCtrl.getDailyWorkerTasksForWorker();
  }

  public countAllWorkerTasksForWorker(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.workerTaskCtrl.countAllWorkerTasksForWorker(searchCriteriaForClass);
  }

  public getAllWorkerTasksForWorker(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number)
    : Observable<WorkerTask[]> {
    return this.workerTaskCtrl.getAllWorkerTasksForWorker(searchCriteriaForClass, pageNumber, pageSize);
  }

  public startTask(taskId: string, moldInstanceBarcode: string): Observable<Task> {
    return this.taskCtrl.startTask(Utility.getObjectId(taskId), moldInstanceBarcode);
  }


  ////////// DISMISSED TASKS

  public setDismissedTaskData(tasks: Task[]): void {
    this.dismissedTasksData = tasks;
  }

  public getDismissedTasksData(): Task[] {
    return this.dismissedTasksData;
  }

  public flushDismissedTasksData(): void {
    this.dismissedTasksData = undefined;
  }

  public countDismissedTasks(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.taskCtrl.countDismissedTasks(searchCriteriaForClass);
  }

  public getDismissedTasks(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Task[]> {
    return this.taskCtrl.getDismissedTasks(searchCriteriaForClass, pageNumber, pageSize);
  }


}


