<div class="image-terminal-preview">
  <div class="image-terminal-preview__header">
    <div class="image-terminal-preview__header__zoom-buttons">
      <button
        class="pi pi-minus"
        (click)="zoomOut()"
      ></button>
      <button
        class="pi pi-plus"
        (click)="zoomIn()"
      ></button>
    </div>
    <div class="image-terminal-preview__header__close-button">
      <button
        class="pi pi-times"
        (click)="closeComponent()"
      ></button>
    </div>
  </div>

  <div class="image-terminal-preview__body">
    <app-secured-image
      *ngIf="imgUrl"
      [src]="imgUrl"
      [class]="customClass"
      [ngStyle]="zoomStyle"
    ></app-secured-image>
  </div>
</div>

