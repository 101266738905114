import {NgModule} from '@angular/core';
import {BaseFormComponent} from './base-form/base-form.component';
import {BaseFromFieldComponent} from './base-form-field/base-from-field.component';
import {CommonModule} from '@angular/common';
import {FooterModule} from '../footer/footer.module';
import {CustomButtonModule} from '../custom-button/custom-buttom.module';
import {TranslateModule} from '../../../core/translations/translate.module';
import {ColorPickerModule} from 'ngx-color-picker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';

@NgModule({
  declarations: [
    BaseFormComponent,
    BaseFromFieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ColorPickerModule,
    FooterModule,
    CustomButtonModule,
    DropdownModule,
  ],
  exports: [
    BaseFormComponent,
    BaseFromFieldComponent
  ],
  providers: [
  ]
})
export class BaseFormModule {
}
