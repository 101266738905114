/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Currency, CurrencyRate} from './model-currency';
import {SearchCriteriaForClass} from './model-dto';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class CurrencyCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countCurrencies(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/currency/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createCurrency(currency: Currency, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Currency>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Currency>();
    this.httpService.post('/api/currency/w/create', JsonScopedSerializer.stringify(currency, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getCurrencies(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Currency[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Currency[]>();
    this.httpService.post('/api/currency/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getEuroCurrency(): Observable<Currency>  {
   const subject = new Subject<Currency>();
    this.httpService.get('/api/currency/r/get-euro', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<Currency>  {
   const subject = new Subject<Currency>();
    this.httpService.get('/api/currency/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: Currency, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Currency>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Currency>();
    this.httpService.put('/api/currency/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class CurrencyRateCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countCurrencyRatesForCurrency(searchCriteria: SearchCriteriaForClass, currencyID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'currencyID', value: currencyID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/currency-rate/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createCurrencyRateForCurrency(currencyRate: CurrencyRate, jsonScope: JsonScope = new JsonScope(false, [])): Observable<CurrencyRate>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<CurrencyRate>();
    this.httpService.post('/api/currency-rate/w/create', JsonScopedSerializer.stringify(currencyRate, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getCurrencyRatesForCurrency(searchCriteria: SearchCriteriaForClass, currencyID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<CurrencyRate[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'currencyID', value: currencyID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<CurrencyRate[]>();
    this.httpService.post('/api/currency-rate/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: CurrencyRate, jsonScope: JsonScope = new JsonScope(false, [])): Observable<CurrencyRate>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<CurrencyRate>();
    this.httpService.put('/api/currency-rate/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

