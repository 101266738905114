import {
  Component,
  EventEmitter,
  Input,
  OnInit
} from '@angular/core';
import {
  BurningSchemaStep,
  DryingSchemaStep, MixingMassesSchemaStep,
  MoldingSchemaStep,
  PostProcessingSchemaStep,
  ProductionSchemaStep
} from '../../../../core/sdk/model-productionschema';
import {ProductionFiringSchemaService} from '../../../services/production-firing-schema.service';
import {ProductionSchemaStepType} from '../../../../core/sdk/enums-types';
import {Utility} from '../../../utilities/utility';
import {NotificationService} from '../../../services/notification.service';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {CrossFunctionalService} from '../../../services/cross-functional.service';
import {MoldType} from '../../../../core/sdk/model-mold';
import {FiringSchema} from '../../../../core/sdk/model-firingschema';
import {TranslateService} from '../../../../core/translations/translate.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstanceValues} from '../../../constance-values/constance-values';
import {SpinnerService} from '../../../services/spinner.service';
import {ProductTypeService} from '../../../services/product-type.service';
import {SchemaService} from '../../../services/schema.service';
import {MixingSchema} from '../../../../core/sdk/model-mixingschema';
import {TranslationKeyEnum} from '../../../enums/translation-key-enum';

@Component({
  selector: 'app-production-schema-step',
  templateUrl: './production-schema-step.component.html'
})
export class ProductionSchemaStepComponent implements OnInit {

  public formGroup: FormGroup;
  public editStepDescriptionFlag = false;
  public stepFormFlag = false;
  public moldType: MoldType;
  public firingSchema: FiringSchema;
  public mixingSchema: MixingSchema;
  public weightReduction: number;
  public quantityOfMiddleProductsAfterPostProcessing: number;

  public isTerminalDevice = false;
  public shouldArrowButtonsInSchemaStepBeDisable;

  @Input() public step: ProductionSchemaStep;
  @Input() public isSchemaTemplateApplied = false;
  @Input() public indexColumnApplied = false;
  @Input() public closeAllStepEditionEmitter: EventEmitter<void>;
  @Input('disableArrowButtons') set setButtonsDisabledFromCross(value : boolean) {
    this.shouldArrowButtonsInSchemaStepBeDisable = value;
  }

  constructor(private productionFiringSchemaService: ProductionFiringSchemaService,
              private productTypeService: ProductTypeService,
              private translateService: TranslateService,
              private notificationService: NotificationService,
              private formBuilder: FormBuilder,
              private crossFunctionalService: CrossFunctionalService,
              private spinnerService: SpinnerService,
              private schemaService: SchemaService) {
    this.isTerminalDevice = Utility.isTerminalDevice();
  }

  public ngOnInit(): void {
    this.initFormGroup();
    if (this.crossFunctionalService.getGatheredData('currentlyEditedStepId') === this.step.id) {
      this.stepFormFlag = true;
    }
    if (this.stepFormFlag) {
      this.crossFunctionalService.flushData('currentlyEditedStepId');
    }
    this.assignExclusiveValues();
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [this.step.name ?? '', [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
      description: [this.step.description ?? '', [Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]]
    });
  }

  private assignExclusiveValues(): void {
    if (this.step.id.includes('burning')) {
      this.firingSchema = (this.step as BurningSchemaStep).firingSchema;
    } else if (this.step.id.includes('molding')) {
      this.moldType = (this.step as MoldingSchemaStep).moldType;
    } else if (this.step.id.includes('drying')) {
      this.weightReduction = (this.step as DryingSchemaStep).weightReduction ?? 0;
    } else if (this.step.id.includes('post-processing')) {
      this.quantityOfMiddleProductsAfterPostProcessing =
        (this.step as PostProcessingSchemaStep).quantityOfMiddleProductsAfterPostProcessing;
    } else if (this.step.id.includes('mixing')) {
      this.mixingSchema = (this.step as MixingMassesSchemaStep).mixingSchema;
    }
  }

  public toggleEditDescriptionFlag(): void {
    this.editStepDescriptionFlag = !this.editStepDescriptionFlag;
  }

  public toggleStepFormFlag(): void {
    this.productionFiringSchemaService.addToCurrentlyEditedSteps(this.step.id);
    this.stepFormFlag = !this.stepFormFlag;
  }

  public removeProductionSchemaStep(): void {
    this.spinnerService.activateSpinner();
    if (!this.indexColumnApplied) {
      this.spinnerService.activateSpinner();
      this.productionFiringSchemaService.removeSchemaStep(this.step.id).subscribe(response => {
        this.productTypeService.updateSchemaForProductTypeInData(response);
        this.productionFiringSchemaService.assignSchemaStepsToData(response.productionSchemaStepList);
        this.spinnerService.deactivateSpinner();
        this.notificationService.displayNotificationToast('products.step-deleted-successfully', NotificationMessageType.SUCCESS);
        this.spinnerService.deactivateSpinner();
      });
    } else {
      this.productionFiringSchemaService.removeStepFromData(this.step);
      this.reloadProductionFiringSummarySteps();
      this.spinnerService.deactivateSpinner();
    }
  }

  public updateProductionSchemaStepDescription(): void {
    this.step = {...this.step, ...this.formGroup.value};
    this.productionFiringSchemaService.updateSchemaStep(this.step).subscribe(response => {
      this.step = response as ProductionSchemaStep;
      Utility.updateObjectInData(response, this.productionFiringSchemaService.schemaSteps);
      this.notificationService.displayNotificationToast('products.step-updated-successfully', NotificationMessageType.SUCCESS);
      this.toggleEditDescriptionFlag();
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }


  public changeIndexHigher(): void {
    this.productionFiringSchemaService.changeIndexHigher(this.step);
  }


  public changeIndexLower(): void {
    this.productionFiringSchemaService.changeIndexLower(this.step);
  }

  public transformSecondsToDurationObject(seconds: number): {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  } {
    return this.schemaService.transformSeconds(seconds);
  }

  public reloadProductionFiringSummarySteps(): void {
    this.schemaService.reloadSummaryStepsDuration(this.productionFiringSchemaService.getSchemaSteps());
  }

  public updateStep(step?: ProductionSchemaStep): void {
    if (step) {
      this.productionFiringSchemaService.updateEditedStepInSchemaSteps(step);
      this.assignExclusiveValues();
      this.reloadProductionFiringSummarySteps();
    } else if (this.productionFiringSchemaService.isItNewStep(this.step.id)) {
      this.productionFiringSchemaService.removeStepFromData(this.step);
      this.reloadProductionFiringSummarySteps();
    }
    this.stepFormFlag = false;
    this.productionFiringSchemaService.removeFromCurrentlyEditedSteps(this.step.id);
  }

  public transformDurationObjectToString(duration: {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  }): string {
    return Utility.transformDurationObjectToUserFriendlyString(duration, this.translateService);
  }

  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  protected readonly ProductionSchemaStepType = ProductionSchemaStepType;
  protected readonly TranslationKeyEnum = TranslationKeyEnum;
}
