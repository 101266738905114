import {inject, Injectable} from '@angular/core';
import {CriteriaValue, CriteriaValueType, SearchCriteria, SearchCriteriaForClass} from './sdk/model-dto';
import {OperationSign} from './sdk/model-enums';
import {ColumnOperationSign} from '../shared/interfaces/interfaces';
import {TranslateService} from './translations/translate.service';

@Injectable({
  providedIn: 'root'
})
export class SearchCriteriaService {

  public columnOperationSignMap: Map<string, ColumnOperationSign> = new Map<string, ColumnOperationSign>();

  translateService = inject(TranslateService);

  updateSearchCriteria(searchCriteriaForClass: SearchCriteriaForClass,
                       filteredFieldName: string,
                       inClassPropertyName: string | undefined,
                       propertyClass: string | undefined,
                       value: CriteriaValue,
                       defaultOperationSign: string,
                       multipleCriteriaOnFieldAllowed: boolean,
                       deepFilterNestingList?: string[] | undefined,
  ): SearchCriteriaForClass {
    if (((this.getSearchCriteriaForField(filteredFieldName, propertyClass, searchCriteriaForClass) === null
          || this.getSearchCriteriaForField(filteredFieldName, propertyClass, searchCriteriaForClass) === undefined)
        || multipleCriteriaOnFieldAllowed)
      && value && !this.isBlank(value)) {
      searchCriteriaForClass.searchCriteriaList.push(this.createSearchCriteriaForField(
        filteredFieldName, inClassPropertyName, propertyClass, deepFilterNestingList, defaultOperationSign, value));
    } else {
      const searchForField: any = this.getSearchCriteriaForField(filteredFieldName, propertyClass, searchCriteriaForClass);
      if (searchForField !== undefined) {
        const searchCriteria: SearchCriteria = searchForField;
        const index: number = searchCriteriaForClass.searchCriteriaList.indexOf(searchCriteria);
        const operationSign: string = this.getOperationSignFromCriteriaMap(searchCriteria);
        if (operationSign === defaultOperationSign) {
          searchCriteria.criteriaMap[defaultOperationSign] = value;
        } else {
          searchCriteria.criteriaMap[operationSign] = value;
        }
        searchCriteriaForClass.searchCriteriaList[index] = searchCriteria;
      }
    }
    return this.removeCriteriaMapWithAssignedNullValue(searchCriteriaForClass);
  }

  public updateOperationSignInSearchCriteria(searchCriteriaForClass: SearchCriteriaForClass,
                                             filteredFieldName: string,
                                             newOperationSign: string,
                                             inClassPropertyName?: string,
                                             propertyClass?: string,
                                             deepFilterNestingList?: string[]): SearchCriteriaForClass {
    const searchForField: any = this.getSearchCriteriaForField(filteredFieldName, propertyClass, searchCriteriaForClass);
    if (searchForField !== undefined) {
      const searchCriteria: SearchCriteria = searchForField;
      const index: number = searchCriteriaForClass.searchCriteriaList.indexOf(searchCriteria);
      if (searchCriteria.criteriaMap === null) {
        searchCriteria.criteriaMap[newOperationSign] = null;
      } else {
        const operationSign: string = this.getOperationSignFromCriteriaMap(searchCriteria);
        const criteriaValue: CriteriaValue = searchCriteria.criteriaMap[operationSign];
        delete searchCriteria.criteriaMap[operationSign];
        searchCriteria.criteriaMap[newOperationSign] = criteriaValue;
      }
      searchCriteriaForClass.searchCriteriaList[index] = searchCriteria;
    } else {
      searchCriteriaForClass.searchCriteriaList.push(this.createSearchCriteriaForField(
        filteredFieldName, inClassPropertyName, propertyClass, deepFilterNestingList, newOperationSign, null));
    }
    return searchCriteriaForClass;
  }

  public parseLocalizedNumber(value: string): number {
    const locale = this.translateService.getCurrentLanguage().locale.split('_')[0];
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [...new Intl.NumberFormat(locale, {useGrouping: false}).format(9876543210)].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    const group = new RegExp(`[${parts.find(d => d.type === 'group').value}]`, 'g');
    const decimal = new RegExp(`[${parts.find(d => d.type === 'decimal').value}]`);
    const numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    const indeks = d => index.get(d);

    return this.parse(value, group, decimal, numeral, indeks);
  }

  private parse(value: string, group, decimal, numeral, indeks) {
    return (value = value.trim()
      .replace(group, '')
      .replace(decimal, '.')
      .replace(numeral, indeks)) ? +value : NaN;
  }

  private getOperationSignFromCriteriaMap(searchCriteria: SearchCriteria): string {
    return Object.entries(searchCriteria.criteriaMap)[0][0];
  }

  public removeCriteriaMapWithAssignedNullValue(searchCriteriaForClass: SearchCriteriaForClass): SearchCriteriaForClass {
    searchCriteriaForClass.searchCriteriaList.forEach((searchCriteria: SearchCriteria) => {
      if (Object.entries(searchCriteria.criteriaMap)[0][1] === null) {
        this.changeOperationSignForGivenField(OperationSign.EQUAL.toString(), searchCriteria.filterFieldName);
        const index: number = searchCriteriaForClass.searchCriteriaList.indexOf(searchCriteria);
        searchCriteriaForClass.searchCriteriaList.splice(index, 1);
      }
    });
    return searchCriteriaForClass;
  }

  public changeOperationSignForGivenField(operationSign: string, filteredFieldName: string): Map<string, ColumnOperationSign> {
    let operationSignIconClass: string;
    const columnOperationSign: ColumnOperationSign = {operationSignIconClass: '', operationSign: ''};
    switch (OperationSign[operationSign]) {
      case 'EQUAL' : {
        operationSignIconClass = 'fa-equal-sign';
        break;
      }
      case 'NOT_EQUAL' : {
        operationSignIconClass = 'fa-not-equal-sign';
        break;
      }
      case 'MORE' : {
        operationSignIconClass = 'fa-more-sign';
        break;
      }
      case 'LESS' : {
        operationSignIconClass = 'fa-less-sign';
        break;
      }
      case 'GREATER_THAN_OR_EQUAL' : {
        operationSignIconClass = 'fa-greater-than-or-equal-sign';
        break;
      }
      case 'LESS_THAN_OR_EQUAL' : {
        operationSignIconClass = 'fa-less-than-or-equal-sign';
        break;
      }
      case 'RANGE_SIGN' : {
        operationSignIconClass = 'fa-range-sign';
        break;
      }
    }
    columnOperationSign.operationSign = operationSign;
    columnOperationSign.operationSignIconClass = operationSignIconClass;
    return this.columnOperationSignMap.set(filteredFieldName, columnOperationSign);
  }

  private isBlank(value: CriteriaValue): boolean {
    return value.value === '' || (value.value === 0 && value.valueType !== CriteriaValueType.ENUM);
  }

  public createSearchCriteriaForClass(className: string, sortBy: string, ascending: boolean,
                                      sortByInClassPropertyName?: string): SearchCriteriaForClass {
    return {
      deepSortNestingList: undefined,
      isSortingStringNumeric: false,
      sortPropertyClass: undefined,
      sortingStringNumeric: undefined,
      searchCriteriaList: [],
      filteredClass: className,
      sortBy,
      sortByInClassPropertyName,
      ascending
    };
  }

  public getSearchCriteriaForField(column: string, propertyClassName: string,
                                   searchCriteriaForClass: SearchCriteriaForClass): SearchCriteria | undefined {
    return searchCriteriaForClass.searchCriteriaList
      .find(criteria => criteria.propertyClass == propertyClassName && criteria.filterFieldName === column);
  }

  public createSearchCriteriaForField(filterFieldName: string, inClassPropertyName: string, propertyClass: string,
                                      deepFilterNestingList: string[], sign: string, value: CriteriaValue): SearchCriteria {
    return {
      deepFilterNestingList,
      filterFieldName,
      inClassPropertyName,
      propertyClass,
      criteriaMap: {[sign]: value}
    };
  }

  public removeAllSearchCriteriaForGivenColumn(searchCriteriaForClass: SearchCriteriaForClass, column: string): SearchCriteriaForClass {
    if (this.searchCriteriaForFieldExists(searchCriteriaForClass, column)) {
      searchCriteriaForClass = this.removeSearchCriteriaForGivenColumn(searchCriteriaForClass, column);
      this.removeAllSearchCriteriaForGivenColumn(searchCriteriaForClass, column);
    }
    return searchCriteriaForClass;
  }


  public searchCriteriaForFieldExists(searchCriteriaForClass: SearchCriteriaForClass, column: string): boolean {
    return this.getSearchCriteriaForField(column, null, searchCriteriaForClass) !== null
      && this.getSearchCriteriaForField(column, null, searchCriteriaForClass) !== undefined;
  }

  public updateFilteredClassNameInSearchCriteria(searchCriteriaForClass: SearchCriteriaForClass, newClassName: string)
    : SearchCriteriaForClass {
    searchCriteriaForClass.filteredClass = newClassName;
    return searchCriteriaForClass;
  }

  public removeSearchCriteriaForGivenColumn(searchCriteriaForClass: SearchCriteriaForClass, column: string): SearchCriteriaForClass {
    const searchForField: any = this.getSearchCriteriaForField(column, null, searchCriteriaForClass);
    const index = searchCriteriaForClass.searchCriteriaList.indexOf(searchForField);
    searchCriteriaForClass.searchCriteriaList.splice(index, 1);
    return searchCriteriaForClass;
  }

}
