/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Batch, BatchRequest} from './model-batch';
import {SearchCriteriaForClass} from './model-dto';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class BatchCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countProcessStepIncomingBatches(searchCriteria: SearchCriteriaForClass, processStepID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'processStepID', value: processStepID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/batch/r/count-incoming', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countProcessStepOutgoingBatches(searchCriteria: SearchCriteriaForClass, processStepID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'processStepID', value: processStepID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/batch/r/count-outgoing', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createBatch(piecesQuantity: number, originProcessStepID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Batch>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'piecesQuantity', value: piecesQuantity.toString()});
  
    queryParamsList.push({name: 'originProcessStep', value: originProcessStepID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Batch>();
    this.httpService.post('/api/batch/w/create', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findBatchByBarcode(barcode: string): Observable<Batch>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'barcode', value: barcode});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<Batch>();
    this.httpService.get('/api/batch/r/find-by-barcode', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<Batch>  {
   const subject = new Subject<Batch>();
    this.httpService.get('/api/batch/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProcessStepIncomingBatches(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, processStepID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Batch[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    queryParamsList.push({name: 'processStepID', value: processStepID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Batch[]>();
    this.httpService.post('/api/batch/r/get-incoming', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProcessStepOutgoingBatches(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, processStepID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Batch[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    queryParamsList.push({name: 'processStepID', value: processStepID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Batch[]>();
    this.httpService.post('/api/batch/r/get-outgoing', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: Batch, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Batch>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Batch>();
    this.httpService.put('/api/batch/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class BatchRequestCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countBatchRequestsForGroupLeader(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/batch-request/r/count-for-group-leader', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createBatch(processStepID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<BatchRequest>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'processStepID', value: processStepID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<BatchRequest>();
    this.httpService.post('/api/batch-request/w/create', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getBatchRequestsForGroupLeader(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<BatchRequest[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<BatchRequest[]>();
    this.httpService.post('/api/batch-request/r/get-for-group-leader', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<BatchRequest>  {
   const subject = new Subject<BatchRequest>();
    this.httpService.get('/api/batch-request/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

