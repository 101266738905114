<div class="global-flex global-justify__flex-end">
  <button
    type="button"
    class="global-button"
    (click)="closeViewEmitter.emit()"
  >{{'production.return' | translate}}</button>
</div>


<div class="production-planning-manual-table">
  <table class="production-planning-manual-table__table-container">
    <thead class="production-planning-manual-table__head">
    <tr class="production-planning-manual-table__row">
      <th class="production-planning-manual-table__header">{{'production.name' | translate}}</th>
      <th class="production-planning-manual-table__header">{{'production.email' | translate}}</th>
      <th class="production-planning-manual-table__header">{{'production.telephone' | translate}}</th>
      <ng-template [ngIf]="applyAssigning">
        <th class="production-planning-manual-table__header">{{'production.action' | translate}}</th>
      </ng-template>
    </tr>
    </thead>
    <tbody>

    <ng-template [ngIf]="!persons.isEmpty()">
      <tr
        *ngFor="let person of persons"
        class="production-planning-manual-table__records">
        <td class="production-planning-manual-table__single-record">{{person.name}}</td>
        <td class="production-planning-manual-table__single-record">{{person.email}}</td>
        <td class="production-planning-manual-table__single-record">{{person.telephone}}</td>
        <ng-template [ngIf]="applyAssigning">
          <button
            type="button"
            class="global-button"
            (click)="chosenWorkerEmitter.emit(person)"
          >{{'production.assign-worker' | translate}}</button>
        </ng-template>
      </tr>
    </ng-template>

    <ng-template [ngIf]="persons.isEmpty()">
      <tr class="production-planning-manual-table__records">
        <td
          class="production-planning-manual-table__single-record"
          colspan="3"
        >{{'common.no-records' | translate}}</td>
      </tr>
    </ng-template>

    </tbody>
  </table>
</div>
