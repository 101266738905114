<div class="global-flex global-justify__flex-end">
  <button
    *ngIf="forRepack"
    type="button"
    class="global-button"
    (click)="confirmRepack()"
  >{{ 'mobile.confirm-repack' | translate }}
  </button>
  <button
    type="button"
    class="global-button"
    (click)="closeViewEmitter.emit()"
  >{{ 'mobile.return' | translate }}
  </button>
</div>

<div class="mobile-split-product-details-tile">

  <div class="global-text-align__center">
    <span class="global-font-size__x-large global-font-weight--bold"
    >{{ 'mobile.details' | translate }}</span>
  </div>

  <ng-template [ngIf]="!forRepack && !splitProductStoredInstance.destinationStorageArea">
    <div style="border: 5px solid red; padding: 2rem; text-align: center; font-weight: bold; margin-bottom: 1rem">
      <span>{{ 'warehouse.you-must-assign-destination-area-first-to-move-split-product-stored-instance' | translate }}</span>
    </div>
  </ng-template>

  <app-mobile-split-product-in-storing-details-tile
  [splitProductStoredInstance]="splitProductStoredInstance"
  [forRepack]="forRepack"
  [storingUnitInstance]="storingUnitInstance"
  ></app-mobile-split-product-in-storing-details-tile>

  <ng-template [ngIf]="!forRepack && splitProductStoredInstance.destinationStorageArea">
    <div class="shipment-pack-pick-up-product-form global-margin-top__1rem">
      <h3 class="global-color--black"
      >{{ 'mobile.confirm-move-to-destination-storage-area'| translate }}</h3>
      <div
        class="shipment-pack-pick-up-product-form__form-container">
        <form
          class="shipment-pack-pick-up-product-form__form-container__form"
          [formGroup]="formGroup"
          (ngSubmit)="confirmMoveToDestinationArea()">
          <div
            class="shipment-pack-pick-up-product-form__form-container__form__label">
            <label
            >{{ 'mobile.scan-area-barcode' | translate }}</label>
          </div>
          <input
            #areaBarcodeInput
            autofocus
            type="text"
            formControlName="areaBarcode">
          <span
            [ngClass]="areaBarcodeControl.invalid && (areaBarcodeControl.touched) ? 'visibility--true' : 'visibility--false'"
            class="global-error-message global-align--self-center"
          >{{ 'common.valid-value-required' | translate }}</span>
          <div
            class="shipment-pack-pick-up-product-form__form-container__form__label">
            <label>{{ 'mobile.scan-split-product-stored-instance-barcode' | translate }}</label>
          </div>
          <input
            autofocus
            type="text"
            formControlName="splitProductStoredInstance">
          <span
            [ngClass]="splitProductStoredInstanceControl.invalid && (splitProductStoredInstanceControl.touched) ? 'visibility--true' : 'visibility--false'"
            class="global-error-message global-align--self-center"
          >{{ 'common.valid-value-required' | translate }}</span>
          <div class="shipment-pack-pick-up-product-form__form-container__form__buttons">
            <button
              type="submit"
              [disabled]="formGroup.invalid"
            >{{ 'mobile.confirm' | translate }}
            </button>
            <button
              type="button"
              [ngClass]="'global-margin--left-5vw'"
              (click)="onBack()"
            >{{ 'common.return' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>
