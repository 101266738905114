/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AdditionalAddressLineType, DeliveryTerm, MachineModelType, ManufacturingMethod, PaymentTerm, ProductShape} from './model-enumentity';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class AdditionalAddressLineTypeCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObjectByName(name: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<AdditionalAddressLineType>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'name', value: name});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<AdditionalAddressLineType>();
    this.httpService.post('/api/additional-address-line-type/w/new', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAllEnumObjects(): Observable<AdditionalAddressLineType[]>  {
   const subject = new Subject<AdditionalAddressLineType[]>();
    this.httpService.get('/api/additional-address-line-type/r/all', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getEnumObject(id: string): Observable<AdditionalAddressLineType>  {
   const subject = new Subject<AdditionalAddressLineType>();
    this.httpService.get('/api/additional-address-line-type/r/get/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class DeliveryTermCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObjectByName(name: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<DeliveryTerm>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'name', value: name});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<DeliveryTerm>();
    this.httpService.post('/api/delivery-term/w/new', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/delivery-term/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAllEnumObjects(): Observable<DeliveryTerm[]>  {
   const subject = new Subject<DeliveryTerm[]>();
    this.httpService.get('/api/delivery-term/r/all', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class MachineModelTypeCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public findAllEnumObjects(): Observable<MachineModelType[]>  {
   const subject = new Subject<MachineModelType[]>();
    this.httpService.get('/api/machine-model-type/r/all', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ManufacturingMethodCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public findAllEnumObjects(): Observable<ManufacturingMethod[]>  {
   const subject = new Subject<ManufacturingMethod[]>();
    this.httpService.get('/api/manufacturing-method/r/all', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class PaymentTermCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObjectByName(name: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<PaymentTerm>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'name', value: name});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<PaymentTerm>();
    this.httpService.post('/api/payment-term/w/new', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/payment-term/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAllEnumObjects(): Observable<PaymentTerm[]>  {
   const subject = new Subject<PaymentTerm[]>();
    this.httpService.get('/api/payment-term/r/all', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class ProductShapeCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public findAllEnumObjects(): Observable<ProductShape[]>  {
   const subject = new Subject<ProductShape[]>();
    this.httpService.get('/api/product-shape/r/all', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

