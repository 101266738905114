<div class="global-flex global-justify__flex-end">
  <button
    type="button"
    class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
    (click)="back()"
  >{{ 'common.return' | translate }}
  </button>
</div>

<div class="global-flex global-justify__flex-end">
  <button
    type="button"
    class="global-button"
    (click)="handleFiltering()"
  >{{ showFilteringRow ? ('warehouse.reset-filters' | translate) : ('warehouse.filter' | translate) }}
  </button>
</div>

<app-product-filtering-options
  *ngIf="showFilteringRow"
  (selectedOptionEmitter)="setSelectedFilteringOption($event)"
></app-product-filtering-options>

<p-table
  class="main-table-header"
  dataKey="id"
  responsiveLayout="scroll"
  [value]="getTableData()"
  [paginator]="true"
  [lazy]="true"
  [rowHover]="true"
  [rows]="pageable.pageSize"
  [totalRecords]="pageable.count"
  [(first)]="pageable.pageNumber"
  (onPage)="paginate($event)">

  <ng-template pTemplate="header">
    <tr>
      <th
        pSortableColumn="order"
        (click)="sortByColumn('shorthand', false, 'order', 'Order')"
      >{{ 'production.order' | translate }}
        <p-sortIcon field="order"></p-sortIcon>
      </th>

      <th
        pSortableColumn="duration"
        (click)="sortByColumn('duration')"
      >{{ 'production.duration' | translate }}
        <p-sortIcon field="duration"></p-sortIcon>
      </th>

      <th
        pSortableColumn="productName"
        appCustomSortFieldTableDirective
        [component]="this"
        appStyleTableFieldDirective
        [elementHasChildren]="true"
        [applyPointerEventsStyleForSortingTableElement]="true"
        [fieldIsShared]="true">
        {{ 'production.product-name' | translate }}
        <p-sortIcon field="productName"></p-sortIcon>
      </th>

      <th
        pSortableColumn="articleNumber"
        appStyleTableFieldDirective
        [elementHasChildren]="true"
        [applyPointerEventsStyleForSortingTableElement]="true"
        (click)="sortByColumn('articleNumber', false, 'productType', 'ProductType')"
      >{{'production.article-number' | translate}}
        <p-sortIcon field="articleNumber"></p-sortIcon>
      </th>

      <th
        pSortableColumn="quality"
        appStyleTableFieldDirective
        [elementHasChildren]="true"
        [applyPointerEventsStyleForSortingTableElement]="true"
        (click)="sortByColumn('name', false, 'productType', 'ProductQuality')"
      >{{'production.product-quality' | translate}}
        <p-sortIcon field="quality"></p-sortIcon>
      </th>

      <th
        *ngIf="!showMachineInstanceInfo && showWorkspaceInstanceColumn"
        pSortableColumn="workspaceInstance"
        (click)="sortByColumn('shorthand', false, 'workspaceInstance', 'WorkspaceInstance')"
      >{{ 'production.workspace-instance' | translate }}
        <p-sortIcon field="workspaceInstance"></p-sortIcon>
      </th>

      <th
        *ngIf="showMachineInstanceInfo"
        pSortableColumn="machineInstance"
        (click)="sortByColumn('shorthand', false, 'workspaceInstance', 'ProductionDeviceInstance')"
      >{{ 'production.machine-instance' | translate }}
        <p-sortIcon field="machineInstance"></p-sortIcon>
      </th>

      <th
        pSortableColumn="workerName"
        (click)="sortByColumn('name', false, 'worker', 'Person')"
      >{{ 'production.worker' | translate }}
        <p-sortIcon field="workerName"></p-sortIcon>
      </th>
    </tr>

    <tr
      *ngIf="showFilteringRow"
      class="primeng-table__search-row">

      <td>
        <input
          appStringLengthInputValidation
          type="text"
          data-cy="name-search"
          class="global-input primeng-table__filter-col"
          placeholder="{{ 'production.search-by-order' | translate }}"
          [(ngModel)]="orderFilterInput"
          (keydown.enter)="searchByString($event, 'shorthand', 'order', 'Order')">
      </td>
      <td>
      </td>

      <td>
        <input
          #productNameInput
          appStringLengthInputValidation
          appCustomFilterFieldTableDirective
          [component]="this"
          [filteringType]="FilteringTypeEnum.STRING"
          appStyleTableFieldDirective
          [fieldIsShared]="true"
          type="text"
          class="global-input primeng-table__filter-col"
          placeholder="{{ 'warehouse.search-by-product-name' | translate }}">
      </td>

      <td>
        <input
          #articleNumberInput
          appStringLengthInputValidation
          appStyleTableFieldDirective
          type="text"
          placeholder="{{ 'production.search-by-article-number' | translate }}"
          class="global-input primeng-table__filter-col"
          [(ngModel)]="productFilterInput"
          (keydown.enter)="searchByString($event, 'articleNumber', 'productType', 'ProductType')">
      </td>
      <td>
        <input
          #qualityInput
          appStringLengthInputValidation
          appStyleTableFieldDirective
          type="text"
          placeholder="{{ 'production.search-by-product-quality' | translate }}"
          class="global-input primeng-table__filter-col"
          [(ngModel)]="qualityFilterInput"
          (keydown.enter)="searchByString($event, 'name', 'productType', 'ProductQuality',
          ['quality'])">
      </td>
      <td *ngIf="!showMachineInstanceInfo && showWorkspaceInstanceColumn">
        <input
          appStringLengthInputValidation
          type="text"
          placeholder="{{ 'production.search-by-workspace-instance' | translate }}"
          class="global-input primeng-table__filter-col"
          [(ngModel)]="workspaceInstanceFilterInput"
          (keydown.enter)="searchByString($event, 'shorthand', 'workspaceInstance', 'WorkspaceInstance')">
      </td>
      <td *ngIf="showMachineInstanceInfo">
        <input
          appStringLengthInputValidation
          type="text"
          placeholder="{{ 'production.search-by-machine-instance' | translate }}"
          class="global-input primeng-table__filter-col"
          [(ngModel)]="workspaceInstanceFilterInput"
          (keydown.enter)="searchByString($event, 'shorthand', 'workspaceInstance', 'ProductionDeviceInstance')">
      </td>
      <td>
        <input
          appStringLengthInputValidation
          type="text"
          placeholder="{{ 'production.search-by-worker-name' | translate }}"
          class="global-input primeng-table__filter-col"
          [(ngModel)]="workerNameInstanceFilterInput"
          (keydown.enter)="searchByString($event, 'name', 'worker', 'Person')">
      </td>
    </tr>

  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [colSpan]="showWorkspaceInstanceColumn ? 7 : 6"
      >{{ 'common.no-records-available' | translate }}
      </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-workload
    class="global-pointer">
    <tr>
      <td>{{ workload.order.shorthand }}</td>
      <td>{{ transformTimestampToUserFriendlyString(workload.duration?.seconds) }}</td>
      <td>{{ workload.productType? workload.productType?.name : workload.productForService?.name}}</td>
      <td>{{workload.productType ? workload.productType.articleNumber : ('production.not-applicable' | translate)}}</td>
      <td>{{ workload.productType ?workload.productType.quality.name : ('production.not-applicable' | translate)}}</td>
      <td *ngIf="!showMachineInstanceInfo && showWorkspaceInstanceColumn">{{ workload.workspaceInstance.shorthand }}
      </td>
      <td *ngIf="showMachineInstanceInfo">{{ workload.workspaceInstance.productionDeviceInstance?.shorthand }}</td>
      <td>{{ workload.worker?.name }}</td>

    </tr>
  </ng-template>
</p-table>

