<ng-container *ngIf="showMonthlyCalendarViewFlag then monthlyCalendarView else initialSelectionForm"></ng-container>
<ng-template #initialSelectionForm>

  <div class="global-flex global-justify__flex-end">
    <button
      type="button"
      class="global-button"
      (click)="closeView()"
    >{{'production.return' | translate}}</button>
  </div>

  <div>
    <h2 class="monthly-calendar-view__title">{{'production.please-specify-month-and-year' | translate}}</h2>
    <div class="form-details">
      <div class="form-details__container form-details__container--background-color">
        <form
          [formGroup]="formGroup"
          (ngSubmit)="onSubmit()">
          <div class="form-details__field">
            <label for="month">{{'production.month' | translate}}</label>
            <p-dropdown
              id="month"
              class="user-role__action-container__add-role__buttons__select"
              optionValue="value"
              formControlName="month"
              placeholder="{{'production.select-month' | translate}}"
              [options]="getAvailableMonthOptions()">
            </p-dropdown>
            <ng-template
              let-item
              pTemplate="selectedItem">
              {{item.label | translate}}
            </ng-template>
            <ng-template
              let-item
              pTemplate="item">
              {{item.label | translate}}
            </ng-template>
            <span
              [ngClass]="monthControl.invalid && (monthControl.dirty || monthControl.touched) ? 'visibility--true' : 'visibility--false'"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>
          </div>
          <div class="form-details__field">
            <label for="year">{{'production.year' | translate}}</label>
            <input
              id="year"
              type="number"
              appIntegerInputValidation
              formControlName="year">
            <span
              [ngClass]="yearControl.invalid && (yearControl.dirty || yearControl.touched) ? 'visibility--true' : 'visibility--false'"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>
          </div>
          <div class="global-flex global-justify__center">
            <button
              type="submit"
              [disabled]="formGroup.invalid"
            >{{'common.generate' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #monthlyCalendarView>

  <div class="monthly-calendar-view__title-calendar-view">
    <p>{{('production.monthly-calendar-view-for' | translate) + ' ' + (getEnumMonthValue(monthControl.value)| translate) + ' ' + yearControl.value}}</p>
  </div>

  <div class="global-flex global-justify__flex-end">
    <button
      type="button"
      class="global-button"
      (click)="toggleShowMonthlyCalendarViewFlag()"
    >{{'production.return' | translate}}</button>
  </div>

  <div class="production-planning-manual-table">
    <table class="monthly-calendar-view__table-width">
      <thead class="production-planning-manual-table__head">
      <tr class="production-planning-manual-table__row">
        <ng-template [ngIf]="workspaceGroups || loadDataForGroupLeader || showAllGroups">
          <th class="production-planning-manual-table__header monthly-calendar-view__header-width"
          >{{'production.workspace-group'| translate}}</th>
          <th class="production-planning-manual-table__header monthly-calendar-view__header-width"
          >{{'production.workspace-instance' | translate}}</th>
          <th class="production-planning-manual-table__header monthly-calendar-view__header-width"
          >{{'production.worker' | translate}}</th>
        </ng-template>

        <ng-template [ngIf]="workspaceInstance">
          <th class="production-planning-manual-table__header monthly-calendar-view__header-width"
        >{{''}}</th>
        </ng-template>

        <th
          *ngFor="let day of numberOfDaysInMonth"
          class="production-planning-manual-table__header monthly-calendar-view__item-cell-width"
        >{{day}}</th>
      </tr>
      </thead>
      <tbody>

      <ng-template [ngIf]="!tableRows.isEmpty()">
        <tr
          *ngFor="let tableRow of tableRows let i=index;"
          class="production-planning-manual-table__records">

          <td
            class="production-planning-manual-table__single-record"
            [ngStyle]="{'background-color' : rowColors[i]}"
          >{{tableRow.workspaceGroupName}}</td>

          <td
            class="production-planning-manual-table__single-record"
            [ngStyle]="{'background-color' : rowColors[i]}"
          >{{tableRow.workCalendarData.workspaceInstance.shorthand}}</td>

          <td
            class="production-planning-manual-table__single-record"
            [ngStyle]="{'background-color' : rowColors[i]}"
          >{{tableRow.workCalendarData.worker.name}}</td>

          <td
            *ngFor="let day of numberOfDaysInMonth"
            class="production-planning-manual-table__single-record"
            [ngStyle]="{'background-color' : isDayBetweenRange(day, tableRow.workCalendarData.dateFrom, tableRow.workCalendarData.dateTo) ? 'green' : 'white'}"></td>
        </tr>
      </ng-template>

            <ng-template [ngIf]="!workCalendarData.isEmpty()">
              <tr
                *ngFor="let calendarData of workCalendarData"
                class="production-planning-manual-table__records">
                <td class="production-planning-manual-table__single-record">{{calendarData.worker.name}}</td>

                <td
                  *ngFor="let day of numberOfDaysInMonth"
                  class="production-planning-manual-table__single-record"
                  [ngStyle]="{'background-color' : isDayBetweenRange(day, calendarData.dateFrom, calendarData.dateTo) ? 'green' : 'white'}"></td>
              </tr>
            </ng-template>

            <ng-template [ngIf]="workCalendarData.isEmpty() && tableRows.isEmpty()">
              <tr class="production-planning-manual-table__records">
                <td
                  class="production-planning-manual-table__single-record"
                  [colSpan]="workspaceInstance ? numberOfDaysInMonth.length + 1 : numberOfDaysInMonth.length + 3"
                >{{'common.no-records' | translate}}</td>
              </tr>
            </ng-template>

      </tbody>
    </table>
  </div>
</ng-template>
