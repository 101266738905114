import {
  ChangeInLengthNormCtrl,
  NormTestAtTemperatureCtrl,
  ProductQualityNormCtrl,
  StrengthNormCtrl, ThermalExpansionNormCtrl
} from '../../core/sdk/controllers-producttype';
import {Injectable} from '@angular/core';
import {
  ChangeInLengthNorm,
  NormTestAtTemperature,
  ProductQualityNorm,
  StrengthNorm,
  ThermalExpansionNorm
} from '../../core/sdk/model-producttype';
import {Utility} from '../utilities/utility';
import {Observable} from 'rxjs';
import {SearchCriteriaForClass} from '../../core/sdk/model-dto';

@Injectable({
  providedIn: 'root'
})
export class ProductQualityNormService {

  private normTestAtTemperatureData: NormTestAtTemperature[] = [];
  private productQualityNormData: ProductQualityNorm[] = [];

  constructor(private productQualityNormCtrl: ProductQualityNormCtrl,
              private strengthNormCtrl: StrengthNormCtrl,
              private normTestAtTemperatureCtrl: NormTestAtTemperatureCtrl,
              private thermalExpansionNormCtrl: ThermalExpansionNormCtrl,
              private changeInLengthNormCtrl: ChangeInLengthNormCtrl) {
  }

  public getNewEmptyStrengthNorm(): StrengthNorm {
    return {
      id: 'strength-norm/' + Utility.getUUID(),
      additionalDescription: '',
      creationDate: Date.now(),
      dateOfPerformingNormCheck: null,
      deleted: false,
      dinNorm: '',
      entityVersion: 0,
      isDeleted: false,
      normTestAtTemperatureList: [],
      normType: null,
      productQuality: null,
      temperaturePrefix: null,
      timestamp: Date.now(),
      type: null,
      version: 0
    };
  }

  public createStrengthNorm(entity: StrengthNorm, productQualityID: string): Observable<StrengthNorm> {
    return this.strengthNormCtrl.create(entity, Utility.getObjectId(productQualityID));
  }

  public updateStrengthNorm(entity: StrengthNorm): Observable<StrengthNorm> {
    return this.strengthNormCtrl.updateObject(entity);
  }

  public getNewEmptyNormTestAtTemperature(): NormTestAtTemperature {
    return {
      id: 'norm-test-at-temperature/' + Utility.getUUID(),
      dateOfPerformingTest: null,
      deleted: false,
      entityVersion: 0,
      isDeleted: false,
      temperature: 0,
      timestamp: Date.now(),
      value: 0,
      version: 0
    };
  }

  public getNormTestAtTemperatureData(): NormTestAtTemperature[] {
    return this.normTestAtTemperatureData;
  }

  public addNormTestAtTemperatureToData(normTestAtTemperature: NormTestAtTemperature): void {
    this.normTestAtTemperatureData.push(normTestAtTemperature);
  }

  public setNormTestAtTemperatureData(data: NormTestAtTemperature[]): void {
    this.normTestAtTemperatureData = data;
  }

  public removeNormTestAtTemperatureFromData(entity: NormTestAtTemperature): void {
    const index = this.normTestAtTemperatureData.findIndex(item => item.id === entity.id);
    this.normTestAtTemperatureData.splice(index, 1);
  }

  public clearNormTestAtTemperatureData(): void {
    this.normTestAtTemperatureData = [];
  }

  public getProductQualityNormData(): ProductQualityNorm[] {
    return this.productQualityNormData;
  }

  public setProductQualityNormData(data: ProductQualityNorm[]): void {
    this.productQualityNormData = data;
  }

  public getProductQualityNorms(searchCriteria: SearchCriteriaForClass, productQualityID: string,
                                pageNumber: number, pageSize: number): Observable<ProductQualityNorm[]> {
    return this.productQualityNormCtrl.getProductQualityNorms(searchCriteria, Utility.getObjectId(productQualityID),
      pageNumber, pageSize);
  }

  public countProductQualityNorms(searchCriteria: SearchCriteriaForClass,
                                  productQualityID: string): Observable<number> {
    return this.productQualityNormCtrl.countProductQualityNorms(searchCriteria, Utility.getObjectId(productQualityID));
  }

  public updateNormTestAtTemperature(entity: NormTestAtTemperature): Observable<NormTestAtTemperature> {
    return this.normTestAtTemperatureCtrl.updateObject(entity);
  }

  // STRENGTH NORM

  public countProductQualityStrengthNorms(searchCriteria: SearchCriteriaForClass,
                                          productQualityID: string): Observable<number> {
    return this.strengthNormCtrl.countProductQualityStrengthNorms(searchCriteria, Utility.getObjectId(productQualityID));
  }

  public getProductQualityStrengthNorms(searchCriteria: SearchCriteriaForClass, productQualityID: string,
                                        pageNumber: number, pageSize: number,): Observable<StrengthNorm[]> {
    return this.strengthNormCtrl.getProductQualityStrengthNorms(searchCriteria, Utility.getObjectId(productQualityID),
      pageNumber, pageSize);
  }

  public countAllStrengthNorm(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.strengthNormCtrl.countAllStrengthNorm(searchCriteria);
  }

  public getAllStrengthNorm(searchCriteria: SearchCriteriaForClass,
                            pageNumber: number, pageSize: number): Observable<StrengthNorm[]> {
    return this.strengthNormCtrl.getAllStrengthNorm(searchCriteria, pageNumber, pageSize);
  }

  public countAllProductQualityNorm(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.productQualityNormCtrl.countAllProductQualityNorm(searchCriteria);
  }

  public getAllProductQualityNorm(searchCriteria: SearchCriteriaForClass,
                                  pageNumber: number, pageSize: number): Observable<ProductQualityNorm[]> {
    return this.productQualityNormCtrl.getAllProductQualityNorm(searchCriteria, pageNumber, pageSize);
  }

  public getSingleStrengthNorm(entityId: string): Observable<StrengthNorm> {
    return this.strengthNormCtrl.getObject(Utility.getObjectId(entityId));
  }


  // THERMAL EXPANSION NORM

  public getNewEmptyThermalExpansionNorm(): ThermalExpansionNorm {
    return {
      id: 'thermal-expansion-norm/' + Utility.getUUID(),
      additionalDescription: '',
      creationDate: Date.now(),
      dateOfPerformingNormCheck: 0,
      deleted: false,
      dinNorm: '',
      entityVersion: 0,
      isDeleted: false,
      normTestAtTemperatureList: [],
      normType: null,
      productQuality: null,
      progressionType: null,
      reversibilityType: null,
      timestamp: Date.now(),
      version: 0
    };
  }

  public getSingleThermalExpansionNorm(entityId: string): Observable<ThermalExpansionNorm> {
    return this.thermalExpansionNormCtrl.getObject(Utility.getObjectId(entityId));
  }

  public createThermalExpansionNorm(entity: ThermalExpansionNorm, productQualityId: string): Observable<ThermalExpansionNorm> {
    return this.thermalExpansionNormCtrl.create(entity, Utility.getObjectId(productQualityId));
  }

  public updateThermalExpansionNorm(entity: ThermalExpansionNorm): Observable<ThermalExpansionNorm> {
    return this.thermalExpansionNormCtrl.updateObject(entity);
  }

  public countProductQualityThermalExpansionNorms(searchCriteria: SearchCriteriaForClass,
                                                  productQualityID: string): Observable<number> {
    return this.thermalExpansionNormCtrl.countProductQualityThermalExpansionNorms(searchCriteria,
      Utility.getObjectId(productQualityID));
  }

  public getProductQualityThermalExpansionNorms(searchCriteria: SearchCriteriaForClass,
                                                productQualityID: string,
                                                pageNumber: number,
                                                pageSize: number): Observable<ThermalExpansionNorm[]> {
    return this.thermalExpansionNormCtrl.getProductQualityThermalExpansionNorms(searchCriteria,
      Utility.getObjectId(productQualityID), pageNumber, pageSize);
  }

  public countAllThermalExpansionNorm(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.thermalExpansionNormCtrl.countAllThermalExpansionNorm(searchCriteria);
  }

  public getAllThermalExpansionNorm(searchCriteria: SearchCriteriaForClass,
                                    pageNumber: number,
                                    pageSize: number): Observable<ThermalExpansionNorm[]> {
    return this.thermalExpansionNormCtrl.getAllThermalExpansionNorm(searchCriteria, pageNumber, pageSize);
  }

  // ChangeInLengthNorm

  public getNewEmptyChangeInLengthNorm(): ChangeInLengthNorm {
    return {
      id: 'change-in-length-norm/' + Utility.getUUID(),
      additionalDescription: '',
      creationDate: Date.now(),
      dateOfPerformingNormCheck: 0,
      deleted: false,
      dinNorm: '',
      entityVersion: 0,
      isDeleted: false,
      normTestAtTemperatureList: [],
      normType: null,
      productQuality: null,
      progressionType: null,
      reversibilityType: null,
      timestamp: Date.now(),
      version: 0
    };
  }

  public getSingleChangeInLengthNorm(entityId: string): Observable<ChangeInLengthNorm> {
    return this.changeInLengthNormCtrl.getObject(Utility.getObjectId(entityId));
  }

  public createChangeInLengthNorm(entity: ChangeInLengthNorm, productQualityId: string): Observable<ChangeInLengthNorm> {
    return this.changeInLengthNormCtrl.create(entity, Utility.getObjectId(productQualityId));
  }

  public updateChangeInLengthNorm(entity: ChangeInLengthNorm): Observable<ChangeInLengthNorm> {
    return this.changeInLengthNormCtrl.updateObject(entity);
  }

  public countProductQualityChangeInLengthNorms(searchCriteria: SearchCriteriaForClass,
                                                productQualityID: string): Observable<number> {
    return this.changeInLengthNormCtrl.countProductQualityChangeInLengthNorms(searchCriteria, Utility.getObjectId(productQualityID));
  }

  public getProductQualityChangeInLengthNorms(searchCriteria: SearchCriteriaForClass,
                                              productQualityID: string,
                                              pageNumber: number,
                                              pageSize: number): Observable<ChangeInLengthNorm[]> {
    return this.changeInLengthNormCtrl.getProductQualityChangeInLengthNorms(searchCriteria, Utility.getObjectId(productQualityID),
      pageNumber, pageSize);
  }

  public countAllChangeInLengthNorm(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.changeInLengthNormCtrl.countAllChangeInLengthNorm(searchCriteria);
  }

  public getAllChangeInLengthNorm(searchCriteria: SearchCriteriaForClass,
                                  pageNumber: number,
                                  pageSize: number,): Observable<ChangeInLengthNorm[]> {
    return this.changeInLengthNormCtrl.getAllChangeInLengthNorm(searchCriteria, pageNumber, pageSize);
  }

}
