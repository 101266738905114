import {ElementRef, EventEmitter, Injectable, Renderer2} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {TableRefreshService} from '../../services/table-refresh.service';
import {Router} from '@angular/router';
import {FunctionalityGroup} from '../../../core/sdk/model-enums';
import {FunctionalityService} from '../../services/functionality.service';

@Injectable({
  providedIn: 'root'
})
export class BaseSubNavbarService {

  public showFilteringRow = new BehaviorSubject<boolean>(false);
  public applyExport: EventEmitter<void> = new EventEmitter<void>();
  public applyFiltering = false;
  public subNavbarRenderer: Renderer2;
  public subNavbarFilterButtonsRef: ElementRef;
  public flagStateSaved: boolean;
  public shouldNavbarBeHidden = false;

  constructor(private tableRefreshService: TableRefreshService,
              private router: Router,
              private functionalityService: FunctionalityService) {
  }

  public setFilteringButtonsRenderingNecessities(subNavbarSubject: Subject<{ renderer: Renderer2; buttonsRef: ElementRef }>): void {
    subNavbarSubject.subscribe(passedData => {
      this.subNavbarRenderer = passedData.renderer;
      this.subNavbarFilterButtonsRef = passedData.buttonsRef;
      if (this.flagStateSaved !== null) {
        this.displayFilteringButtons(this.flagStateSaved);
        this.flagStateSaved = null;
      }
    });
  }

  public displayFilteringButtons(value: boolean): void {
    if (this.subNavbarRenderer && this.subNavbarFilterButtonsRef) {
      value ? this.subNavbarRenderer.setStyle(this.subNavbarFilterButtonsRef.nativeElement, 'visibility', 'visible') :
        this.subNavbarRenderer.setStyle(this.subNavbarFilterButtonsRef.nativeElement, 'visibility', 'hidden');
    } else {
      this.flagStateSaved = value;
    }
  }

  public resetActiveFilters(): void {
    if (this.applyFiltering) {
      this.switchActiveFilters();
    }
  }

  public switchActiveFilters(): void {
    this.applyFiltering = !this.applyFiltering;
    this.setFilterFlag(this.applyFiltering);
  }

  public setFilterFlag(value: boolean): void {
    this.showFilteringRow.next(value);
  }

  public redirectToCurrentSubFunctionality(functionalityName: string, functionalityGroup?: FunctionalityGroup): void {
    let functionalityGroupName: string;
    if (functionalityGroup === undefined) {
      functionalityGroupName = this.functionalityService.getActiveFunctionalityGroupName();
    } else {
      functionalityGroupName = FunctionalityGroup[functionalityGroup].toString();
    }
    this.resetActiveFilters();
    this.router.navigate(['/bh/' + functionalityGroupName.toLowerCase().split('_').join('-')
    + '/' + functionalityName]);
  }

  public redirectToPreferredFunctionalityIfAvailable(functionalityNameList: string[]): void {
    const paths = this.router.url.split('/');
    if (paths.length === 3) {
      for (const functionalityName of functionalityNameList) {
        if (this.functionalityService.isFunctionalityAvailable(functionalityName)) {
          this.router.navigate([this.router.url + '/' + (functionalityName)]);
          break;
        }
      }
    }
  }

  public hideNavbar(): void {
    this.shouldNavbarBeHidden = true;
  }

  public showNavbar(): void {
    this.shouldNavbarBeHidden = false;
  }

  public getHideNavbarFlag(): boolean {
    return this.shouldNavbarBeHidden;
  }
}
