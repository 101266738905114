<div class="global-flex global-flex-direction__row global-justify__flex-end">
  <button
  type="button"
  class="global-button"
  (click)="resetFilters()"
  >{{'production.reset-filters' | translate}}</button>
  <button
    *ngIf="!unplannedPostProcessingStep"
    type="button"
    class="button button__submit-button button__submit-button--color button__submit-button--background-color
                button__submit-button--fit-content global-align--self-end"
    (click)="finishAllWorkerTasks()"
  >{{'production.finish-all-worker-task-with-submitted-lack-of-products' | translate}}</button>
</div>

<p-table
  dataKey="id"
  editMode="row"
  class="main-table-header"
  [value]="getTableData()"
  [paginator]="true"
  [lazy]="true"
  [rowHover]="true"
  [rows]="pageable.pageSize"
  [totalRecords]="pageable.count"
  [(first)]="pageable.pageNumber"
  (onPage)="paginate($event)">

  <ng-template pTemplate="header">
    <tr>
      <th
        pSortableColumn="estimatedDuration"
        (click)="sortByColumn('estimatedDuration')">
        {{'production.estimated-duration' | translate}}
        <p-sortIcon field="estimatedDuration"></p-sortIcon>
      </th>

      <th
        pSortableColumn="creationDate"
        (click)="sortByColumn('creationDate')">
        {{'production.creation-date' | translate}}
        <p-sortIcon field="creationDate"></p-sortIcon>
      </th>

      <th
        pSortableColumn="startDate"
        (click)="sortByColumn('startDate')">
        {{'production.start-date' | translate}}
        <p-sortIcon field="startDate"></p-sortIcon>
      </th>

      <th
        pSortableColumn="goal"
        (click)="sortByColumn('goal')">
        {{'production.worker-task-goal' | translate}}
        <p-sortIcon field="goal"></p-sortIcon>
      </th>

      <th
        pSortableColumn="doneGoodQuantity"
        (click)="sortByColumn('doneGoodQuantity')">
        {{'production.done-good-quantity' | translate}}
        <p-sortIcon field="doneGoodQuantity"></p-sortIcon>
      </th>

      <th
        pSortableColumn="doneFaultyQuantity"
        (click)="sortByColumn('doneFaultyQuantity')">
        {{'production.done-faulty-quantity' | translate}}
        <p-sortIcon field="doneFaultyQuantity"></p-sortIcon>
      </th>

      <th
        pSortableColumn="name"
        (click)="sortByColumn('name', false, 'personRole', 'Person' )">
        {{'production.worker-name' | translate}}
        <p-sortIcon field="name"></p-sortIcon>
      </th>

      <th
        pSortableColumn="progressStatus"
        (click)="sortByColumn('progressStatus')">
        {{'production.task-status' | translate}}
        <p-sortIcon field="progressStatus"></p-sortIcon>
      </th>

      <th
        pSortableColumn="workerSubmittedLackOfAwaitingProductsForTask"
        (click)="sortByColumn('workerSubmittedLackOfAwaitingProductsForTask')">
        {{'production.is-lack-of-awaiting-products' | translate}}
        <p-sortIcon field="workerSubmittedLackOfAwaitingProductsForTask"></p-sortIcon>
      </th>

      <th *ngIf="!unplannedPostProcessingStep">{{'common.action' | translate}}</th>
    </tr>

    <tr class="primeng-table__search-row no-print">
      <td></td>
      <td>
        <p-calendar
          appendTo="body"
          dateFormat="dd.mm.yy"
          class="primeng-table__calendar-filter-col"
          placeholder="{{'production.search-by-creation-date' | translate }}"
          selectionMode="{{ calendarSelectionModeMap.get('creationDate') | calendarSelectionMode}}"
          [ngModel]="calendarSelectionModeMap.get('creationDate') ? rangeDateMap.get('creationDate') : ''"
          (ngModelChange)="assignRangeDateValue($event, 'creationDate')"
          [showButtonBar]="true"
          [clearButtonStyleClass]="'display--false'"
          [readonlyInput]="true"
          [showIcon]="true"
          [showOnFocus]="false"
          [inputStyleClass]="'global-input'"
          [styleClass]="'global-width--100'"
          [(ngModel)]="creationDateFilterField"
          (onSelect)="!calendarSelectionModeMap.get('creationDate') ? searchByDate($event, 'creationDate') : null">
          <ng-template pTemplate="footer">
            <div class="global-flex global-align__items-center global-justify__space-between global-margin-top__1rem">
              <button
                type="button"
                class="global-button global-height--fit-content global-margin--none"
                (click)="toggleRangeSelectionModeApplied('creationDate')">
                <i [ngClass]="!calendarSelectionModeMap.get('creationDate') | calendarButtonClass"></i>
              </button>
              <button
                *ngIf="calendarSelectionModeMap.get('creationDate')"
                type="button"
                class="global-button global-margin--none"
                [disabled]=" rangeDateMap.get('creationDate').isEmpty() || rangeDateMap.get('creationDate').includes(null)"
                (click)="searchByDate(rangeDateMap.get('creationDate'), 'creationDate')"
              >{{ 'common.search' | translate }}
              </button>
            </div>
            <span
              class="global-text-align__center global-margin-top__1rem"
            >{{calendarSelectionModeMap.get('creationDate') | calendarButtonLabel | translate}}</span>
          </ng-template>
        </p-calendar>
      </td>
      <td>
        <p-calendar
          appendTo="body"
          dateFormat="dd.mm.yy"
          class="primeng-table__calendar-filter-col"
          placeholder="{{'production.search-by-start-date' | translate }}"
          selectionMode="{{ calendarSelectionModeMap.get('startDate') | calendarSelectionMode}}"
          [ngModel]="calendarSelectionModeMap.get('startDate') ? rangeDateMap.get('startDate') : ''"
          (ngModelChange)="assignRangeDateValue($event, 'startDate')"
          [showButtonBar]="true"
          [clearButtonStyleClass]="'display--false'"
          [readonlyInput]="true"
          [showIcon]="true"
          [showOnFocus]="false"
          [inputStyleClass]="'global-input'"
          [styleClass]="'global-width--100'"
          [(ngModel)]="startDateFilterField"
          (onSelect)="!calendarSelectionModeMap.get('startDate') ? searchByDate($event, 'startDate') : null">
          <ng-template pTemplate="footer">
            <div class="global-flex global-align__items-center global-justify__space-between global-margin-top__1rem">
              <button
                type="button"
                class="global-button global-height--fit-content global-margin--none"
                (click)="toggleRangeSelectionModeApplied('startDate')">
                <i [ngClass]="!calendarSelectionModeMap.get('startDate') | calendarButtonClass"></i>
              </button>
              <button
                *ngIf="calendarSelectionModeMap.get('startDate')"
                type="button"
                class="global-button global-margin--none"
                [disabled]=" rangeDateMap.get('startDate').isEmpty() || rangeDateMap.get('startDate').includes(null)"
                (click)="searchByDate(rangeDateMap.get('startDate'), 'startDate')"
              >{{ 'common.search' | translate }}
              </button>
            </div>
            <span
              class="global-text-align__center global-margin-top__1rem"
            >{{calendarSelectionModeMap.get('startDate') | calendarButtonLabel | translate}}</span>
          </ng-template>
        </p-calendar>
      </td>
      <td>
        <div class="global-flex">
          <ng-template
            [ngIf]="columnOperationSignMap.get('goal')?.operationSign !== OperationSign.RANGE_SIGN.toString()">
            <input
              appIntegerInputValidation
              type="number"
              placeholder="{{ 'production.search-by-goal' | translate }}"
              class="global-input primeng-table__filter-col"
              [(ngModel)]="goalFilterField"
              (keydown.enter)="searchByNumber($event, 'goal')">
          </ng-template>
          <app-custom-operation-sign-filter
            appIntegerInputValidation
            [fieldName]="'goal'"
            [columnOperationSignMap]="columnOperationSignMap"
            [operationSigns]="getAvailableOperationSignsForNumber()"
            (rangeSignEmitter)="searchByNumber($event, 'goal')"
            (operationSignEmitter)="operationSignValueChange('goal', $event)"
          ></app-custom-operation-sign-filter>
        </div>
      </td>
      <td>
        <div class="global-flex">
          <ng-template
            [ngIf]="columnOperationSignMap.get('doneGoodQuantity')?.operationSign !== OperationSign.RANGE_SIGN.toString()">
            <input
              appIntegerInputValidation
              type="number"
              placeholder="{{ 'production.search-by-done-good-quantity' | translate }}"
              class="global-input primeng-table__filter-col"
              [(ngModel)]="doneGoodQuantityFilterField"
              (keydown.enter)="searchByNumber($event, 'doneGoodQuantity')">
          </ng-template>
          <app-custom-operation-sign-filter
            appIntegerInputValidation
            [fieldName]="'doneGoodQuantity'"
            [columnOperationSignMap]="columnOperationSignMap"
            [operationSigns]="getAvailableOperationSignsForNumber()"
            (rangeSignEmitter)="searchByNumber($event, 'doneGoodQuantity')"
            (operationSignEmitter)="operationSignValueChange('doneGoodQuantity', $event)"
          ></app-custom-operation-sign-filter>
        </div>
      </td>
      <td>
        <div class="global-flex">
          <ng-template
            [ngIf]="columnOperationSignMap.get('doneFaultyQuantity')?.operationSign !== OperationSign.RANGE_SIGN.toString()">
            <input
              appIntegerInputValidation
              type="number"
              placeholder="{{ 'production.search-by-done-faulty-quantity' | translate }}"
              class="global-input primeng-table__filter-col"
              [(ngModel)]="doneFaultyQuantityFilterField"
              (keydown.enter)="searchByNumber($event, 'doneFaultyQuantity')">
          </ng-template>
          <app-custom-operation-sign-filter
            appIntegerInputValidation
            [fieldName]="'doneFaultyQuantity'"
            [columnOperationSignMap]="columnOperationSignMap"
            [operationSigns]="getAvailableOperationSignsForNumber()"
            (rangeSignEmitter)="searchByNumber($event, 'doneFaultyQuantity')"
            (operationSignEmitter)="operationSignValueChange('doneFaultyQuantity', $event)"
          ></app-custom-operation-sign-filter>
        </div>
      </td>
      <td>
        <input
          appStringLengthInputValidation
          type="text"
          placeholder="{{ 'production.search-by-worker-name' | translate }}"
          class="global-input primeng-table__filter-col"
          [(ngModel)]="workerNameFilterField"
          (keydown.enter)="searchByString($event, 'name', 'personRole', 'Person')">
      </td>
      <td>
        <p-dropdown
          *ngIf="availableTasksStatus"
          name="status-select"
          class="user-role__action-container__add-role__buttons__select"
          placeholder="{{'production.select-status' | translate}}"
          [options]="availableTasksStatus"
          [(ngModel)]="progressStatusFilterField"
          (onChange)="searchByEnum($event, 'progressStatus')">
          <ng-template
            let-item
            pTemplate="item">
            {{item.label | translate}}
          </ng-template>
          <ng-template
            let-item
            pTemplate="selectedItem">
            {{item.label | translate}}
          </ng-template>
        </p-dropdown>
      </td>
      <td></td>
      <td *ngIf="!unplannedPostProcessingStep"></td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td
        [colSpan]="unplannedPostProcessingStep ? 9 : 10">
        {{'common.no-records-available' | translate}}
      </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-workerTask>
    <tr [pEditableRow]="workerTask">
      <td>{{convertDurationObjectToString(workerTask?.estimatedDuration?.seconds)}}</td>
      <td>{{convertTimestampToStringDate(workerTask.creationDate) | translate}}</td>
      <td>{{convertTimestampToStringDate(workerTask.startDate) | translate}}</td>
      <td>{{workerTask.goal}}</td>
      <td>{{workerTask.doneGoodQuantity}}</td>
      <td>{{workerTask.doneFaultyQuantity}}</td>
      <td>{{workerTask.personRole?.person?.name}}</td>
      <td>{{getTaskStatusEnumValue(workerTask.progressStatus) | translate}}</td>
      <td>{{getBooleanTranslation(workerTask.workerSubmittedLackOfAwaitingProductsForTask)}}</td>

      <td *ngIf="!unplannedPostProcessingStep">
        <button
          *ngIf="isTaskOpenForFinish(workerTask)"
          type="button"
          class="button button__submit-button button__submit-button--color button__submit-button--background-color
    button__submit-button--fit-content global-align--self-end"
          (click)="finishWorkerTask(workerTask)"
        >{{'production.finish-worker-task' | translate}}</button>
      </td>
    </tr>
  </ng-template>
</p-table>
