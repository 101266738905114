import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EnumService} from '../../services/enum.service';
import {SelectItem} from 'primeng/api';
import {ProductQualityNormType} from '../../../core/sdk/enums-types';
import {TranslationKeyEnum} from '../../enums/translation-key-enum';

@Component({
  selector: 'app-norm-type-filtering-option',
  templateUrl: './norm-type-filtering-option.component.html',
})
export class NormTypeFilteringOptionComponent implements OnInit {

  public availableFilteringOptions: SelectItem[];
  @Input() public test: SelectItem;
  @Output() public selectedOptionEmitter: EventEmitter<SelectItem> = new EventEmitter<SelectItem>();

  constructor(private enumService: EnumService) {
  }

  public ngOnInit(): void {
    this.availableFilteringOptions = this.enumService.transformEnumOptionsForPrimeDropdown(ProductQualityNormType,
      TranslationKeyEnum.PRODUCTS);
  }

  public getAvailableFilteringOptions(): SelectItem[] {
    return this.availableFilteringOptions;
  }

  public handleFilteringOptionChange(data: any): void {
    this.selectedOptionEmitter.emit(data.value);
  }

}
