<div class="custom-expand-panel"
     [ngClass]="[activeTileFlag ? 'tasks-sub-navbar__single-tab--active-tab' : '', shouldSetGlobalWidth ? 'global-width--65vw' : '']"
     [ngStyle]="{'margin-bottom' : activeTileFlag ? '0' : '1rem', 'background-color' : backgroundColor ? backgroundColor : '#3185FC'}"
     (click)="toggleActiveTileFlag()">

  <div class="custom-expand-panel__header">
    <div>
      <span>{{headerTitle | translate}}</span>
    </div>
    <div>
      <span>{{headerValue}}[EUR]</span>
    </div>
  </div>
</div>

<div
  *ngIf="activeTileFlag"
  class="custom-expand-panel__content"
  [ngStyle]="{'border-right' : displayRightBorder ? '2px solid #CF0A0A' : 'none'}"
  [ngClass]="[shouldSetContentWidth ? 'global-width--64vw' : '', shouldSetContentPadding ? 'global-panel-padding' : '']">
  <ng-content></ng-content>
</div>
