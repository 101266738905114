import {Component, Input} from '@angular/core';
import {OrderPackageProduct, OrderPackageRequest} from '../../../core/sdk/model-order';

@Component({
  selector: 'app-printable-order-package-label',
  templateUrl: './printable-order-package-label.component.html',
})
export class PrintableOrderPackageLabelComponent {

  @Input() public currentOrderPackageRequest: OrderPackageRequest;
  @Input() orderPackageProducts: OrderPackageProduct[];


}
