import {NgModule} from '@angular/core';
import {CustomButtonComponent} from './custom-button.component';

@NgModule({
  declarations: [
    CustomButtonComponent
  ],
  imports: [
  ],
  providers: [
  ],
  exports: [
    CustomButtonComponent
  ]
})
export class CustomButtonModule {
}
