import {Injectable} from '@angular/core';
import {DryerInstance, DryerModel, ProductionDeviceGroup} from '../../../core/sdk/model-productiondevices';
import {DryerInstanceCtrl, DryerModelCtrl} from '../../../core/sdk/controllers-productiondevices';
import {Observable} from 'rxjs';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Utility} from '../../utilities/utility';
import {ProductionDeviceInstanceStatus} from '../../../core/sdk/enums-statuses';

@Injectable({
  providedIn: 'root'
})
export class DryerService {

  public dryerModelData: DryerModel[];

  constructor(private dryerModelCtrl: DryerModelCtrl,
              private dryerInstanceCtrl: DryerInstanceCtrl) {
  }

  ///// DRYER_INSTANCE

  public getNewDryerInstance(dryerModel: DryerModel, group: ProductionDeviceGroup, status?: ProductionDeviceInstanceStatus): DryerInstance {
    return {
      id: 'dryer-instance/' + Utility.getUUID(),
      timestamp: Date.now(),
      version: 0,
      entityVersion: 0,
      deleted: false,
      isDeleted: false,
      shorthand: null,
      workspaceInstance: null,
      dryerModel,
      group,
      status: status ?? ProductionDeviceInstanceStatus.READY
    };
  }

  public countDryerInstances(searchCriteriaForClass, dryerModelId: string): Observable<number> {
    return this.dryerInstanceCtrl.countDryerInstances(searchCriteriaForClass, Utility.getObjectId(dryerModelId));
  }

  public getDryerInstances(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                           dryerModelId: string): Observable<DryerInstance[]> {
    return this.dryerInstanceCtrl.getDryerInstances(searchCriteriaForClass, pageNumber, pageSize, Utility.getObjectId(dryerModelId));
  }

  public removeDryerInstance(dryerInstanceId: string): Observable<void> {
    return this.dryerInstanceCtrl.deleteObject(Utility.getObjectId(dryerInstanceId));
  }

  public removeDryerInstanceFromDryerModelData(dryerModelId: string, dryerInstanceId: string): void {
    for (const index in this.dryerModelData) {
      if (this.dryerModelData[index].id === dryerModelId) {
        Utility.deleteObjectFromArrayById(this.dryerModelData[index].dryerInstanceList, dryerInstanceId);
        break;
      }
    }
  }

  public addNewDryerInstanceToDryerModel(dryerModelId: string, dryerInstance: DryerInstance): void {
    for (const index in this.dryerModelData) {
      if (this.dryerModelData[index].id === dryerModelId) {
        this.dryerModelData[index].dryerInstanceList.push(dryerInstance);
        break;
      }
    }
  }


  //// DRYER_MODEL

  isDryerModelDataEmpty(): boolean {
    return this.dryerModelData.isEmpty();
  }

  public setDryerModelData(data: DryerModel[]): void {
    this.dryerModelData = data;
  }

  public getDryerModelData(): DryerModel[] {
    return this.dryerModelData;
  }

  getAllDryerModels(): Observable<DryerModel[]> {
    return this.dryerModelCtrl.findAll();
  }

  public countDryerModels(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.dryerModelCtrl.countDryerModels(searchCriteriaForClass);
  }

  public getDryerModels(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<DryerModel[]> {
    return this.dryerModelCtrl.getDryerModels(searchCriteriaForClass, pageNumber, pageSize);
  }

  public getDryerModel(dryerModelId: string): Observable<DryerModel> {
    return this.dryerModelCtrl.getObject(Utility.getObjectId(dryerModelId));
  }
}
