import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  ProductionSchemaStep,
  ProductionSchemaTemplate
} from '../../../../core/sdk/model-productionschema';
import {FiringSchemaStep, FiringSchemaTemplate} from '../../../../core/sdk/model-firingschema';
import {Utility} from '../../../utilities/utility';
import {TranslateService} from '../../../../core/translations/translate.service';
import {SchemaService} from '../../../services/schema.service';
import {MixingSchemaStep, MixingSchemaTemplate} from '../../../../core/sdk/model-mixingschema';
import {SchemaTemplateService} from '../../../services/schema-template.service';

@Component({
  selector: 'app-schema-template-overview-tile',
  templateUrl: './schema-template-overview-tile.component.html',
})
export class SchemaTemplateOverviewTileComponent {

  @Input() template: ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate;
  @Output() editTemplateEmitter =
    new EventEmitter<ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate>();
  @Output() removeTemplateEmitter =
    new EventEmitter<ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate>();
  @Output() cloneTemplateEmitter =
    new EventEmitter<ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate>();

  constructor(public schemaTemplateService: SchemaTemplateService,
              private translateService: TranslateService,
              public schemaService: SchemaService) {
  }

  public editTemplate = (): void => {
    this.editTemplateEmitter.emit(this.template);
  };

  public removeProductionSchemaTemplate(): void {
    this.removeTemplateEmitter.emit(this.template);
  }

  public getTemplateSteps(template: ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate):
    ProductionSchemaStep[] | FiringSchemaStep[] | MixingSchemaStep[] {
    return this.schemaTemplateService.getTemplateSteps(template);
  }

  public cloneTemplate(): void {
    this.cloneTemplateEmitter.emit(this.template);
  }

  public transformDurationObjectToString(duration: {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  }): string {
    return Utility.transformDurationObjectToUserFriendlyString(duration, this.translateService);
  }

}
