<ng-container
  *ngIf="getAllServiceSchemaSteps()?.length > 0 then serviceSchemaSteps else placeholder"></ng-container>

<ng-template #serviceSchemaSteps>
  <div
    *ngFor="let serviceSchemaStep of this.getAllServiceSchemaSteps()"
    class="global-flex global-align__items-center global-flex-direction__column">
    <app-service-schema-step
      *ngIf="serviceSchemaStep.productForService != null"
      [currentServiceSchemaStep]="serviceSchemaStep"
      [indexColumnApplied]="indexColumnApplied">
    </app-service-schema-step>
  </div>
</ng-template>

<ng-template #placeholder>
  <div
    class="production-schema__placeholder">
    <h3>{{'products.there-is-no-service-schema-steps' | translate}}</h3>
  </div>
</ng-template>
