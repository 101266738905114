import {Workload} from '../../../core/sdk/bighero-model';
import {Injectable} from '@angular/core';
import {WorkloadCtrl} from '../../../core/sdk/bighero-controllers';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {Utility} from '../../utilities/utility';

@Injectable({
  providedIn: 'root'
})
export class WorkloadService {
  public workloadData: Workload[];

  constructor(private workloadCtrl: WorkloadCtrl) {
  }

  public setWorkloadData(workloadData: Workload[]): void {
    this.workloadData = workloadData;
  }

  public getWorkloadData(): Workload[] {
    return this.workloadData;
  }

  public getMachineModelWorkload(searchCriteria: SearchCriteriaForClass, machineModelId: string, pageNumber: number,
                                 pageSize: number): Observable<Workload[]> {
    return this.workloadCtrl.getMachineModelWorkload(searchCriteria, Utility.getObjectId(machineModelId),
      pageNumber, pageSize);
  }

  public countMachineModelWorkload(searchCriteria: SearchCriteriaForClass, machineModelId: string): Observable<number> {
    return this.workloadCtrl.countMachineModelWorkload(searchCriteria, Utility.getObjectId(machineModelId));
  }

  public getMachineInstanceWorkload(searchCriteria: SearchCriteriaForClass, machineInstanceId: string, pageNumber: number,
                                    pageSize: number): Observable<Workload[]> {
    return this.workloadCtrl.getMachineInstanceWorkload(searchCriteria, Utility.getObjectId(machineInstanceId),
      pageNumber, pageSize);
  }

  public countMachineInstanceWorkload(searchCriteria: SearchCriteriaForClass, machineInstanceId: string): Observable<number> {
    return this.workloadCtrl.countMachineInstanceWorkload(searchCriteria, Utility.getObjectId(machineInstanceId));
  }


  // WORKSPACEGROUPS

  public getWorkspaceGroupWorkload(searchCriteria: SearchCriteriaForClass, workspaceGroupId: string, pageNumber: number,
                                   pageSize: number): Observable<Workload[]> {
    return this.workloadCtrl.getWorkspaceGroupWorkload(searchCriteria, Utility.getObjectId(workspaceGroupId),
      pageNumber, pageSize);
  }

  public countWorkspaceGroupWorkload(searchCriteria: SearchCriteriaForClass, workspaceGroupId: string): Observable<number> {
    return this.workloadCtrl.countWorkspaceGroupWorkload(searchCriteria, Utility.getObjectId(workspaceGroupId));
  }

  public getWorkspaceInstanceWorkload(searchCriteria: SearchCriteriaForClass, workspaceInstanceId: string,
                                      pageNumber: number, pageSize: number): Observable<Workload[]> {
    return this.workloadCtrl.getWorkspaceInstanceWorkload(searchCriteria, Utility.getObjectId(workspaceInstanceId),
      pageNumber, pageSize);
  }

  public countWorkspaceInstanceWorkload(searchCriteria: SearchCriteriaForClass, workspaceInstanceId: string): Observable<number> {
    return this.workloadCtrl.countWorkspaceInstanceWorkload(searchCriteria, Utility.getObjectId(workspaceInstanceId));
  }

}
