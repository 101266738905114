import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {TableRefreshService} from '../../services/table-refresh.service';
import {OperationSign} from '../../../core/sdk/model-enums';
import {ColumnOperationSign} from '../../interfaces/interfaces';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-custom-sort-filtering-fields',
  templateUrl: './custom-sort-filtering-fields.component.html',
})
export class CustomSortFilteringFieldsComponent implements OnDestroy {

  public isSortingAscending = false;
  public sortingField: {
    type: 'string' | 'number' | 'date', label: string, isNumeric: boolean, inClassPropertyName: string,
    classProperty?: string,
    propertyClass?: string,
    deepNestedFields?: string[]
  };

  @Input() filterFields: {
    type: 'string' | 'number' | 'date', label: string, isNumeric: boolean, inClassPropertyName: string,
    classProperty?: string,
    propertyClass?: string,
    deepNestedFields?: string[],
    value?: any
  }[];

  @Input() translationModule: string;
  @Input() public tableNameForResetFilters: string;
  @Input() columnOperationSignMap: Map<string, ColumnOperationSign>;
  @Input() options: SelectItem[];

  @Input() public calendarSelectionModeMap: Map<string, boolean>;
  @Input() public rangeDateMap: Map<string, Date[]>;

  @Output() filterByEmitter: EventEmitter<{
    type: 'number' | 'string' | 'date', value: any,
    filteredFieldName: string, inClassPropertyName?: string, propertyClass?: string, deepNestedFields?: string[]
  }> = new EventEmitter<{
    type: 'number' | 'string' | 'date'; value: any; filteredFieldName: string;
    inClassPropertyName?: string; propertyClass?: string, deepNestedFields?: string[]
  }>();

  @Output() sortByEmitter: EventEmitter<{
    columnName: string, isNumeric: boolean, inClassPropertyName: string,
    sortPropertyClass: string, deepNestedFields?: string[]
  }>
    = new EventEmitter<{
    columnName: string; isNumeric: boolean; inClassPropertyName: string; sortPropertyClass: string,
    deepNestedFields?: string[]
  }>();

  @Output() public operationSignChangeEmitter: EventEmitter<{
    filteredFieldName: string,
    operationSign: string,
    inClassPropertyName: string,
    propertyClass: string,
    deepFilterNestingList: string[]
  }> = new EventEmitter<{
    filteredFieldName: string;
    operationSign: string; inClassPropertyName: string;
    propertyClass: string; deepFilterNestingList: string[]
  }>();

  @Output() public assignRangeDateEmitter: EventEmitter<{ rangeDate: Date[], fieldName: string }> =
    new EventEmitter<{ rangeDate: Date[]; fieldName: string }>();
  @Output() public toggleRangeSelectionModeEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private tableRefreshService: TableRefreshService) {
  }

  public filterBy(object: {
    type: 'string' | 'number' | 'date', label: string, isNumeric: boolean, inClassPropertyName: string,
    classProperty?: string, propertyClass?: string, deepNestedFields?: string[]
  }, value: any): void {
    this.filterByEmitter.emit({
      type: object.type,
      value,
      filteredFieldName: object.classProperty,
      inClassPropertyName: object.inClassPropertyName,
      propertyClass: object.propertyClass,
      deepNestedFields: object.deepNestedFields
    });
  }

  public changeOperationSign(object: {
    type: 'string' | 'number' | 'date', label: string, isNumeric: boolean, inClassPropertyName: string,
    classProperty?: string, propertyClass?: string, deepNestedFields?: string[]
  }, operationSign: string): void {
    this.operationSignChangeEmitter.emit({
      filteredFieldName: object.classProperty,
      inClassPropertyName: object.inClassPropertyName,
      propertyClass: object.propertyClass,
      deepFilterNestingList: object.deepNestedFields,
      operationSign
    });
  }

  public sortBy(object: {
    type: 'string' | 'number' | 'date', label: string, isNumeric: boolean, inClassPropertyName: string,
    classProperty?: string,
    propertyClass?: string,
    deepNestedFields?: string[]
  }): void {
    if (this.sortingField === object) {
      this.isSortingAscending = !this.isSortingAscending;
    } else {
      this.isSortingAscending = true;
      this.sortingField = object;
    }

    this.sortByEmitter.emit({
      columnName: object.classProperty,
      isNumeric: object.isNumeric,
      inClassPropertyName: object.inClassPropertyName,
      sortPropertyClass: object.propertyClass,
      deepNestedFields: object.deepNestedFields
    });
  }

  public resetCustomFilters(): void {
    this.clearFilterFieldsValue();
    this.rangeDateMap.clear();
    this.tableRefreshService.onTableRefresh(this.tableNameForResetFilters, true);
  }

  public setCalendarValueForField(data: any, field:any): void {
   field.value = data;
  }

  private clearFilterFieldsValue(): void {
    this.filterFields.forEach(filterField => filterField.value = null);
  }

  public assignDateRange(rangeDate: Date[], fieldName: string): void {
    this.assignRangeDateEmitter.emit({rangeDate, fieldName});
  }

  public ngOnDestroy(): void {
    this.clearFilterFieldsValue();
  }

  protected readonly OperationSign = OperationSign;
}
