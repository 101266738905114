import {Component, Input} from '@angular/core';
import {ProductionLog} from '../../../core/sdk/bighero-model';
import {EnumService} from '../../services/enum.service';
import {ProductionLogActionType} from '../../../core/sdk/enums-types';
import {Utility} from '../../utilities/utility';

@Component({
  selector: 'app-production-log-tile',
  templateUrl: './production-log-tile.component.html',

})
export class ProductionLogTileComponent {

  @Input() productionLog: ProductionLog;
  @Input() translationModule: string;
  @Input() logsForHomeProduction: boolean;

  constructor(private enumService: EnumService) {
  }

  public displayLogActionTypeEnum(enumItem: number): string {
    const module = this.translationModule.replace('.', '');
    return this.enumService.getEnumValueWithModule(enumItem, ProductionLogActionType, module);
  }

  public convertTimestampToStringDate(timestamp: number): string {
    return Utility.transformTimestampToUserFriendlyStringDateTime(timestamp);
  }


}
