
<div *ngIf="!showAssigningStoringUnitType">
  <app-storing-unit-type-table
    (createClicked)="assignStoringUnitType($event)">
  </app-storing-unit-type-table>
</div>

<div *ngIf="showAssigningStoringUnitType">
  <app-storing-unit-form
    [storingUnitInstance]="storingUnitInstance"
    (formSubmitEmitter)="onSubmit()">
  </app-storing-unit-form>
</div>
