export class Pageable {

  constructor(public pageSize = 10,
              public pageNumber = 0,
              public count = 0,
              public first = 0) {
  }

  public nextPage(): void {
    if (this.pageNumber < this.count / this.pageSize || (this.count % this.pageSize) > 0) {
      this.pageNumber++;
    }
  }

  public prevPage(): void {
    if (this.pageNumber > 0) {
      this.pageNumber--;
    }
  }

  public isLastPage(data: Array<any>): boolean {
    return data ? data.length < this.pageSize && this.pageSize * this.pageNumber > this.count : true;
  }

  public isFirstPage(data: Array<any>): boolean {
    return data ? this.pageNumber === 0 : true;
  }

  public reset(): void {
    this.pageNumber = 0;
  }
}
