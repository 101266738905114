import {Pipe, PipeTransform} from '@angular/core';
import {InventoryInStoringProduct} from '../../core/sdk/model-inventory';

@Pipe({
  name: 'inventoryInStoringProductData',
  pure: true
})
export class InventoryInStoringProductDataPipe implements PipeTransform {

  transform(inventoryInStoringProduct: InventoryInStoringProduct, attribute: string): string | number {
    const data = inventoryInStoringProduct.productStoredInstanceRequest ? inventoryInStoringProduct.productStoredInstanceRequest
      : inventoryInStoringProduct.productStoredInstance;

    switch (attribute) {
      case 'name' : {
      return data.productType ? data.productType.name : data.productForService.name;
      }
      case 'articleNumber' : {
       return data.productType ? data.productType.articleNumber : 'warehouse.not-applicable';
      }
      case 'quality' : {
        return data.productType ? data.productType.quality.name : 'warehouse.not-applicable';
      }
      case'quantity' : {
       return  data.quantity;
      }
      case'quantityOff' : {
       return inventoryInStoringProduct.countedQuantity - data.quantity;
      }
    }
  }

}
