import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-print-barcode-label',
  templateUrl: './print-barcode-label.component.html',
})
export class PrintBarcodeLabelComponent {

  @Input() objectNameToDisplay: string;
  @Input() barcodeValue: string;

}
