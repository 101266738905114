import {Component, Input} from '@angular/core';

/**
 * A single tab page. It renders the passed template
 * via the @Input properties by using the ngTemplateOutlet
 * and ngTemplateOutletContext directives.
 */
@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
})
export class TabComponent {
  @Input() tabTitle: string;
  @Input() active = false;
  @Input() dataBindMap: Map<string, object>;

}
