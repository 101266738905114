import {MixingSchemaTemplateCtrl} from '../../../core/sdk/controllers-mixingschema';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MixingSchemaTemplate} from '../../../core/sdk/model-mixingschema';
import {Utility} from '../../utilities/utility';

@Injectable({
  providedIn: 'root'
})
export class MixingSchemaTemplateService {

  constructor(private mixingSchemaTemplateCtrl: MixingSchemaTemplateCtrl) {
  }

  public getEmptyMixingSchemaTemplate(): MixingSchemaTemplate {
    return {
      id: 'mixing-schema-template/' + Utility.getUUID(),
      deleted: false,
      description: '',
      directLaborRateCost: 0,
      energyCost: 0,
      entityVersion: 0,
      fixedOverheadCost: 0,
      indirectLaborRateCost: 0,
      isDeleted: false,
      mixingSchemaStepList: [],
      name: '',
      timestamp: Date.now(),
      variableOverheadCost: 0,
      version: 0
    };
  }

  public getAllMixingSchemaTemplates(): Observable<MixingSchemaTemplate[]> {
    return this.mixingSchemaTemplateCtrl.getAllTemplates();
  }

  public removeMixingSchemaTemplate(entityId: string): Observable<void> {
    return this.mixingSchemaTemplateCtrl.deleteObject(Utility.getObjectId(entityId));
  }

  public updateMixingSchemaTemplate(entity: MixingSchemaTemplate): Observable<MixingSchemaTemplate> {
    return this.mixingSchemaTemplateCtrl.updateObject(entity);
  }

  public getAllMixingSchemaTemplatesId(): Observable<MixingSchemaTemplate[]> {
    return this.mixingSchemaTemplateCtrl.getAllTemplatesWithoutSteps();
  }

  public importMixingSchemaTemplate(templateId: string): Observable<MixingSchemaTemplate> {
    return this.mixingSchemaTemplateCtrl.getMixingSchemaTemplateWithDuplicatedStepList(Utility.getObjectId(templateId));
  }

  public getCloneOfMixingSchemaTemplate(mixingSchemaTemplateId: string): Observable<MixingSchemaTemplate> {
    return this.mixingSchemaTemplateCtrl.createDuplicateFromMixingSchemaTemplate(Utility.getObjectId(mixingSchemaTemplateId));
  }
}
