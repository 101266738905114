import {Component} from '@angular/core';
import {UserDecisionModalService} from '../../services/user-decision-modal.service';

@Component({
  selector: 'app-user-decision-modal',
  templateUrl: './user-decision-modal.component.html',

})
export class UserDecisionModalComponent {
  public title: string;
  public modalContentInfo: string;

  constructor(private userDecisionModalService: UserDecisionModalService) {
  }

  public acceptDecision(): void {
    this.userDecisionModalService.handlePerformingUserDecisionAction(true);
    this.closeModal();
  }

  public rejectDecision(): void {
    this.userDecisionModalService.handlePerformingUserDecisionAction(false);
    this.closeModal();
  }

  public closeModal(): void {
    this.userDecisionModalService.closeUserDecisionModal();
  }
}
