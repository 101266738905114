import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  @Input() buttonDisabled: boolean;
  @Input() showCancelButton: boolean;
  @Input() showSubmitButton: boolean;
  @Input() showCloseButton: boolean;
  @Input() customButtonText: string;
  @Input() buttonSize: string;
  @Input() customConfirmButtonStyle: string;
  @Input() customDeclineButtonStyle: string;
  @Input() customFooterStyle: string;
  @Input() customButtonOrder: boolean;
  @Output() buttonSubmitPressed: EventEmitter<boolean> = new EventEmitter();
  @Output() buttonCancelPressed: EventEmitter<boolean> = new EventEmitter();
  @Output() buttonClosePressed: EventEmitter<boolean> = new EventEmitter();

  public onSubmit(): void {
    this.buttonSubmitPressed.emit();
  }

  public onCancel(): void {
    this.buttonCancelPressed.emit();
  }

  public onClose(): void {
    this.buttonClosePressed.emit();
  }

  public getConfirmButtonStyle(): string {
    if (this.customConfirmButtonStyle) {
      return this.customConfirmButtonStyle;
    }
    const defaultButtonSize = this.buttonSize ? this.buttonSize : 'medium';
    return 'button button-' + defaultButtonSize + ' button-confirm-color';
  }

  public getDeclineButtonStyle(): string {
    if (this.customDeclineButtonStyle) {
      return this.customDeclineButtonStyle;
    }
    const defaultButtonSize = this.buttonSize ? this.buttonSize : 'medium';
    return 'button button-' + defaultButtonSize + ' button-decline-color';
  }

  public getFooterStyle(): string {
    return this.customFooterStyle ? this.customFooterStyle : 'form-buttons';
  }

}
