import {Component, Input} from '@angular/core';
import {Workload} from '../../../../core/sdk/bighero-model';
import {Utility} from '../../../utilities/utility';
import {TranslateService} from '../../../../core/translations/translate.service';

@Component({
  selector: 'app-workload-tile',
  templateUrl: './workload-tile.component.html',
})
export class WorkloadTileComponent {

  @Input() workload: Workload;
  @Input() isWorkspaceGroup: boolean;

  constructor(private translateService: TranslateService) {
  }

  public transformTimestampToUserFriendlyString(seconds: number): string {
    return Utility.transformTimestampToUserFriendlyString(seconds, this.translateService);
  }

}
