import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Workload} from '../../../core/sdk/bighero-model';

@Component({
  selector: 'app-workload-tiles',
  templateUrl: './workload-tiles.component.html',
})
export class WorkloadTilesComponent {

  public showWorkloadHistoryTable = false;

  @Input() workloadList: Workload[];
  @Input() instanceId: string;
  @Input() isWorkspaceGroup: boolean;
  @Input() shouldButtonBeDisabled = false;
  @Output() fullHistoryEmitter: EventEmitter<void> = new EventEmitter<void>();


  public seeFullWorkLoadHistory(): void {
    this.fullHistoryEmitter.emit();
    this.showWorkloadHistoryTable = !this.showWorkloadHistoryTable;
  }

}
