/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {InventoryStatisticsSingleOccurrenceDataCollection, SearchCriteriaForClass} from './model-dto';
import {InventoryReportFile} from './model-files';
import {Inventory, InventoryCountableStack, InventoryDeclaration, InventoryInProductionProduct, InventoryInStoringLocationDeclaration, InventoryInStoringProduct, InventoryProductionStep, InventoryResultStatistics, InventoryResultWorkerStatistics, InventoryStatisticInProductionStep, InventoryStatisticProductQuantityCollate, InventoryStoredProduct} from './model-inventory';
import {ProductStoredInstanceRequestLocationMark} from './model-warehouse';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class InventoryCountableStackCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public getObject(id: string): Observable<InventoryCountableStack>  {
   const subject = new Subject<InventoryCountableStack>();
    this.httpService.get('/api/inventory-countable-stack/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public stopCountingManually(inventoryCountableStackID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<InventoryCountableStack>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryCountableStackID', value: inventoryCountableStackID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<InventoryCountableStack>();
    this.httpService.put('/api/inventory-countable-stack/w/manual-count-stop', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class InventoryCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countInventories(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/inventory/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createInventory(countingStartDate: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Inventory>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Inventory>();
    this.httpService.post('/api/inventory/w/create', JsonScopedSerializer.stringify(countingStartDate, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public finishInventory(inventoryID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Inventory>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryID', value: inventoryID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Inventory>();
    this.httpService.put('/api/inventory/w/finish', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public generateInventoryReportDocumentFile(inventoryID: string): Observable<InventoryReportFile>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryID', value: inventoryID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<InventoryReportFile>();
    this.httpService.get('/api/inventory/w/generate', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getInventories(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Inventory[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Inventory[]>();
    this.httpService.post('/api/inventory/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<Inventory>  {
   const subject = new Subject<Inventory>();
    this.httpService.get('/api/inventory/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public startInventory(inventoryID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Inventory>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryID', value: inventoryID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Inventory>();
    this.httpService.put('/api/inventory/w/start', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class InventoryDeclarationCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countInventoryDeclarations(searchCriteria: SearchCriteriaForClass, inventoryCountableStackID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryCountableStackID', value: inventoryCountableStackID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/inventory-declaration/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createInventoryDeclaration(inventoryCountableStackID: string, countedQuantity: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<InventoryDeclaration>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryCountableStackID', value: inventoryCountableStackID});
  
    queryParamsList.push({name: 'countedQuantity', value: countedQuantity.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<InventoryDeclaration>();
    this.httpService.post('/api/inventory-declaration/w/create', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: InventoryDeclaration, jsonScope: JsonScope = new JsonScope(false, [])): Observable<InventoryDeclaration>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<InventoryDeclaration>();
    this.httpService.post('/api/inventory-declaration/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getInventoryDeclarations(searchCriteria: SearchCriteriaForClass, inventoryCountableStackID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<InventoryDeclaration[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryCountableStackID', value: inventoryCountableStackID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<InventoryDeclaration[]>();
    this.httpService.post('/api/inventory-declaration/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<InventoryDeclaration>  {
   const subject = new Subject<InventoryDeclaration>();
    this.httpService.get('/api/inventory-declaration/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class InventoryInProductionProductCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countInventoryInProductionProducts(searchCriteria: SearchCriteriaForClass, inventoryID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryID', value: inventoryID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/inventory-in-production-product/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getInventoryInProductionProducts(searchCriteria: SearchCriteriaForClass, inventoryID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<InventoryInProductionProduct[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryID', value: inventoryID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<InventoryInProductionProduct[]>();
    this.httpService.post('/api/inventory-in-production-product/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<InventoryInProductionProduct>  {
   const subject = new Subject<InventoryInProductionProduct>();
    this.httpService.get('/api/inventory-in-production-product/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class InventoryInStoringLocationDeclarationCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createInventoryInStoringLocationDeclaration(inventoryInStoringLocationDeclaration: InventoryInStoringLocationDeclaration, inventoryCountableStackID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<InventoryInStoringLocationDeclaration>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryCountableStackID', value: inventoryCountableStackID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<InventoryInStoringLocationDeclaration>();
    this.httpService.post('/api/inventory-in-storing-location-declaration/w/create', JsonScopedSerializer.stringify(inventoryInStoringLocationDeclaration, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<InventoryInStoringLocationDeclaration>  {
   const subject = new Subject<InventoryInStoringLocationDeclaration>();
    this.httpService.get('/api/inventory-in-storing-location-declaration/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductStoredInstanceRequestLocationMarkFromDeclaration(declarationID: string): Observable<ProductStoredInstanceRequestLocationMark>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'declarationID', value: declarationID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<ProductStoredInstanceRequestLocationMark>();
    this.httpService.get('/api/inventory-in-storing-location-declaration/r/get-product-stored-instance-request-location-mark', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class InventoryInStoringProductCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countInventoryInStoringProducts(searchCriteria: SearchCriteriaForClass, inventoryID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryID', value: inventoryID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/inventory-in-storing-product/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllInventoryInStoringProductsForLatestInventory(): Observable<InventoryInStoringProduct[]>  {
   const subject = new Subject<InventoryInStoringProduct[]>();
    this.httpService.get('/api/inventory-in-storing-product/r/get-all-for-latest-inventory', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getInventoryInStoringProducts(searchCriteria: SearchCriteriaForClass, inventoryID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<InventoryInStoringProduct[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryID', value: inventoryID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<InventoryInStoringProduct[]>();
    this.httpService.post('/api/inventory-in-storing-product/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<InventoryInStoringProduct>  {
   const subject = new Subject<InventoryInStoringProduct>();
    this.httpService.get('/api/inventory-in-storing-product/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class InventoryProductionStepCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countInventoryProductionSteps(searchCriteria: SearchCriteriaForClass, inventoryInProductionProductID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryInProductionProductID', value: inventoryInProductionProductID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/inventory-production-step/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countWorkerInventoryProductionSteps(jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/inventory-production-step/r/worker-count', null , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getInventoryProductionSteps(searchCriteria: SearchCriteriaForClass, inventoryInProductionProductID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<InventoryProductionStep[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryInProductionProductID', value: inventoryInProductionProductID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<InventoryProductionStep[]>();
    this.httpService.post('/api/inventory-production-step/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<InventoryProductionStep>  {
   const subject = new Subject<InventoryProductionStep>();
    this.httpService.get('/api/inventory-production-step/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkerInventoryProductionSteps(jsonScope: JsonScope = new JsonScope(false, [])): Observable<InventoryProductionStep[]>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<InventoryProductionStep[]>();
    this.httpService.post('/api/inventory-production-step/r/worker-get', null , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class InventoryResultStatisticsCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public getGatheredSingleOccurrenceDTOForStatistic(inventoryResultStatisticsID: string): Observable<InventoryStatisticsSingleOccurrenceDataCollection>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryResultStatisticsID', value: inventoryResultStatisticsID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<InventoryStatisticsSingleOccurrenceDataCollection>();
    this.httpService.get('/api/inventory-result-statistics/r/get-single-occurrence-dto', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getInProductionProductTypeStepsStatisticsFromResult(inventoryResultStatisticsID: string): Observable<InventoryStatisticInProductionStep[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryResultStatisticsID', value: inventoryResultStatisticsID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<InventoryStatisticInProductionStep[]>();
    this.httpService.get('/api/inventory-result-statistics/r/get-product-type-in-production-steps-statistics', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getInProductionProductsForServiceStepsStatisticsFromResult(inventoryResultStatisticsID: string): Observable<InventoryStatisticInProductionStep[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryResultStatisticsID', value: inventoryResultStatisticsID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<InventoryStatisticInProductionStep[]>();
    this.httpService.get('/api/inventory-result-statistics/r/get-products-for-service-in-production-steps-statistics', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<InventoryResultStatistics>  {
   const subject = new Subject<InventoryResultStatistics>();
    this.httpService.get('/api/inventory-result-statistics/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductTypesStatisticsFromResult(inventoryResultStatisticsID: string): Observable<InventoryStatisticProductQuantityCollate[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryResultStatisticsID', value: inventoryResultStatisticsID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<InventoryStatisticProductQuantityCollate[]>();
    this.httpService.get('/api/inventory-result-statistics/r/get-product-type-statistics', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getProductsForServiceStatisticsFromResult(inventoryResultStatisticsID: string): Observable<InventoryStatisticProductQuantityCollate[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryResultStatisticsID', value: inventoryResultStatisticsID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<InventoryStatisticProductQuantityCollate[]>();
    this.httpService.get('/api/inventory-result-statistics/r/get-products-for-service-statistics', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getResultStatisticsForInventory(inventoryID: string): Observable<InventoryResultStatistics>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryID', value: inventoryID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<InventoryResultStatistics>();
    this.httpService.get('/api/inventory-result-statistics/r/get-for-inventory', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getWorkersStatisticsFromResult(inventoryResultStatisticsID: string): Observable<InventoryResultWorkerStatistics[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryResultStatisticsID', value: inventoryResultStatisticsID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<InventoryResultWorkerStatistics[]>();
    this.httpService.get('/api/inventory-result-statistics/r/get-workers-statistics', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class InventoryStoredProductCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countInventoryStoredProducts(searchCriteria: SearchCriteriaForClass, inventoryID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryID', value: inventoryID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/inventory-stored-product/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getInventoryStoredProductByBarcode(barcode: string): Observable<InventoryStoredProduct>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'barcode', value: barcode});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<InventoryStoredProduct>();
    this.httpService.get('/api/inventory-stored-product/r/get-by-product-barcode', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getInventoryStoredProducts(searchCriteria: SearchCriteriaForClass, inventoryID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<InventoryStoredProduct[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'inventoryID', value: inventoryID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<InventoryStoredProduct[]>();
    this.httpService.post('/api/inventory-stored-product/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getInventoryStoredProductsByStorageAreaBarcode(barcode: string): Observable<InventoryStoredProduct[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'barcode', value: barcode});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<InventoryStoredProduct[]>();
    this.httpService.get('/api/inventory-stored-product/r/get-by-storage-barcode', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<InventoryStoredProduct>  {
   const subject = new Subject<InventoryStoredProduct>();
    this.httpService.get('/api/inventory-stored-product/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

