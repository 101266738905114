import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {FilteringTypeEnum} from '../enums/enums';
import {FilterTableField} from '../interfaces/interfaces';

@Directive({
  selector: '[appCustomFilterFieldTableDirective]'
})
export class CustomFilterFieldTableDirective {

  @Input() public filteringType: number;
  @Input() public component: any;

  constructor(private elementRef: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  performCustomFiltering(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      const filterObjectData = this.component.filterFieldMap.get(this.component.selectedFilteringOption.value);
      this.handleSearchingByDefinedType(filterObjectData, event);
    }
  }

  private handleSearchingByDefinedType(filterObjectData: FilterTableField, event: KeyboardEvent): void {
    switch (this.filteringType) {
      case FilteringTypeEnum.STRING : {
        this.component.searchByString(event, filterObjectData.filteredFieldName,
          filterObjectData.inClassPropertyName, filterObjectData.propertyClass, filterObjectData.deepFilterNestingList);
        break;
      }
      case FilteringTypeEnum.NUMBER : {
        this.component.searchByNumber(event, filterObjectData.filteredFieldName,
          filterObjectData.inClassPropertyName, filterObjectData.propertyClass, filterObjectData.deepFilterNestingList);
        break;
      }
      case FilteringTypeEnum.ENUM : {
        this.component.searchByEnum(event, filterObjectData.filteredFieldName,
          filterObjectData.inClassPropertyName, filterObjectData.propertyClass, filterObjectData.deepFilterNestingList);
        break;
      }
      case FilteringTypeEnum.DATE : {
        this.component.searchByDate(this.elementRef.nativeElement.value, filterObjectData.filteredFieldName,
          filterObjectData.inClassPropertyName, filterObjectData.propertyClass, filterObjectData.deepFilterNestingList);
      }
    }
  }


}
