
import {Component, Input, OnInit} from '@angular/core';
import {Country} from '../../../../core/sdk/model-address';
import {CrossFunctionalService} from '../../../services/cross-functional.service';
import {Router} from '@angular/router';
import {PTableControlService} from '../../../services/p-table-control.service';
import {TaxRateService} from '../../../services/tax-rate.service';
import {TaxRate} from '../../../../core/sdk/model-tax';
import {Utility} from '../../../utilities/utility';

@Component({
  selector: 'app-country-details',
  templateUrl: './country-details.component.html',
})
export class CountryDetailsComponent implements OnInit {

  public taxRate: TaxRate;
  @Input() public currentCountry: Country;
  @Input() public isCrossActive: boolean;

  constructor(private crossFunctionalService: CrossFunctionalService,
              private router: Router,
              private pTableControlService: PTableControlService,
              private taxRateService: TaxRateService) {
  }

  public ngOnInit(): void {
    this.taxRate = this.crossFunctionalService.getGatheredData('goToCountryManagementTableToAssignCountryToCurrentTaxRate');
  }

  public assignCountry(): void {
    this.taxRateService.handleAssigningCountriesTaxRate([Utility.getObjectId(this.currentCountry.id)],
      this.taxRate, 'country-assigned-successfully', true);
  }

  public closeComponent(): void {
    this.taxRateService.returnToPreviousLocation(this.taxRate);
  }

}
