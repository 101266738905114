<div class="production-planning-manual-table">
  <table class="production-planning-manual-table__table-container">
    <thead class="production-planning-manual-table__head">
    <tr class="production-planning-manual-table__row">
      <th class="production-planning-manual-table__header">{{ 'products.temperature' | translate }} [℃]</th>
      <th *ngIf="normType === ProductQualityNormType.STRENGTH_TEST" class="production-planning-manual-table__header">{{ 'products.force' | translate }} [N/mm²]</th>
      <th *ngIf="normType === ProductQualityNormType.THERMAL_EXPANSION || normType === ProductQualityNormType.CHANGE_IN_LENGTH"
          class="production-planning-manual-table__header">{{ 'products.percentage' | translate }}  [%]</th>

      <th class="production-planning-manual-table__header">{{ 'products.date-of-performing-test' | translate }}</th>
      <th class="production-planning-manual-table__header">{{ 'products.action' | translate }}</th>
    </tr>
    </thead>
    <tbody>

    <ng-template [ngIf]="!normTestAtTemperatureList.isEmpty()">
      <tr
        *ngFor="let normTestAtTemperature of normTestAtTemperatureList"
        class="production-planning-manual-table__records">
        <td class="production-planning-manual-table__single-record">{{ normTestAtTemperature.temperature }}</td>
        <td class="production-planning-manual-table__single-record">{{ normTestAtTemperature.value }}</td>
        <td class="production-planning-manual-table__single-record">{{
            normTestAtTemperature.dateOfPerformingTest ?
              displayDateInDetailedFormat(normTestAtTemperature.dateOfPerformingTest) : ('products.not-defined' | translate)
          }}
        </td>
        <td class="global-flex global-justify__center global-align__items-center">
          <button
            type="button"
            class="global-button"
            (click)="startEditingNormTestAtTemperature(normTestAtTemperature)"
          >{{ 'products.edit' | translate }}
          </button>
          <button
            *ngIf="createProductQualityNormMode"
            pButton
            type="button"
            class="ui-button-info global-background-color--red"
            icon="pi pi-trash"
            (click)="removeNormTestAtTemperatureFromList(normTestAtTemperature)">
          </button>
        </td>
      </tr>
    </ng-template>

    <ng-template [ngIf]="normTestAtTemperatureList.isEmpty()">
      <tr class="production-planning-manual-table__records">
        <td
          class="production-planning-manual-table__single-record"
          [colSpan]="4"
        >{{ 'common.no-records' | translate }}
        </td>
      </tr>
    </ng-template>

    </tbody>
  </table>
</div>
