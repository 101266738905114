import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-missing-data-placeholder',
  templateUrl: './missing-data-placeholder.component.html',
})
export class MissingDataPlaceholderComponent {

  @Input() showReturnButton = false;

}
