import {Injectable} from '@angular/core';
import {InvoiceCtrl} from '../../core/sdk/controllers-invoices';
import {SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {Invoice} from '../../core/sdk/model-invoices';
import {InvoiceFile} from '../../core/sdk/model-files';
import {Utility} from '../utilities/utility';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  public invoiceData: Invoice[];

  constructor(private invoiceCtrl: InvoiceCtrl) {
  }

  public getAllInvoices(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Invoice[]> {
    return this.invoiceCtrl.getAllInvoices(searchCriteriaForClass, pageNumber, pageSize);
  }

  public countAllInvoices(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.invoiceCtrl.countAllInvoices(searchCriteriaForClass);
  }

  public setInvoiceData(invoices: Invoice[]): void {
    this.invoiceData = invoices;
  }

  public getInvoiceData(): Invoice[] {
    return this.invoiceData;
  }

  public generateInvoiceDocumentFile(invoiceId: string, locale: string): Observable<InvoiceFile> {
    return this.invoiceCtrl.generateInvoiceDocumentFile(Utility.getObjectId(invoiceId), locale);
  }

}
