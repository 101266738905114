import {Injectable} from '@angular/core';
import {EChartsOption} from 'echarts';
import {TranslateService} from '../../../core/translations/translate.service';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  constructor(private translateService: TranslateService) {
  }

  public generateBarChartData(title: string, xAxisData: string[], seriesData: number[], isMonthlyViewApplied: boolean,
                              year: number, monthName: string): EChartsOption {
    return {
      title: {
        text: this.translateService.translate(title) + ': ' + this.getProperTitleEnding(isMonthlyViewApplied, year, monthName),
        textStyle: {
          fontSize: 20
        },
        subtext: seriesData.isEmpty() ? this.translateService.translate('production.there-is-no-data') : '',
        subtextStyle: {
          fontSize: 15
        },
        left: 'center',
        top: seriesData.length > 0 ? 'top' : 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        show: seriesData.length > 0,
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: [
        {
          show: seriesData.length > 0,
          type: 'category',
          data: xAxisData,
          axisLabel: {
            width: 200,
            overflow: 'break'
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          show: seriesData.length > 0,
          type: 'value'
        }
      ],
      series: [
        {
          type: 'bar',
          barWidth: '60%',
          data: seriesData
        }
      ]
    };
  }

  public generatePieChartData(title: string, seriesData: { value: number, name: string }[],
                              displayPercentInTooltip: boolean, bottom: number | string,
                              left: number | string, orient: any, top: number | string): EChartsOption {
    const legend: string[] = [];
    if (!seriesData.isEmpty()) {
      seriesData.forEach(item => {
        legend.push(item.name);
      });
    }
    return {
      title: {
        text: title,
        left: 'center',
        top: seriesData.length > 0 ? 'top' : 'center',
        textStyle: {
          fontSize: 20
        },
        subtext: seriesData.isEmpty() ? this.translateService.translate('common.there-is-no-data') : '',
        subtextStyle: {
          fontSize: 15
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: displayPercentInTooltip ? '{c} %' : '{c}'
      },
      legend: {
        bottom: bottom,
        top: top,
        type: 'scroll',
        right: 20,
        left: left,
        orient: orient,
        data: legend
      },
      toolbox: {
        show: seriesData.length > 0,
        feature: {
          saveAsImage: {}
        }
      },
      series: [
        seriesData.length > 0 ?
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            label: {
              formatter: '{b}',
            },
            data: seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              }
            }
          } : null
      ]
    };
  }

  public generateLineChartData(title: string, legendData: string[],
                               xAxisData: string[], seriesData: any[],
                               isMonthlyViewApplied: boolean,
                               year: number, monthName: string): EChartsOption {
    return {
      animationDuration: 1000,
      title: {
        text: this.translateService.translate(title) + ': ' + this.getProperTitleEnding(isMonthlyViewApplied, year, monthName),
        left: 'center',
        top: seriesData.length > 0 ? 'top' : 'center',
        textStyle: {
          fontSize: 20
        },
        subtext: seriesData.isEmpty() ? this.translateService.translate('common.there-is-no-data') : '',
        subtextStyle: {
          fontSize: 15
        },
      },
      tooltip: {
        order: 'valueDesc',
        trigger: 'axis'
      },
      legend: {
        data: legendData,
        top: 35,
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        show: seriesData.length > 0,
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        show: seriesData.length > 0,
        type: 'category',
        boundaryGap: false,
        data: xAxisData,
        axisLabel: {
          width: 200,
          overflow: 'break'
        },
      },
      yAxis: {
        show: seriesData.length > 0,
        type: 'value'
      },
      series: seriesData
    };
  }

  public generateHorizontalBarChartData(title: string, yAxisData: string[], seriesData: any[]): EChartsOption {
    return {
      title: {
        text: title,
        subtext: seriesData.isEmpty() ? this.translateService.translate('production.there-is-no-data') : '',
        top: seriesData.length > 0 ? 'top' : 'center',
        left: 'center',
        textStyle: {
          fontSize: 20
        },
        subtextStyle: {
          fontSize: 15
        },
      },
      tooltip: {
        trigger: 'axis',
        order: 'valueDesc',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        top: 35,
      },
      dataZoom: {
        show: seriesData.length > 0,
        type: 'slider'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        show: seriesData.length > 0,
        type: 'value'
      },
      yAxis: {
        show: seriesData.length > 0,
        type: 'category',
        axisLabel: {
          width: 200,
          overflow: 'break'
        },
        data: yAxisData
      },
      series: seriesData
    };

  }

  public generateSimpleLineChartData(title: string, xAxis: any[], seriesData: number[], xAxisName: string, xAxisUnit: string,
                                     yAxisName: string, yAxisUnit: string): EChartsOption {
    return {
      title: {
        left: 'center',
        text: this.translateService.translate(title)
      },
      xAxis: {
        type: 'category',
        name: this.translateService.translate(xAxisName) + ' ' + xAxisUnit,
        data: xAxis
      },
      yAxis: {
        type: 'value',
        name: this.translateService.translate(yAxisName) + ' ' + yAxisUnit
      },
      tooltip: {
        trigger: 'axis'
      },
      series: [
        {
          data: seriesData,
          type: 'line'
        }
      ]
    };
  }


  public getProperTitleEnding(isMonthlyViewApplied: boolean, year: number, month?: string): string {
    return isMonthlyViewApplied ? this.translateService.translate(month) + ' ' + year :
      year.toString();
  }

}
