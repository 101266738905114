/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SearchCriteriaForClass} from './model-dto';
import {MoldGroup, MoldInstance, MoldPartInstance, MoldPartType, MoldType, MultiPartMoldInstance, MultiPartMoldType} from './model-mold';
import {MachineInstance} from './model-productiondevices';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class MoldGroupCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: MoldGroup, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldGroup>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldGroup>();
    this.httpService.post('/api/mold-group/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public findAll(): Observable<MoldGroup[]>  {
   const subject = new Subject<MoldGroup[]>();
    this.httpService.get('/api/mold-group/r/', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<MoldGroup>  {
   const subject = new Subject<MoldGroup>();
    this.httpService.get('/api/mold-group/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: MoldGroup, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldGroup>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldGroup>();
    this.httpService.put('/api/mold-group/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class MoldInstanceCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public assignMachineToMoldInstance(machineInstance: MachineInstance, moldInstanceID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldInstance>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'moldInstanceID', value: moldInstanceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldInstance>();
    this.httpService.post('/api/mold-instance/w/assign-machine', JsonScopedSerializer.stringify(machineInstance, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countMoldInstances(searchCriteria: SearchCriteriaForClass, moldTypeId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'moldTypeId', value: moldTypeId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/mold-instance/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countMoldInstancesForProductType(productTypeID: string): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeID', value: productTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<number>();
    this.httpService.get('/api/mold-instance/r/for-product-type-count', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: MoldInstance, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldInstance>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldInstance>();
    this.httpService.post('/api/mold-instance/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/mold-instance/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMoldInstances(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, moldTypeId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    queryParamsList.push({name: 'moldTypeId', value: moldTypeId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldInstance[]>();
    this.httpService.post('/api/mold-instance/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMoldInstancesForWorkspaceGroupForGivenProductType(productTypeID: string, workspaceGroupID: string): Observable<MoldInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeID', value: productTypeID});
  
    queryParamsList.push({name: 'workspaceGroupID', value: workspaceGroupID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<MoldInstance[]>();
    this.httpService.get('/api/mold-instance/r/get-at-workspace-group-for-pt', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<MoldInstance>  {
   const subject = new Subject<MoldInstance>();
    this.httpService.get('/api/mold-instance/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: MoldInstance, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldInstance>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldInstance>();
    this.httpService.put('/api/mold-instance/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class MoldPartInstanceCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countMoldPartInstancesOfGivenType(searchCriteria: SearchCriteriaForClass, moldPartTypeID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'moldPartTypeID', value: moldPartTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/mold-part-instance/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: MoldPartInstance, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldPartInstance>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldPartInstance>();
    this.httpService.post('/api/mold-part-instance/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/mold-part-instance/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMoldPartInstancesOfGivenType(searchCriteria: SearchCriteriaForClass, moldPartTypeID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldPartInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'moldPartTypeID', value: moldPartTypeID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldPartInstance[]>();
    this.httpService.post('/api/mold-part-instance/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<MoldPartInstance>  {
   const subject = new Subject<MoldPartInstance>();
    this.httpService.get('/api/mold-part-instance/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: MoldPartInstance, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldPartInstance>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldPartInstance>();
    this.httpService.put('/api/mold-part-instance/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class MoldPartTypeCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countMoldPartTypes(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/mold-part-type/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: MoldPartType, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldPartType>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldPartType>();
    this.httpService.post('/api/mold-part-type/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/mold-part-type/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMoldPartTypes(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldPartType[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldPartType[]>();
    this.httpService.post('/api/mold-part-type/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<MoldPartType>  {
   const subject = new Subject<MoldPartType>();
    this.httpService.get('/api/mold-part-type/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: MoldPartType, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldPartType>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldPartType>();
    this.httpService.put('/api/mold-part-type/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class MoldTypeCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countMoldTypes(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/mold-type/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: MoldType, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldType>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldType>();
    this.httpService.post('/api/mold-type/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/mold-type/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMoldTypes(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldType[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldType[]>();
    this.httpService.post('/api/mold-type/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<MoldType>  {
   const subject = new Subject<MoldType>();
    this.httpService.get('/api/mold-type/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: MoldType, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldType>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldType>();
    this.httpService.put('/api/mold-type/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class MultiPartMoldInstanceCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public addMoldPartInstanceToMultiPartMoldInstance(multiPartMoldInstanceID: string, moldPartInstanceID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldPartInstance>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'multiPartMoldInstanceID', value: multiPartMoldInstanceID});
  
    queryParamsList.push({name: 'moldPartInstanceID', value: moldPartInstanceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldPartInstance>();
    this.httpService.post('/api/multi-part-mold-instance/w/add-mold-part-instance', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countMultiPartMoldInstancesForGivenType(searchCriteria: SearchCriteriaForClass, multiPartMoldTypeID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'multiPartMoldTypeID', value: multiPartMoldTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/multi-part-mold-instance/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: MultiPartMoldInstance, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MultiPartMoldInstance>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MultiPartMoldInstance>();
    this.httpService.post('/api/multi-part-mold-instance/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMultiPartMoldInstanceMoldPartInstances(multiPartMoldInstanceID: string): Observable<MoldPartInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'multiPartMoldInstanceID', value: multiPartMoldInstanceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<MoldPartInstance[]>();
    this.httpService.get('/api/multi-part-mold-instance/r/get-mold-part-instance-list', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMultiPartMoldInstancesForGivenType(searchCriteria: SearchCriteriaForClass, multiPartMoldTypeID: string, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MultiPartMoldInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'multiPartMoldTypeID', value: multiPartMoldTypeID});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MultiPartMoldInstance[]>();
    this.httpService.post('/api/multi-part-mold-instance/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<MultiPartMoldInstance>  {
   const subject = new Subject<MultiPartMoldInstance>();
    this.httpService.get('/api/multi-part-mold-instance/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public removeMoldPartInstanceFromMultiPartMoldInstance(multiPartMoldInstanceID: string, moldPartInstanceID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldPartInstance[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'multiPartMoldInstanceID', value: multiPartMoldInstanceID});
  
    queryParamsList.push({name: 'moldPartInstanceID', value: moldPartInstanceID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldPartInstance[]>();
    this.httpService.post('/api/multi-part-mold-instance/w/remove-mold-part-instance', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: MultiPartMoldInstance, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MultiPartMoldInstance>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MultiPartMoldInstance>();
    this.httpService.put('/api/multi-part-mold-instance/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class MultiPartMoldTypeCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public addMoldPartTypeToMultiPartMoldType(multiPartMoldTypeID: string, moldPartTypeID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MultiPartMoldType>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'multiPartMoldTypeID', value: multiPartMoldTypeID});
  
    queryParamsList.push({name: 'moldPartTypeID', value: moldPartTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MultiPartMoldType>();
    this.httpService.post('/api/multi-part-mold-type/w/add-mold-part-type', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countMultiPartMoldType(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/multi-part-mold-type/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createMultiPartMoldInstanceForMultiPartMoldType(multiPartMoldTypeID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MultiPartMoldInstance>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'multiPartMoldTypeID', value: multiPartMoldTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MultiPartMoldInstance>();
    this.httpService.post('/api/multi-part-mold-type/w/create-instance', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: MultiPartMoldType, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MultiPartMoldType>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MultiPartMoldType>();
    this.httpService.post('/api/multi-part-mold-type/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMultiPartMoldType(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MultiPartMoldType[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MultiPartMoldType[]>();
    this.httpService.post('/api/multi-part-mold-type/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getMultiPartMoldTypePartTypes(multiPartMoldTypeID: string): Observable<MoldPartType[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'multiPartMoldTypeID', value: multiPartMoldTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<MoldPartType[]>();
    this.httpService.get('/api/multi-part-mold-type/r/get-part-types', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<MultiPartMoldType>  {
   const subject = new Subject<MultiPartMoldType>();
    this.httpService.get('/api/multi-part-mold-type/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public removeMoldPartTypeFromMultiPartMoldType(multiPartMoldTypeID: string, moldPartTypeID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MoldPartType[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'multiPartMoldTypeID', value: multiPartMoldTypeID});
  
    queryParamsList.push({name: 'moldPartTypeID', value: moldPartTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MoldPartType[]>();
    this.httpService.post('/api/multi-part-mold-type/w/remove-mold-part-type', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: MultiPartMoldType, jsonScope: JsonScope = new JsonScope(false, [])): Observable<MultiPartMoldType>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<MultiPartMoldType>();
    this.httpService.put('/api/multi-part-mold-type/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

