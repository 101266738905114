import {Injectable} from '@angular/core';
import {CountryCtrl} from '../../core/sdk/controllers-address';
import {Country} from '../../core/sdk/model-address';
import {SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {Utility} from '../utilities/utility';


@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private countryData: Country[];

  constructor(private countryCtrl: CountryCtrl) {
  }

  public setCountryData(data: Country[]): void {
    this.countryData = data;
  }

  public getCountryData(): Country[] {
    return this.countryData;
  }

  public getCountries(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Country[]> {
    return this.countryCtrl.getCountries(searchCriteria, pageNumber, pageSize);
  }

  public countCountries(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.countryCtrl.countCountries(searchCriteria);
  }

  public createCountry(name: string | null, isoCode: string | null,
                       isInsideEuropeanUnion: boolean | null, isOutsideEuropeContinent: boolean | null,
  ): Observable<Country> {
    return this.countryCtrl.createCountry(name, isoCode, isInsideEuropeanUnion, isOutsideEuropeContinent);
  }

  public updateCountry(updatedCountry: Country, countryId: string): Observable<Country> {
    return this.countryCtrl.updateCountry(updatedCountry, Utility.getObjectId(countryId));
  }
}
