import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeclarationRequestService} from '../../../../shared/services/production/declaration-request.service';
import {WebSocketService} from '../../../../shared/services/webSocket.service';
import {NotificationMessageType} from '../../../../shared/enums/NotificationMessageType';
import {NotificationService} from '../../../../shared/services/notification.service';
import {WebsocketMessage} from '../../../../core/sdk/model-dto';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-declaration-request-badge-button',
  templateUrl: './declaration-request-badge-button.component.html',
  styles: []
})
export class DeclarationRequestBadgeButtonComponent implements OnInit, OnDestroy {

  numberOfDeclarationRequests: string;
  isTerminalDevice = true;
  mainTitle = 'check-declaration-requests';
  messageEmitterSubscription: Subscription;


  constructor(private declarationRequestService: DeclarationRequestService,
              private webSocketService: WebSocketService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.declarationRequestService.countRequestDeclarations().subscribe(response => {
      this.numberOfDeclarationRequests = response.toString();
    });
    this.webSocketService.openWebSocketConnection('declaration-requests');
    this.messageEmitterSubscription = this.webSocketService.websocketMessageEmitter.subscribe((response: string) => {
      const webSocketMessage = JSON.parse(response);
      this.numberOfDeclarationRequests = webSocketMessage.messageValue.toString();
      this.displayNotification(webSocketMessage);
    });
  }

  displayNotification(notification: WebsocketMessage): void {
    if (notification.messageType.toString() === 'CREATE_NOTIFICATION') {
      this.notificationService.displayNotificationToast('production.new-declaration-request-appeared', NotificationMessageType.INFO);
    } else if (notification.messageType.toString() === 'UPDATE_NOTIFICATION') {
      this.notificationService.displayNotificationToast('production.one-of-declaration-requests-has-been-completed',
        NotificationMessageType.INFO);
    }
  }

  ngOnDestroy(): void {
    this.webSocketService.closeWebSocketConnection();
    this.messageEmitterSubscription.unsubscribe();
  }

  getValidValueForBadge(): string {
    return this.numberOfDeclarationRequests ?? '0';
  }

}
