<div [formGroup]="form" class="form__field" [ngClass]="formGroupStyle">
  <label [class]="getLabelStyle()" *ngIf="!isCheckBox()" data-toggle="tooltip" title="{{formField.toolTip | translate}}"
         [attr.for]="formField.key">{{formField.label | translate}}</label>
  <div [ngSwitch]="formField.controlType" [class]="getFieldStyle()">
    <input
      [ngClass]="formField.readOnly ? 'global-input--inactive': 'global-input'"
      [class]="getInputStyle()"
      *ngSwitchCase="formFieldTypeEnum.STRING_INPUT"
      [formControlName]="formField.key"
      [placeholder]="formField.placeholder | translate"
      [id]="formField.key"
      [type]="formField.type"
      [readOnly]="formField.readOnly"
      maxlength="250">
    <input
      [ngClass]="formField.readOnly ? 'global-input--inactive': 'global-input'"
      [class]="getInputStyle()"
      *ngSwitchCase="formFieldTypeEnum.NUMBER_INPUT"
      [formControlName]="formField.key"
      [placeholder]="formField.placeholder | translate"
      [id]="formField.key"
      [type]="formField.type"
      [readOnly]="formField.readOnly"
      max="999999">

    <input
      [ngClass]="formField.readOnly ? 'global-input--inactive': 'global-input'"
      [class]="getInputStyle()"
      *ngSwitchCase="formFieldTypeEnum.PASSWORD"
      [formControlName]="formField.key"
      [placeholder]="formField.placeholder | translate"
      [id]="formField.key"
      [type]="formField.type"
      [readOnly]="formField.readOnly"
      maxlength="40">

    <input
      [ngClass]="formField.readOnly ? 'global-input--inactive': 'global-input'"
      [class]="getInputStyle()"
      *ngSwitchCase="formFieldTypeEnum.COLOR_PICKER"
      [colorPicker]="formField.colorPicker"
      (colorPickerChange)="form.get(formField.key)?.setValue($event)"
      [value]="form.get(formField.key)?.value"
      [formControlName]="formField.key"
      [id]="formField.key"
      [type]="formField.type"
      [style.background]="form.get(formField.key)?.value">

    <div
      [class]="getInputStyle()"
      *ngSwitchCase="formFieldTypeEnum.FILE_UPLOAD">
      <app-custom-button>
        <label class="base-form-image-upload-button btn btn-bwm">
          <div>{{'common.browse' | translate}}</div>
          <input [formControlName]="formField.key"
                 [id]="formField.key"
                 type="file"
                 accept="image/*"
                 (change)="fileProgress($event)">
        </label>
      </app-custom-button>
      <div class="base-form-image-upload-container">
        <div
          *ngIf="!previewUrl"
          class="base-form-image-upload-placeholder">
          <i class="pi pi-camera base-form-image-upload-placeholder-icon">
          </i>
        </div>
        <img class="base-form-image-upload" src="{{previewUrl}}">
      </div>
      <div *ngIf="uploadedFilePath">
        {{uploadedFilePath}}
      </div>
    </div>

    <input
      style="width: 30px; height: 30px"
      [class]="getInputStyle()"
      *ngSwitchCase="formFieldTypeEnum.CHECKBOX"
      [checked]="formField.value"
      [value]="form.get(formField.key)?.value"
      (change)="switchCheck()"
      [formControlName]="formField.key"
      [id]="formField.key"
      [type]="formField.type">

    <p-dropdown
      [ngClass]="formField.readOnly ? 'global-input--inactive global-input': 'user-role__action-container__add-role__buttons__select'"
      [class]="getInputStyle()"
      [id]="formField.key"
      *ngSwitchCase="formFieldTypeEnum.SELECT"
      [options]="formField.options.objects"
      [formControlName]="formField.key"
      [optionLabel]="formField.options.field"
      [(ngModel)]="formField.value"
      [readonly]="formField.readOnly"
      [placeholder]="formField.placeholder | translate">
      <ng-template let-item pTemplate="item">
        {{getTranslatedField(item, formField.options.field)}}
      </ng-template>
      <ng-template let-selectedItem pTemplate="selectedItem">
        {{getTranslatedField(selectedItem, formField.options.field)}}
      </ng-template>
    </p-dropdown>


    <textarea
      [ngClass]="formField.readOnly ? 'global-input--inactive': 'global-input'"
      [class]="getInputStyle()"
      [id]="formField.key"
      *ngSwitchCase="formFieldTypeEnum.TEXTAREA"
      [formControlName]="formField.key"
      [placeholder]="formField.placeholder | translate">
    </textarea>

    <label [class]="getLabelStyle()" *ngIf="isCheckBox()" class="base-form-check-field-form"
           [attr.for]="formField.key">{{formField.label | translate}}</label>
    <div class="global-height--05rem" [ngClass]="form.get(formField.key).dirty? 'visibility--true' : 'visibility--false'">
      <small class="{{isValid ? 'text-transparent' : 'text-dngr'}}">{{formField.alert | translate}}</small>
    </div>
  </div>
</div>
