<form
  id="stepForm"
  class="global-flex global-flex-direction__column"
  [formGroup]="this.formGroup"
  (ngSubmit)="submitForm()">
  <div
    class="production-schema-step-form">
    <div class="production-schema-step-form__details">
      <label class="global-title margin--top">{{"production.basic-information" | translate}}</label>
      <div class="production-schema-step-form__details__inputs">
        <div class="production-schema-step-form__details__inputs__base-info">

          <div class="production-schema-step-form__details__inputs__base-info__index">
            <label>{{"production.index" | translate}}</label>
            <input
              readonly
              id="index"
              type="number"
              appIntegerInputValidation
              formControlName="index">
            <span
              *ngIf="indexControl.invalid && (indexControl.dirty || indexControl.touched)"
              class="global-error-message"
            >{{'common.valid-value-required' | translate}}</span>
          </div>
          <div class="production-schema-step-form__details__inputs__base-info__type">
            <label>{{"production.type" | translate}}</label>
            <p-dropdown
              id="select-step-type"
              optionValue="value"
              optionLabel="label"
              formControlName="temperatureFluctuation"
              [options]="stepTypeSelectOption"
              (onChange)="adjustViewToSelectedTemperatureFluctuation($event)"
            ></p-dropdown>
            <span
              *ngIf="temperatureFluctuationControl.invalid && (temperatureFluctuationControl.dirty || temperatureFluctuationControl.touched)"
              class="global-error-message"
            >{{'common.valid-value-required' | translate}}</span>
          </div>
        </div>

        <div class="production-schema-step-form__details__inputs__description">
          <label class="global-subtitle">{{'production.description' | translate}}</label>
          <textarea
            id="description"
            class="global-input"
            formControlName="description"
          ></textarea>
          <span
            *ngIf="descriptionControl.invalid && (descriptionControl.dirty || descriptionControl.touched)"
            class="global-error-message"
          >{{'common.max-length-for-this-field-is-255-signs' | translate}}</span>
        </div>

      </div>
      <div class="production-schema-step-form__details__numbers">
        <div class="production-schema-step-form__details__numbers__time">
          <label class="global-subtitle global-margin__small-bottom">{{"production.step-duration" | translate}}</label>
          <label for="days">{{'common.days' | translate}}</label>
          <input
            id="days"
            type="number"
            appIntegerInputValidation
            formControlName="days">
          <span
            *ngIf="daysControl.invalid && (daysControl.dirty || daysControl.touched)"
            class="global-error-message"
          >{{'common.value-cannot-be-negative-or-greater-than-999' | translate}}</span>

          <label for="hours">{{"common.hours" | translate}}</label>
          <input
            id="hours"
            type="number"
            appIntegerInputValidation
            formControlName="hours">
          <span
            *ngIf="hoursControl.invalid && (hoursControl.dirty || hoursControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label for="minutes">{{"common.minutes" |translate}}</label>
          <input
            id="minutes"
            type="number"
            appIntegerInputValidation
            formControlName="minutes">
          <span
            *ngIf="minutesControl.invalid && (minutesControl.dirty || minutesControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label for="seconds">{{"common.seconds" | translate}}</label>
          <input
            id="seconds"
            type="number"
            appIntegerInputValidation
            formControlName="seconds">
          <span
            *ngIf="secondsControl.invalid && (secondsControl.dirty || secondsControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
        </div>

        <div class="production-schema-step-form__details__numbers__costs">
          <label class="global-subtitle global-margin__small-bottom">{{"production.temperature" | translate}}</label>
          <ng-container *ngIf="isStepIncreasing">
            <label>{{"production.starting-temperature" | translate}} [℃]</label>
            <input
              id="starting-temperature"
              type="number"
              appIntegerInputValidation
              formControlName="startTemperature">
            <span
              *ngIf="startTemperatureControl.invalid && (startTemperatureControl.dirty || startTemperatureControl.touched)"
              class="global-error-message"
            >{{'common.valid-value-required' | translate}}</span>
          </ng-container>

          <label>{{(isStepIncreasing ? "production.target-temperature" : 'production.temperature') | translate}} [℃]</label>
          <input
            id="target-temperature"
            type="number"
            appIntegerInputValidation
            formControlName="targetTemperature">
          <span
            *ngIf="targetTemperatureControl.invalid && (targetTemperatureControl.dirty || targetTemperatureControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="production-schema-step-form__footer">
    <button
      type="submit"
      class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
      data-cy="save-button"
      [disabled]="this.formGroup.invalid"
    >{{'common.save' | translate}}</button>
    <button
      type="button"
      class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
      data-cy="cancel-button"
      (click)="cancelForm()"
    >{{(isItNewStep ? 'schemas.delete' : 'common.cancel') | translate}}</button>

  </div>
</form>
