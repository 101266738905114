/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseEnumEntity} from './model-enumentity';
import {EnumTranslation} from './model-translations';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class EnumEntityCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/enum-entity/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllEnumEntitiesGroupedByType(): Observable<{[key: string] :BaseEnumEntity[]}>  {
   const subject = new Subject<{[key: string] :BaseEnumEntity[]}>();
    this.httpService.get('/api/enum-entity/r/get-all', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<BaseEnumEntity>  {
   const subject = new Subject<BaseEnumEntity>();
    this.httpService.get('/api/enum-entity/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: BaseEnumEntity, jsonScope: JsonScope = new JsonScope(false, [])): Observable<BaseEnumEntity>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<BaseEnumEntity>();
    this.httpService.put('/api/enum-entity/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class EnumEntityTranslationCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public addEnumTranslationList(enumTranslationList: EnumTranslation[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<EnumTranslation[]>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<EnumTranslation[]>();
    this.httpService.post('/api/enum-translations/w/add-list', JsonScopedSerializer.stringify(enumTranslationList, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/enum-translations/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<EnumTranslation>  {
   const subject = new Subject<EnumTranslation>();
    this.httpService.get('/api/enum-translations/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: EnumTranslation, jsonScope: JsonScope = new JsonScope(false, [])): Observable<EnumTranslation>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<EnumTranslation>();
    this.httpService.put('/api/enum-translations/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

