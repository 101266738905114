import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {BaseObject} from '../../core/sdk/bighero-model';

@Injectable({
  providedIn: 'root'
})
export class BasketService {
  private basketMap: Map<string, Set<object>> = new Map<string, Set<object>>();
  public basketRemoveItemSubject: Subject<{ tableName: string, id: string }> = new Subject<{ tableName: string, id: string }>();

  public getBasketList(tableName: string): Set<object> {
    return this.basketMap.get(tableName);
  }

  public isBasketActive(tableName: string): boolean {
    return this.basketMap.has(tableName);
  }

  public isBasketActiveAndFilled(tableName: string): boolean {
    return this.basketMap.has(tableName) && !this.isBasketEmpty(tableName);
  }

  public isBasketEmpty(tableName: string): boolean {
    return this.basketMap.get(tableName).size === 0;
  }

  public registerBasket(tableName: string): void {
    this.basketMap.set(tableName, new Set<object>());
  }

  public flushBasket(tableName: string): void {
    this.basketMap.delete(tableName);
  }

  public removeFromBasket(tableName: string, object: BaseObject): void {
    const map = this.basketMap.get(tableName);
    map.delete(object);
    this.basketMap.set(tableName, map);
    this.basketRemoveItemSubject.next({tableName, id: object.id});
  }

  public addOrRemoveObjectToBasket(tableName: string, object: object): void {
    let objectList: Set<object> = new Set<object>();
    if (this.basketMap.has(tableName)) {
      objectList = this.basketMap.get(tableName);
    }
    if (objectList.has(object)) {
      objectList.delete(object);
    } else {
      objectList.add(object);
    }
    this.basketMap.set(tableName, objectList);
  }
}
