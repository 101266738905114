import {Injectable} from '@angular/core';
import {SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {PrintModalService} from '../printable-components/print-modal/print-modal.service';
import {ExportComponent} from '../printable-components/export/export.component';
import {TranslateService} from '../../core/translations/translate.service';

@Injectable({
  providedIn: 'root'
})
export class ExportTableDataService {

  private tableData: any[];
  private numberOfAllExportedItems: number;
  private tableFunctionalityInstance: any;
  private currentlyAppliedSearchCriteriaForTableFunctionality: SearchCriteriaForClass;
  private tableName: string;
  private additionalObjectPropertiesMap: Map<string, any> = new Map<string, any>();
  private className: string;

  constructor(private printModalService: PrintModalService,
              private translateService: TranslateService) {
  }

  public setTableData(tableData: any[]): void {
    this.tableData = tableData;
    setTimeout(() => {
      window.print();
    }, 1);
  }

  public getTableData(): any[] {
    return this.tableData;
  }

  public setNumberOfAllExportedTableItems(allItems: number): void {
    this.numberOfAllExportedItems = allItems;
  }

  public getNumberOfAllIExportedTableItems(): number {
    return this.numberOfAllExportedItems;
  }

  public setTableFunctionalityInstance(component: any): void {
    this.tableFunctionalityInstance = component;
  }

  public getTableFunctionalityInstance(): any {
    return this.tableFunctionalityInstance;
  }


  public setCurrentlyAppliedSearchCriteriaForTableFunctionality(searchCriteria: SearchCriteriaForClass): void {
    this.currentlyAppliedSearchCriteriaForTableFunctionality = searchCriteria;
  }

  public getCurrentlyAppliedSearchCriteriaForTableFunctionality(): SearchCriteriaForClass {
    return this.currentlyAppliedSearchCriteriaForTableFunctionality;
  }

  public clearAllData(): void {
    this.tableData = null;
    this.numberOfAllExportedItems = null;
    this.tableFunctionalityInstance = null;
    this.currentlyAppliedSearchCriteriaForTableFunctionality = null;
    this.tableName = null;
  }

  public setExportedTableName(tableName: string): void {
    this.tableName = tableName;
  }

  public getExportedTableName(): string {
    return this.tableName;
  }

  public setAdditionalObjectPropertiesMap(key: string, data: any): void {
    this.additionalObjectPropertiesMap.set(key, data);
  }

  public getAdditionalObjectPropertiesMap(key: string): any {
    return this.additionalObjectPropertiesMap.get(key);
  }

  public clearAdditionalObjectPropertiesMap(key: string): void {
    this.additionalObjectPropertiesMap.delete(key);
  }

  public setClassName(className: string): void {
    this.className = className;
  }

  public getClassName(): string {
    return this.className;
  }

  public translateExportedTableName(translationKey: string, exportedTableNameToTranslate: string): string {
    const preparedTableName: string = exportedTableNameToTranslate.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    return this.translateService.translate(translationKey + '.' + preparedTableName);
  }

  public export(): void {
    this.printModalService.openModal(ExportComponent, null, false, false);
  }


}
