<div class="template-schemas">

    <button
            class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
            type="button"
            (click)="createNewTemplate()">{{ 'schemas.create-new-template' | translate }}
    </button>

    <ng-template [ngIf]="!data.isEmpty()">
        <ng-container *ngFor="let template of data">
            <app-schema-template-overview-tile (removeTemplateEmitter)="removeSchemaTemplate($event)"
                                               (cloneTemplateEmitter)="cloneTemplate($event)"
                                               (editTemplateEmitter)="editTemplate($event)" [template]="template">
            </app-schema-template-overview-tile>
        </ng-container>
    </ng-template>

    <ng-template [ngIf]="data.isEmpty()">
        <div class="global-flex global-justify__center ">
            <div class="global-border global-background-color--white global-padding__2rem global-width--80vw">
                <h2>{{ 'common.there-is-no-defined-schema-templates' | translate }}</h2>
            </div>
        </div>
    </ng-template>
</div>

