<div class="mobile-split-product-details-tile__info-container">
  <div class="mobile-split-product-details-tile__title">
      <span
      >{{ 'mobile.split-from-product-at' | translate }}:</span>
  </div>
  <div class="mobile-split-product-details-tile__info-body">
    <div class="mobile-split-product-details-tile__info-record">
        <span
        >{{ 'mobile.storage-area' | translate }}</span>
      <span class="mobile-split-product-details-tile__info-record-value"
      >{{
          splitProductStoredInstance.splitFrom?.storageArea ? splitProductStoredInstance.splitFrom.storageArea.name :
            splitProductStoredInstance.splitFrom.sharedStorageUnitMedia.storageArea.name
        }}</span>
    </div>
    <div class="mobile-split-product-details-tile__info-record">
        <span
        >{{ 'mobile.section' | translate }}</span>
      <span class="mobile-split-product-details-tile__info-record-value"
      >{{
          splitProductStoredInstance.splitFrom?.storageArea ? splitProductStoredInstance.splitFrom.storageArea.section.name :
            splitProductStoredInstance.splitFrom.sharedStorageUnitMedia.storageArea.section.name
        }}</span>
    </div>
    <div class="mobile-split-product-details-tile__info-record">
        <span
        >{{ 'mobile.warehouse' | translate }}</span>
      <span class="mobile-split-product-details-tile__info-record-value"
      >{{
          splitProductStoredInstance.splitFrom?.storageArea ? splitProductStoredInstance.splitFrom.storageArea.section.warehouse.name :
            splitProductStoredInstance.splitFrom.sharedStorageUnitMedia.storageArea.section.warehouse.name
        }}</span>
    </div>
  </div>
</div>

<ng-template [ngIf]="!forRepack && splitProductStoredInstance.destinationStorageArea">
  <div class="mobile-split-product-details-tile__info-container">
    <div class="mobile-split-product-details-tile__title">
        <span
        >{{ 'mobile.destination-area' | translate }}:</span>
    </div>
    <div class="mobile-split-product-details-tile__info-body">
      <div class="mobile-split-product-details-tile__info-record">
          <span
          >{{ 'mobile.storage-area' | translate }}</span>
        <span class="mobile-split-product-details-tile__info-record-value"
        >{{ splitProductStoredInstance.destinationStorageArea.name }}</span>
      </div>
      <div class="mobile-split-product-details-tile__info-record">
          <span
          >{{ 'mobile.section' | translate }}</span>
        <span class="mobile-split-product-details-tile__info-record-value"
        >{{ splitProductStoredInstance.destinationStorageArea.section.name }}</span>
      </div>
      <div class="mobile-split-product-details-tile__info-record">
          <span
          >{{ 'mobile.warehouse' | translate }}</span>
        <span class="mobile-split-product-details-tile__info-record-value"
        >{{ splitProductStoredInstance.destinationStorageArea.section.warehouse.name }}</span>
      </div>
    </div>
  </div>
</ng-template>

<div class="mobile-split-product-details-tile__info-container">
  <div class="mobile-split-product-details-tile__title">
    <span>{{ 'mobile.storing-unit-instance' | translate }}:</span>
  </div>
  <div class="global-flex global-flex-direction__column">
    <div class="mobile-split-product-details-tile__info-body">
      <div class="mobile-split-product-details-tile__info-record">
          <span
          >{{ 'mobile.name' | translate }}</span>
        <span class="mobile-split-product-details-tile__info-record-value"
        >{{ storingUnitInstance.type.name }}</span>
      </div>
      <div class="mobile-split-product-details-tile__info-record">
          <span
          >{{ 'mobile.type' | translate }}</span>
        <span class="mobile-split-product-details-tile__info-record-value"
        >{{ storingUnitInstance.type.type |  status:StoringUnitTypeEnum: TranslationKeyEnum.MOBILE | translate }}</span>
      </div>
      <div class="mobile-split-product-details-tile__info-record">
          <span
          >{{ 'mobile.max-load' | translate }}</span>
        <span class="mobile-split-product-details-tile__info-record-value"
        >{{ storingUnitInstance.type.maxLoad ?? 0 }}</span>
      </div>
      <div class="mobile-split-product-details-tile__info-record">
          <span
          >{{ 'mobile.pieces-capacity' | translate }}</span>
        <span class="mobile-split-product-details-tile__info-record-value"
        >{{ storingUnitInstance.piecesCapacity }}</span>
      </div>
    </div>
    <div class="mobile-split-product-details-tile__info-body">
      <div class="mobile-split-product-details-tile__info-record">
          <span
          >{{ 'mobile.width' | translate }}</span>
        <span class="mobile-split-product-details-tile__info-record-value"
        >{{ storingUnitInstance.type.width }}</span>
      </div>
      <div class="mobile-split-product-details-tile__info-record">
          <span
          >{{ 'mobile.height' | translate }}</span>
        <span class="mobile-split-product-details-tile__info-record-value"
        >{{ storingUnitInstance.type.height }}</span>
      </div>
      <div class="mobile-split-product-details-tile__info-record">
          <span
          >{{ 'mobile.length' | translate }}</span>
        <span class="mobile-split-product-details-tile__info-record-value"
        >{{ storingUnitInstance.type.length }}</span>
      </div>
    </div>
  </div>
</div>
