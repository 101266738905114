import {Injectable} from '@angular/core';
import {StoringUnitInstanceCtrl, StoringUnitTypeCtrl} from '../../core/sdk/bighero-controllers';
import {SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {StoringUnitInstance, StoringUnitType} from '../../core/sdk/bighero-model';
import {Utility} from '../utilities/utility';

@Injectable({
  providedIn: 'root'
})
export class ProductStoringUnitTypeService {

  constructor(private storingUnitTypeCtrl: StoringUnitTypeCtrl,
              private storingUnitInstanceCtrl: StoringUnitInstanceCtrl) {
  }

  getNewEmptyStoringUnitInstance(): StoringUnitInstance {
    return {
      id: 'storing-unit-instance/' + Utility.getUUID(),
      piecesCapacity: null,
      productType: null,
      productForService: null,
      timestamp: Date.now(),
      type: null,
      version: 0
    };
  }

  getStoringUnitTypes(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<StoringUnitType[]> {
    return this.storingUnitTypeCtrl.getStoringUnitTypes(searchCriteria, pageNumber, pageSize);
  }

  countStoringUnitTypes(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.storingUnitTypeCtrl.getStoringUnitTypeCount(searchCriteria);
  }

  updateStoringUnitInstance(storingUnitInstance: StoringUnitInstance): Observable<StoringUnitInstance> {
    return this.storingUnitInstanceCtrl.updateObject(storingUnitInstance);
  }
}
