import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BaseFormField} from '../base-form-field';
import {BehaviorSubject} from 'rxjs';
import {FormControlService} from '../../../services/form-control.service';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
})
export class BaseFormComponent<T> implements OnInit {

  private formFieldsSubject = new BehaviorSubject<BaseFormField<T>[]>([]);
  public formFieldsCategories: Map<string, BaseFormField<T>[]> = new Map();
  private defaultString = 'default';

  @Input()
  showCategoryName: boolean | null = null;

  @Input() set formFields(value: BaseFormField<T>[]) {
    if (value) {
      this.formFieldsSubject.next(value);
    }
  }

  public get formFields(): BaseFormField<T>[] {
    return this.formFieldsSubject.getValue();
  }

  @Input()
  insertedTemplate: TemplateRef<any>;
  @Input()
  customButtonText: string;
  @Input()
  buttonSize: string;
  @Input()
  formAlert: string;
  @Input()
  showCancelButton: boolean;
  @Input()
  showSubmitButton = true;
  @Input()
  customConfirmButtonStyle: string;
  @Input()
  customDeclineButtonStyle: string;
  @Input()
  customFooterStyle: string;
  @Input()
  customButtonOrder: boolean;
  @Input()
  customContainerStyle: string;
  @Input()
  customCategoriesStyle: string;
  @Input()
  customRecordsStyle: string;
  @Input()
  customSingleRecordStyle: string;
  @Input()
  customCategoryStyleMap: Map<string, string>;
  @Input()
  formGroupStyle: string;
  @Output()
  formSubmitted: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output()
  formCancelled: EventEmitter<void> = new EventEmitter();
  @Output()
  assignButtonEmitter: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup;

  public fileData: File;

  constructor(private formControlService: FormControlService<T>) {
  }

  public ngOnInit(): void {
    this.formFieldsSubject
      .subscribe(formFields => {
        this.form = this.formControlService.toFormGroup(formFields);
        this.setCategories();
      });
  }

  public onSubmit(): void {
    if (this.form.value[`select-logo`] && this.form.value[`select-logo`][0] === 'C') {
      this.form.value[`select-logo`] = this.fileData;
    }
    this.formSubmitted.emit(this.form);
  }

  public onCancel(): void {
    this.formCancelled.emit();
  }

  public copyFileInput(file: File): void {
    this.fileData = file;
  }

  public onChange(): void {
    if (!this.fileData) {
      this.copyFileInput(this.form.value[`select-logo`]);
    }
  }

  public setCategories(): void {
    const categoryNames: Array<string> = [];
    const formFieldsCategories: Map<string, BaseFormField<T>[]> = new Map<string, any>();
    this.formFieldsSubject.getValue().forEach(record => {
      if (record.category && !(categoryNames.includes(record.category))) {
        categoryNames.push(record.category);
      }
    });
    categoryNames.forEach(record => {
      formFieldsCategories.set(record, []);
    });
    if (categoryNames.length) {
      let arrayForMap: BaseFormField<T>[];
      this.formFieldsSubject.getValue().forEach(record => {
        arrayForMap = formFieldsCategories.get(record.category);
        arrayForMap.push(record);
        formFieldsCategories.set(record.category, arrayForMap);
      });

    } else {
      formFieldsCategories.set(this.defaultString, []);
      this.formFieldsSubject.forEach(record => {
        formFieldsCategories.set(this.defaultString, record);
      });
    }
    this.formFieldsCategories = formFieldsCategories;
  }

  public getCategoryStyle(categoryName: string): string {
    if (this.customCategoryStyleMap) {
      if (this.customCategoryStyleMap.has(categoryName)) {
        return this.customCategoryStyleMap.get(categoryName);
      }
    }
    return this.customRecordsStyle;
  }

  public trackByFn(index: any): number {
    return index;
  }
}
