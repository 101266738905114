import {Injectable} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableRefreshService {
  private refreshMap: Map<string, ReplaySubject<boolean>> = new Map<string, ReplaySubject<boolean>>();
  private registeredMainFunctionalityTableName: string;

  registeredMainFunctionalityTableNameSubject: Subject<string> = new Subject<string>();

  constructor() {
    this.registeredMainFunctionalityTableNameSubject.subscribe((tableName: string) => {
      this.registeredMainFunctionalityTableName = tableName;
    });
  }

  public onTableRefresh(tableName?: string, shouldBeSearchCriteriaRefreshed?: boolean): void {
    this.refreshMap.get(tableName ? tableName : this.registeredMainFunctionalityTableName).next(shouldBeSearchCriteriaRefreshed);
  }

  public createRefreshMap(tableName: string): void {
    this.refreshMap.set(tableName, new ReplaySubject<boolean>());
  }

  public getTableRefreshSubject(tableName: string): ReplaySubject<boolean> {
    return this.refreshMap.get(tableName);
  }
}
