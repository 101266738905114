import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Country} from '../../../../core/sdk/model-address';
import {CountryService} from '../../../services/country.service';
import {SpinnerService} from '../../../services/spinner.service';
import {NotificationService} from '../../../services/notification.service';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {Utility} from '../../../utilities/utility';
import {BaseSubNavbarService} from '../../base-sub-navbar/base-sub-navbar.service';

@Component({
  selector: 'app-country-form',
  templateUrl: './country-form.component.html',
})
export class CountryFormComponent implements OnInit {

  public formGroup: FormGroup;
  public showISOCodeInputFlag: boolean = false;
  @Input() public hideReturnButton = false;
  @Input() public shouldFormBeDisabled = false;
  @Input() public currentCountry: Country;
  @Input() public updateCountryFlag = false;
  @Output() public formSubmitEmitter = new EventEmitter();
  @Output() public formCancelEmitter = new EventEmitter();

  constructor(private formBuilder: FormBuilder,
              private countryService: CountryService,
              private spinnerService: SpinnerService,
              private notificationService: NotificationService,
              private baseSubNavbarService: BaseSubNavbarService) {
  }

  public ngOnInit(): void {
    this.initFormGroup();
    if (!this.updateCountryFlag) {
      this.baseSubNavbarService.displayFilteringButtons(false);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      isInsideEuropeanUnion: [{
        value: this.currentCountry?.isInsideEuropeanUnion ?? false,
        disabled: this.shouldFormBeDisabled
      }],
      isOutsideEuropeContinent: [{
        value: this.currentCountry?.isOutsideEuropeContinent ?? false,
        disabled: this.shouldFormBeDisabled
      }]
    });
    this.addNameCountryControl();
    if (this.updateCountryFlag) {
      this.addISOCodeControl();
    }
  }

  private addISOCodeControl(): void {
    this.formGroup.addControl('codeISO31661alpha2', this.formBuilder.control(this.currentCountry?.codeISO31661alpha2 ?? '',
      Validators.required));
  }

  private addNameCountryControl(): void {
    this.formGroup.addControl('name', this.formBuilder.control({
        value: this.currentCountry?.name ?? '',
        disabled: this.shouldFormBeDisabled
      },
      [Validators.required]));
  }

  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get isInsideEuropeanUnionControl(): AbstractControl {
    return this.formGroup.get('isInsideEuropeanUnion');
  }

  public get isOutsideEuropeContinent(): AbstractControl {
    return this.formGroup.get('isOutsideEuropeContinent');
  }

  public get codeISO31661alpha2Control(): AbstractControl {
    return this.formGroup.get('codeISO31661alpha2');
  }

  private getCountryName(): string {
    return this.formGroup.contains('name') ? this.nameControl.value : null;
  }

  private getISOCodeValue(): string {
    return this.formGroup.contains('codeISO31661alpha2') ? this.codeISO31661alpha2Control.value : null;
  }

  private createNewCountry(): void {
    this.spinnerService.activateSpinner();
    this.countryService.createCountry(this.getCountryName(),
      this.getISOCodeValue(),
      this.isInsideEuropeanUnionControl.value,
      this.isOutsideEuropeContinent.value)
      .subscribe({
        next: () => {
          this.notificationService.displayNotificationToast('admin.country-created-successfully',
            NotificationMessageType.SUCCESS);
          this.formSubmitEmitter.emit();
          this.formGroup.reset();
          this.spinnerService.deactivateSpinner();
        },
        error: (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
  }

  private updateCountry(): void {
    this.spinnerService.activateSpinner();
    this.currentCountry = {...this.currentCountry, ...this.formGroup.value};
    this.countryService.updateCountry(this.currentCountry, this.currentCountry.id).subscribe({
      next: (response) => {
        Utility.updateObjectInData(response, this.countryService.getCountryData());
        this.notificationService.displayNotificationToast('admin.country-updated-successfully',
          NotificationMessageType.SUCCESS);
        this.spinnerService.deactivateSpinner();
      },
      error: (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public onCancel(): void {
    this.formCancelEmitter.emit();
  }

  private test(): void {
    this.addISOCodeControl();
    this.formGroup.removeControl('name');
  }

  private test2(): void {
    this.addNameCountryControl();
    this.formGroup.removeControl('codeISO31661alpha2');
  }

  public toggleShowISOCodeInputFlag(): void {
    !this.showISOCodeInputFlag ? this.test() : this.test2();
    this.showISOCodeInputFlag = !this.showISOCodeInputFlag;
  }

  public onSubmit(): void {
    if (!this.updateCountryFlag) {
      this.createNewCountry();
    } else {
      this.updateCountry();
    }
  }
}
