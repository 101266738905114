import {AfterViewInit, Component, ViewChild, ViewContainerRef} from '@angular/core';
import {SpinnerService} from './shared/services/spinner.service';
import {Utility} from './shared/utilities/utility';
import {VirtualKeyboardService} from './shared/services/virtual-keyboard/virtual-keyboard.service';
import {NotificationService} from './shared/services/notification.service';
import {PlaceholderNotificationModalDirective} from './shared/directives/placeholder-notification-modal.directive';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit {

  public title = 'bh-web-ui';
  public overlayBodyClass = ' global-overflow--overlay';
  public isTerminalView = false;
  public isMobileView = false;
  public showVirtualKeyboard = false;
  public topPositionForVirtualKeyboard: number;

  @ViewChild(PlaceholderNotificationModalDirective, {static: false})
  public notificationModalDirective: PlaceholderNotificationModalDirective;


  constructor(private vc: ViewContainerRef,
              private spinnerService: SpinnerService,
              private virtualKeyboardService: VirtualKeyboardService,
              private notificationService: NotificationService) {
    this.spinnerService.container = vc;
    if (Utility.isTerminalDevice()) {
      this.isTerminalView = true;
      document.scrollingElement.classList.add('terminal-scrollbar');
    }
    this.isMobileView = Utility.isMobileDevice();
    registerLocaleData(localeDe, 'de', localeDeExtra);
  }

  public ngAfterViewInit(): void {
    if (this.isTerminalView) {
      document.getElementById('pToast').classList.add('terminal-p-toast');
      this.handleVirtualKeyboard();
      this.makeVirtualKeyboardUnfocused();
    }
    this.notificationService.viewContainerRef = this.notificationModalDirective.viewContainerRef;
  }

  private handleVirtualKeyboard(): void {
    this.virtualKeyboardService.keyboardDataContent$.subscribe({
      next: (response: { showVirtualKeyboard: boolean, topKeyboardPosition: number }) => {
        this.showVirtualKeyboard = response.showVirtualKeyboard;
        this.topPositionForVirtualKeyboard = response.topKeyboardPosition;
      }
    });
  }

  private makeVirtualKeyboardUnfocused(): void {
    document.getElementById('keyboard').addEventListener('mousedown', (event) => {
      event.preventDefault();
    });
  }
}
