import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StoringUnitInstance, StoringUnitType} from '../../../../core/sdk/bighero-model';
import {ProductStoringUnitTypeService} from '../../../services/product-storing-unit-type.service';
import {ProductType} from '../../../../core/sdk/model-producttype';
import {NotificationService} from '../../../services/notification.service';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {ProductForService} from '../../../../core/sdk/model-productforservice';

@Component({
  selector: 'app-product-storing-unit-create',
  templateUrl: './product-storing-unit-create.component.html',
})
export class ProductStoringUnitCreateComponent implements OnInit {

  public showAssigningStoringUnitType = false;
  public storingUnitInstance: StoringUnitInstance;
  @Output() submitClicked: EventEmitter<StoringUnitInstance> = new EventEmitter<StoringUnitInstance>();
  @Input() product: ProductType | ProductForService;

  constructor(private productStoringUnitTypeService: ProductStoringUnitTypeService,
              private notificationService: NotificationService) {
  }

  public ngOnInit(): void {
    this.storingUnitInstance = this.productStoringUnitTypeService.getNewEmptyStoringUnitInstance();
  }

  assignStoringUnitType(storingUnitType: StoringUnitType): void {
    this.storingUnitInstance.type = storingUnitType;
    this.showAssigningStoringUnitType = false;
    this.notificationService.displayNotificationToast(
      'products.storing-unit-type-assigned-successfully', NotificationMessageType.SUCCESS);
    this.applyAssigningStoringUnitType();
  }

  public onSubmit(): void {
    this.product.storingUnitInstance = this.storingUnitInstance;
    this.submitClicked.emit(this.storingUnitInstance);
    this.applyAssigningStoringUnitType();
  }

  public applyAssigningStoringUnitType(): void {
    this.showAssigningStoringUnitType = !this.showAssigningStoringUnitType;
  }

}
