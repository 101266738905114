
<ng-template [ngIf]="currentType === 'firing' && !getSteps().isEmpty()">
  <div *ngFor="let step of getSteps()">
    <app-firing-schema-step
      [step]="$any(step)"
      [disableArrowButtons]="disabledArrowButtonsWhileCrossIsActive"
      [closeAllStepEditionEmitter]="closeAllStepEditionEmitter"></app-firing-schema-step>
  </div>
</ng-template>

<ng-template [ngIf]="currentType === 'production' && !getSteps().isEmpty()">
  <div *ngFor="let step of getSteps()">
    <app-production-schema-step
      [step]="$any(step)"
      [isSchemaTemplateApplied]="isTemplate(currentSchema.id)"
      [closeAllStepEditionEmitter]="closeAllStepEditionEmitter"
      [indexColumnApplied]=indexColumnApplied
      [disableArrowButtons]="disabledArrowButtonsWhileCrossIsActive">
    </app-production-schema-step>
  </div>
</ng-template>

<ng-template [ngIf]="getSteps().isEmpty()">
  <div
    class="production-schema__placeholder global-margin__small">
    <h3>{{'schemas.there-is-no-defined-steps' | translate}}</h3>
  </div>
</ng-template>
