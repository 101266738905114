import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeclarationService} from '../../../../shared/services/production/declaration.service';
import {NotificationService} from '../../../../shared/services/notification.service';
import {WebSocketService} from '../../../../shared/services/webSocket.service';
import {NotificationMessageType} from '../../../../shared/enums/NotificationMessageType';
import {SupervisionNotification} from '../../../../core/sdk/model-dto';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-pending-supervision-requests-badge-button',
  templateUrl: '../../worker-declaration-requests/declaration-request-badge-button/declaration-request-badge-button.component.html',
  styles: []
})
export class PendingSupervisionRequestsBadgeButtonComponent implements OnInit, OnDestroy {

  numberOfSupervisionRequests: string;
  isTerminalDevice = false;
  mainTitle = 'pending-supervision-requests';

  messageEmitterSubscription: Subscription;

  constructor(private declarationService: DeclarationService,
              private notificationService: NotificationService,
              private websocketService: WebSocketService) {
  }

  ngOnInit(): void {
    this.initValueForSupervisionRequests();
    this.websocketService.openWebSocketConnection('workers-supervision');
    this.subscribeToWebsocketMessage();
  }

  initValueForSupervisionRequests(): void {
    this.declarationService.countDeclarationsForSupervision().subscribe(response => {
      this.numberOfSupervisionRequests = response.toString();
    });
  }

  subscribeToWebsocketMessage(): void {
    this.messageEmitterSubscription = this.websocketService.websocketMessageEmitter.subscribe((response: string) => {
      const supervisionNotification: SupervisionNotification = JSON.parse(response);
      this.numberOfSupervisionRequests = supervisionNotification.notificationCounter.toString();
      this.displayNotification(supervisionNotification);
    });
  }

  displayNotification(notification: SupervisionNotification): void {
    if (notification.messageType.toString() === 'CREATE_NOTIFICATION') {
      this.notificationService.displayNotificationToast('production.new-supervision-request-appeared',
        NotificationMessageType.INFO);
    } else if (notification.messageType.toString() === 'UPDATE_NOTIFICATION') {
      this.notificationService.displayNotificationToast('production.one-of-supervision-requests-has-been-resolved',
        NotificationMessageType.INFO);
    }
  }

  getValidValueForBadge(): string {
    return this.numberOfSupervisionRequests ?? '0';
  }

  ngOnDestroy(): void {
    this.websocketService.closeWebSocketConnection();
    this.messageEmitterSubscription.unsubscribe();
  }

}
