import {Component, OnDestroy} from '@angular/core';
import {MixingSchemaStep} from '../../../core/sdk/model-mixingschema';
import {MixingSchemaService} from '../../services/production/mixing-schema.service';

@Component({
  selector: 'app-mixing-schema-step-list',
  templateUrl: './mixing-schema-step-list.component.html',
})
export class MixingSchemaStepListComponent implements OnDestroy {


  constructor(private mixingSchemaService: MixingSchemaService) {
  }

  public getMixingSchemaSteps(): MixingSchemaStep[] {
    return this.mixingSchemaService.getMixingSchemaSteps();
  }

  public ngOnDestroy(): void {
    this.mixingSchemaService.clearAllSteps();
  }
}
