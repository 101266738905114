<p-table
  class="main-table-header"
  dataKey="id"
  editMode="row"
  responsiveLayout="scroll"
  [value]="getTableData()"
  [paginator]="true"
  [lazy]="true"
  [rows]="pageable.pageSize"
  [totalRecords]="pageable.count"
  [(first)]="pageable.first"
  (onPage)="paginate($event)">

  <ng-template pTemplate="header">
    <tr>
      <th
        pSortableColumn="name"
        (click)="sortByColumn('name')"
      >{{'products.storing-unit-type-name' | translate}}
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th>{{'common.action' | translate}}</th>
    </tr>

    <tr class="primeng-table__search-row">
      <td>
        <input
          appStringLengthInputValidation
          pInputText
          type="text"
          class="primeng-table__filter-col global-input"
          placeholder="{{ 'products.search-by-storing-unit-type-name' | translate }}"
          data-cy="storing-unit-type-name-search"
          (keydown.enter)="searchByString($event, 'name')">
      </td>
      <td></td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="2">
        {{'common.no-records-available' | translate}}
      </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-storingUnitType
    let-ri="rowIndex">
    <tr [pEditableRow]="storingUnitType">
      <td>
        {{storingUnitType.name}}
      </td>
      <td class="global-text-align__center">
        <button
          pSaveEditableRow
          type="button"
          class="global-background-color--green global-width--fit-content  global-margin__small-right
         button button__submit-button button__submit-button--color"
          data-cy="create-button"
          (click)="emitStoringUnitType(storingUnitType)"
        >{{'common.create' | translate}}
        </button>
      </td>
    </tr>
  </ng-template>

</p-table>


