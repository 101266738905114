/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Customer} from './model-customer';
import {OfferDetails, SearchCriteriaForClass} from './model-dto';
import {FileInfo, OfferFile} from './model-files';
import {DummyOfferProduct, Offer, OfferProduct} from './model-offer';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class DummyOfferProductCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public createObject(entity: DummyOfferProduct, jsonScope: JsonScope = new JsonScope(false, [])): Observable<DummyOfferProduct>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<DummyOfferProduct>();
    this.httpService.post('/api/dummy-offer-product/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: DummyOfferProduct, jsonScope: JsonScope = new JsonScope(false, [])): Observable<DummyOfferProduct>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<DummyOfferProduct>();
    this.httpService.put('/api/dummy-offer-product/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class OfferCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public calculateAllActiveOffers(jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/offer/w/calculate-all', null , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public calculateOffer(offerID: string, priority: boolean | null, jsonScope: JsonScope = new JsonScope(false, [])): Observable<OfferDetails>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'offerID', value: offerID});
  
    if (priority !== undefined && priority !== null) {
      queryParamsList.push({name: 'priority', value: priority.toString()});
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<OfferDetails>();
    this.httpService.post('/api/offer/w/calculate', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public cloneOffer(customer: Customer, offerID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Offer>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'offerID', value: offerID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Offer>();
    this.httpService.post('/api/offer/w/clone', JsonScopedSerializer.stringify(customer, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countOfferFiles(offerId: string): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'offerId', value: offerId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<number>();
    this.httpService.get('/api/offer/r/count-files', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countOffers(searchCriteria: SearchCriteriaForClass, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/offer/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countOffersWithGivenProductType(searchCriteria: SearchCriteriaForClass, productTypeID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'productTypeID', value: productTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/offer/r/count-with-product-type', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: Offer, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Offer>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Offer>();
    this.httpService.post('/api/offer/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteOfferFile(fileId: string): Observable<void>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'fileId', value: fileId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/offer/w/delete-file', {params})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<Offer>  {
   const subject = new Subject<Offer>();
    this.httpService.get('/api/offer/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getOfferDetails(offerID: string): Observable<OfferDetails>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'offerID', value: offerID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<OfferDetails>();
    this.httpService.get('/api/offer/r/get-details', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getOfferFiles(offerId: string, pageNumber: number, pageSize: number): Observable<OfferFile[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'offerId', value: offerId});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<OfferFile[]>();
    this.httpService.get('/api/offer/r/get-files', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getOffers(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Offer[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Offer[]>();
    this.httpService.post('/api/offer/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getOffersWithGivenProductType(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, productTypeID: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Offer[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    queryParamsList.push({name: 'productTypeID', value: productTypeID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Offer[]>();
    this.httpService.post('/api/offer/r/get-with-product-type', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public isOfferWithProductsThatHaveMFCOrPriceEqualZero(offerID: string): Observable<boolean>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'offerID', value: offerID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<boolean>();
    this.httpService.get('/api/offer/r/is-offer-with-products-that-have-mfc-or-price-equal-zero', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: Offer, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Offer>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Offer>();
    this.httpService.put('/api/offer/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateOfferStatus(offerId: string, status: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<Offer>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'offerId', value: offerId});
  
    queryParamsList.push({name: 'status', value: status.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<Offer>();
    this.httpService.post('/api/offer/w/update-status', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public uploadFile(offerId: string, fileName: string, fileExtension: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<FileInfo>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'offerId', value: offerId});
  
    queryParamsList.push({name: 'fileName', value: fileName});
  
    queryParamsList.push({name: 'fileExtension', value: fileExtension});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<FileInfo>();
    this.httpService.post('/api/offer/w/upload', null , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

@Injectable()
export class OfferProductCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countOfferProductsForOffer(searchCriteria: SearchCriteriaForClass, offerId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'offerId', value: offerId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<number>();
    this.httpService.post('/api/offer-product/r/count', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: OfferProduct, jsonScope: JsonScope = new JsonScope(false, [])): Observable<OfferProduct>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<OfferProduct>();
    this.httpService.post('/api/offer-product/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createOfferProductsFromList(offerProductList: OfferProduct[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<OfferProduct[]>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<OfferProduct[]>();
    this.httpService.post('/api/offer-product/w/create-offer-products-from-list', JsonScopedSerializer.stringify(offerProductList, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/offer-product/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllOfferProductsForOffer(offerID: string): Observable<OfferProduct[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'offerID', value: offerID});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<OfferProduct[]>();
    this.httpService.get('/api/offer-product/r/get', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getObject(id: string): Observable<OfferProduct>  {
   const subject = new Subject<OfferProduct>();
    this.httpService.get('/api/offer-product/r/' + id + '', {responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getOfferProductsForOffer(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, offerId: string, jsonScope: JsonScope = new JsonScope(false, [])): Observable<OfferProduct[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    queryParamsList.push({name: 'offerId', value: offerId});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<OfferProduct[]>();
    this.httpService.post('/api/offer-product/r/get', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public setMarginForAllOfferProductsInOffer(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number, offerId: string, margin: number, jsonScope: JsonScope = new JsonScope(false, [])): Observable<OfferProduct[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
  
    queryParamsList.push({name: 'offerId', value: offerId});
  
    queryParamsList.push({name: 'margin', value: margin.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<OfferProduct[]>();
    this.httpService.post('/api/offer-product/w/set-margin-for-all', JsonScopedSerializer.stringify(searchCriteria, jsonScope) , {headers, params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateObject(entity: OfferProduct, jsonScope: JsonScope = new JsonScope(false, [])): Observable<OfferProduct>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<OfferProduct>();
    this.httpService.put('/api/offer-product/w/' + entity.id.split('/')[1] + '', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public updateProductOfferList(offerProductList: OfferProduct[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<OfferProduct[]>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<OfferProduct[]>();
    this.httpService.put('/api/offer-product/w/update-list', JsonScopedSerializer.stringify(offerProductList, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

