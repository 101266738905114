import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Locale} from '../../enums/locale';
import {FileInfo} from '../../../core/sdk/model-files';
import {Invoice} from '../../../core/sdk/model-invoices';
import {InvoiceApplicableTaxType} from '../../../core/sdk/enums-types';
import {TranslationKeyEnum} from '../../enums/translation-key-enum';
import {EnumService} from '../../services/enum.service';

@Component({
  selector: 'app-invoice-document-file',
  templateUrl: './invoice-document-file.component.html',
})
export class InvoiceDocumentFileComponent implements OnInit {

  public germanLocale = Locale.GERMAN;
  public englishLocale = Locale.ENGLISH;
  public englishFileInfo: FileInfo;
  public germanFileInfo: FileInfo;
  @Input() public currentInvoice: Invoice;
  @Input() public showReturnButton = true;
  @Input() public containerStyle: string;
  @Output() closeEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private enumService: EnumService) {
  }

  public ngOnInit(): void {
    this.loadDocumentFiles();
  }

  public loadDocumentFiles(): void {
    if (this.currentInvoice) {
      this.currentInvoice.fileInfoList.forEach((invoiceFile) => {
        if (invoiceFile.locale.includes('en')) {
          this.englishFileInfo = invoiceFile;
        } else {
          this.germanFileInfo = invoiceFile;
        }
      });
    }
  }

  public getApplicableTaxEnumValue(enumItem: number): string {
    return this.enumService.getEnumValueWithModule(enumItem, InvoiceApplicableTaxType, TranslationKeyEnum.ORDER_MANAGEMENT);
  }

}
