import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'price',
  pure: true
})
export class PricePipe extends DecimalPipe implements PipeTransform {

  public transform(priceValue: any): any {
   return  super.transform(priceValue.value, '1.0-2') + ' ' + priceValue.currency.shortname;
  }

}
