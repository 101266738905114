<div [class]="getFooterStyle()">
  <div *ngIf="customButtonOrder === false">
    <ng-content></ng-content>
  </div>
  <button *ngIf="showSubmitButton"
    [class]="getConfirmButtonStyle()"
    type="submit"
    (click)="onSubmit()"
    [disabled]="buttonDisabled"
    data-cy="submit-form-button">
    {{(customButtonText ? customButtonText : 'common.submit') | translate}}
  </button>
  <button
    [class]="getDeclineButtonStyle()"
    *ngIf="showCancelButton || showCloseButton"
    type="button"
    (click)="showCancelButton ? onCancel() : onClose()"
    data-cy="cancel-form-button">
    {{(showCancelButton ? 'common.cancel' : 'common.close') | translate }}
  </button>
  <div *ngIf="customButtonOrder === true">
    <ng-content></ng-content>
  </div>
</div>
