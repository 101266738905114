import {Component, Input} from '@angular/core';
import {SplitProductStoredInstance} from '../../../../../core/sdk/model-warehouse';
import {ProductStoredInstanceStatus} from '../../../../../core/sdk/enums-statuses';
import {Utility} from '../../../../../shared/utilities/utility';

@Component({
  selector: 'app-split-product-in-storing-tile',
  templateUrl: './split-product-in-storing-tile.component.html',
})
export class SplitProductInStoringTileComponent {

  @Input() public splitProductStoredInstance: SplitProductStoredInstance;
  @Input() public displayBorder: boolean = true;
  protected readonly ProductStoredInstanceStatus = ProductStoredInstanceStatus;

  public displayCreationDateInProperFormat(timestamp: number): string {
    return Utility.transformTimestampToUserFriendlyStringDateTime(timestamp);
  }
}
