import {SplitProductStoredInstanceCtrl} from '../../../core/sdk/controllers-warehouse';
import {Injectable} from '@angular/core';
import {ProductStoredInstance, SplitProductStoredInstance, StorageArea} from '../../../core/sdk/model-warehouse';
import {Observable} from 'rxjs';
import {Utility} from '../../utilities/utility';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {SplitProductStoredInstanceStatus} from '../../../core/sdk/enums-statuses';

@Injectable({
  providedIn: 'root'
})
export class SplitProductStoredInstanceService {

  private splitProductStoredInstanceData: SplitProductStoredInstance[];

  constructor(private splitProductStoredInstanceCtrl: SplitProductStoredInstanceCtrl) {
  }

  public getNewEmptySplitProductStoredInstance(quantity: number,
                                               splitFrom: ProductStoredInstance,
                                               destinationStorageArea: StorageArea): SplitProductStoredInstance {
    return {
      id: 'split-product-stored-instance/' + Utility.getUUID(),
      barcode: '',
      creationDate: 0,
      deleted: false,
      destinationStorageArea,
      entityVersion: 0,
      isDeleted: false,
      originBookingSeries: '',
      productForService: null,
      productType: null,
      quantity,
      sharedStorageUnitMedia: null,
      splitFrom,
      additionalStepDuringStoringAtLocation: null,
      splitStatus: SplitProductStoredInstanceStatus.REPACKING,
      status: null,
      storageArea: null,
      timestamp: Date.now(),
      version: 0,
      workerAssignedToMoveTheProduct: null
    };
  }

  public setSplitProductStoredInstanceData(data: SplitProductStoredInstance[]): void {
    this.splitProductStoredInstanceData = data;
  }

  public getSplitProductStoredInstanceData(): SplitProductStoredInstance[] {
    return this.splitProductStoredInstanceData;
  }

  public getObject(objectId: string): Observable<SplitProductStoredInstance> {
    return this.splitProductStoredInstanceCtrl.getObject(Utility.getObjectId(objectId));
  }

  public create(splitProductStoredInstance: SplitProductStoredInstance): Observable<SplitProductStoredInstance> {
    return this.splitProductStoredInstanceCtrl.create(splitProductStoredInstance);
  }

  public countSplitProductStoredInstances(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.splitProductStoredInstanceCtrl.countSplitProductStoredInstances(searchCriteria);
  }

  public getSplitProductStoredInstances(searchCriteria: SearchCriteriaForClass,
                                        pageNumber: number,
                                        pageSize: number): Observable<SplitProductStoredInstance[]> {
    return this.splitProductStoredInstanceCtrl.getSplitProductStoredInstances(searchCriteria, pageNumber, pageSize);
  }

  public assignWarehouseWorker(splitProductStoredInstanceID: string): Observable<SplitProductStoredInstance> {
    return this.splitProductStoredInstanceCtrl.assignWarehouseWorker(Utility.getObjectId(splitProductStoredInstanceID));
  }

  public assignDestinationStorageArea(storageArea: StorageArea,
                                      splitProductStoredInstanceID: string): Observable<SplitProductStoredInstance> {
    return this.splitProductStoredInstanceCtrl.assignDestinationStorageArea(Utility.getObjectId(storageArea.id),
      Utility.getObjectId(splitProductStoredInstanceID));
  }

  public getWorkerReadyForRepackSplitProductStoredInstances(): Observable<SplitProductStoredInstance[]> {
    return this.splitProductStoredInstanceCtrl.getWorkerReadyForRepackSplitProductStoredInstances();
  }

  public confirmRepack(splitProductStoredInstanceID: string): Observable<SplitProductStoredInstance> {
    return this.splitProductStoredInstanceCtrl.confirmRepack(Utility.getObjectId(splitProductStoredInstanceID));
  }

  public getWorkerReadyForMoveSplitProductStoredInstances(): Observable<SplitProductStoredInstance[]> {
    return this.splitProductStoredInstanceCtrl.getWorkerReadyForMoveSplitProductStoredInstances();
  }

  public confirmMoveToDestinationArea(splitProductStoredInstanceBarcode: string,
                                      destinationAreaBarcode: string,): Observable<SplitProductStoredInstance> {
    return this.splitProductStoredInstanceCtrl.confirmMoveToDestinationArea(splitProductStoredInstanceBarcode, destinationAreaBarcode);
  }
}
