import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VirtualKeyboardService {

  private keyboardDataSource = new Subject<{ showVirtualKeyboard: boolean, topKeyboardPosition: number }>();

  public keyboardDataContent$ = this.keyboardDataSource.asObservable();

  public handleVirtualKeyboardAction(showVirtualKeyboard: boolean, topKeyboardPosition: number): void {
    this.keyboardDataSource.next({showVirtualKeyboard, topKeyboardPosition});
  }

}
