import {Directive, HostListener, Input} from '@angular/core';


@Directive({
  selector: '[appCustomSortFieldTableDirective]'
})
export class CustomSortFieldTableDirective {

  @Input() public component: any;

  @HostListener('click', ['$event.target'])
  performCustomSorting(): void {
    const sortObjectData = this.component.sortFieldMap.get(this.component.selectedFilteringOption.value);
    this.component.sortByColumn(sortObjectData.columnName, sortObjectData.isSortingStringNumeric, sortObjectData.inClassPropertyName,
      sortObjectData.sortPropertyClass, sortObjectData.deepSortNestingList);
  }


}
