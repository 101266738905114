import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EnumService} from '../../../services/enum.service';
import {StoringUnitTypeEnum} from '../../../../core/sdk/enums-types';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StoringUnitInstance} from '../../../../core/sdk/bighero-model';
import {TranslateService} from '../../../../core/translations/translate.service';

@Component({
  selector: 'app-storing-unit-form',
  templateUrl: './storing-unit-form.component.html',
})
export class StoringUnitFormComponent implements OnInit {

  public formGroup: FormGroup;
  public currentLocalValue: string;
  public storingUnitTypeOptions = this.enumService.transformEnumOptionsForPrimeDropdown(StoringUnitTypeEnum,
    'products');

  @Output() formSubmitEmitter = new EventEmitter();
  @Output() formCancelEmitter = new EventEmitter();
  @Input() storingUnitInstance: StoringUnitInstance;

  constructor(private enumService: EnumService,
              private formBuilder: FormBuilder,
              private  translateService:  TranslateService) {
  }

  public ngOnInit(): void {
    this.initFormGroup();
    this.currentLocalValue = this.translateService.getCurrentLanguage()?.locale.split('_')[0];
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      piecesCapacity: [this.storingUnitInstance ? this.storingUnitInstance.piecesCapacity : 0, [Validators.required]],
      type: this.formBuilder.group({
        name: [{value: this.storingUnitInstance?.type.name, disabled: true}],
        type: [{value: this.storingUnitInstance?.type.type, disabled: true}],
        width: [{value: this.storingUnitInstance?.type.width, disabled: true}],
        height: [{value: this.storingUnitInstance?.type.height, disabled: true}],
        nettoWeight: [{value: this.storingUnitInstance?.type.nettoWeight, disabled: true}]
      })
    });
  }

  public onSubmit(): void {
    this.storingUnitInstance = Object.assign(this.storingUnitInstance, this.formGroup.value);
    this.formSubmitEmitter.next(this.storingUnitInstance);
  }

  get piecesCapacityControl(): AbstractControl {
    return this.formGroup.get('piecesCapacity');
  }


}
