import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Utility} from '../utilities/utility';
import {Observable, Subject, Subscriber} from 'rxjs';
import {FileInfo, PersonFile} from '../../core/sdk/model-files';
import {AuthService} from '../../core/auth/auth.service';
import {NotificationService} from './notification.service';
import {Person} from '../../core/sdk/bighero-model';
import {SpinnerService} from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class ImageFilesService {
  public avatarChanged: Subject<string> = new Subject<string>();
  public avatarUrl: string;

  constructor(private httpService: HttpClient,
              private authService: AuthService,
              private notificationService: NotificationService,
              private spinnerService: SpinnerService) {
  }

  public uploadAvatar(uploadFile: File, filename: string): Observable<PersonFile> {
    const file = new Blob([uploadFile]);
    const queryParamsList = this.getQueryParams(this.authService.getCurrentUser().id, this.getFileExtension(filename));
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders({'Content-type': 'application/octet-stream'});
    const subject = new Subject<PersonFile>();
    this.httpService.post('/api/person/avatar-upload', file, {params, headers}).subscribe(
      (res: PersonFile) => {
        this.avatarChanged.next(this.getImageUrl(res));
        this.authService.setAvatarForLoggedUser(res);
        subject.next(res);
      }, error => {
        this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService);
      }
    );
    return subject.asObservable();
  }

  private getFileExtension(filename: string): string {
    return filename.split('.')[1];
  }

  private getDownloadPath(fileInfo: FileInfo): string {
    switch (true) {
      case (fileInfo.id.split('/')[0] === 'customer-file') : {
        return 'r/customer/';
      }
      case (fileInfo.id.split('/')[0] === 'person-file') : {
        return 'r/person/';
      }
      case (fileInfo.id.split('/')[0] === 'product-type-file') : {
        return 'r/product-type/';
      }

    }
  }

  public getImageUrl(imageFile: FileInfo | null): string {
    return '/api/files/' + this.getDownloadPath(imageFile) + Utility.getObjectId(imageFile ? imageFile.id : '');
  }

  get(url: string): Observable<string> {
    return new Observable((observer: Subscriber<any>) => {
      let objectUrl: string;

      this.httpService
        .get(url, {
          headers: this.getHeaders(),
          responseType: 'blob'
        })
        .subscribe(m => {
          objectUrl = URL.createObjectURL(m);
          observer.next(objectUrl);
        });

      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
        }
      };
    });
  }

  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.set('Authorization', this.authService.getCurrentAuthToken());
    return headers;
  }

  private getQueryParams(personId: string, fileExtension: string):
    { name: string, value: string }[] {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'personId', value: personId ? Utility.getObjectId(personId) : ''});
    queryParamsList.push({name: 'fileExtension', value: fileExtension});
    return queryParamsList;
  }

  getUserAvatar(): string {
    if (this.authService.getCurrentUser() && this.authService.getCurrentUser().avatar) {
      return this.getImageUrl(this.authService.getCurrentUser().avatar);
    } else {
      return 'assets/user_avatar.png';
    }
  }

  getAvatarForUser(user: Person): string {
    if (user.avatar) {
      return this.getImageUrl(user.avatar);
    } else {
      return 'assets/user_avatar.png';
    }
  }
}
