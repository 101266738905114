import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PTableControlService {

  expandedRowsMap: Map<string, { [s: string]: boolean }> = new Map<string, { [p: string]: boolean }>();
  oneTimeTabSelectionMap: Map<string, string> = new Map<string, string>();

  public static removeObjectFromExpandedRows(key: string, collection: { [p: string]: boolean }): { [p: string]: boolean } {
    const newCollection: { [p: string]: boolean } = {};
    Object.entries(collection).forEach(obj => {
      if (obj[0] !== key) {
        newCollection[obj[0]] = true;
      }
    });
    return newCollection;
  }

  getExpandedRows(tableName: string): { [s: string]: boolean } {
    const rowToBeExpanded = this.expandedRowsMap.get(tableName);
    this.expandedRowsMap.delete(tableName);
    return rowToBeExpanded;
  }

  pushExpandedRowToMap(tableName: string, objectId: string): void {
    let expandedRows = this.expandedRowsMap.get(tableName);
    if (expandedRows) {
      expandedRows[objectId] = true;
    } else {
      expandedRows = {[objectId]: true};
    }
    this.expandedRowsMap.set(tableName, expandedRows);
  }

  removeExpandedRowFromMap(tableName: string): void {
    this.expandedRowsMap.delete(tableName);
  }

  setOneTimeSelectedTab(tableName: string, tabNameKey: string): void {
    this.oneTimeTabSelectionMap.set(tableName, tabNameKey);
  }

  removeOneTimeSelectedTab(tableName: string): void {
    this.oneTimeTabSelectionMap.delete(tableName);
  }

  getOneTimeSelectedTab(tableName: string): string | undefined {
    const tabNameKey = this.oneTimeTabSelectionMap.get(tableName);
    this.oneTimeTabSelectionMap.delete(tableName);
    return tabNameKey;
  }

  isOneTimeSelectedTabActive(tableName: string): boolean {
    return this.oneTimeTabSelectionMap.has(tableName);
  }

  flushMap(tableName: string): void {
    if (this.expandedRowsMap.has(tableName)) {
      this.expandedRowsMap.delete(tableName);
    }
  }

  flushOneTimeSelectedTab(tableName: string): void {
    this.oneTimeTabSelectionMap.delete(tableName);
  }
}
