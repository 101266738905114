import {ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Type} from '@angular/core';
import {PrintModalComponent} from './print-modal.component';

@Injectable({
  providedIn: 'root'
})
export class PrintModalService {

  private printModalComponentRef: ComponentRef<PrintModalComponent>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {
  }

  private appendPrintModalComponentToBody(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PrintModalComponent);
    const componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    this.printModalComponentRef = componentRef;
    this.printModalComponentRef.instance.modalRef = componentRef;
  }

  public openModal(componentType: Type<any>, dataMap?: Map<string, any>, showCloseButton?: boolean, printModalWindow?: boolean): void {
    this.appendPrintModalComponentToBody();
    this.printModalComponentRef.instance.childComponentType = componentType;
    if (printModalWindow !== undefined) {
      this.printModalComponentRef.instance.printModalWindow = printModalWindow;
    }
    if (showCloseButton && dataMap) {
      dataMap.set('showCloseButton', true);
    }
    if (dataMap){
      this.printModalComponentRef.instance.inputObjects = dataMap;
    }
  }

  // Maybe this will be usefully in future
  public detachPrintModalComponentFromBody(): void {
    if (this.appRef && this.printModalComponentRef) {
      this.appRef.detachView(this.printModalComponentRef.hostView);
      this.printModalComponentRef.destroy();
    }
  }


}
