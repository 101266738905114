<form
  class="global-flex global-flex-direction__column"
  [formGroup]="formGroup"
  (ngSubmit)="onSubmitForm()">

  <div
    class="production-schema-step-form">
    <div class="production-schema-step-form__details">
      <label class="global-title margin--top">{{"products.basic-information" | translate}}</label>
      <div class="production-schema-step-form__details__inputs">
        <div class="production-schema-step-form__details__inputs__base-info">
          <div class="production-schema-step-form__details__inputs__base-info__index">
            <label>{{"products.index" | translate}}</label>
            <input
              type="number"
              readonly
              formControlName="index">
            <span
              *ngIf="indexControl.invalid && (indexControl.dirty || indexControl.touched)"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>
          </div>
          <div class="production-schema-step-form__details__inputs__base-info__type">
            <label>{{"products.type" | translate}}</label>
            <p-dropdown
              id="type"
              optionLabel="label"
              optionValue="value"
              formControlName="type"
              [options]="stepTypeSelectOption"
              (onChange)="performActionOnTypeChange()">
            </p-dropdown>
            <span
              *ngIf="typeControl.invalid && (typeControl.dirty || typeControl.touched)"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>
          </div>

        </div>
        <div class="production-schema-step-form__details__inputs__assigning"
             [id]="'assigningContainer' + serviceSchemaStep.id">
          <div>

            <label for="workspaceInput">{{'products.workspace-group' | translate}}</label>
            <div id="workspaceInput">
              <input
                readonly
                type="text"
                class="global-input"
                value="{{workspaceGroup?.name}}"
                placeholder="{{'products.select-workspace-group' | translate}}"
                formControlName="workspaceGroupName">
              <button
                type="button"
                data-cy="assign-workspace-group-button"
                (click)="assignWorkspaceGroup()"
              >{{'schemas.assign-workspace-group' | translate}}</button>
            </div>
            <span
              *ngIf="workspaceGroupNameControl.invalid && (workspaceGroupNameControl.dirty || workspaceGroupNameControl.touched)"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>
          </div>

          <div *ngIf="isBurningStepType">
            <label for="firingSchemaInput">{{'schemas.firing-schema' | translate}}</label>
            <div id="firingSchemaInput">
              <input
                readonly
                id="firingSchemaName"
                type="text"
                class="global-input"
                value="{{firingSchema?.name}}"
                placeholder="{{'schemas.select-firing-schema' | translate}}"
                formControlName="firingSchemaName">
              <button
                type="button"
                data-cy="assign-firing-schema-button"
                (click)="assignFiringSchema()"
              >{{'schemas.assign-firing-schema' | translate}}</button>
            </div>
            <span
              *ngIf="firingSchemaNameControl.invalid && (firingSchemaNameControl.dirty || firingSchemaNameControl.touched)"
              class="global-error-message"
            >{{'common.field-required' | translate}}</span>
          </div>
        </div>

        <div class="production-schema-step-form__details__inputs__description">
          <label
            class="global-subtitle"
            for="name"
          >{{'products.name' | translate}}</label>
          <input
            id="name"
            type="text"
            class="global-input global-margin__small-bottom"
            formControlName="name">
          <span
            *ngIf="nameControl.invalid && (nameControl.dirty || nameControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>

          <label
            for="description"
            class="global-subtitle"
          >{{'products.description' | translate}}</label>
          <textarea
            id="description"
            class="global-input"
            formControlName="description"
          ></textarea>
          <span
            *ngIf="descriptionControl.invalid && (descriptionControl.dirty || descriptionControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
        </div>

        <div
          *ngIf="isPostProcessingStepType"
          class="global-text-align__center">
          <label
            for="quantityOfMiddleProductsAfterPostProcessing">{{'products.quantity-of-middle-products-after-post-processing' | translate}}</label>
          <input
            id="quantityOfMiddleProductsAfterPostProcessing"
            type="number"
            appIntegerInputValidation
            class="global-input global-margin__small-bottom"
            formControlName="quantityOfMiddleProductsAfterPostProcessing">
          <span
            *ngIf="quantityOfMiddleProductsAfterPostProcessingControl.invalid && (quantityOfMiddleProductsAfterPostProcessingControl.dirty || quantityOfMiddleProductsAfterPostProcessingControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
        </div>

      </div>
      <div class="production-schema-step-form__details__numbers">
        <div class="production-schema-step-form__details__numbers__time">
          <label class="global-subtitle global-margin__small-bottom">{{"products.step-duration" | translate}}</label>
          <label for="days">{{'common.days' | translate}}</label>
          <input
            id="days"
            type="number"
            appIntegerInputValidation
            formControlName="days">
          <span
            *ngIf="daysControl.invalid && (daysControl.dirty || daysControl.touched)"
            class="global-error-message"
          >{{'common.value-cannot-be-negative-or-greater-than-999' | translate}}</span>

          <label for="hours">{{"common.hours" | translate}}</label>
          <input
            id="hours"
            type="number"
            appIntegerInputValidation
            formControlName="hours">
          <span
            *ngIf="hoursControl.invalid && (hoursControl.dirty || hoursControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label for="minutes">{{"common.minutes" |translate}}</label>
          <input
            id="minutes"
            type="number"
            appIntegerInputValidation
            formControlName="minutes">
          <span
            *ngIf="minutesControl.invalid && (minutesControl.dirty || minutesControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
          <label for="seconds">{{"common.seconds" | translate}}</label>
          <input
            id="seconds"
            type="number"
            appIntegerInputValidation
            formControlName="seconds">
          <span
            *ngIf="secondsControl.invalid && (secondsControl.dirty || secondsControl.touched)"
            class="global-error-message"
          >{{'common.valid-value-required' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="production-schema-step-form__footer">
    <button
      type="submit"
      class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
      data-cy="save-button"
      [disabled]="this.formGroup.invalid"
    >{{'common.save' | translate}}</button>
    <button
      type="button"
      class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
      data-cy="delete-button"
      (click)="closeComponent()"
    >{{isIsANewStep() ? ('common.delete' | translate) : ('common.cancel'| translate)}}</button>

  </div>
</form>
