<div
  *ngIf="!showWorkloadHistoryTable"
  class="workload-tiles">
  <h3>{{'production.workload-log' | translate}}</h3>
  <div>
    <ng-container *ngIf="workloadList?.length > 0 then tiles else placeholder"></ng-container>
    <ng-template #tiles>
      <div *ngFor="let workload of workloadList">
        <app-workload-tile
          [workload]="workload"
          [isWorkspaceGroup]="isWorkspaceGroup"
        ></app-workload-tile>
      </div>
    </ng-template>

    <ng-template #placeholder>
      <h3 class="global-color--red">{{'production.there-is-no-log-to-show' | translate}}</h3>
    </ng-template>
  </div>

  <div>
    <button
      type="button"
      class="common-button"
      [disabled]="shouldButtonBeDisabled"
      (click)="seeFullWorkLoadHistory()"
    >{{'production.full-workload-history' | translate}}</button>
  </div>
</div>

<div *ngIf="showWorkloadHistoryTable">
  <app-workload-table
    [instanceId]="instanceId"
    (returnEmitter)="seeFullWorkLoadHistory()"
  ></app-workload-table>
</div>


