import {Component, OnInit} from '@angular/core';
import {ProductionSchemaTemplate} from '../../../core/sdk/model-productionschema';
import {NotificationService} from '../../services/notification.service';
import {SpinnerService} from '../../services/spinner.service';
import {TranslateService} from '../../../core/translations/translate.service';
import {BaseSubNavbarService} from '../../base-components/base-sub-navbar/base-sub-navbar.service';
import {Utility} from '../../utilities/utility';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {FiringSchemaTemplate} from '../../../core/sdk/model-firingschema';
import {ProductionFiringSchemaService} from '../../services/production-firing-schema.service';
import {Router} from '@angular/router';
import {CrossFunctionalService} from '../../services/cross-functional.service';
import {MixingSchemaTemplate} from '../../../core/sdk/model-mixingschema';
import {SchemaTemplateService} from '../../services/schema-template.service';

@Component({
  selector: 'app-schema-template-management',
  templateUrl: './schema-template-management.component.html',
})
export class SchemaTemplateManagementComponent implements OnInit {

  public data: ProductionSchemaTemplate[] | FiringSchemaTemplate[] | MixingSchemaTemplate[];

  constructor(private notificationService: NotificationService,
              private spinnerService: SpinnerService,
              private translateService: TranslateService,
              private crossFunctionalService: CrossFunctionalService,
              private productionFiringSchemaService: ProductionFiringSchemaService,
              private baseSubNavbarService: BaseSubNavbarService,
              private router: Router,
              private schemaTemplateService: SchemaTemplateService) {
    this.baseSubNavbarService.displayFilteringButtons(false);
    this.translateService.loadTranslationModule('schemas');
  }

  public ngOnInit(): void {
    this.spinnerService.activateSpinner();
    this.schemaTemplateService.getAllTemplates(
      this.productionFiringSchemaService.getCurrentPathName(this.router)).subscribe(response => {
      this.data = response;
      this.spinnerService.deactivateSpinner();
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }

  public createNewTemplate(): void {
    const currentLocation: string = this.router.url.split('/')[3].split('-')[0];
    this.editTemplate(this.schemaTemplateService.getEmptySchemaTemplate(currentLocation));
  }

  public editTemplate(template: ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate): void {
    if (this.isProductionTemplateSchema(template)) {
      this.crossFunctionalService.setGatheredData('passedProductionSchema', template);
      this.router.navigate(['bh/products/full-schema-edition']);
    } else if (this.isMixingTemplateSchema(template)) {
      this.crossFunctionalService.setGatheredData('passedMixingSchema', template);
      this.router.navigate(['bh/production/full-mixing-schema-edition']);
    } else {
      this.crossFunctionalService.setGatheredData('passedFiringSchema', template);
      this.router.navigate(['bh/production/full-schema-edition']);
    }
  }

  public removeSchemaTemplate(template?: ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate): void {
    this.schemaTemplateService.removeSchemaTemplate(template.id).subscribe(() => {
      this.notificationService.displayNotificationToast('schemas.template-removed-successfully',
        NotificationMessageType.SUCCESS);
      Utility.deleteObjectFromArray(this.data, template);
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }

  public cloneTemplate(template: FiringSchemaTemplate | ProductionSchemaTemplate | MixingSchemaTemplate): void {
    this.spinnerService.activateSpinner();
    this.schemaTemplateService.getCloneOfSchemaTemplate(template.id).subscribe(response => {
      this.pushTemplateToData(response);
      this.spinnerService.deactivateSpinner();
      this.notificationService.displayNotificationToast('schemas.template-was-cloned-successfully', NotificationMessageType.SUCCESS);
    }, (error) => {
      this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }

  private pushTemplateToData(response: ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate): void {
    if (this.isProductionTemplateSchema(response)) {
      (this.data as ProductionSchemaTemplate[]).push(response as ProductionSchemaTemplate);
    } else if (this.isMixingTemplateSchema(response)) {
      (this.data as MixingSchemaTemplate[]).push(response as MixingSchemaTemplate);
    } else {
      (this.data as FiringSchemaTemplate[]).push(response as FiringSchemaTemplate);
    }
  }

  private isProductionTemplateSchema(template: ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate): boolean {
    return template.id.includes('production');
  }

  private isMixingTemplateSchema(template: ProductionSchemaTemplate | FiringSchemaTemplate | MixingSchemaTemplate): boolean {
    return template.id.includes('mixing');
  }
}
