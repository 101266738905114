<div class="device-instance-form">
  <div class="device-instance-form__body">
    <ng-container>
      <form
        class="device-instance-form__body__form"
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit()"
        [ngClass]="assigningGroupFlag ? 'visibility--false global-height--0': ''">
        <label for="status">{{'device-instance.device-status' | translate}}</label>
        <p-dropdown
          id="status"
          class="user-role__action-container__add-role__buttons__select"
          formControlName="status"
          optionLabel="label"
          placeholder="{{'device-instance.select-status' | translate}}"
          data-cy="status-dropdown"
          [options]="this.availableStatusOptions">
          <ng-template pTemplate="item" let-item>
            {{item.label | translate}}
          </ng-template>
          <ng-template pTemplate="selectedItem" let-item>
            {{item.label | translate}}
          </ng-template>
        </p-dropdown>

        <label for="group"
        >{{'device-instance.production-device-group' | translate}}
        </label>
        <div
          class="order-create__form__assigning__record global-margin--none"
          id="group">
          <input
            readonly
            formControlName="group"
            class="global-input global-text-align__center"
            placeholder="{{'device-instance.select-group' | translate}}">
          <button
            type="button"
            (click)="toggleAssigningGroupFlag()"
            data-cy="assign-group-button">
            {{'device-instance.assign-group' | translate}}</button>
        </div>

        <div
          class="device-instance-form__body__form__buttons">
          <button
            type="submit"
            class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
            data-cy="submit-button"
            [disabled]="formGroup.invalid"
          >{{'common.submit' | translate}}</button>
          <button
            type="button"
            class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
            data-cy="back-button"
            (click)="closeForm()"
          >{{'common.return' | translate}}</button>
        </div>

      </form>
    </ng-container>


    <ng-container *ngIf="assigningGroupFlag">
      <app-production-device-groups
        class="global-width--100"
        [availableGroupOptions]="availableGroupOptions"
        (assignGroupEmitter)="assignGroup($event)">
      </app-production-device-groups>
    </ng-container>
  </div>
</div>
