import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function positiveTotalTimeFormValidator(): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {

    const days: number = form.get('days').value;
    const hours: number = form.get('hours').value;
    const minutes: number = form.get('minutes').value;
    const seconds: number = form.get('seconds').value;

    if (!isNaN(days) && !isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) {
      const isTimeValid = (days + hours + minutes + seconds > 0);

      return isTimeValid ? null : {invalidTime: true};
    }

    return null;
  };
}
