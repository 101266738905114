import {AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  BurningSchemaStep,
  DryingSchemaStep,
  MixingMassesSchemaStep,
  MoldingSchemaStep,
  PostProcessingSchemaStep,
  ProductionSchemaStep
} from '../../../../../core/sdk/model-productionschema';
import {WorkspaceGroup} from '../../../../../core/sdk/bighero-model';
import {ProductionSchemaStepType, WorkspaceGroupType} from '../../../../../core/sdk/enums-types';
import {SelectItem} from 'primeng/api';
import {EnumService} from '../../../../services/enum.service';
import {CrossFunctionalService} from '../../../../services/cross-functional.service';
import {Router} from '@angular/router';
import {Utility} from '../../../../utilities/utility';
import {ProductionFiringSchemaService} from '../../../../services/production-firing-schema.service';
import {MoldType} from '../../../../../core/sdk/model-mold';
import {FiringSchema} from '../../../../../core/sdk/model-firingschema';
import {NotificationService} from '../../../../services/notification.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstanceValues} from '../../../../constance-values/constance-values';
import {positiveTotalTimeFormValidator} from '../../../../custom-validators/positive-total-time-form-validator';
import {TranslationKeyEnum} from '../../../../enums/translation-key-enum';
import {MixingSchema} from '../../../../../core/sdk/model-mixingschema';
import {SchemaService} from '../../../../services/schema.service';
import {TranslateService} from '../../../../../core/translations/translate.service';

@Component({
  selector: 'app-production-schema-step-form',
  templateUrl: './production-schema-step-form.component.html'
})
export class ProductionSchemaStepFormComponent implements OnInit, OnDestroy, AfterViewChecked {

  private wasEmitterActivated = false;
  private wasScrollActivated = false;

  public currentLocalValue: string;

  public isItNewStep = false;
  public formGroup: FormGroup;

  public stepTypeSelectOption = this.enumService.transformEnumOptionsForPrimeDropdown(ProductionSchemaStepType,
    TranslationKeyEnum.PRODUCTS);
  public selectedStepType: SelectItem;
  public displayForm = false;
  public workspaceGroup: WorkspaceGroup;
  public moldType: MoldType;
  public firingSchema: FiringSchema;
  public mixingSchema: MixingSchema;
  public weightReduction: number;
  public quantityOfMiddleProductsAfterPostProcessing: number;
  public isBurningStepType = false;
  public isMoldingStepType = false;
  public isDryingStepType = false;
  public isPostProcessingStepType = false;
  public isMixingMassesSchemaStepType = false;
  public shouldDurationFieldBeDisabled: boolean = false;

  @Input() public step: ProductionSchemaStep;
  @Input() public isSchemaTemplateApplied = false;
  @Input() public closeAllStepEditionEmitter: EventEmitter<void>;
  @Output() public submitNewStepEmitter = new EventEmitter<ProductionSchemaStep>();


  constructor(private enumService: EnumService,
              private productionFiringSchemaService: ProductionFiringSchemaService,
              private formBuilder: FormBuilder,
              private notificationService: NotificationService,
              private crossFunctionalService: CrossFunctionalService,
              private router: Router,
              private schemaService: SchemaService,
              private  translateService:  TranslateService) {
  }

  public ngOnInit(): void {
    this.isItNewStep = this.productionFiringSchemaService.isItNewStep(this.step.id);
    if (this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepValues')) {
      this.selectedStepType =
        this.stepTypeSelectOption[this.crossFunctionalService.getGatheredData('currentlyEditedStepValues').type];
      this.initFormGroup(this.crossFunctionalService.getGatheredData('currentlyEditedStepValues'));
      if (this.crossFunctionalService.isCrossFunctionalActive(this.step.id)) {
        this.crossFunctionalService.flushData(this.step.id);
        this.isItNewStep = true;
      }
    } else {
      if (this.step.type) {
        this.selectedStepType = this.stepTypeSelectOption[this.step.type];
      } else {
        this.selectedStepType = this.stepTypeSelectOption[0];
      }
      this.initFormGroup();
    }
    this.checkReturnedObjectsFromCrossFunctional();
    this.displayForm = true;
    this.currentLocalValue = this.translateService.getCurrentLanguage()?.locale.split('_')[0];
  }

  public ngAfterViewChecked(): void {
    if (!this.wasScrollActivated) {
      if (this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepValues')) {
        this.scrollToForm();
      } else if (this.isItNewStep) {
        this.scrollToForm();
      }
    }
    this.closeAllStepEditionEmitter.subscribe(() => this.ngOnDestroy());
  }

  public ngOnDestroy(): void {
    if (this.isItNewStep && this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepId')) {
      this.crossFunctionalService.setGatheredData(this.step.id, true);
    }
    this.crossFunctionalService.flushData('workspaceGroupForProductionSchemaStep');
    this.crossFunctionalService.flushData('moldTypeForProductionSchemaStep');
    this.crossFunctionalService.flushData('firingSchemaForProductionSchemaStep');
    this.crossFunctionalService.flushData('mixingSchemaForProductionSchemaStep');
    if (!this.wasEmitterActivated && !this.crossFunctionalService.isCrossFunctionalActive('currentlyEditedStepId')) {
      this.closeComponent();
    }
  }

  private getProperDurationValue(previouslyEditedStep?: ProductionSchemaStep | BurningSchemaStep
    | MoldingSchemaStep | DryingSchemaStep | MixingMassesSchemaStep | PostProcessingSchemaStep): {
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  } {
    switch (true) {
      case this.selectedStepType.value === ProductionSchemaStepType.MIXING_MASSES_SCHEMA_STEP : {
        this.shouldDurationFieldBeDisabled = true;
        return Utility.convertSecondsToDurationObject(this.getStepDurationForMixingSchemaStep(
          (previouslyEditedStep as MixingMassesSchemaStep)?.mixingSchema));
      }
      case this.selectedStepType.value === ProductionSchemaStepType.BURNING_SCHEMA_STEP : {
        this.shouldDurationFieldBeDisabled = true;
        return Utility.convertSecondsToDurationObject(this.getStepDurationForFiringSchemaStep(
          (previouslyEditedStep as BurningSchemaStep)?.firingSchema));
      }
      case previouslyEditedStep != null : {
        this.shouldDurationFieldBeDisabled = false;
        return Utility.convertSecondsToDurationObject(previouslyEditedStep.stepDuration.seconds);
      }
      default : {
        this.shouldDurationFieldBeDisabled = false;
        return Utility.convertSecondsToDurationObject(this.step.stepDuration.seconds);
      }
    }
  }

  private getStepDurationForMixingSchemaStep(previouslyEditedStep: MixingSchema): number {
    if (this.crossFunctionalService.isCrossFunctionalActive('mixingSchemaForProductionSchemaStep')) {
      return this.schemaService.getAllStepsTotalDuration(
        this.crossFunctionalService.getGatheredData('mixingSchemaForProductionSchemaStep')?.mixingSchema?.mixingSchemaStepList
      );
    } else if (previouslyEditedStep) {
      return this.schemaService.getAllStepsTotalDuration(previouslyEditedStep.mixingSchemaStepList);
    } else {
      return this.step.stepDuration.seconds;
    }
  }

  private getStepDurationForFiringSchemaStep(previouslyEditedStep: FiringSchema): number {
    if (this.crossFunctionalService.isCrossFunctionalActive('firingSchemaForProductionSchemaStep')) {
      return this.schemaService.getAllStepsTotalDuration(
        this.crossFunctionalService.getGatheredData('firingSchemaForProductionSchemaStep')?.firingSchema?.firingSchemaStepList
      );
    } else if (previouslyEditedStep) {
      return this.schemaService.getAllStepsTotalDuration(previouslyEditedStep.firingSchemaStepList);
    } else {
      return this.step.stepDuration.seconds;
    }
  }


  private initFormGroup(previouslyEditedStep?: ProductionSchemaStep | BurningSchemaStep
    | MoldingSchemaStep | DryingSchemaStep | MixingMassesSchemaStep | PostProcessingSchemaStep): void {
    const {days, hours, minutes, seconds} = this.getProperDurationValue(previouslyEditedStep);
    this.formGroup = this.formBuilder.group({
        index: [previouslyEditedStep ? previouslyEditedStep.index : this.step.index,
          [Validators.required, Validators.min(ConstanceValues.MIN_POSITIVE_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
        type: [previouslyEditedStep ? previouslyEditedStep.type : this.step.type, [Validators.required]],
        workspaceGroupName: [this.workspaceGroup ? this.workspaceGroup?.name : this.step.workspaceGroup?.name,
          [Validators.required]], /// assigning
        name: [previouslyEditedStep ? previouslyEditedStep.name : this.step.name,
          [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
        description: [previouslyEditedStep ? previouslyEditedStep?.description : this.step?.description,
          [Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
        days: [{value: days, disabled: this.shouldDurationFieldBeDisabled}, [Validators.max(999)]],
        hours: [{
          value: hours,
          disabled: this.shouldDurationFieldBeDisabled
        }, [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
        minutes: [{
          value: minutes,
          disabled: this.shouldDurationFieldBeDisabled
        }, [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
        seconds: [{
          value: seconds,
          disabled: this.shouldDurationFieldBeDisabled
        }, [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
        directLaborRateCost: [previouslyEditedStep ? previouslyEditedStep.directLaborRateCost : this.step.directLaborRateCost,
          [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
        indirectLaborRateCost: [previouslyEditedStep ? previouslyEditedStep.indirectLaborRateCost : this.step.indirectLaborRateCost,
          [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
        energyCost: [previouslyEditedStep ? previouslyEditedStep.energyCost : this.step.energyCost,
          [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
        fixedOverheadCost: [previouslyEditedStep ? previouslyEditedStep.fixedOverheadCost : this.step.fixedOverheadCost,
          [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
        variableOverheadCost: [previouslyEditedStep ? previouslyEditedStep.variableOverheadCost : this.step.variableOverheadCost,
          [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)]],
      },
      {validators: [positiveTotalTimeFormValidator()]});

    if (previouslyEditedStep?.workspaceGroup) {
      this.workspaceGroup = previouslyEditedStep.workspaceGroup;
      this.workspaceGroupNameControl.patchValue(this.workspaceGroup?.name);
    }

    if ((previouslyEditedStep as PostProcessingSchemaStep)?.quantityOfMiddleProductsAfterPostProcessing) {
      this.quantityOfMiddleProductsAfterPostProcessing =
        (previouslyEditedStep as PostProcessingSchemaStep).quantityOfMiddleProductsAfterPostProcessing;
    }

    switch (previouslyEditedStep ? previouslyEditedStep.type : this.step.type) {
      case ProductionSchemaStepType.BURNING_SCHEMA_STEP:
        if ((previouslyEditedStep as BurningSchemaStep)?.firingSchema) {
          this.firingSchema = (previouslyEditedStep as BurningSchemaStep).firingSchema;
        }
        this.attachFormControlsForBurningSchemaStep();
        this.isBurningStepType = true;
        break;
      case ProductionSchemaStepType.MOLDING_SCHEMA_STEP:
        if ((previouslyEditedStep as MoldingSchemaStep)?.moldType) {
          this.moldType = (previouslyEditedStep as MoldingSchemaStep).moldType;
        }
        this.attachFormControlsForMoldingSchemaStep();
        this.isMoldingStepType = true;
        break;
      case ProductionSchemaStepType.DRYING_SCHEMA_STEP:
        this.weightReduction = previouslyEditedStep ?
          (previouslyEditedStep as DryingSchemaStep)?.weightReduction
          : (this.step as DryingSchemaStep)?.weightReduction ?? 0;
        this.attachFormControlsForDryingSchemaStep();
        this.isDryingStepType = true;
        break;
      case ProductionSchemaStepType.POST_PROCESSING_SCHEMA_STEP:
        this.attachFormControlForPostProcessingSchemaStep();
        this.isPostProcessingStepType = true;
        break;
      case ProductionSchemaStepType.MIXING_MASSES_SCHEMA_STEP:
        if ((previouslyEditedStep as MixingMassesSchemaStep)?.mixingSchema) {
          this.mixingSchema = (previouslyEditedStep as MixingMassesSchemaStep).mixingSchema;
        }
        this.attachFormControlsForMixingMassesSchemaStep();
        this.isMixingMassesSchemaStepType = true;
        break;
    }

  }

  private attachFormControlsForBurningSchemaStep(): void {
    this.formGroup.addControl(
      'firingSchemaName',
      this.formBuilder.control(this.firingSchema ? this.firingSchema?.name :
          (this.step as BurningSchemaStep)?.firingSchema?.name,
        [Validators.required]));
  }

  private attachFormControlsForMixingMassesSchemaStep(): void {
    this.formGroup.addControl(
      'mixingSchemaName',
      this.formBuilder.control(this.mixingSchema ? this.mixingSchema?.name :
        (this.step as MixingMassesSchemaStep)?.mixingSchema?.name, [Validators.required]));
  }

  private detachFormControlsForMixingMassesSchemaStep(): void {
    this.formGroup.removeControl('mixingSchemaName');
  }

  private detachFormControlForBurningSchemaStep(): void {
    this.formGroup.removeControl('firingSchemaName');
  }

  private attachFormControlsForMoldingSchemaStep(): void {
    const requiredValidator = [Validators.required];
    this.formGroup.addControl(
      'moldTypeName', this.formBuilder.control(this.moldType ? this.moldType?.name :
        (this.step as MoldingSchemaStep)?.moldType?.name));
    if (!this.isSchemaTemplateApplied) {
      this.moldTypeNameControl.setValidators(requiredValidator);
    }
  }

  private detachFormControlForMoldingSchemaStep(): void {
    this.formGroup.removeControl('moldTypeName');
  }

  private attachFormControlsForDryingSchemaStep(): void {
    this.formGroup.addControl(
      'weightReduction', this.formBuilder.control(this.weightReduction ?? 0,
        [Validators.required, Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER)])
    );
  }

  private detachFormControlForDryingSchemaStep(): void {
    this.formGroup.removeControl('weightReduction');
  }

  private attachFormControlForPostProcessingSchemaStep(): void {
    this.formGroup.addControl('quantityOfMiddleProductsAfterPostProcessing',
      this.formBuilder.control(this.quantityOfMiddleProductsAfterPostProcessing ?
          this.quantityOfMiddleProductsAfterPostProcessing :
          (this.step as PostProcessingSchemaStep).quantityOfMiddleProductsAfterPostProcessing ?? 1,
        [Validators.required, Validators.min(ConstanceValues.MIN_POSITIVE_NUMBER),
          Validators.max(ConstanceValues.MAX_NUMBER)]));
  }

  private detachFormControlForPostProcessingSchemaStep(): void {
    this.formGroup.removeControl('quantityOfMiddleProductsAfterMolding');
  }

  private scrollToForm(): void {
    document.getElementById('assigningContainer' + this.step.id).scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'center'
    });
    this.crossFunctionalService.flushData('currentlyEditedStepValues');
    this.wasScrollActivated = true;
  }

  public closeComponent(): void {
    this.wasEmitterActivated = true;
    this.submitNewStepEmitter.emit();
  }

  private checkReturnedObjectsFromCrossFunctional(): void {
    if (this.crossFunctionalService.isCrossFunctionalActive('returningFlag')) {
      if (this.crossFunctionalService.isCrossFunctionalActive('workspaceGroupForProductionSchemaStep')
        && this.crossFunctionalService.getGatheredData('workspaceGroupForProductionSchemaStep')) {
        this.workspaceGroup = this.crossFunctionalService.getGatheredData('workspaceGroupForProductionSchemaStep');
        this.workspaceGroupNameControl.patchValue(this.workspaceGroup?.name);
        this.workspaceGroupNameControl.markAsDirty();
      } else if (this.crossFunctionalService.isCrossFunctionalActive('moldTypeForProductionSchemaStep')
        && this.crossFunctionalService.getGatheredData('moldTypeForProductionSchemaStep')) {
        this.moldType = this.crossFunctionalService.getGatheredData('moldTypeForProductionSchemaStep');
        this.moldTypeNameControl.patchValue(this.moldType?.name);
        this.moldTypeNameControl.markAsDirty();
      } else if (this.crossFunctionalService.isCrossFunctionalActive('firingSchemaForProductionSchemaStep')
        && this.crossFunctionalService.getGatheredData('firingSchemaForProductionSchemaStep')) {
        this.firingSchema = this.crossFunctionalService.getGatheredData('firingSchemaForProductionSchemaStep').firingSchema;
        this.firingSchemaNameControl.patchValue(this.firingSchema?.name);
        this.firingSchemaNameControl.markAsDirty();
      } else if (this.crossFunctionalService.isCrossFunctionalActive('mixingSchemaForProductionSchemaStep')
        && this.crossFunctionalService.getGatheredData('mixingSchemaForProductionSchemaStep')) {
        this.mixingSchema = this.crossFunctionalService.getGatheredData('mixingSchemaForProductionSchemaStep').mixingSchema;
        this.mixingSchemaNameControl.patchValue(this.mixingSchema?.name);
        this.mixingSchemaNameControl.markAsDirty();
      }
      this.crossFunctionalService.flushData('returningFlag');
    }
  }

  private assignValuesToStep(step: ProductionSchemaStep | BurningSchemaStep | MoldingSchemaStep | DryingSchemaStep
    | PostProcessingSchemaStep | MixingMassesSchemaStep):
    ProductionSchemaStep | BurningSchemaStep | MoldingSchemaStep | DryingSchemaStep
    | PostProcessingSchemaStep | MixingMassesSchemaStep {
    step = {...step, ...this.formGroup.value};
    step.stepDuration = {
      nanos: 0,
      seconds: this.secondsControl.value + 60 * (this.minutesControl.value
        + 60 * (this.hoursControl.value + this.daysControl.value * 24) ?? 0)
    };
    if (this.workspaceGroup) {
      step.workspaceGroup = this.workspaceGroup;
    }
    if (this.firingSchema) {
      (step as BurningSchemaStep).firingSchema = this.firingSchema;
    }
    if (this.moldType) {
      (step as MoldingSchemaStep).moldType = this.moldType;
    }
    if (this.mixingSchema) {
      (step as MixingMassesSchemaStep).mixingSchema = this.mixingSchema;
    }
    return step;
  }

  public submitForm(): void {
    this.wasEmitterActivated = true;
    this.step = this.assignValuesToStep(this.step);
    this.submitNewStepEmitter.emit(this.step);
  }

  //// ASSIGNING
  public assignWorkspaceGroup(): void {
    this.crossFunctionalService.setGatheredData('currentlyEditedStepId', this.step.id);
    this.crossFunctionalService.setGatheredData('workspaceGroupTypeForStep',
      this.productionFiringSchemaService.getWorkspaceGroupTypeForCurrentStep(this.typeControl.value));
    switch (this.selectedStepType.value) {
      case  ProductionSchemaStepType.PRODUCTION_SCHEMA_STEP:
        this.storeCurrentValues('production');
        break;
      case ProductionSchemaStepType.BURNING_SCHEMA_STEP:
        this.storeCurrentValues('burning');
        break;
      case ProductionSchemaStepType.MOLDING_SCHEMA_STEP:
        this.storeCurrentValues('molding');
        break;
      case ProductionSchemaStepType.DRYING_SCHEMA_STEP:
        this.storeCurrentValues('drying');
        break;
      case ProductionSchemaStepType.POST_PROCESSING_SCHEMA_STEP:
        this.storeCurrentValues('postProcessing');
        break;
      case ProductionSchemaStepType.MIXING_MASSES_SCHEMA_STEP:
        this.storeCurrentValues('mixingMasses');
        if (this.mixingSchema || (this.step as MixingMassesSchemaStep)?.mixingSchema) {
          const mixingSchema = this.mixingSchema ? this.mixingSchema : (this.step as MixingMassesSchemaStep)?.mixingSchema;
          this.crossFunctionalService.setGatheredData('assignWorkspaceGroupToAlreadyDefinedMixingSchema', mixingSchema);
        } else {
          this.crossFunctionalService.setGatheredData('assigningWorkspaceGroupToNotDefinedMixingSchema', null);
        }
    }
    this.router.navigate(['bh/production/workspace-group-management']);
  }

  private disableDurationFormControls(): void {
    this.formGroup.get('days').disable();
    this.formGroup.get('hours').disable();
    this.formGroup.get('minutes').disable();
    this.formGroup.get('seconds').disable();
  }

  private enableDurationFormControls(): void {
    this.formGroup.get('days').enable();
    this.formGroup.get('hours').enable();
    this.formGroup.get('minutes').enable();
    this.formGroup.get('seconds').enable();
  }

  public assignMoldType(): void {
    this.crossFunctionalService.setGatheredData('currentlyEditedStepId', this.step.id);
    this.storeCurrentValues('molding');
    this.router.navigate(['bh/production/molds-management']);
  }

  public assignFiringSchema(): void {
    this.crossFunctionalService.setGatheredData('currentlyEditedStepId', this.step.id);
    this.storeCurrentValues('burning');
    this.router.navigate(['bh/production/firing-schema-management']);
  }

  public assignMixingSchema(): void {
    this.crossFunctionalService.setGatheredData('currentlyEditedStepId', this.step.id);
    if (this.workspaceGroup || this.step.workspaceGroup?.type === WorkspaceGroupType.MIXING_STAND_OPERATION) {
      const mixingMachineModelId: string = this.workspaceGroup ? this.workspaceGroup.productionDeviceModel.id :
        this.step.workspaceGroup.productionDeviceModel.id;
      this.crossFunctionalService.setGatheredData('assignWorkspaceGroupToProperMixingSchema', mixingMachineModelId);
    }
    this.storeCurrentValues('mixingMasses');
    this.router.navigate(['bh/production/mixing-schema-management']);
  }

  public storeCurrentValues(stepType: 'burning' | 'molding' | 'production' | 'drying' | 'postProcessing' | 'mixingMasses'): void {
    let step: ProductionSchemaStep | MoldingSchemaStep | BurningSchemaStep | PostProcessingSchemaStep
      | DryingSchemaStep | MixingMassesSchemaStep
      = this.productionFiringSchemaService.getEmptySchemaStep(stepType) as
      ProductionSchemaStep | MoldingSchemaStep | BurningSchemaStep | PostProcessingSchemaStep | DryingSchemaStep | MixingMassesSchemaStep;
    step = this.assignValuesToStep(step);
    this.crossFunctionalService.setGatheredData('currentlyEditedStepValues', step);
  }

  /// p-dropdown onChange

  public updateStepTypeValue(): void {
    switch (this.typeControl.value) {
      case ProductionSchemaStepType.PRODUCTION_SCHEMA_STEP:
        this.updateFormGroupAndFlagsForProductionSchemaStep();
        this.enableDurationFormControls();
        break;
      case ProductionSchemaStepType.BURNING_SCHEMA_STEP:
        this.updateFormGroupAndFlagsForBurningSchemaStep();
        this.disableDurationFormControls();
        break;
      case ProductionSchemaStepType.MOLDING_SCHEMA_STEP:
        this.updateFormGroupAndFlagsForMoldingSchemaStep();
        this.enableDurationFormControls();
        break;
      case ProductionSchemaStepType.DRYING_SCHEMA_STEP:
        this.updateFormGroupAndFlagsForDryingSchemaStep();
        this.enableDurationFormControls();
        break;
      case ProductionSchemaStepType.POST_PROCESSING_SCHEMA_STEP:
        this.updateFormGroupAndFlagsForPostProcessingSchemaStep();
        this.enableDurationFormControls();
        break;
      case ProductionSchemaStepType.MIXING_MASSES_SCHEMA_STEP:
        this.updateFormGroupAndFlagsForMixingMassesSchemaStep();
        this.disableDurationFormControls();
        break;
    }
    this.selectedStepType = this.stepTypeSelectOption[this.typeControl.value];
    this.flushWorkspaceGroup();
  }

  private updateFormGroupAndFlagsForProductionSchemaStep(): void {
    this.detachFormControlForBurningSchemaStep();
    this.detachFormControlForPostProcessingSchemaStep();
    this.detachFormControlForMoldingSchemaStep();
    this.detachFormControlForDryingSchemaStep();
    this.detachFormControlsForMixingMassesSchemaStep();
    this.isBurningStepType = false;
    this.isMoldingStepType = false;
    this.isDryingStepType = false;
    this.isMixingMassesSchemaStepType = false;
    this.isPostProcessingStepType = false;
  }

  private updateFormGroupAndFlagsForBurningSchemaStep(): void {
    this.isMoldingStepType = false;
    this.isDryingStepType = false;
    this.isMixingMassesSchemaStepType = false;
    this.isPostProcessingStepType = false;
    this.detachFormControlForMoldingSchemaStep();
    this.detachFormControlForPostProcessingSchemaStep();
    this.detachFormControlForDryingSchemaStep();
    this.detachFormControlsForMixingMassesSchemaStep();
    this.attachFormControlsForBurningSchemaStep();
    this.firingSchemaNameControl.patchValue(this.firingSchema?.name);
    this.isBurningStepType = true;
  }

  private updateFormGroupAndFlagsForMoldingSchemaStep(): void {
    if (!(this.step as MoldingSchemaStep).moldType && !this.moldType &&
      this.crossFunctionalService.isCrossFunctionalActive('passedProductType')) {
      (this.step as MoldingSchemaStep).moldType = this.moldType
        = this.crossFunctionalService.getGatheredData('passedProductType')?.moldType;
    }
    this.isBurningStepType = false;
    this.isDryingStepType = false;
    this.isMixingMassesSchemaStepType = false;
    this.isPostProcessingStepType = false;
    this.detachFormControlForBurningSchemaStep();
    this.detachFormControlForPostProcessingSchemaStep();
    this.detachFormControlForDryingSchemaStep();
    this.detachFormControlsForMixingMassesSchemaStep();
    this.attachFormControlsForMoldingSchemaStep();
    this.moldTypeNameControl.patchValue(this.moldType?.name);
    this.isMoldingStepType = true;

  }

  private updateFormGroupAndFlagsForDryingSchemaStep(): void {
    this.isBurningStepType = false;
    this.isMoldingStepType = false;
    this.isMixingMassesSchemaStepType = false;
    this.isPostProcessingStepType = false;
    this.detachFormControlForBurningSchemaStep();
    this.detachFormControlForPostProcessingSchemaStep();
    this.detachFormControlForMoldingSchemaStep();
    this.attachFormControlsForDryingSchemaStep();
    this.detachFormControlsForMixingMassesSchemaStep();
    this.isDryingStepType = true;
  }

  private updateFormGroupAndFlagsForPostProcessingSchemaStep(): void {
    this.isBurningStepType = false;
    this.isMoldingStepType = false;
    this.isDryingStepType = false;
    this.isMixingMassesSchemaStepType = false;
    this.detachFormControlForMoldingSchemaStep();
    this.detachFormControlForDryingSchemaStep();
    this.detachFormControlForBurningSchemaStep();
    this.detachFormControlsForMixingMassesSchemaStep();
    this.attachFormControlForPostProcessingSchemaStep();
    this.quantityOfMiddleProductsAfterPostProcessingControl.patchValue((this.step as PostProcessingSchemaStep)
      ?.quantityOfMiddleProductsAfterPostProcessing);
    this.isPostProcessingStepType = true;
  }

  private updateFormGroupAndFlagsForMixingMassesSchemaStep(): void {
    this.isBurningStepType = false;
    this.isMoldingStepType = false;
    this.isDryingStepType = false;
    this.isPostProcessingStepType = false;
    this.detachFormControlForBurningSchemaStep();
    this.detachFormControlForPostProcessingSchemaStep();
    this.detachFormControlForMoldingSchemaStep();
    this.detachFormControlForDryingSchemaStep();
    this.attachFormControlsForMixingMassesSchemaStep();
    this.mixingSchemaNameControl.patchValue(this.mixingSchema?.name);
    this.isMixingMassesSchemaStepType = true;
  }

  private flushWorkspaceGroup(): void {
      this.workspaceGroup = null;
      this.step.workspaceGroup = null;
      this.workspaceGroupNameControl.patchValue(null);
  }


  /// FORM_CONTROL_GETTERS
  public get indexControl(): AbstractControl {
    return this.formGroup.get('index');
  }

  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  public get typeControl(): AbstractControl {
    return this.formGroup.get('type');
  }

  public get workspaceGroupNameControl(): AbstractControl {
    return this.formGroup.get('workspaceGroupName');
  }

  public get daysControl(): AbstractControl {
    return this.formGroup.get('days');
  }

  public get hoursControl(): AbstractControl {
    return this.formGroup.get('hours');
  }

  public get minutesControl(): AbstractControl {
    return this.formGroup.get('minutes');
  }

  public get secondsControl(): AbstractControl {
    return this.formGroup.get('seconds');
  }

  public get directLaborRateCostControl(): AbstractControl {
    return this.formGroup.get('directLaborRateCost');
  }


  public get indirectLaborRateCostControl(): AbstractControl {
    return this.formGroup.get('indirectLaborRateCost');
  }


  public get energyCostControl(): AbstractControl {
    return this.formGroup.get('energyCost');
  }


  public get fixedOverheadCostControl(): AbstractControl {
    return this.formGroup.get('fixedOverheadCost');
  }


  public get variableOverheadCostControl(): AbstractControl {
    return this.formGroup.get('variableOverheadCost');
  }

  public get firingSchemaNameControl(): AbstractControl {
    return this.formGroup.get('firingSchemaName');
  }

  public get mixingSchemaNameControl(): AbstractControl {
    return this.formGroup.get('mixingSchemaName');
  }

  public get moldTypeNameControl(): AbstractControl {
    return this.formGroup.get('moldTypeName');
  }

  public get weightReductionControl(): AbstractControl {
    return this.formGroup.get('weightReduction');
  }

  public get quantityOfMiddleProductsAfterPostProcessingControl(): AbstractControl {
    return this.formGroup.get('quantityOfMiddleProductsAfterPostProcessing');
  }

}
