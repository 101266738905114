export enum TranslationKeyEnum {
  CUSTOMERS = 'customers',
  OFFERS = 'offers',
  ORDER_MANAGEMENT = 'order-management',
  RAW_MATERIALS = 'raw-materials',
  PRODUCTS = 'products',
  PRODUCT_QUALITY_CATALOG = 'product-quality-catalog',
  PRODUCTION = 'production',
  WAREHOUSE = 'warehouse',
  ADMIN = 'admin',
  APPLICATION_CONFIG = 'application-config',
  COMMON = 'common',
  SHIPMENT = 'shipment',
  MOBILE = 'mobile'
}
