import {Injectable} from '@angular/core';
import {MixingMachineInstanceCtrl} from '../../core/sdk/controllers-productiondevices';
import {SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {MixingMachineInstance, MixingMachineModel, ProductionDeviceGroup} from '../../core/sdk/model-productiondevices';
import {Utility} from '../utilities/utility';
import {ProductionDeviceInstanceStatus} from '../../core/sdk/enums-statuses';

@Injectable({
  providedIn: 'root'
})
export class MixingMachineInstanceService {

  constructor(private mixingMachineInstanceCtrl: MixingMachineInstanceCtrl) {
  }

  public getNewMixingMachineInstance(mixingMachineModel: MixingMachineModel, group: ProductionDeviceGroup,
                                     status?: ProductionDeviceInstanceStatus): MixingMachineInstance {
    return {
      id: 'mixing-machine-instance/' + Utility.getUUID(),
      version: 0,
      entityVersion: 0,
      deleted: false,
      isDeleted: false,
      timestamp: Date.now(),
      workspaceInstance: null,
      mixingMachineModel,
      group,
      status: status ?? ProductionDeviceInstanceStatus.READY,
      shorthand: ''
    };
  }

  public countMixingMachineInstances(searchCriteriaForClass: SearchCriteriaForClass, mixingMachineModelId: string): Observable<number> {
    return this.mixingMachineInstanceCtrl.countMixingMachineInstances(searchCriteriaForClass, Utility.getObjectId(mixingMachineModelId));
  }

  public getMixingMachineInstances(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                                   mixingMachineModelId: string)
    : Observable<MixingMachineInstance[]> {
    return this.mixingMachineInstanceCtrl.getMixingMachineInstances(searchCriteriaForClass, pageNumber, pageSize,
      Utility.getObjectId(mixingMachineModelId));
  }

  public deleteMixingMachineInstance(machineId: string): Observable<void> {
    return this.mixingMachineInstanceCtrl.deleteObject(Utility.getObjectId(machineId));
  }
}
