import {Component, OnInit} from '@angular/core';
import {TranslateService} from '../translate.service';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html'
})
export class LanguageSelectorComponent implements OnInit {
  authService: AuthService;
  translateService: TranslateService;
  languages: any[];

  constructor(translateService: TranslateService, authService: AuthService) {
    this.translateService = translateService;
    this.authService = authService;
  }

  onSelect(newLanguage: any): void {
    this.authService.getCurrentUser().defaultLanguage = newLanguage.value.locale;
    this.translateService.setLanguage(newLanguage.value);
    this.authService.doUpdateDefaultLanguageForPerson();
    this.translateService.loadTranslationModule('user-self-management');
  }

  ngOnInit(): void {
       this.translateService.translationsLoaded.asObservable().subscribe(() => {
      this.languages = [];
      this.translateService.languages.map(lang => {
          const item = {label: lang.locale, value: lang};
          this.languages.push(item);
        }
      );
    });
  }


  getDefaultLanguage(): string {
    return this.authService.getCurrentUser().defaultLanguage;
  }
}
