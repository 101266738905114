import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'calendarButtonClass',
  pure: false
})
export class CalendarButtonClassPipe implements PipeTransform {

  public transform(flagValue: boolean): string {
    return flagValue ? 'fa fa-arrows-h' : 'fa fa-thumb-tack';
  }


}
