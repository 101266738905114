import {inject, Pipe, PipeTransform} from '@angular/core';
import {EnumService} from '../services/enum.service';

@Pipe({
  name: 'status',
  pure: true
})
export class StatusPipe implements PipeTransform {

  public enumService = inject(EnumService);

  public transform(value: number, enumObject: any, translationModule: any): string {
    return value == null ? (translationModule + '.' + 'not-defined') :
      this.enumService.getEnumValueWithModule(value, enumObject, translationModule);
  }

}
