<div class="global-flex global-justify__space-evenly global-align__items-center">
  <app-base-details-display
    [baseDetailsRecords]="initBaseObjectMainInfoRecords()"
    [detailsTitle]="'production.main-information'"
    [translationModule]="'production'">
  </app-base-details-display>

  <app-base-details-display
    [baseDetailsRecords]="initBaseObjectCostsRecords()"
    [detailsTitle]="'production.costs-information'"
    [translationModule]="'production'">
  </app-base-details-display>
</div>
