import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ProductionDeviceGroup} from '../../../../core/sdk/model-productiondevices';
import {BasePrimengTableDirective} from '../../../base-primeng-table/base-primeng-table.directive';
import {SpinnerService} from '../../../services/spinner.service';
import {PTableControlService} from '../../../services/p-table-control.service';
import {BaseSubNavbarService} from '../../base-sub-navbar/base-sub-navbar.service';
import {SearchCriteriaService} from '../../../../core/search-criteria.service';
import {TableRefreshService} from '../../../services/table-refresh.service';
import {ProductionDeviceService} from '../../../services/production/production-device.service';

@Component({
  selector: 'app-production-device-groups',
  templateUrl: './production-device-groups.component.html',
})
export class ProductionDeviceGroupsComponent extends BasePrimengTableDirective<ProductionDeviceGroup> implements OnInit,
  AfterViewInit, OnDestroy {

  @Input() availableGroupOptions: ProductionDeviceGroup[];
  @Output() assignGroupEmitter: EventEmitter<ProductionDeviceGroup | null> = new EventEmitter<ProductionDeviceGroup | null>();

  deviceGroups: ProductionDeviceGroup[];

  constructor(private productionDeviceService: ProductionDeviceService,
              protected spinnerService: SpinnerService,
              protected pTableControlService: PTableControlService,
              protected baseSubNavbarService: BaseSubNavbarService,
              protected searchCriteriaService: SearchCriteriaService,
              protected tableRefreshService: TableRefreshService) {
    super(searchCriteriaService, tableRefreshService, spinnerService, baseSubNavbarService, pTableControlService);
    this.assignClassName('ProductionDeviceGroup');
    this.assignTableName('productionDeviceGroupTable');
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.availableGroupOptions) {
      this.setTableData(this.availableGroupOptions);
      this.pageable.count = this.availableGroupOptions.length;
      this.spinnerService.deactivateSpinner();
    }
  }

  ngAfterViewInit(): void {
    if (!this.availableGroupOptions) {
      this.refreshTable();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  returnToForm(): void {
    this.assignGroupEmitter.emit(null);
  }

  onSubmit(group: ProductionDeviceGroup): void {
    this.assignGroupEmitter.emit(group);
  }

  doCount(): void {
    this.productionDeviceService.countProductionDeviceGroups(this.searchCriteriaForClass).subscribe(response => {
      this.pageable.count = response;
    });
  }

  doLoad(): void {
    this.productionDeviceService.getProductionDeviceGroups(this.searchCriteriaForClass, this.pageable.pageNumber,
      this.pageable.pageSize).subscribe(response => {
      this.setTableData(response);
      this.spinnerService.deactivateSpinner();
    });
  }

  private setTableData(data: ProductionDeviceGroup[]): void {
    this.deviceGroups = data;
  }

  getTableData(): ProductionDeviceGroup[] {
    return this.deviceGroups;
  }

}
