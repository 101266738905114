import {Component, OnInit} from '@angular/core';
import {NewPasswordService} from './new-password.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/services/notification.service';
import {NotificationMessageType} from '../../shared/enums/NotificationMessageType';
import {SpinnerService} from '../../shared/services/spinner.service';
import {validateMatchingPasswords} from '../../shared/custom-validators/repeat-password-validator';
import {ConstanceValues} from '../../shared/constance-values/constance-values';

@Component({
  selector: 'app-new-password',
  templateUrl: 'new-password.component.html'
})
export class NewPasswordComponent implements OnInit {

  public formGroup: FormGroup;
  private token: string;

  constructor(private newPasswordService: NewPasswordService,
              private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private spinnerService: SpinnerService,
              private router: Router,
              private notificationService: NotificationService) {
  }

  public ngOnInit(): void {
    this.spinnerService.activateSpinner();
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params[`token`];
      this.spinnerService.deactivateSpinner();
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
    this.initFormGroup();
  }

  private initFormGroup(): void {
    const passwordValidator = [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)];
    this.formGroup = this.formBuilder.group({
      newPassword: ['', passwordValidator],
      newPasswordRepeat: ['', passwordValidator],
    }, {validators: validateMatchingPasswords()});
  }

  public onSubmit(): void {
    this.spinnerService.activateSpinner();
    this.newPasswordService.setNewPassword(this.token, this.newPasswordControl.value)
      .subscribe(() => {
        this.spinnerService.deactivateSpinner();
        this.notificationService.displayNotificationToast('common.password-reset-successfully', NotificationMessageType.SUCCESS);
        this.returnToLoginPage();
      }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public returnToLoginPage(): void {
    this.router.navigate(['/login']);
  }

  public get newPasswordControl(): AbstractControl {
    return this.formGroup.get('newPassword');
  }

  public get newPasswordRepeatControl(): AbstractControl {
    return this.formGroup.get('newPasswordRepeat');
  }
}
