import {Injectable} from '@angular/core';
import {MixingSchemaCtrl} from '../../../core/sdk/controllers-mixingschema';
import {MixingSchema, MixingSchemaStep} from '../../../core/sdk/model-mixingschema';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {Utility} from '../../utilities/utility';
import {MixingMachineModel} from '../../../core/sdk/model-productiondevices';
import {SchemaService} from '../schema.service';

@Injectable({
  providedIn: 'root'
})
export class MixingSchemaService {

  private mixingSchemaData: MixingSchema[];
  private mixingSchemaSteps: MixingSchemaStep[] = [];
  private openedMixingSchemaStepIds: string[] = [];
  private newlyAddedMixingSchemaSteps: MixingSchemaStep[] = [];

  constructor(private mixingSchemaCtrl: MixingSchemaCtrl,
              private schemaService: SchemaService) {
  }

  public getNewEmptyMixingSchema(): MixingSchema {
    return {
      id: 'mixing-schema/' + Utility.getUUID(),
      name: '',
      directLaborRateCost: 0,
      energyCost: 0,
      entityVersion: 0,
      fixedOverheadCost: 0,
      indirectLaborRateCost: 0,
      mixingMachineModelList: [],
      mixingSchemaStepList: [],
      timestamp: Date.now(),
      variableOverheadCost: 0,
      version: 0,
      deleted: false,
      isDeleted: false,
    };
  }

  public getMixingSchemaData(): MixingSchema[] {
    return this.mixingSchemaData;
  }

  public setMixingSchemaData(data: MixingSchema[]): void {
    this.mixingSchemaData = data;
  }

  public getMixingSchemas(searchCriteria: SearchCriteriaForClass,
                          pageNumber: number,
                          pageSize: number): Observable<MixingSchema[]> {
    return this.mixingSchemaCtrl.getMixingSchemas(searchCriteria, pageNumber, pageSize);
  }

  public countMixingSchemas(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.mixingSchemaCtrl.countMixingSchemas(searchCriteria);
  }

  public updateMixingSchema(entity: MixingSchema): Observable<MixingSchema> {
    return this.mixingSchemaCtrl.updateObject(entity);
  }

  public getMixingSchema(entityId: string): Observable<MixingSchema> {
    return this.mixingSchemaCtrl.getObject(Utility.getObjectId(entityId));
  }

  public assignMixingMachineModelsToMixingSchema(mixingMachineModelList: MixingMachineModel[],
                                                 mixingSchemaID: string): Observable<MixingSchema> {
    return this.mixingSchemaCtrl.assignMixingMachineModelsToMixingSchema(mixingMachineModelList,
      Utility.getObjectId(mixingSchemaID));
  }

  public unassignMixingMachineModelsToMixingSchema(mixingMachineModelList: MixingMachineModel[],
                                                   mixingSchemaID: string): Observable<MixingSchema> {
    return this.mixingSchemaCtrl.unassignMixingMachineModelsToMixingSchema(mixingMachineModelList,
      Utility.getObjectId(mixingSchemaID));
  }

  public countMixingSchemasWithSpecificMixingMachineModelAssigned(searchCriteria: SearchCriteriaForClass,
                                                                  mixingMachineModelID: string): Observable<number> {
    return this.mixingSchemaCtrl.countMixingSchemasWithSpecificMixingMachineModelAssigned(searchCriteria,
      Utility.getObjectId(mixingMachineModelID));
  }

  public getMixingSchemasWithSpecificMixingMachineModelAssigned(searchCriteria: SearchCriteriaForClass,
                                                                mixingMachineModelID: string,
                                                                pageNumber: number,
                                                                pageSize: number): Observable<MixingSchema[]> {
    return this.mixingSchemaCtrl.getMixingSchemasWithSpecificMixingMachineModelAssigned(searchCriteria,
      Utility.getObjectId(mixingMachineModelID), pageNumber, pageSize);
  }

  // STEPS

  public getNewEmptyMixingSchemaStep(): MixingSchemaStep {
    return {
      id: 'mixing-schema-step/' + Utility.getUUID(),
      actionType: null,
      deleted: false,
      description: '',
      entityVersion: 0,
      index: 0,
      isDeleted: false,
      rawMaterial: null,
      waterAdditionInLiters: 0,
      stepDuration: Utility.convertSecondsToDurationObject(0),
      timestamp: Date.now(),
      tonnage: 0,
      version: 0
    };
  }

  public addNewMixingSchemaStep(mixingStep: MixingSchemaStep): void {
    this.newlyAddedMixingSchemaSteps.push(mixingStep);
    this.mixingSchemaSteps.push(mixingStep);
  }

  public getMixingSchemaSteps(): MixingSchemaStep[] {
    return this.mixingSchemaSteps;
  }

  public tagMixingSchemaStepAsOpened(mixingSchemaStepId: string): void {
    this.openedMixingSchemaStepIds.push(mixingSchemaStepId);
  }

  public tagMixingSchemaStepAsClosed(mixingSchemaStepId: string): void {
    this.openedMixingSchemaStepIds.splice(this.openedMixingSchemaStepIds.indexOf(mixingSchemaStepId), 1);
  }

  public someOfStepsIsOpen(): boolean {
    return this.openedMixingSchemaStepIds.length > 0;
  }

  public clearAllSteps(): void {
    this.mixingSchemaSteps = [];
  }

  public clearListOfOpenedSteps(): void {
    this.openedMixingSchemaStepIds = [];
  }

  public assignMixingSchemaSteps(mixingSchemaSteps: MixingSchemaStep[]): void {
    this.mixingSchemaSteps = mixingSchemaSteps;
    this.mixingSchemaSteps.sort((a, b) => Utility.compareNumbers(a.index, b.index));
  }

  public removeFormMixingSchemaSteps(mixingSchemaStep: MixingSchemaStep): void {
    this.mixingSchemaSteps.splice(this.mixingSchemaSteps.indexOf(mixingSchemaStep), 1);
  }

  public joinStepsFromImportedMixingSchemaTemplate(steps: MixingSchemaStep[]): void {
    if (!this.mixingSchemaSteps) {
      this.assignMixingSchemaSteps(steps);
    } else {
      if (steps && steps.length) {
        const temporaryData = this.mixingSchemaSteps;
        (temporaryData as MixingSchemaStep[]).push(...steps as MixingSchemaStep[]);
        this.assignMixingSchemaSteps(temporaryData);
        this.updateIndexAndPropertiesInSchemaSteps();
      }
    }
  }

  public isItNewStep(mixingSchemaStep: MixingSchemaStep): boolean {
    return this.newlyAddedMixingSchemaSteps.findIndex(step => step.id === mixingSchemaStep.id) !== -1;
  }

  public updateIndexAndPropertiesInSchemaSteps(): void {
    if (this.mixingSchemaSteps) {
      this.mixingSchemaSteps.forEach((item, index) => {
        this.mixingSchemaSteps[index].index = index + 1;
      });
    }
  }

  public changeIndexHigher(step: MixingSchemaStep): void {
    this.mixingSchemaSteps = this.schemaService.changeIndexHigher(step, this.mixingSchemaSteps);
  }

  public changeIndexLower(step: MixingSchemaStep): void {
    this.mixingSchemaSteps = this.schemaService.changeIndexLower(step, this.mixingSchemaSteps);
  }

  public flushNewlyAddedMixingSchemaSteps(): void {
    this.newlyAddedMixingSchemaSteps = [];
  }

}
