<div class="production-log-tile">

  <div class="production-log-tile__record">
    <span
      class="production-log-tile__record__name"
    >{{translationModule + '.' + 'created-at' | translate}}</span>
    <p
      class="production-log-tile__record__value"
    >{{this.convertTimestampToStringDate(this.productionLog.timestamp)}}</p>
  </div>


  <div class="production-log-tile__record">
      <span
        class="production-log-tile__record__name"
      >{{translationModule + '.' + 'performing-person' | translate}}</span>
    <p
      class="production-log-tile__record__value"
    >{{this.productionLog.performingPerson.name}}</p>
  </div>


  <div class="production-log-tile__record">
    <span
      class="production-log-tile__record__name"
    >{{translationModule + '.' + 'action-type' | translate}}</span>
    <p
      class="production-log-tile__record__value"
    >{{this.displayLogActionTypeEnum(this.productionLog.actionType) | translate}}</p>
  </div>

  <div
    *ngIf="logsForHomeProduction"
    class="production-log-tile__record">
    <span
      class="production-log-tile__record__name"
    >{{translationModule + '.' + 'order-id' | translate}}</span>
    <p
      class="production-log-tile__record__value"
    >{{this.productionLog.order.shorthand}}</p>
  </div>

  <div
    *ngIf="productionLog.processStep?.productionBookedProduct"
    class="production-log-tile__record">
    <span
      class="production-log-tile__record__name"
    >{{translationModule + '.' + 'order-product-position' | translate}}</span>
    <p
      class="production-log-tile__record__value"
    >{{this.productionLog.processStep.productionBookedProduct.orderProduct.position}}</p>
  </div>

  <div
    *ngIf="productionLog.processStep"
    class="production-log-tile__record">
    <span
      class="production-log-tile__record__name"
    >{{translationModule + '.' + 'process-step-index-booking-series' | translate}}</span>
    <p
      class="production-log-tile__record__value"
    >{{this.productionLog.processStep.stepIndex + ' / ' + this.productionLog.processStep.bookingSeries}}</p>
  </div>

  <div
    *ngIf="productionLog.processStep"
    class="production-log-tile__record">
    <span
      class="production-log-tile__record__name"
    >{{translationModule + '.' + 'task-name' | translate}}</span>
    <p
      class="production-log-tile__record__value"
    >{{this.productionLog.processStep.taskName}}</p>
  </div>

  <div
    *ngIf="productionLog.quantity"
    class="production-log-tile__record">
    <span
      class="production-log-tile__record__name"
    >{{translationModule + '.' + 'quantity' | translate}}</span>
    <p
      class="production-log-tile__record__value"
    >{{this.productionLog.quantity}}</p>
  </div>


</div>
