<div class="custom-filtering-fields">
  <div
    *ngIf="tableNameForResetFilters"
    class="global-align--self-end">
    <button
      type="button"
      class="global-button"
      (click)="resetCustomFilters()"
    >{{ (translationModule + '.reset-filters') | translate }}
    </button>
  </div>

  <div class="global-flex">
    <div
      *ngFor="let field of filterFields"
      class="custom-filtering-fields__field"
      [ngClass]="field === sortingField ?  'global-borders--sorting' : 'global-borders--transparent'">
      <div class="custom-filtering-fields__field__label-button">
        <label
        >{{ (translationModule + '.' + field.label) | translate }}</label>
        <button
          (click)="sortBy(field)"
          class="button button__submit-button button__submit-button--background-color button__submit-button--color">
          <ng-container
            *ngIf="isSortingAscending && field === sortingField; then ascendingIcon; else descendingIcon">
          </ng-container>
          <ng-template #ascendingIcon>
            <i class="pi pi-sort-amount-down"></i>
          </ng-template>
          <ng-template #descendingIcon>
            <i class="pi pi-sort-amount-up-alt"></i>
          </ng-template>
        </button>
      </div>

      <div class="custom-filtering-fields__field__input">
        <ng-container [ngSwitch]="field.type">

          <input
            *ngSwitchCase="'string'"
            appStringLengthInputValidation
            type="text"
            placeholder="{{(translationModule + '.search-by-' + field.label) | translate }}"
            class="global-input primeng-table__filter-col"
            [(ngModel)]="field.value"
            (keydown.enter)="filterBy(field, $event)">

          <div
            *ngSwitchCase="'number'"
            class="global-flex">
            <ng-template
              [ngIf]="columnOperationSignMap.get(field.classProperty)?.operationSign !== OperationSign.RANGE_SIGN.toString()">
              <input
                appIntegerInputValidation
                type="number"
                placeholder="{{(translationModule + '.search-by-' + field.label) | translate }}"
                class="global-input primeng-table__filter-col"
                [(ngModel)]="field.value"
                (keydown.enter)="filterBy(field, $event)">
            </ng-template>
            <app-custom-operation-sign-filter
              [fieldName]="field.classProperty"
              [columnOperationSignMap]="columnOperationSignMap"
              [operationSigns]="options"
              (rangeSignEmitter)="filterBy(field, $event)"
              (operationSignEmitter)="changeOperationSign(field, $event)"
            ></app-custom-operation-sign-filter>
          </div>

          <p-calendar
            *ngSwitchCase="'date'"
            appendTo="body"
            dateFormat="dd.mm.yy"
            [showButtonBar]="true"
            [readonlyInput]="true"
            [showIcon]="true"
            [clearButtonStyleClass]="'display--false'"
            [showOnFocus]="false"
            [inputStyleClass]="'global-input'"
            placeholder="{{ (translationModule +'.search-by-'+ field.label) | translate }}"
            selectionMode="{{ calendarSelectionModeMap.get(field.classProperty) | calendarSelectionMode}}"
            [ngModel]="calendarSelectionModeMap.get(field.classProperty) ? rangeDateMap.get(field.classProperty) : field.value"
            (ngModelChange)="!calendarSelectionModeMap.get(field.classProperty) ? setCalendarValueForField($event, field) : assignDateRange($event, field.classProperty)"
            (onSelect)="!calendarSelectionModeMap.get(field.classProperty) ?  filterBy(field, $event) : null">
            <ng-template pTemplate="footer">
              <div class="global-flex global-align__items-center global-justify__space-between global-margin-top__1rem">
                <button
                  type="button"
                  class="global-button global-height--fit-content global-margin--none"
                  (click)="toggleRangeSelectionModeEmitter.emit(field)">
                  <i [ngClass]="!calendarSelectionModeMap.get(field.classProperty) | calendarButtonClass"></i>
                </button>
                <button
                  *ngIf="calendarSelectionModeMap.get(field.classProperty)"
                  type="button"
                  class="global-button global-margin--none"
                  [disabled]=" rangeDateMap.get(field.classProperty).isEmpty() || rangeDateMap.get(field.classProperty)?.includes(null)"
                  (click)="filterBy(field, rangeDateMap.get(field.classProperty))"
                >{{ 'common.search' | translate }}
                </button>
              </div>
              <span
                class="global-text-align__center global-margin-top__1rem"
              >{{ calendarSelectionModeMap.get(field.classProperty) | calendarButtonLabel | translate }}</span>
            </ng-template>
          </p-calendar>
          <div
            *ngSwitchDefault><label
          >{{ 'common.filtering-for-this-type-of-object-is-not-supported' | translate }}</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

</div>

