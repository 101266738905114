import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {environment} from '../../../environments/environment';

@NgModule({
  declarations: [
    environment.translatePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    environment.translatePipe,
  ],
  providers: [
  ]
})
export class TranslateModule {}
