<ng-template [ngIf]="!showDetailsView && !showUpdateForm">
  <div
    *ngIf="showFilteringRow"
    class="global-flex global-justify__center no-print">
    <app-norm-type-filtering-option
      [test]="selectedFilteringNormTypeOption"
      (selectedOptionEmitter)="handleNormTypeChangeEvent($event)">
    </app-norm-type-filtering-option>
  </div>

  <div
    *ngIf="showFilteringRow && displayAsMainTable"
    class="global-flex global-justify__center global-text-align__center">
    <div
      class="global-width--65vw">
    <span
      style="color: #fbdeb2; font-size: 1.75rem">{{
        'products.please-note-without-selecting-a-specific-norm-type-from-the-dropdown-it-is-possible-to-filter-by-common-fields-of-every-product-quality-norm-in-the-application-' +
        ' after-selecting-a-particular-kind-of-norm-results-are-shown-only-for-the-chosen-norm-type'
      }}</span>
    </div>
  </div>


  <p-table
    class="main-table-header"
    dataKey="id"
    responsiveLayout="scroll"
    [value]="getTableData()"
    [paginator]="!shouldExportBeApplied"
    [lazy]="true"
    [rowHover]="true"
    [rows]="pageable.pageSize"
    [totalRecords]="pageable.count"
    [(first)]="pageable.first"
    (onPage)="paginate($event)">

    <ng-template pTemplate="header">
      <tr>
        <th
          *ngIf="displayAsMainTable"
          class="primeng-table__search-col no-print"></th>
        <th
          pSortableColumn="normType"
          (click)="sortByColumn('normType')"
        >{{ 'products.norm-type' | translate }}
          <p-sortIcon class="no-print" field="normType"></p-sortIcon>
        </th>
        <th
          pSortableColumn="dinNorm"
          (click)="sortByColumn('dinNorm')"
        >{{ 'products.din-norm' | translate }}
          <p-sortIcon class="no-print" field="dinNorm"></p-sortIcon>
        </th>
        <ng-template [ngIf]="selectedFilteringNormTypeOption?.value === ProductQualityNormType.STRENGTH_TEST">
          <th
            pSortableColumn="temperaturePrefix"
            (click)="sortByColumn('temperaturePrefix')"
          >{{ 'products.temperature-prefix' | translate }}
            <p-sortIcon class="no-print" field="temperaturePrefix"></p-sortIcon>
          </th>
          <th
            pSortableColumn="type"
            (click)="sortByColumn('type')"
          >{{ 'products.strength-norm-type' | translate }}
            <p-sortIcon class="no-print" field="type"></p-sortIcon>
          </th>
        </ng-template>
        <ng-template [ngIf]="(selectedFilteringNormTypeOption?.value === ProductQualityNormType.THERMAL_EXPANSION) ||
                             (selectedFilteringNormTypeOption?.value === ProductQualityNormType.CHANGE_IN_LENGTH)">
          <th
            pSortableColumn="progressionType"
            (click)="sortByColumn('progressionType')"
          >{{ 'products.progression-type' | translate }}
            <p-sortIcon class="no-print" field="progressionType"></p-sortIcon>
          </th>
          <th
            pSortableColumn="reversibilityType"
            (click)="sortByColumn('reversibilityType')"
          >{{ 'products.reversibility-type' | translate }}
            <p-sortIcon class="no-print" field="reversibilityType"></p-sortIcon>
          </th>
        </ng-template>
        <ng-template [ngIf]="displayAsMainTable">
          <th
            pSortableColumn="name"
            (click)="sortByColumn('name', false, 'productQuality', 'ProductQuality')"
          >{{ 'products.product-quality' | translate }}
            <p-sortIcon class="no-print" field="name"></p-sortIcon>
          </th>
          <th
            pSortableColumn="dateOfPerformingNormCheck"
            (click)="sortByColumn('dateOfPerformingNormCheck')"
          >{{ 'products.date-of-performing-norm-check' | translate }}
            <p-sortIcon class="no-print" field="dateOfPerformingNormCheck"></p-sortIcon>
          </th>
          <th
            pSortableColumn="timestamp"
            (click)="sortByColumn('timestamp')"
          >{{ 'products.last-update' | translate }}
            <p-sortIcon class="no-print" field="timestamp"></p-sortIcon>
          </th>
        </ng-template>
        <th
          *ngIf="!displayAsMainTable"
        >{{ 'products.action' }}
        </th>
      </tr>

      <tr
        *ngIf="displayAsMainTable ? showFilteringRow : true"
        class="primeng-table__search-row no-print">
        <th
          *ngIf="displayAsMainTable"
          class="primeng-table__search-col">
          <i class="primeng-table__search-icon"></i>
        </th>
        <ng-template [ngIf]="!selectedFilteringNormTypeOption">
          <td>
            <p-dropdown
              name="size-change-type-select"
              class="user-role__action-container__add-role__buttons__select"
              [options]="getAvailableProductQualityNormTypeOptions()"
              [placeholder]="'products.norm-type' | translate"
              [(ngModel)]="normTypeFilterFieldValue"
              (onChange)="searchByEnum($event, 'normType')">
            </p-dropdown>
          </td>
        </ng-template>
        <ng-template [ngIf]="selectedFilteringNormTypeOption">
          <td>
            <span class="global-font-weight--bold"
            >{{ 'products.type-already-selected' | translate }}</span>
          </td>
        </ng-template>
        <td>
          <input
            appStringLengthInputValidation
            type="text"
            class="global-input primeng-table__filter-col"
            placeholder="{{ 'products.search-by-din-norm' | translate }}"
            [(ngModel)]="dinNormFilterFieldValue"
            (keydown.enter)="searchByString($event, 'dinNorm')">
        </td>
        <ng-template [ngIf]="selectedFilteringNormTypeOption?.value === ProductQualityNormType.STRENGTH_TEST">
          <td>
            <p-dropdown
              name="size-change-type-select"
              class="user-role__action-container__add-role__buttons__select"
              [options]="getAvailableTemperaturePrefixOptions()"
              [placeholder]="'products.temperature-prefix' | translate"
              [(ngModel)]="temperaturePrefixFilterFieldValue"
              (onChange)="searchByEnum($event, 'temperaturePrefix')">
            </p-dropdown>
          </td>
          <td>
            <p-dropdown
              name="size-change-type-select"
              class="user-role__action-container__add-role__buttons__select"
              [options]="getAvailableStrengthNormTypeOptions()"
              [placeholder]="'products.strength-norm-type' | translate"
              [(ngModel)]="typeFilterFieldValue"
              (onChange)="searchByEnum($event, 'type')">
            </p-dropdown>
          </td>
        </ng-template>
        <ng-template [ngIf]="(selectedFilteringNormTypeOption?.value === ProductQualityNormType.THERMAL_EXPANSION) ||
                             (selectedFilteringNormTypeOption?.value === ProductQualityNormType.CHANGE_IN_LENGTH)">
          <td>
            <p-dropdown
              name="size-change-type-select"
              class="user-role__action-container__add-role__buttons__select"
              [options]="getAvailableProgressionTypeOptions()"
              [placeholder]="'products.progression-type' | translate"
              [(ngModel)]="progressionTypeFieldValue"
              (onChange)="searchByEnum($event, 'progressionType')">
            </p-dropdown>
          </td>
          <td>
            <p-dropdown
              name="size-change-type-select"
              class="user-role__action-container__add-role__buttons__select"
              [options]="getAvailableReversibilityTypeOptions()"
              [placeholder]="'products.reversibility-type' | translate"
              [(ngModel)]="reversibilityTypeFieldValue"
              (onChange)="searchByEnum($event, 'reversibilityType')">
            </p-dropdown>
          </td>
        </ng-template>
        <ng-template [ngIf]="displayAsMainTable">
          <td>
            <input
              appStringLengthInputValidation
              type="text"
              class="global-input primeng-table__filter-col"
              placeholder="{{ 'products.product-quality-name' | translate }}"
              [(ngModel)]="productQualityFieldValue"
              (keydown.enter)="searchByString($event, 'name', 'productQuality', 'ProductQuality')">
          </td>
          <td>
            <p-calendar
              appendTo="body"
              dateFormat="dd.mm.yy"
              class="primeng-table__calendar-filter-col"
              placeholder="{{ 'products.date-of-performing-norm-check' | translate }}"
              selectionMode="{{ calendarSelectionModeMap.get('dateOfPerformingNormCheck') | calendarSelectionMode}}"
              [ngModel]="calendarSelectionModeMap.get('dateOfPerformingNormCheck') ? rangeDateMap.get('dateOfPerformingNormCheck') : ''"
              (ngModelChange)="assignRangeDateValue($event, 'dateOfPerformingNormCheck')"
              [showButtonBar]="true"
              [readonlyInput]="true"
              [showIcon]="true"
              [showOnFocus]="false"
              [clearButtonStyleClass]="'display--false'"
              [inputStyleClass]="'global-input'"
              [styleClass]="'global-width--100'"
              (onSelect)="!calendarSelectionModeMap.get('dateOfPerformingNormCheck') ? searchByDate($event, 'dateOfPerformingNormCheck') : null">
              <ng-template pTemplate="footer">
                <div
                  class="global-flex global-align__items-center global-justify__space-between global-margin-top__1rem">
                  <button
                    type="button"
                    class="global-button global-height--fit-content global-margin--none"
                    (click)="toggleRangeSelectionModeApplied('dateOfPerformingNormCheck')">
                    <i [ngClass]="!calendarSelectionModeMap.get('dateOfPerformingNormCheck') | calendarButtonClass"></i>
                  </button>
                  <button
                    *ngIf="calendarSelectionModeMap.get('dateOfPerformingNormCheck')"
                    type="button"
                    class="global-button global-margin--none"
                    [disabled]=" rangeDateMap.get('dateOfPerformingNormCheck').isEmpty() || rangeDateMap.get('dateOfPerformingNormCheck').includes(null)"
                    (click)="searchByDate(rangeDateMap.get('dateOfPerformingNormCheck'), 'dateOfPerformingNormCheck')"
                  >{{ 'common.search' | translate }}
                  </button>
                </div>
                <span
                  class="global-text-align__center global-margin-top__1rem"
                >{{ calendarSelectionModeMap.get('creationDate') | calendarButtonLabel | translate }}</span>
              </ng-template>
            </p-calendar>
          </td>
          <td>
            <p-calendar
              appendTo="body"
              dateFormat="dd.mm.yy"
              class="primeng-table__calendar-filter-col"
              placeholder="{{ 'products.last-update-date' | translate }}"
              selectionMode="{{ calendarSelectionModeMap.get('timestamp') | calendarSelectionMode}}"
              [ngModel]="calendarSelectionModeMap.get('timestamp') ? rangeDateMap.get('timestamp') : ''"
              (ngModelChange)="assignRangeDateValue($event, 'timestamp')"
              [showButtonBar]="true"
              [readonlyInput]="true"
              [showIcon]="true"
              [showOnFocus]="false"
              [clearButtonStyleClass]="'display--false'"
              [inputStyleClass]="'global-input'"
              [styleClass]="'global-width--100'"
              (onSelect)="!calendarSelectionModeMap.get('timestamp') ? searchByDate($event, 'timestamp') : null">
              <ng-template pTemplate="footer">
                <div
                  class="global-flex global-align__items-center global-justify__space-between global-margin-top__1rem">
                  <button
                    type="button"
                    class="global-button global-height--fit-content global-margin--none"
                    (click)="toggleRangeSelectionModeApplied('timestamp')">
                    <i [ngClass]="!calendarSelectionModeMap.get('timestamp') | calendarButtonClass"></i>
                  </button>
                  <button
                    *ngIf="calendarSelectionModeMap.get('timestamp')"
                    type="button"
                    class="global-button global-margin--none"
                    [disabled]=" rangeDateMap.get('timestamp').isEmpty() || rangeDateMap.get('timestamp').includes(null)"
                    (click)="searchByDate(rangeDateMap.get('timestamp'), 'timestamp')"
                  >{{ 'common.search' | translate }}
                  </button>
                </div>
                <span
                  class="global-text-align__center global-margin-top__1rem"
                >{{ calendarSelectionModeMap.get('creationDate') | calendarButtonLabel | translate }}</span>
              </ng-template>
            </p-calendar>
          </td>
        </ng-template>
        <td
          *ngIf="!displayAsMainTable">
          <button
            type="button"
            class="global-button"
            (click)="toggleShowFilteringRowFlag()"
          >{{
              !showFilteringRow ? ('products.show-specific-norm-type-filters' | translate) :
                ('products.hide-specific-norm-type-filters' | translate)
            }}
          </button>
          <button
            type="button"
            class="global-button"
            (click)="resetFilters()"
          >{{ 'products.reset-filters' | translate }}
          </button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td
          [colSpan]="getProperColSpanValue()"
        >{{ 'common.no-records-available' | translate }}
        </td>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-productQualityNorm
      let-expanded="expanded">
      <tr
        class="global-pointer"
        [pRowToggler]="productQualityNorm">
        <td
          *ngIf="displayAsMainTable"
          class="no-print">
          <i class="primeng-table__angle-down"
             [ngClass]="expanded ? 'pi pi-angle-down' : 'pi pi-angle-right'">
          </i>
        </td>
        <td>{{ productQualityNorm.normType | status: ProductQualityNormType : TranslationKeyEnum.PRODUCTS }}</td>
        <td>{{ productQualityNorm.dinNorm }}</td>
        <ng-template [ngIf]="selectedFilteringNormTypeOption?.value === ProductQualityNormType.STRENGTH_TEST">
          <td>{{ productQualityNorm.temperaturePrefix | status: StrengthNormTemperaturePrefix: TranslationKeyEnum.PRODUCTS }}</td>
          <td>{{ productQualityNorm.type | status: StrengthNormType: TranslationKeyEnum.PRODUCTS }}</td>
        </ng-template>
        <ng-template [ngIf]="(selectedFilteringNormTypeOption?.value === ProductQualityNormType.THERMAL_EXPANSION) ||
                             (selectedFilteringNormTypeOption?.value === ProductQualityNormType.CHANGE_IN_LENGTH)">
          <td>{{ productQualityNorm.progressionType | status : ProductQualityNormProgressionType: TranslationKeyEnum.PRODUCTS }}</td>
          <td>{{ productQualityNorm.reversibilityType | status : ProductQualityNormReversibilityType: TranslationKeyEnum.PRODUCTS }}</td>
        </ng-template>
        <ng-template [ngIf]="displayAsMainTable">
          <td>{{ productQualityNorm.productQuality.name }}</td>
          <td>{{ productQualityNorm.dateOfPerformingNormCheck ? displayDate(productQualityNorm.dateOfPerformingNormCheck) : ('products.not-defined'| translate) }}</td>
          <td>{{ displayDateInProperFormat(productQualityNorm.timestamp) }}</td>
        </ng-template>
        <td
          *ngIf="!displayAsMainTable">
          <button
            type="button"
            class="global-button"
            (click)="showView(productQualityNorm, true)"
          >{{ 'products.see-details' | translate }}
          </button>
          <button
            type="button"
            class="global-button"
            (click)="showView(productQualityNorm, false)"
          >{{ 'products.edit' | translate }}
          </button>
        </td>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="rowexpansion"
      let-productQualityNorm>
      <tr
        *ngIf="displayAsMainTable">
        <td
          [colSpan]="getProperColSpanValue()"
          class="primeng-table__edit--tab-padding primeng-table__edit--tab--border-top">
          <div class="primeng-table__edit--tab-component">
            <app-tabs
              (click)="clearTabToBeActivated()"
              [tabsMap]="getTabsMap()"
              [defaultActiveTab]="tabToBeActivate">
              <app-tab
                [tabTitle]="('product-quality-catalog.details')"
                [active]="true"
                [dataBindMap]="bindData(productQualityNorm)">
              </app-tab>
            </app-tabs>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<ng-template [ngIf]="showDetailsView && !showUpdateForm">
  <app-product-quality-norm-details
    [productQualityNorm]="selectedNorm"
    (closeViewEmitter)="closeView(true)"
  ></app-product-quality-norm-details>
</ng-template>

<ng-template [ngIf]="showUpdateForm && !showDetailsView">
  <app-product-quality-norm-form
    [productQuality]="productQuality"
    [productQualityNorm]="selectedNorm"
    [updateModeApplied]="true"
    (closeViewEmitter)="closeView(false)">
  </app-product-quality-norm-form>
</ng-template>
