<div class="user-decision-modal">
  <span class="user-decision-modal__title">{{(title | translate) + ' ?'}}</span>
  <span class="user-decision-modal__content">{{modalContentInfo | translate}}</span>
  <div class="user-decision-modal__buttons-container">
    <button
      type="button"
      class="global-button"
      (click)="rejectDecision()"
    >{{'common.no' | translate}}</button>
    <button
      type="button"
      class="global-button"
      (click)="acceptDecision()"
    >{{'common.yes' | translate}}</button>
  </div>

</div>
<div class="spinner-background"></div>

