import {Injectable} from '@angular/core';
import {ProductionSchemaStep} from '../../core/sdk/model-productionschema';
import {FiringSchemaStep} from '../../core/sdk/model-firingschema';
import {MixingSchemaStep} from '../../core/sdk/model-mixingschema';
import {NotificationMessageType} from '../enums/NotificationMessageType';
import {NotificationService} from './notification.service';
import {Utility} from '../utilities/utility';
import {Subject} from 'rxjs';
import {ServiceSchemaStep} from '../../core/sdk/model-productforservice';


@Injectable({
  providedIn: 'root'
})
export class SchemaService {

  private reloadSummaryStepDurationSource = new Subject<any[]>();

  public reloadSummaryStepDurationSourceContent = this.reloadSummaryStepDurationSource.asObservable();

  constructor(private notificationService: NotificationService) {
  }

  public reloadSummaryStepsDuration(steps: any): void {
    this.reloadSummaryStepDurationSource.next(steps);
  }

  public transformSeconds(seconds: number): { hours: number, minutes: number, seconds: number, days: number } {
    return Utility.convertSecondsToDurationObject(seconds);
  }

  public getAllStepsTotalDuration(stepList: ProductionSchemaStep[] | FiringSchemaStep[]
    | MixingSchemaStep[] | ServiceSchemaStep[]): number {
    let duration = 0;
    stepList?.forEach(step => duration += step.stepDuration.seconds);
    return duration;
  }

  public changeIndexHigher(step: ProductionSchemaStep | FiringSchemaStep | MixingSchemaStep, steps: any[]): any[] {
    const stepIndex = this.findStepIndexInArray(step, steps);
    if (stepIndex === 0) {
      this.notificationService.displayNotificationToast('common.step-is-already-first', NotificationMessageType.ERROR);
    } else {
      steps[stepIndex] = steps[stepIndex - 1];
      steps[stepIndex - 1] = step;
    }
    return steps;
  }

  public changeIndexLower(step: ProductionSchemaStep | FiringSchemaStep | MixingSchemaStep, steps: any[]): any[] {
    const stepIndex = this.findStepIndexInArray(step, steps);
    if (stepIndex === steps.length - 1) {
      this.notificationService.displayNotificationToast('common.step-is-already-last', NotificationMessageType.ERROR);
    } else {
      steps[stepIndex] = steps[stepIndex + 1];
      steps[stepIndex + 1] = step;
    }
    return steps;
  }

  public findStepIndexInArray(step: ProductionSchemaStep | FiringSchemaStep | MixingSchemaStep, steps: any[]): number {
    return steps ? steps.findIndex(item => item.id === step.id) : -1;
  }

}
