<div class="schemas__list__record schemas__list__record--half-round-borders">
  <div class="schemas__list__record__step-column schemas__list__record__step-column--width-42">
    <label
      class="schemas__list__record__step-column__box-label">{{template.name}}</label>
    <ng-container
      *ngIf="template && schemaService.transformSeconds(schemaService.getAllStepsTotalDuration(getTemplateSteps(template))) as stepDuration">
      <label
        class="global-subtitle global-flex-wrap">{{'schemas.summary-steps-duration' |translate}}</label>
      <label>{{transformDurationObjectToString(stepDuration)}}</label>
    </ng-container>
  </div>
  <div class="schemas__list__record__detail-column schemas__list__record__detail-column--width-42">
    <label>{{'schemas.description' | translate}}</label>
    <p>{{template.description && !template.description.isEmpty() ? template.description : ('common.not-defined' | translate)}}</p>
  </div>
  <button
    class="button button__submit-button button__submit-button--color button__submit-button--background-color
    button__submit-button--fit-content global-align--self-center global-height--max-content"
    type="button"
    (click)="cloneTemplate()">{{'schemas.clone-template' | translate}}</button>
  <div class="schemas__list__record__action-column">
    <button class="schemas__list__record__action-column__edit-button"
            (click)="editTemplate()"><i class="pi pi-pencil"></i></button>
    <button class="schemas__list__record__action-column__remove-button"
            (click)="removeProductionSchemaTemplate()"><i class="pi pi-trash"></i></button>
  </div>
</div>

