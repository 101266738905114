import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BaseFormField} from '../components/base-form/base-form-field';

@Injectable({
  providedIn: 'root'
})
export class FormControlService<T> {

  public toFormGroup(formFields: BaseFormField<T>[]): FormGroup {
    const group: any = {};

    formFields.forEach(formField => {
      group[formField.key] = this.createFormControl(formField);
    });
    return new FormGroup(group);
  }

  private createFormControl(formField: BaseFormField<T>): FormControl {
    return new FormControl(this.checkIfUndefined(formField.value) ? '' : formField.value, formField.validators);
  }

  private checkIfUndefined(value: any): boolean {
    return value === undefined;
  }
}
