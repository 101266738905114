<ng-container
  *ngIf="showWorkspaceInstanceTableForSelectedWorkspaceGroupFlag then workspaceInstanceForWorkspaceGroupTable else workspaceGroupsTable"></ng-container>

<ng-template #workspaceGroupsTable>
  <div
    class="global-flex global-justify__flex-end">
    <button
      type="button"
      class="common-button"
      (click)="resetFilters()"
    >{{'production.reset-filters' | translate}}</button>
    <button
      *ngIf="!showAssignButtonForWorkspaceInstance"
      type="button"
      class="common-button"
      (click)="addNewWorkspaceGroup()"
    >{{'production.add-new-workspace-group' | translate}}</button>
    <button
      *ngIf="showAssignButtonForWorkspaceInstance"
      type="button"
      class="common-button"
      (click)="closeComponentEmitter.emit()"
    >{{'common.return'| translate}}</button>
  </div>

  <p-table
    class="main-table-header"
    responsiveLayout="scroll"
    rowExpandMode="single"
    dataKey="id"
    [value]="getTableData()"
    (onPage)="paginate($event)"
    [paginator]="!shouldExportBeApplied"
    [lazy]="true"
    [rowHover]="true"
    [(first)]="pageable.pageNumber"
    [rows]="pageable.pageSize"
    [totalRecords]="pageable.count">

    <ng-template pTemplate="header">
      <tr>
        <th>{{'production.workspace-group-name' | translate}}</th>
        <th>{{'production.type' | translate}}</th>
        <th>{{'common.action' | translate}}</th>
      </tr>

      <tr
        class="primeng-table__search-row">
        <td>
          <input
            appStringLengthInputValidation
            type="text"
            placeholder="{{ 'production.search-by-workspace-group-name' | translate }}"
            class="global-input primeng-table__filter-col"
            [(ngModel)]="workspaceGroupNameFieldValue"
            (keydown.enter)="searchByString($event, 'name')">
        </td>
        <td>
          <p-dropdown
            *ngIf="availableWorkspaceGroupTypeOptions"
            class="user-role__action-container__add-role__buttons__select"
            name="role-select"
            placeholder="{{'production.select-type' | translate}}"
            (onChange)="searchByEnum($event, 'type')"
            [(ngModel)]="selectedWorkspaceGroupType"
            [options]="getAvailableWorkspaceGroupTypeOptions()">
            <ng-template
              let-item
              pTemplate="selectedItem"
            >{{item.label | translate}}
            </ng-template>
            <ng-template
              let-item
              pTemplate="item"
            >{{item.label | translate}}
            </ng-template>
          </p-dropdown>
        </td>
        <td></td>
      </tr>

    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3">
          {{'common.no-records-available' | translate}}
        </td>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-workspaceGroup
      class="global-pointer">
      <tr
        [pRowToggler]="workspaceGroup">
        <td>{{workspaceGroup.name}}</td>
        <td>{{workspaceGroup.type !== null ? displayWorkspaceGroupType(workspaceGroup.type) : ('production.not-assigned' | translate)}}</td>
        <td
          *ngIf="showAssignButtonForWorkspaceInstance">
          <button
            type="button"
            class="common-button"
            (click)="checkWorkspaceInstancesForWorkspaceGroup(workspaceGroup)"
          >{{'production.check-instances'| translate}}</button>
        </td>
        <td
          *ngIf="!showAssignButtonForWorkspaceInstance">
          <button
            type="button"
            class="common-button"
            (click)="checkWorkspaceGroupDetails(workspaceGroup)"
          >{{'production.check-workspace-group-details' | translate}}</button>
          <button
            type="button"
            class="common-button"
            (click)="unAssignWorkspaceGroup(workspaceGroup)"
          >{{'production.unassign-workspace-group' | translate}}</button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<ng-template #workspaceInstanceForWorkspaceGroupTable>
  <app-workspace-instances-for-workspace-group
    [showAssignButton]="showAssignButtonForWorkspaceInstance"
    [selectedWorkspaceGroup]="currentlySelectedWorkspaceGroup"
    (chosenWorkspaceInstanceEmitter)="emitChosenWorkspaceInstanceToAssignForProductionDeviceInstance($event)"
    (closeComponentEmitter)="toggleShowWorkspaceInstanceTableForSelectedWorkspaceGroupFlag()">
  </app-workspace-instances-for-workspace-group>
</ng-template>



