<button
  *ngIf="showCloseButton"
  type="button"
  class="print-modal__close-button pi pi-times"
  (click)="closeModal()"
></button>
<div
  class="printable print-modal__container"
  [ngClass]="{'important-padding-top-60px': showCloseButton, 'global-margin--none': showCloseButton}">
  <ng-template appInsertion></ng-template>
</div>
