import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Person} from '../../../core/sdk/bighero-model';

@Component({
  selector: 'app-production-planning-person-table',
  templateUrl: './production-planning-person-table.component.html',
})
export class ProductionPlanningPersonTableComponent {

  @Input() public persons: Person[];
  @Input() public applyAssigning: boolean;
  @Output() public chosenWorkerEmitter: EventEmitter<Person> = new EventEmitter<Person>();
  @Output() public closeViewEmitter: EventEmitter<void> = new EventEmitter<void>();
}
