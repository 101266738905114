import {Injectable} from '@angular/core';
import {TranslateService} from '../../../core/translations/translate.service';
import {NotificationService} from '../notification.service';
import {Utility} from '../../utilities/utility';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {Observable} from 'rxjs';
import {DeclarationRequest} from '../../../core/sdk/model-declarations';
import {DeclarationRequestCtrl} from '../../../core/sdk/controllers-declarations';

@Injectable({
  providedIn: 'root'
})
export class DeclarationRequestService {


  constructor(private declarationRequestCtrl: DeclarationRequestCtrl) {
  }


  requestDeclaration(currentStepId: string, translateService: TranslateService, notificationService: NotificationService): void {
    this.declarationRequestCtrl.requestDeclarations(Utility.getObjectId(currentStepId)).subscribe(response => {
      let toastMessage = translateService.translate('production.successfully-requested');
      toastMessage += '\t' + response + '\t';
      toastMessage += translateService.translate('production.declarations');
      notificationService.displayNotificationToast(toastMessage, NotificationMessageType.SUCCESS);
    }, (error) => {
      notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error), NotificationMessageType.ERROR);
    });
  }

  getRequestDeclarations(): Observable<DeclarationRequest[]> {
    return this.declarationRequestCtrl.getRequestDeclarations();
  }

  countRequestDeclarations(): Observable<number> {
    return this.declarationRequestCtrl.countRequestDeclarations();
  }

}
