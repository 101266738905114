import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styles: []
})
export class CustomButtonComponent {
  @Input()
  buttonStyle: string;

  public hovered = false;
  public clicked = false;

  public onHover(hovered: boolean): void {
    this.hovered = hovered;
    if (!hovered) {
      this.clicked = false;
    }
  }

  public onClick(clicked: boolean): void {
    this.clicked = clicked;
  }
}
