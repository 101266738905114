import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FiringSchemaStep} from '../../../../core/sdk/model-firingschema';
import {FiringSchemaTemperatureFluctuation} from '../../../../core/sdk/model-enums';
import {ProductionFiringSchemaService} from '../../../services/production-firing-schema.service';
import {Utility} from '../../../utilities/utility';
import {CrossFunctionalService} from '../../../services/cross-functional.service';
import {TranslateService} from '../../../../core/translations/translate.service';
import {SchemaService} from '../../../services/schema.service';

@Component({
  selector: 'app-firing-schema-step',
  templateUrl: './firing-schema-step.component.html',
})
export class FiringSchemaStepComponent implements OnInit {

  public shouldArrowButtonsInSchemaStepBeDisable;
  @Input() step: FiringSchemaStep;
  @Input() closeAllStepEditionEmitter: EventEmitter<void>;

  @Input('disableArrowButtons') set setButtonsDisabledFromCross(value: boolean) {
    this.shouldArrowButtonsInSchemaStepBeDisable = value;
  }

  public stepFormFlag = false;

  constructor(private schemaService: SchemaService,
              private productionFiringSchemaService: ProductionFiringSchemaService,
              private translateService: TranslateService,
              private crossFunctionalService: CrossFunctionalService) {
  }

  public ngOnInit(): void {
    this.stepFormFlag = this.crossFunctionalService.getGatheredData('currentlyEditedStepId') === this.step.id;
    if (this.stepFormFlag) {
      this.crossFunctionalService.flushData('currentlyEditedStepId');
    }
  }

  public toggleEditFlag(): void {
    this.stepFormFlag = !this.stepFormFlag;
  }

  public removeFiringSchemaStep(): void {
    this.productionFiringSchemaService.removeStepFromData(this.step);
    this.reloadProductionFiringSummarySteps();
  }

  public changeIndexHigher(): void {
    this.productionFiringSchemaService.changeIndexHigher(this.step);
  }

  public changeIndexLower(): void {
    this.productionFiringSchemaService.changeIndexLower(this.step);
  }

  public getEnumValue(value: FiringSchemaTemperatureFluctuation): string {
    return this.productionFiringSchemaService.getTranslatedValueOfStepType(value, FiringSchemaTemperatureFluctuation);
  }

  public transformSecondsToDurationObject(timestamp: number): {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  } {
    return this.schemaService.transformSeconds(timestamp);
  }

  public transformDurationObjectToString(duration: {
    hours: number,
    minutes: number,
    seconds: number,
    days: number
  }): string {
    return Utility.transformDurationObjectToUserFriendlyString(duration, this.translateService);
  }

  public reloadProductionFiringSummarySteps(): void {
    this.schemaService.reloadSummaryStepsDuration(this.productionFiringSchemaService.getSchemaSteps());
  }

  public updateStep(step?: FiringSchemaStep): void {
    if (step) {
      Utility.updateObjectInData(step, this.productionFiringSchemaService.schemaSteps);
      this.reloadProductionFiringSummarySteps();
    } else if (this.productionFiringSchemaService.isItNewStep(this.step.id)) {
      this.productionFiringSchemaService.removeStepFromData(this.step);
      this.reloadProductionFiringSummarySteps();
    }
    this.productionFiringSchemaService.removeFromCurrentlyEditedSteps(this.step.id);
    this.toggleEditFlag();
  }

  public startEditionStep(): void {
    this.productionFiringSchemaService.addToCurrentlyEditedSteps(this.step.id);
    this.toggleEditFlag();
  }
}
