import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import {InsertionDirective} from './insertion.directive';
import {PrintModalService} from './print-modal.service';

@Component({
  selector: 'app-print-modal',
  templateUrl: './print-modal.component.html',
})
export class PrintModalComponent implements OnInit, AfterViewInit {

  public childComponentType: Type<any>;
  public componentRef: ComponentRef<any>;
  public modalRef: ComponentRef<this>;
  public inputObjects: Map<string, any> = new Map<string, any>();
  public showCloseButton = false;
  public printModalWindow = true;

  @ViewChild(InsertionDirective) insertionPoint: InsertionDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private changeDetector: ChangeDetectorRef,
              private appRef: ApplicationRef,
              private printModalService: PrintModalService) {
    window.onbeforeprint = () => {
      this.closeModal();
    };
  }

  public ngOnInit(): void {
    if (this.inputObjects.has('showCloseButton')) {
      this.showCloseButton = true;
      this.inputObjects.delete('showCloseButton');
    }
  }

  public ngAfterViewInit(): void {
    this.loadDynamicChildComponent(this.childComponentType, this.inputObjects);
    this.changeDetector.detectChanges();
    if (this.printModalWindow) {
      window.print();
    }
  }

  public loadDynamicChildComponent(dynamicComponentType: Type<any>, dataMap: Map<string, any>): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(dynamicComponentType);
    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentFactory);
    if (dataMap) {
      dataMap.forEach((value, key) => {
        this.componentRef.instance[key] = value;
      });
    }
  }

  public closeModal(): void {
    this.printModalService.detachPrintModalComponentFromBody();
  }

}
