import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OperationSign} from '../../../core/sdk/model-enums';
import {ColumnOperationSign} from '../../interfaces/interfaces';
import {SelectItem} from 'primeng/api';
import {SearchCriteriaValuesRange} from '../../../core/sdk/model-dto';

@Component({
  selector: 'app-custom-operation-sign-filter',
  templateUrl: './custom-operation-sign-filter.component.html',
})
export class CustomOperationSignFilterComponent {

  protected readonly OperationSign = OperationSign;

  @Input() public fieldName: string;
  @Input() public columnOperationSignMap: Map<string, ColumnOperationSign>;
  @Input() public operationSigns: SelectItem[];
  @Output() public rangeSignEmitter: EventEmitter<SearchCriteriaValuesRange> = new EventEmitter<SearchCriteriaValuesRange>();
  @Output() public operationSignEmitter: EventEmitter<string> = new EventEmitter<string>();
}
