<div class="enum-translations">
  <div class="enum-translations__base-form">
    <app-base-form #formFieldsActive
                   [formFields]="formFields"
                   [showSubmitButton]="false"
                   [insertedTemplate]="addFields"></app-base-form>
    <ng-template #addFields>
      <div class="enum-translations__base-form__buttons">
      <button
        class="button button__add-button button__add-button--background-color button__submit-button--color pi pi-plus"
        (click)="addForms(formFieldsActive.form)"
        data-cy="plus-button"></button>
      <button
        class="button button__remove-button button__remove-button--background-color button__submit-button--color pi pi-minus"
        (click)="removeForms(formFieldsActive.form)"
        data-cy="minus-button"></button>
      </div>
    </ng-template>
  </div>

</div>
