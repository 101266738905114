import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SplitProductStoredInstance} from '../../../../core/sdk/model-warehouse';
import {Utility} from '../../../utilities/utility';

@Component({
  selector: 'app-mobile-split-product-instance-tile',
  templateUrl: './mobile-split-product-instance-tile.component.html',
})
export class MobileSplitProductInstanceTileComponent {

  public showDetailsInfoFlag: boolean = false;
  public chosenSplitProduct: SplitProductStoredInstance;
  @Input() public forRepack: boolean;
  @Input() public splitProductStoredInstances: SplitProductStoredInstance[];
  @Output() public submitEmitter: EventEmitter<void> = new EventEmitter<void>();


  public toggleShowDetailsInfoFlag(): void {
    this.showDetailsInfoFlag = !this.showDetailsInfoFlag;
  }

  public displayCreationDateInProperFormat(timestamp: number): string {
    return Utility.transformTimestampToUserFriendlyStringDateTime(timestamp);
  }

  public openDetailsView(splitProduct: SplitProductStoredInstance): void {
    this.toggleShowDetailsInfoFlag();
    this.setChosenSplitProduct(splitProduct);
  }

  private setChosenSplitProduct(splitProduct: SplitProductStoredInstance): void {
    this.chosenSplitProduct = splitProduct;
  }

  public closeView(): void {
    this.setChosenSplitProduct(null);
    this.toggleShowDetailsInfoFlag();
  }

  public reloadDataAfterSubmit(): void {
    this.submitEmitter.emit();
    this.closeView();
  }

}
