import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SearchCriteriaValuesRange} from '../../../core/sdk/model-dto';
import {ConstanceValues} from '../../constance-values/constance-values';
import {NotificationService} from '../../services/notification.service';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {TranslateService} from '../../../core/translations/translate.service';

@Component({
  selector: 'app-range-operation-sign-form',
  templateUrl: './range-operation-sign-form.component.html',
})
export class RangeOperationSignFormComponent implements OnInit {

  public formGroup: FormGroup;
  public currentLocalValue: string;
  private integerValidators = [Validators.required, Validators.min(ConstanceValues.MIN_POSITIVE_NUMBER),
    Validators.max(ConstanceValues.MAX_NUMBER), Validators.pattern(ConstanceValues.NUMBERS_WITH_DIGITS)];
  @Output() public submitEmitter: EventEmitter<SearchCriteriaValuesRange> = new EventEmitter<SearchCriteriaValuesRange>();

  constructor(private formBuilder: FormBuilder,
              private notificationService: NotificationService,
              private  translateService:  TranslateService) {
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      startValue: [null, this.integerValidators],
      endValue: [null, this.integerValidators]
    });
    this.currentLocalValue = this.translateService.getCurrentLanguage()?.locale.split('_')[0];
  }

  public get startValueControl(): AbstractControl {
    return this.formGroup.get('startValue');
  }

  public get endValueControl(): AbstractControl {
    return this.formGroup.get('endValue');
  }

  public onSubmit(): void {
    if (this.startValueControl.value === null || this.endValueControl.value === null) {
      this.notificationService.displayNotificationToast('common.you-need-to-specify-value-for-both-inputs',
        NotificationMessageType.WARNING);
    } else  if (this.startValueControl.value > this.endValueControl.value) {
      this.notificationService.displayNotificationToast('common.from-value-cannot-be-grater-then-end-value',
        NotificationMessageType.WARNING);
    } else {
      const valuesRange: SearchCriteriaValuesRange = {
        startValue: this.startValueControl.value,
        endValue: this.endValueControl.value
      };
      this.submitEmitter.emit(valuesRange);
    }
  }

}
