<div
  data-cy="files-component-div">
  <div class="global-flex global-justify__flex-end">
    <label
      for="fileInput"
      class="global-button global-pointer"
      data-cy="upload-file-button"
      [ngStyle]="{'font-size': isTerminalDevice ? '1.75rem' : ''}"
    >{{'common.upload-file'| translate}}</label>
    <input
      #fileInput
      id="fileInput"
      class="button button__submit-button"
      type="file"
      [(ngModel)]="selectedPathToFile"
      (change)="uploadFile()">
  </div>

  <p-table
    dataKey="id"
    editMode="row"
    data-cy="files-table"
    responsiveLayout="scroll"
    [value]="data"
    [paginator]="true"
    [lazy]="true"
    [rows]="pageable.pageSize"
    [totalRecords]="pageable.count"
    [(first)]="pageable.first"
    (onPage)="paginate($event)">

    <ng-template pTemplate="header">
      <tr>
        <th>{{'common.file-name' | translate}}</th>
        <th>{{'common.action' | translate}}</th>
      </tr>

    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="2">
          {{'common.no-records-available' | translate}}
        </td>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-file>
      <tr [pEditableRow]="file">
        <td>
          {{file.filename}}
        </td>
        <td class="global-text-align__center"
            [ngClass]="isTerminalDevice ? 'terminal-icon-button' : ''">
          <button
            pButton
            type="button"
            icon="pi pi-download"
            class="ui-button-info global-margin__small-right"
            (click)="fileDownload(file)"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-trash"
            class="global-background-color--red"
            (click)="fileDelete(file)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

</div>
