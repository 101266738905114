import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProductStoredInstanceRequestLocationMark} from '../../../../core/sdk/model-warehouse';
import {StorageAreaType} from '../../../../core/sdk/enums-types';

@Component({
  selector: 'app-mobile-location-mark-tile',
  templateUrl: './mobile-location-mark-tile.component.html',
})
export class MobileLocationMarkTileComponent {

  @Input() public locationMark: ProductStoredInstanceRequestLocationMark;
  @Output() public locationMarkEmitter: EventEmitter<ProductStoredInstanceRequestLocationMark> =
    new EventEmitter<ProductStoredInstanceRequestLocationMark>();
  protected readonly StorageAreaType = StorageAreaType;
}
