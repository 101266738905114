import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileInfo} from '../../../../core/sdk/model-files';
import {Utility} from '../../../utilities/utility';
import {NotificationMessageType} from '../../../enums/NotificationMessageType';
import {InvoiceService} from '../../../services/invoice.service';
import {SpinnerService} from '../../../services/spinner.service';
import {NotificationService} from '../../../services/notification.service';
import {FileService} from '../../../services/file.service';
import {Invoice} from '../../../../core/sdk/model-invoices';

@Component({
  selector: 'app-invoice-action',
  templateUrl: './invoice-action.component.html',
})
export class InvoiceActionComponent {

  public readonly englishButtonLabel = 'order-management.generate-english-invoice-document-file';
  public readonly germanButtonLabel = 'order-management.generate-german-invoice-document-file';
  public targetedEndpoint: string;

  @Input() fileInfo: FileInfo;
  @Input() locale: string;
  @Input() currentInvoice: Invoice;

  @Output() generateFileEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private invoiceService: InvoiceService,
              private spinnerService: SpinnerService,
              private notificationService: NotificationService,
              private fileService: FileService) {
  }

  public generateInvoiceDocumentFile(locale: string): void {
    if (this.currentInvoice?.id) {
      this.spinnerService.activateSpinner();
      this.invoiceService.generateInvoiceDocumentFile(this.currentInvoice.id, locale).subscribe((response) => {
        this.currentInvoice.fileInfoList.push(response);
        this.notificationService.displayNotificationToast('order-management.invoice-document-file-generated-successfully',
          NotificationMessageType.SUCCESS);
        this.generateFileEmitter.emit();
        this.spinnerService.deactivateSpinner();
      }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
    }
  }

  private setTargetedEndpointForFile(file: FileInfo): void {
    this.targetedEndpoint = file.id.split('/')[0].split('-file')[0];
  }

  public displayInvoice(file: FileInfo): void {
    this.setTargetedEndpointForFile(file);
    this.fileService.getDocument(Utility.getObjectId(file.id), file.filename, this.targetedEndpoint).subscribe({
      next: response => {
        const fileToDisplay = new Blob([response], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(fileToDisplay);
        window.open(fileURL);
      },
      error: (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public printInvoice(file: FileInfo): void {
    this.setTargetedEndpointForFile(file);
    this.fileService.getDocument(Utility.getObjectId(file.id), file.filename, this.targetedEndpoint).subscribe({
      next: (response) => {
        this.fileService.attachDocumentFromResponseToDomAndPrint(response, 'application/pdf');
      },
      error: (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  public downloadInvoice(file: FileInfo): void {
    this.spinnerService.activateSpinner();
    this.fileService.downloadDocument(file).subscribe(() => {
      this.notificationService.displayNotificationToast('common.download-will-begin-shortly',
        NotificationMessageType.SUCCESS);
      this.spinnerService.deactivateSpinner();
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public displayTimestampToUserFriendlyStringDateTime(timestamp: number): string {
    return Utility.transformTimestampToUserFriendlyStringDateTime(timestamp);
  }

  public getProperButtonLabel(): string {
    return this.locale.includes('en') ? this.englishButtonLabel : this.germanButtonLabel;
  }

  public isExcelFile(): boolean {
    return this.fileInfo.fileExtension.includes('xls');
  }

}
