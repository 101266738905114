import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseFormField} from '../components/base-form/base-form-field';
import {StringInputField} from '../components/base-form/string-input-field';
import {SelectField} from '../components/base-form/select-field';
import {LanguageCtrl} from '../../core/sdk/bighero-controllers';
import {EnumTranslation, Language} from '../../core/sdk/model-translations';
import {TranslateService} from '../../core/translations/translate.service';
import {NotificationService} from '../services/notification.service';
import {FormGroup, Validators} from '@angular/forms';
import {BaseFormComponent} from '../components/base-form/base-form/base-form.component';
import {NotificationMessageType} from '../enums/NotificationMessageType';
import {SpinnerService} from '../services/spinner.service';
import {Utility} from '../utilities/utility';

@Component({
  selector: 'app-enum-translations',
  templateUrl: './enum-translations.component.html'
})
export class EnumTranslationsComponent implements OnInit {

  public formFields: BaseFormField<any>[];

  private currentTranslationIndex = 0;
  private availableLanguages: Language[];
  private masterLanguage: Language;
  private temporaryValues: string[];
  private temporaryLanguages: Language[];

  @ViewChild('formFieldsActive') public formFieldsActive: BaseFormComponent<any>;

  constructor(private languageCtrl: LanguageCtrl,
              private translateService: TranslateService,
              private notificationService: NotificationService,
              private spinnerService: SpinnerService) {
  }

  public ngOnInit(): void {
    this.masterLanguage = this.translateService.getMasterLanguage();
    this.languageCtrl.findAll().subscribe((response: Language[]) => {
      this.availableLanguages = response;
      this.initForms();
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }


  public initForms(): void {
    this.formFields = [
      new SelectField({
        key: 'language' + this.currentTranslationIndex,
        label: 'common.language',
        value: this.masterLanguage,
        readOnly: true,
        translationModulePrefix: 'common.',
        options: {objects: this.availableLanguages, field: 'locale'}
      }),
      new StringInputField({
        key: 'translation' + this.currentTranslationIndex,
        label: 'common.translation',
        placeholder: 'common.type-translation',
        value: '',
        validators: Validators.required
      }),
    ];
  }

  public addForms(formGroup: FormGroup): void {
    this.setCurrentValuesAndLanguages(formGroup);
    this.removeLanguagesArrayFromSelect();
    if (this.availableLanguages.length === 0) {
      this.notificationService.displayNotificationToast('common.no-more-available-languages', NotificationMessageType.ERROR);
    } else {
      this.currentTranslationIndex++;
      const newFormFields = [
        new SelectField({
          key: 'language' + this.currentTranslationIndex,
          label: 'common.language',
          value: this.availableLanguages[0],
          translationModulePrefix: 'common.',
          options: {objects: this.availableLanguages, field: 'locale'}
        }),
        new StringInputField({
          key: 'translation' + this.currentTranslationIndex,
          label: 'common.translation',
          placeholder: 'common.type-translation',
          value: '',
          validators: Validators.required
        }),
      ];

      const concatList = this.formFields.concat(newFormFields);
      concatList.forEach((item, index) => {
        if (index < concatList.length - 2) {
          if (index % 2 === 1) {
            item.value = this.temporaryValues[index - 1];
          } else {
            item.value = this.temporaryLanguages[index];
            item.options.objects = [this.temporaryLanguages[index]];
          }
        }
      });
      this.formFields = Utility.getDeepClone(concatList);
    }
  }

  public removeForms(formGroup: FormGroup): void {
    if (this.formFields.length > 2) {
      this.setCurrentValuesAndLanguages(formGroup);
      const temporaryFormFields = this.formFields;
      const languageToRemove = formGroup.controls['language' + this.currentTranslationIndex].value;
      temporaryFormFields.pop();
      temporaryFormFields.pop();
      temporaryFormFields.forEach((item, index) => {
        if (index % 2 === 0) {
          item.options.objects.push(languageToRemove);
          this.availableLanguages = item.options.objects;
        }
      });
      this.formFields = temporaryFormFields;
      this.currentTranslationIndex--;
    } else {
      this.notificationService.displayNotificationToast('common.master-language-is-required', NotificationMessageType.ERROR);
    }
  }

  private setCurrentValuesAndLanguages(formGroup: FormGroup): void {
    this.temporaryValues = [];
    this.temporaryLanguages = [];

    for (let index = 0; index <= this.currentTranslationIndex; index++) {
      this.temporaryValues.push(formGroup.controls['translation' + index].value);
      this.temporaryLanguages.push(formGroup.controls['language' + index].value);
    }
  }

  private removeLanguagesArrayFromSelect(): void {
    this.temporaryLanguages.forEach(language => this.removeSingleLanguageFromSelect(language));
  }

  private removeSingleLanguageFromSelect(language: Language): void {
    const languageIndex = this.availableLanguages.findIndex(item => (item.id === language.id));
    this.availableLanguages.splice(languageIndex, 1);
  }

  public getIntroducedTranslations(): EnumTranslation[] {
    const languagesArray: EnumTranslation[] = [];
    for (let index = 0; index <= this.currentTranslationIndex; index++) {
      languagesArray.push(this.translateService.getNewEnumTranslation(
        this.formFieldsActive.form.controls['language' + index].value, this.formFieldsActive.form.controls['translation' + index].value));
    }
    return languagesArray;
  }

  public checkIfTranslationsAreValid(translations: EnumTranslation[]): boolean {
    for (const translation of translations) {
      if (translation.translationValue.length < 1) {
        return false;
      }
    }
    return true;
  }

}
