
export enum FiringSchemaTemperatureFluctuation {
INCREASE,
CONSTANT,
DECREASE
}

export enum FunctionalityGroup {
CLIENTS,
OFFERS,
ORDERS,
PURCHASE,
RAW_MATERIALS,
PRODUCTS,
PRODUCTION,
WAREHOUSE,
ADMIN,
MOBILE,
SHIPMENT
}

export enum MoldMaterial {
WOOD,
STEEL,
PLASTIC
}

export enum OperationSign {
EQUAL,
NOT_EQUAL,
MORE,
LESS,
LIKE,
GREATER_THAN_OR_EQUAL,
LESS_THAN_OR_EQUAL,
RANGE_SIGN
}

export enum PersonJournalEntryAction {
CREATE,
UPDATE,
DELETE,
NOT_SPECIFIED
}

export enum PersonRoleEnum {
ADMINISTRATOR,
SUPERVISOR,
GROUP_LEADER,
SALES_MANAGER,
PURCHASE_MANAGER,
IN_HOUSE_MANAGER,
WORKER,
WAREHOUSE_WORKER,
PLANNING_WORKER
}

export enum RoleModificationEventAction {
ACTIVATED,
DEACTIVATED,
CREATED
}

export enum StrengthNormTemperaturePrefix {
COLD,
HOT
}

