<ng-container
  *ngIf="showServiceSchemaStepFormFlag; then serviceSchemaStepForm; else: serviceSchemaStepOverview"></ng-container>
<ng-template
  #serviceSchemaStepOverview>
  <form
    [formGroup]="formGroup"
    (ngSubmit)="updateServiceSchemaStep()">
    <div
      class="production-schema__record"
      [ngClass]="{'production-schema__record--half-round-borders' : !indexColumnApplied}">
      <div
        *ngIf="indexColumnApplied"
        class="production-schema__record__action-column">
        <button
          type="button"
          class="production-schema__record__action-column__arrow-up-button"
          data-cy="change-index-higher-button"
          (click)="changeIndexHigher()">
          <i class="pi pi-arrow-up"></i>
        </button>
        <button
          type="button"
          class="production-schema__record__action-column__arrow-down-button"
          data-cy="change-index-lower-button"
          (click)="changeIndexLower()">
          <i class="pi pi-arrow-down"></i>
        </button>
      </div>

      <div class="production-schema__record-container">
        <div class="production-schema__record__step-column">
          <label
            class="production-schema__record__step-column__box-label"
          >{{ getServiceSchemaStepType(currentServiceSchemaStep.type) | translate }}</label>
          <ng-container
            *ngIf="currentServiceSchemaStep && transformSecondsToDurationObject(currentServiceSchemaStep?.stepDuration?.seconds) as stepDuration">
            <label
              class="global-subtitle global-flex-wrap"
            >{{ 'products.step-duration' |translate }}</label>
            <label>{{ transformDurationObjectToString(stepDuration) }}</label>
          </ng-container>
        </div>

        <div class="production-schema__record__step-column">
          <label>{{ 'products.workspace-group' | translate }}</label>
          <p>{{ currentServiceSchemaStep.workspaceGroup?.name }}</p>
        </div>

        <div
          *ngIf="currentServiceSchemaStep.firingSchema"
          class="production-schema__record__step-column">
          <label>{{ 'products.firing-schema' | translate }}</label>
          <p>{{ currentServiceSchemaStep.firingSchema.name }}</p>
        </div>

        <div
          *ngIf="isStepOfTypePostProcessing()"
          class="production-schema__record__step-column">
          <label>{{ 'products.quantity-of-middle-products-after-post-processing' | translate }}</label>
          <p>{{ currentServiceSchemaStep.quantityOfMiddleProductsAfterPostProcessing }}</p>
        </div>

        <div class="production-schema__record__step-column">
          <label
            for="name">{{ 'products.name' | translate }}</label>
          <ng-container
            *ngIf="!(editServiceSchemaStepFlag); then nameOutput; else nameInput"
            id="name"
          ></ng-container>
          <ng-template #nameOutput>
            <p>{{ currentServiceSchemaStep.name }}</p>
          </ng-template>
          <ng-template #nameInput>
            <input
              type="text"
              class="global-input"
              placeholder="{{'products.name' | translate}}"
              formControlName="name"
              [ngClass]="nameControl.invalid ? 'global-invalid-input-box-shadow' : ''">
          </ng-template>
          <label
            for="description">{{ 'products.description' | translate }}</label>
          <ng-container
            *ngIf="!(editServiceSchemaStepFlag); then currentInput; else descriptionInput"
            id="description"
          ></ng-container>
          <ng-template #currentInput>
          <textarea
            readonly
            class="global-schema-step-description"
          >{{ currentServiceSchemaStep.description.isEmpty() ? ('products.not-defined' | translate) : currentServiceSchemaStep.description }}</textarea>
          </ng-template>
          <ng-template #descriptionInput>
            <textarea
              type="text"
              class="global-input"
              formControlName="description"
              [ngClass]="descriptionControl.invalid ? 'global-invalid-input-box-shadow' : ''">
            </textarea>
          </ng-template>
        </div>
      </div>

      <div class="production-schema__record__action-column">
        <ng-container
          *ngIf="!(editServiceSchemaStepFlag); then editDescription; else submitEditedDescription"></ng-container>
        <ng-template #editDescription>
          <button
            type="button"
            class="production-schema__record__action-column__edit-button"
            (click)="indexColumnApplied? toggleShowServiceSchemaStepFormFlag() : toggleEditServiceSchemaStepFlag()">
            <i class="pi pi-pencil"></i>
          </button>
        </ng-template>
        <ng-template #submitEditedDescription>
          <button
            type="submit"
            class="production-schema__record__action-column__edit-button"
            data-cy="edit-button"
            [disabled]="formGroup.invalid">
            <i class="pi pi-check"></i>
          </button>
        </ng-template>
        <button
          type="button"
          class="production-schema__record__action-column__remove-button"
          data-cy="remove-button"
          (click)="removeServiceSchemaStep()">
          <i class="pi pi-trash"></i>
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #serviceSchemaStepForm>
  <app-service-schema-step-form
    [serviceSchemaStep]="currentServiceSchemaStep"
    (submitEmitter)="updateServiceSchemaStepInData($event)"
    (closeComponentEmitter)="performActionAfterFromClose()">
  </app-service-schema-step-form>
</ng-template>
