import {Injectable} from '@angular/core';
import {
  Inventory, InventoryCountableStack,
  InventoryDeclaration,
  InventoryInProductionProduct, InventoryInStoringLocationDeclaration, InventoryInStoringProduct,
  InventoryStoredProduct
} from '../../../core/sdk/model-inventory';
import {SearchCriteriaForClass} from '../../../core/sdk/model-dto';
import {Observable} from 'rxjs';
import {Utility} from '../../utilities/utility';
import {
  InventoryCountableStackCtrl,
  InventoryCtrl,
  InventoryDeclarationCtrl,
  InventoryInProductionProductCtrl, InventoryInStoringLocationDeclarationCtrl, InventoryInStoringProductCtrl,
  InventoryStoredProductCtrl
} from '../../../core/sdk/controllers-inventory';
import {InventoryReportFile} from '../../../core/sdk/model-files';
import {ProductStoredInstanceRequestLocationMark} from '../../../core/sdk/model-warehouse';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {HttpErrorResponse} from '@angular/common/http';
import {SpinnerService} from '../spinner.service';
import {NotificationService} from '../notification.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  private inventoryData: Inventory[];
  private inventoryInProductionProductData: InventoryInProductionProduct[];

  constructor(private inventoryCtrl: InventoryCtrl,
              private inventoryInProductionProductCtrl: InventoryInProductionProductCtrl,
              private inventoryStoredProductCtrl: InventoryStoredProductCtrl,
              private inventoryDeclarationCtrl: InventoryDeclarationCtrl,
              private inventoryInStoringProductCtrl: InventoryInStoringProductCtrl,
              private inventoryInStoringLocationDeclarationCtrl: InventoryInStoringLocationDeclarationCtrl,
              private inventoryCountableStackCtrl: InventoryCountableStackCtrl) {
  }


  /// INVENTORY

  public setInventoryData(data: Inventory[]): void {
    this.inventoryData = data;
  }

  public getInventoryData(): Inventory[] {
    return this.inventoryData;
  }

  public getInventory(objectId: string): Observable<Inventory> {
    return this.inventoryCtrl.getObject(Utility.getObjectId(objectId));
  }

  public createInventory(countingStartDate: number): Observable<Inventory> {
    return this.inventoryCtrl.createInventory(countingStartDate);
  }

  public countInventories(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.inventoryCtrl.countInventories(searchCriteriaForClass);
  }

  public getInventories(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Inventory[]> {
    return this.inventoryCtrl.getInventories(searchCriteriaForClass, pageNumber, pageSize);
  }

  public generateInventoryReportDocumentFile(inventoryId: string): Observable<InventoryReportFile> {
    return this.inventoryCtrl.generateInventoryReportDocumentFile(Utility.getObjectId(inventoryId));
  }

  public startInventory(inventoryID: string): Observable<Inventory> {
    return this.inventoryCtrl.startInventory(Utility.getObjectId(inventoryID));
  }

  public finishInventory(inventoryID: string): Observable<Inventory> {
    return this.inventoryCtrl.finishInventory(Utility.getObjectId(inventoryID));
  }

  /// INVENTORY_IN_PRODUCTION_PRODUCTS

  public setInventoryInProductionProductsData(data: InventoryInProductionProduct[]): void {
    this.inventoryInProductionProductData = data;
  }

  public getInventoryInProductionProductsData(): InventoryInProductionProduct[] {
    return this.inventoryInProductionProductData;
  }

  public countInventoryInProductionProducts(searchCriteriaForClass: SearchCriteriaForClass, inventoryId: string): Observable<number> {
    return this.inventoryInProductionProductCtrl.countInventoryInProductionProducts(searchCriteriaForClass,
      Utility.getObjectId(inventoryId));
  }

  public getInventoryInProductionProducts(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                                          inventoryId: string): Observable<InventoryInProductionProduct[]> {
    return this.inventoryInProductionProductCtrl.getInventoryInProductionProducts(searchCriteriaForClass,
      Utility.getObjectId(inventoryId), pageNumber, pageSize);
  }


  /// INVENTORY_IN_STORING_PRODUCTS

  public getInventoryInStoringProducts(searchCriteria: SearchCriteriaForClass, inventoryID: string,
                                       pageNumber: number, pageSize: number): Observable<InventoryInStoringProduct[]> {
    return this.inventoryInStoringProductCtrl.getInventoryInStoringProducts(searchCriteria, Utility.getObjectId(inventoryID),
      pageNumber, pageSize);
  }

  public countInventoryInStoringProducts(searchCriteria: SearchCriteriaForClass, inventoryID: string): Observable<number> {
    return this.inventoryInStoringProductCtrl.countInventoryInStoringProducts(searchCriteria, Utility.getObjectId(inventoryID));
  }

  public getAllInventoryInStoringProductsForLatestInventory(): Observable<InventoryInStoringProduct[]> {
    return this.inventoryInStoringProductCtrl.getAllInventoryInStoringProductsForLatestInventory();
  }

  /// INVENTORY_STORED_PRODUCTS

  public countInventoryStoredProducts(searchCriteriaForClass: SearchCriteriaForClass, inventoryId: string): Observable<number> {
    return this.inventoryStoredProductCtrl.countInventoryStoredProducts(searchCriteriaForClass, Utility.getObjectId(inventoryId));
  }


  public getInventoryStoredProducts(searchCriteriaForClass: SearchCriteriaForClass, pageNumber: number, pageSize: number,
                                    inventoryId: string): Observable<InventoryStoredProduct[]> {
    return this.inventoryStoredProductCtrl.getInventoryStoredProducts(searchCriteriaForClass, Utility.getObjectId(inventoryId),
      pageNumber, pageSize);
  }

  public getInventoryStoredProductsByStorageAreaBarcode(barcode: string): Observable<InventoryStoredProduct[]> {
    return this.inventoryStoredProductCtrl.getInventoryStoredProductsByStorageAreaBarcode(barcode);
  }

  public getInventoryStoredProductByBarcode(barcode: string): Observable<InventoryStoredProduct> {
    return this.inventoryStoredProductCtrl.getInventoryStoredProductByBarcode(barcode);
  }


  /// DECLARATIONS

  public countInventoryDeclarationForInventoryCountableStack(searchCriteriaForClass: SearchCriteriaForClass,
                                                             inventoryCountableStackId: string): Observable<number> {
    return this.inventoryDeclarationCtrl.countInventoryDeclarations(searchCriteriaForClass, Utility.getObjectId(inventoryCountableStackId));
  }

  public getInventoryDeclarationForInventoryCountableStack(searchCriteriaForClass: SearchCriteriaForClass,
                                                           pageNumber: number,
                                                           pageSize: number,
                                                           inventoryCountableStackId: string): Observable<InventoryDeclaration[]> {
    return this.inventoryDeclarationCtrl.getInventoryDeclarations(searchCriteriaForClass, Utility.getObjectId(inventoryCountableStackId),
      pageNumber, pageSize);
  }

  public createInventoryDeclaration(inventoryCountableStackId: string, countedValue: number): Observable<InventoryDeclaration> {
    return this.inventoryDeclarationCtrl.createInventoryDeclaration(Utility.getObjectId(inventoryCountableStackId), countedValue);
  }

  /// INVENTORY_IN_STORING_LOCATION_DECLARATION

  public getNewEmptyInventoryInStoringLocationDeclaration(declaredValue: number,
                                                          stockAlreadyAtAssignedLocation: boolean,
                                                          productStoredInstanceRequestLocationMark:
                                                            ProductStoredInstanceRequestLocationMark)
    : InventoryInStoringLocationDeclaration {
    return {
      id: 'inventory-in-storing-location-declaration/' + Utility.getUUID(),
      countableStack: null,
      declaredValue,
      deleted: false,
      entityVersion: 0,
      isDeleted: false,
      productStoredInstanceRequestLocationMark,
      stockAlreadyAtAssignedLocation,
      timestamp: Date.now(),
      version: 0,
      worker: null
    };
  }

  public createInventoryInStoringLocationDeclaration(inventoryInStoringLocationDeclaration: InventoryInStoringLocationDeclaration,
                                                     inventoryCountableStackID: string)
    : Observable<InventoryInStoringLocationDeclaration> {
    return this.inventoryInStoringLocationDeclarationCtrl.createInventoryInStoringLocationDeclaration(
      inventoryInStoringLocationDeclaration, Utility.getObjectId(inventoryCountableStackID));
  }

  // COUNTABLE_STACK
  public stopCountingManually(inventoryCountableStackID: string, spinnerService: SpinnerService, data: any[],
                              notificationService: NotificationService): void {
    spinnerService.activateSpinner();
    this.inventoryCountableStackCtrl.stopCountingManually(Utility.getObjectId(inventoryCountableStackID)).subscribe({
      next: (response: InventoryCountableStack) => {
        Utility.updateObjectInData(response, data);
        notificationService.displayNotificationToast('warehouse.inventory-counting-stopped-successfully',
          NotificationMessageType.SUCCESS);
        spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => notificationService.handleErrorResponseWithMessage(error, spinnerService)
    });
  }

  public stopCountingStepManually(inventoryCountableStackID: string): Observable<InventoryCountableStack> {
    return this.inventoryCountableStackCtrl.stopCountingManually(Utility.getObjectId(inventoryCountableStackID));
  }

}
