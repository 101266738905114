import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorHandlerService} from '../../core/sdk/error-handler.service';
import {Observable, Subject} from 'rxjs';
import {FileInfo} from '../../core/sdk/model-files';
import {Utility} from '../utilities/utility';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private httpService: HttpClient,
              private errorHandlerService: ErrorHandlerService) {
  }

  public fetchDocument(documentId: string, filename: string, targetedEndpoint: string, download: boolean): Observable<Blob> {
    return new Observable<Blob>((observer) => {
      this.httpService.get('/api/files/r/' + targetedEndpoint + '/' + documentId + '', {responseType: 'blob'})
        .subscribe((res: Blob) => {
          if (download) {
            this.downloadBody(res, filename);
          }
          observer.next(res);
        }, error => {
          this.errorHandlerService.handleErrors(error);
          observer.error(error);
        });
    });
  }

  public downloadDocument(file: FileInfo): Observable<Blob> {
    const targetedEndpoint = file.id.split('/')[0].split('-file')[0];
    return this.fetchDocument(Utility.getObjectId(file.id), file.filename, targetedEndpoint, true);
  }

  public getDocument(documentId: string, filename: string, targetedEndpoint: string): Observable<Blob> {
    return this.fetchDocument(documentId, filename, targetedEndpoint, false);

  }

  private downloadBody(result, filename: string): void {
    const dataType = result.type;
    const binaryData = [];
    binaryData.push(result);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {
      type: dataType.toString()
    }));
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  uploadFile(uploadFile: File, filename: string, targetedEndpoint: string, ownerId?: string,
             queryParamsMap?: Map<string, string>): Observable<FileInfo> {
    const file = new Blob([uploadFile]);
    const queryParamsList = this.getQueryParams(ownerId, filename, this.getFileExtension(filename),
      this.getValidTargetEndpoint(targetedEndpoint), queryParamsMap);
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const headers = new HttpHeaders({'Content-type': 'application/octet-stream'});
    const subject = new Subject<FileInfo>();
    this.httpService.post('/api/' + targetedEndpoint + '/w/upload/', file, {params, headers}).subscribe(
      (res: FileInfo) => {
        subject.next(res);
      }
    );
    return subject.asObservable();
  }


  private getValidTargetEndpoint(targetEndpoint: string): string {
    if (targetEndpoint.includes('-')) {
      const endpointPieces: string[] = targetEndpoint.split('-');
      let formattedEndPoint = endpointPieces[0];
      for (let i = 1; i < endpointPieces.length; i++) {
        formattedEndPoint = formattedEndPoint.concat(endpointPieces[i].charAt(0).toUpperCase()).concat(...endpointPieces[i].substring(1));
      }
      return formattedEndPoint;
    }
    return targetEndpoint;
  }

  public deleteFile(fileId: string, targetedEndpoint: string): Observable<void> {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'fileId', value: fileId});
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    const subject = new Subject<void>();
    this.httpService.delete<void>('/api/' + targetedEndpoint + '/w/delete-file', {params})
      .subscribe(res => subject.next(res), error => {
        this.errorHandlerService.handleErrors(error);
        subject.error(error);
      });
    return subject.asObservable();
  }

  public attachDocumentFromResponseToDomAndPrint(response: Blob, blobType: string): void {
    const iframe = document.createElement('iframe');
    const file = new Blob([response], {type: blobType});
    let fileURL = URL.createObjectURL(file);
    fileURL += '#zoom=100';
    iframe.style.display = 'none';
    iframe.src = fileURL;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  private getFileExtension(filename: string): string {
    return filename.split('.')[1];
  }

  private getQueryParams(ownerId: string, fileName: string, fileExtension: string,
                         targetedEndpoint: string, queryParamsMap?: Map<string, string>):
    { name: string, value: string }[] {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: targetedEndpoint + 'Id', value: ownerId ? Utility.getObjectId(ownerId) : ''});
    queryParamsList.push({name: 'fileName', value: fileName});
    queryParamsList.push({name: 'fileExtension', value: fileExtension});

    if (queryParamsMap) {
      for (const [name, value] of queryParamsMap.entries()) {
        queryParamsList.push({name, value});
      }
    }
    return queryParamsList;
  }

}
