<div class="production-device-groups">
  <div class="production-device-groups__header">
    <button
      class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
      type="button" (click)="returnToForm()"
      data-cy="back-button">{{'common.return' | translate}}</button>
  </div>
  <div class="production-device-groups__body">
    <p-table #ptable
             class="main-table-header"
             responsiveLayout="scroll"
             dataKey="id"
             [value]="getTableData()"
             (onPage)="paginate($event)"
             [paginator]="true"
             [lazy]="true"
             [rowHover]="true"
             [(first)]="pageable.pageNumber"
             [rows]="pageable.pageSize"
             [totalRecords]="pageable.count"
             data-cy="device-instances-group-table">

      <ng-template pTemplate="header">
        <tr>
          <th (click)="sortByColumn('name')"
              pSortableColumn="name">{{'device-instance.group-name' | translate}}
            <p-sortIcon field="name"></p-sortIcon>
          </th>

          <th>{{'device-instance.action' | translate}}</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">
            {{'common.no-records-available' | translate}}
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-group>
        <tr>
          <td>
            {{group.name}}
          </td>

          <td>
            <button
              class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
              type="button" (click)="onSubmit(group)"
              data-cy="assign-button">{{'device-instance.assign' | translate}}</button>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>
</div>
