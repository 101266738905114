<ng-template appPlaceholderNotificationModalDirective></ng-template>
<p-toast #pToast id="pToast"
         [ngClass]="[isTerminalView ? 'terminal-p-toast' : '', isMobileView ? 'mobile-p-toast' : '']"></p-toast>
<div class="global-full-height" [ngClass]="isTerminalView ? '' : overlayBodyClass">
  <router-outlet></router-outlet>
  <div
    id="keyboard"
    class="simple-keyboard"
    [hidden]="!showVirtualKeyboard"
    [ngStyle]="{'top': showVirtualKeyboard ? (topPositionForVirtualKeyboard + 'px') : ''}"
  ></div>
</div>
