<form [class]="customContainerStyle" (change)="onChange()" [formGroup]="form">
  <div [class]="customCategoriesStyle">
    <div [class]="getCategoryStyle(formFieldsGroup.key)"
         *ngFor="let formFieldsGroup of formFieldsCategories | keyvalue; let i=index; trackBy:trackByFn">
      <p *ngIf="showCategoryName">{{formFieldsGroup.key | translate}}</p>
      <div [class]="customSingleRecordStyle" *ngFor="let formField of formFieldsGroup.value">
        <div
          [ngClass]="formField.showAssignButton ? 'global-flex global-align__items-center' : ''">
          <app-form-field [formGroupStyle]="formGroupStyle"
                          [formField]="formField"
                          [form]="form">
          </app-form-field>
          <button
            *ngIf="formField.showAssignButton"
            type="button"
            class="common-button"
            (click)="assignButtonEmitter.emit()"
          >{{'common.assign'| translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="insertedTemplate"></ng-container>
  <small *ngIf="!!formAlert"
         class="{{form.dirty ? 'text-dngr' : 'text-transparent'}}">{{formAlert | translate}}</small>
  <app-footer
    [buttonDisabled]="form.invalid"
    [showCancelButton]="showCancelButton"
    [showSubmitButton]="showSubmitButton"
    [buttonSize]="buttonSize"
    [customConfirmButtonStyle]="customConfirmButtonStyle"
    [customDeclineButtonStyle]="customDeclineButtonStyle"
    [customFooterStyle]="customFooterStyle"
    [customButtonOrder]="customButtonOrder"
    [customButtonText]="customButtonText"
    (buttonSubmitPressed)="onSubmit()"
    (buttonCancelPressed)="onCancel()">
    <ng-content></ng-content>
  </app-footer>
</form>
