<div class="full-schema-edition">
  <div class="full-schema-edition__current-schema schemas__list__record global-border-radius">
    <div class="full-schema-edition__current-schema__information global-flex global-flex-direction__row"
         [formGroup]="formGroup">
      <div class="global-flex global-flex-direction__column">
        <div class="global-flex global-width--80vw">
          <div
            class="full-schema-edition__current-schema__information__read-only global-flex global-flex-direction__column global-flex-1">
            <label class="global-subtitle">{{ 'schemas.summary-steps-duration' | translate }}</label>
            <label>{{ transformDurationToString() }}</label>
          </div>

          <div
            class="full-schema-edition__current-schema__information__import global-width--50 global-padding--02-top global-padding--02-bottom global-flex-1">
            <div class="template-schemas__form__general-panel__column">
              <label class="global-subtitle">
                {{ 'schemas.name' | translate }}
              </label>
              <input
                id="schema-name"
                class="global-input"
                formControlName="name"
                [ngClass]="nameControl.invalid ? 'global-invalid-input-box-shadow' : ''">
            </div>
            <div
              *ngIf="shouldDescriptionBeDisplayed"
              class="template-schemas__form__general-panel__column global-flex-1">
              <label class="global-subtitle">{{'production.template-description' | translate}}</label>
              <textarea
                class="global-input global-height--70"
                formControlName="description"
                [ngClass]="descriptionControl.invalid ? 'global-invalid-input-box-shadow' : ''"
              ></textarea>
            </div>
          </div>

          <div class="full-schema-edition__current-schema__information__import global-width--50 global-flex-1">

            <ng-container *ngIf="importTemplateFlag; then importTemplate; else importButton"></ng-container>

            <ng-template
              #importTemplate
              class="global-flex global-flex-direction__column">
              <div class="global-flex global-width--80"></div>
              <label class="global-subtitle">
                {{'production.select-mixing-schema-template-to-import' | translate}}
              </label>
              <p-dropdown
                *ngIf="templatesOptions"
                class="global-input global-width--fit-content"
                data-cy="import-schema-dropdown"
                [options]="templatesOptions"
                [autoDisplayFirst]="true"
                [optionLabel]="'name'"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="selectedTemplate"
              ></p-dropdown>
              <div class="global-flex global-flex-direction__row">
                <button
                  type="button"
                  class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
                  data-cy="import-button"
                  (click)="importSelectedTemplate(selectedTemplate.id)"
                >{{'common.import' | translate}}</button>
                <button
                  type="button"
                  class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content"
                  (click)="toggleImportFlag()"
                >{{'common.cancel' | translate}}
                </button>
              </div>
            </ng-template>

            <ng-template #importButton>
              <button
                type="button"
                class="button button__submit-button button__submit-button--color button__submit-button--background-color full-schema-edition__current-production-schema__information__import__import-button"
                data-cy="import-schema-template-button"
                (click)="toggleImportFlag()"
              >{{'schemas.import-schema-template-for-current-schema' | translate}}</button>
            </ng-template>
          </div>
        </div>

        <div class="full-schema-edition__costs-container">
          <div class="template-schemas__form__general-panel__column">
            <label class="global-subtitle">{{ 'production.direct-labor-rate-cost' | translate }}</label>
            <p-inputNumber
              class="global-input global-no-border-and-padding"
              formControlName="directLaborRateCost"
              [ngClass]="directLaborRateCostControl.invalid ? 'global-invalid-input-box-shadow' : ''"
              mode="decimal"
              [locale]="currentLocalValue"
              [minFractionDigits]="2"
              ></p-inputNumber>
          </div>
          <div class="template-schemas__form__general-panel__column">
            <label class="global-subtitle">{{ 'production.energy-cost' | translate }}</label>
            <p-inputNumber
              class="global-input global-no-border-and-padding"
              formControlName="energyCost"
              [ngClass]="energyCostControl.invalid ? 'global-invalid-input-box-shadow' : ''"
              mode="decimal"
              [locale]="currentLocalValue"
              [minFractionDigits]="2"
              ></p-inputNumber>
          </div>
          <div class="template-schemas__form__general-panel__column">
            <label class="global-subtitle">{{ 'production.fixed-overhead-cost' | translate }}</label>
            <p-inputNumber
              class="global-input global-no-border-and-padding"
              formControlName="fixedOverheadCost"
              [ngClass]="fixedOverheadCostControl.invalid ? 'global-invalid-input-box-shadow' : ''"
              mode="decimal"
              [locale]="currentLocalValue"
              [minFractionDigits]="2"
              ></p-inputNumber>
          </div>
          <div class="template-schemas__form__general-panel__column">
            <label class="global-subtitle">{{ 'production.indirect-labor-rate-cost' | translate }}</label>
            <p-inputNumber
              class="global-input global-no-border-and-padding"
              formControlName="indirectLaborRateCost"
              [ngClass]="indirectLaborRateCostControl.invalid ? 'global-invalid-input-box-shadow' : ''"
              mode="decimal"
              [locale]="currentLocalValue"
              [minFractionDigits]="2"
              ></p-inputNumber>
          </div>
          <div class="template-schemas__form__general-panel__column">
            <label class="global-subtitle">{{ 'production.variable-overhead-cost' | translate }}</label>
            <p-inputNumber
              class="global-input global-no-border-and-padding"
              formControlName="variableOverheadCost"
              [ngClass]="variableOverheadCostControl.invalid ? 'global-invalid-input-box-shadow' : ''"
              mode="decimal"
              [locale]="currentLocalValue"
              [minFractionDigits]="2"
              ></p-inputNumber>
          </div>
        </div>
      </div>
    </div>

    <div
      class="full-schema-edition__current-schema__action global-flex global-flex-direction__column
      global-align__items-center global-justify__center">
      <button
        type="submit"
        class="global-button global-width--max-content"
        [disabled]="formGroup.invalid || someOfMixingSchemaStepsAreOpened()"
        (click)="onSubmit()"
      >{{ 'common.submit' | translate }}
      </button>
      <button
        *ngIf="isUpdateModeApplied || isTemplate(currentMixingSchema.id)"
        type="button"
        class="global-button global-width--max-content"
        (click)="closeView()"
      >{{ 'common.close' | translate }}
      </button>
    </div>
  </div>

  <div class="full-schema-edition__header global-flex global-flex-direction__column global-justify__center">
    <h2 class="base-display__title">
      {{'products.mixing-schema-steps' | translate}}
    </h2>
    <div class="full-schema-edition__header__buttons global-flex global-flex-direction__row global-justify__flex-end">
      <button
        type="button"
        class="global-button"
        (click)="clearAllStep()"
      >{{'production.clear-all-steps' | translate}}</button>
      <button
        type="button"
        class="global-button"
        (click)="createNewStep()"
        [disabled]="mixingSchemaService.someOfStepsIsOpen()"
      >{{'production.add-new-step' | translate}}</button>
    </div>
  </div>

  <div class="full-schema-edition__list">
    <div
      *ngIf="currentMixingSchema"
      class="full-schema-edition__list__record">
      <app-mixing-schema-step-list></app-mixing-schema-step-list>
    </div>
  </div>

</div>
