<div
  class="active-processes-management">
  <div class="active-processes-management__dropdown-container">
    <h2
      class="active-processes-management__main-title">{{'production.filter-for-process-step-connected-with'| translate}}</h2>
    <p-dropdown
      name="filtering-options"
      class="global-input"
      optionLabel="label"
      placeholder="{{'production.select-option' | translate}}"
      [options]="getAvailableFilteringOptions()"
      (onChange)="handleFilteringOptionChange($event)">
      <ng-template
        let-item
        pTemplate="selectedItem">
        {{item.label | translate}}
      </ng-template>
      <ng-template
        let-item
        pTemplate="item">
        {{item.label | translate}}
      </ng-template>
    </p-dropdown>
  </div>
</div>
