<form
  [formGroup]="formGroup"
  (keydown.enter)="onSubmit()">
  <div>
    <label for="startValue"
    >{{ 'common.from' | translate }}</label>
    <p-inputNumber
      class="global-no-border-and-padding"
      id="startValue"
      formControlName="startValue"
      placeholder="{{'common.from' | translate}}"
      mode="decimal"
      [locale]="currentLocalValue"
      [minFractionDigits]="2"
    ></p-inputNumber>
    <span
      [ngClass]="startValueControl.invalid && (startValueControl.dirty || startValueControl.touched) ? 'visibility--true' : 'visibility--false'"
      class="global-error-message"
    >{{ 'common.field-required' | translate }}</span>
  </div>
  <div>
    <label for="endValue"
    >{{ 'common.to' | translate }}</label>
    <input
      id="endValue"
      type="number"
      formControlName="endValue"
      placeholder="{{'common.to' | translate}}">
    <span
      [ngClass]="endValueControl.invalid && (endValueControl.dirty || endValueControl.touched) ? 'visibility--true' : 'visibility--false'"
      class="global-error-message"
    >{{ 'common.field-required' | translate }}</span>
  </div>
</form>
