import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Functionality} from '../../../core/sdk/bighero-model';
import {TranslateService} from '../../../core/translations/translate.service';
import {Router} from '@angular/router';
import {BaseSubNavbarService} from './base-sub-navbar.service';
import {TableRefreshService} from '../../services/table-refresh.service';
import {FunctionalityService} from '../../services/functionality.service';
import {Subject} from 'rxjs';
import {Utility} from '../../utilities/utility';
import {ExportTableDataService} from '../../services/exportTableData.service';
import {PrintModalService} from '../../printable-components/print-modal/print-modal.service';

@Component({
  selector: 'app-base-sub-navbar',
  templateUrl: './base-sub-navbar.component.html',
})
export class BaseSubNavbarComponent implements OnInit, AfterViewInit {

  public filterRow: boolean;
  public functionalities: Functionality[] = [];
  public isTerminalDevice = false;
  public tableInstance: any;
  public printableObjectsInputMap: Map<string, any> = new Map<string, any>();
  public subNavbarSubject = new Subject<{ renderer: Renderer2; buttonsRef: ElementRef }>();
  public isMobileDevice = false;
  public collapsedFunctionalities = false;
  public collapsedFunctionalitiesForTerminalView = true;

  @ViewChild('filteringButtons') filteringButtons: ElementRef;

  constructor(public functionalityService: FunctionalityService,
              private translateService: TranslateService,
              private router: Router,
              private baseSubNavbarService: BaseSubNavbarService,
              private tableRefreshService: TableRefreshService,
              private printModalService: PrintModalService,
              private renderer: Renderer2,
              private exportTableDataService: ExportTableDataService) {
    this.isTerminalDevice = Utility.isTerminalDevice();
    this.isMobileDevice = Utility.isMobileDevice();
  }

  public ngOnInit(): void {
    this.loadFilterFlag();
    this.baseSubNavbarService.setFilteringButtonsRenderingNecessities(this.subNavbarSubject);
    this.getCurrentSubFunctionalities();
  }

  public ngAfterViewInit(): void {
    this.subNavbarSubject.next({renderer: this.renderer, buttonsRef: this.filteringButtons});
  }

  public displaySubFunctionalitiesUiForTerminalDevice(): boolean {
    return this.functionalityService.getCurrentSubFunctionalities().length >= 3 && this.isTerminalDevice;
  }

  public loadFilterFlag(): void {
    this.baseSubNavbarService.showFilteringRow.subscribe(value => {
      this.filterRow = value;
    });
  }

  public getCurrentSubFunctionalityName(): string {
    return this.functionalityService.getActiveFunctionalityName();
  }

  public isActiveFunctionality(functionalityName: string): boolean {
    return this.functionalityService.getActiveFunctionalityName() === functionalityName;
  }

  public resetFilters(): void {
    this.baseSubNavbarService.switchActiveFilters();
    if (!this.filterRow) {
      this.tableRefreshService.onTableRefresh(null, true);
    }
  }

  public getButtonLabel(): string {
    return (this.filterRow) ? 'common.reset-filters' : 'common.filter';
  }

  public getCurrentSubFunctionalities(): Functionality[] {
    this.functionalities = this.functionalityService.getCurrentSubFunctionalities();
    return this.functionalities.sort((a, b) => a.name.localeCompare(b.name));
  }

  public redirectToCurrentSubFunctionality(functionalityName: string): void {
    this.baseSubNavbarService.redirectToCurrentSubFunctionality(functionalityName);
    this.collapsedFunctionalities = true;
    this.collapsedFunctionalitiesForTerminalView = true;
  }

  public shouldNavbarBeHidden(): boolean {
    return this.baseSubNavbarService.getHideNavbarFlag();
  }

  public exportTableData(): void {
    this.baseSubNavbarService.applyExport.emit();
  }

  public isAnyFunctionalityGroupActive(): boolean {
    return this.functionalityService.getActiveFunctionalityGroupName() !== undefined;
  }


}
