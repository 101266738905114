/* eslint-disable max-len */
import {Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {DynamicFunctionalityComponentFactoryService} from './dynamic-functionality-component-factory.service';
import {
  DeclarationRequestBadgeButtonComponent
} from '../../../components/production/worker-declaration-requests/declaration-request-badge-button/declaration-request-badge-button.component';
import {
  PendingSupervisionRequestsBadgeButtonComponent
} from '../../../components/production/pending-supervision-requests/pending-supervision-requests-badge-button/pending-supervision-requests-badge-button.component';

@Component({
  selector: 'app-dynamic-functionality-container-component',
  templateUrl: './dynamic-functionality-container.component.html',
})
export class DynamicFunctionalityContainerComponent implements OnInit {

  @Input() componentNameToAttach = '';

  componentToAttach: any;

  constructor(public vc: ViewContainerRef,
              public dynamicFunctionalityComponentFactoryService: DynamicFunctionalityComponentFactoryService) {

  }

  public ngOnInit(): void {
    this.assignComponentToAttach();
    this.dynamicFunctionalityComponentFactoryService.container = this.vc;
    this.dynamicFunctionalityComponentFactoryService.attachDynamicFunctionalityComponent(this.componentToAttach);
  }


  public assignComponentToAttach(): void {
    switch (true) {
      case this.componentNameToAttach === 'declaration-requests':
        this.componentToAttach = DeclarationRequestBadgeButtonComponent;
        break;
      case this.componentNameToAttach === 'pending-supervision-requests':
        this.componentToAttach = PendingSupervisionRequestsBadgeButtonComponent;
        break;
    }
  }


}
