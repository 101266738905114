<div class="tasks-sub-navbar">

  <ng-template [ngIf]="!isMobileDevice">
    <div class="tasks-sub-navbar__left-side-switch-buttons">
      <div
        *ngIf="getLeftSideStyleButtonsNames() != null"
        class="tasks-sub-navbar__left-buttons-toggle">
        <button
          class="tasks-sub-navbar__left-single-button"
          *ngFor="let leftSideStyleButtonName of getLeftSideStyleButtonsNames()"
          [ngStyle]="{'font-size': isTerminalDevice ? '1.25rem' : ''}"
          [ngClass]="(isSwitchLeftSideButtonActive(leftSideStyleButtonName) ? 'global-background-color--background' : 'global-background-color--bright-blue')"
          [disabled]="isSwitchLeftSideButtonActive(leftSideStyleButtonName)"
          (click)="switchLeftSideButtonName(leftSideStyleButtonName)">{{leftSideStyleButtonName | translate}}</button>
      </div>
    </div>
  </ng-template>

  <div class="tasks-sub-navbar__tabs">
    <div (click)="switchTab(tab)"
         [ngClass]="tab === activeTab ? 'tasks-sub-navbar__single-tab--active-tab' : 'tasks-sub-navbar__single-tab--inactive-tab global-pointer'"
         class="tasks-sub-navbar__single-tab" *ngFor="let tab of tabsNames">
      <label class="global-pointer">{{tab | translate}}</label>
    </div>
  </div>

  <ng-template [ngIf]="!isMobileDevice">
    <div class="tasks-sub-navbar__right-side-buttons-container">
      <div
        *ngIf="rightSideActionButtonNames"
        class="tasks-sub-navbar__buttons">
        <button
          *ngFor="let buttonLabel of getButtonsForActiveTab()"
          (click)="makeButtonAction(buttonLabel)">
          {{buttonLabel | translate}}
        </button>
        <ng-container *ngFor="let customButton of getCustomButtons()">
          <ng-container *ngTemplateOutlet="customButton"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>

</div>
