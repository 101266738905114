<p-table
  class="main-table-header"
  responsiveLayout="scroll"
  dataKey="id"
  [value]="getTableData()"
  (onPage)="paginate($event)"
  [paginator]="!shouldExportBeApplied"
  [lazy]="true"
  [rowHover]="true"
  [(first)]="pageable.pageNumber"
  [rows]="pageable.pageSize"
  [totalRecords]="pageable.count">

  <ng-template pTemplate="header">
    <tr>
      <th (click)="sortByColumn('name')"
          pSortableColumn="name">{{'common.name' | translate}}
        <p-sortIcon class="no-print" field="name"></p-sortIcon>
      </th>
      <th (click)="sortByColumn('shortname')"
          pSortableColumn="shortname">{{'common.shortname' | translate}}
        <p-sortIcon class="no-print" field="status"></p-sortIcon>
      </th>
      <th (click)="sortByColumn('pricePerUnitInEUR', false, 'activeCurrencyRate', 'CurrencyRate')"
          pSortableColumn="activeCurrencyRate">{{'common.price-per-unit-in-eur' | translate}}
        <p-sortIcon class="no-print" field="status"></p-sortIcon>
      </th>
      <th (click)="sortByColumn('timestamp')"
          pSortableColumn="timestamp">{{'common.last-rate-update-date' | translate}}
        <p-sortIcon class="no-print" field="status"></p-sortIcon>
      </th>
      <th>{{'common.action' | translate}}</th>
    </tr>

    <tr
      class="primeng-table__search-row no-print">
      <td>
        <input
          appStringLengthInputValidation
          type="text"
          class="global-input primeng-table__filter-col"
          placeholder="{{ 'common.search-by-currency-name' | translate }}"
          [(ngModel)]="currencyName"
          (keydown.enter)="searchByString($event, 'name')">
      </td>
      <td>
        <input
          appStringLengthInputValidation
          type="text"
          class="global-input primeng-table__filter-col"
          placeholder="{{ 'common.search-by-shortname' | translate }}"
          [(ngModel)]="currencyShortname"
          (keydown.enter)="searchByString($event, 'shortname')">
      </td>
      <td>
        <div class="global-flex">
          <ng-template
            [ngIf]="columnOperationSignMap.get('pricePerUnitInEUR')?.operationSign !== OperationSign.RANGE_SIGN.toString()">
            <p-inputNumber
              class="global-input primeng-table__filter-col global-no-border-and-padding"
              placeholder="{{ 'common.search-by-price-per-unit' | translate }}"
              mode="decimal"
              [locale]="currentLocalValue"
              [minFractionDigits]="2"
              [min]="0"
              [(ngModel)]="currencyPricePerUnitInEur"
              (keydown.enter)="searchByNumber($event, 'pricePerUnitInEUR', 'activeCurrencyRate', 'CurrencyRate')"
            ></p-inputNumber>
          </ng-template>
          <app-custom-operation-sign-filter
            appDoubleInputValidationDirective
            [fieldName]="'pricePerUnitInEUR'"
            [columnOperationSignMap]="columnOperationSignMap"
            [operationSigns]="getAvailableOperationSignsForNumber()"
            (rangeSignEmitter)="searchByNumber($event, 'pricePerUnitInEUR', 'activeCurrencyRate', 'CurrencyRate')"
            (operationSignEmitter)="operationSignValueChange('pricePerUnitInEUR', $event, 'activeCurrencyRate', 'CurrencyRate')"
          ></app-custom-operation-sign-filter>
        </div>
      </td>
      <td>
        <p-calendar
          appendTo="body"
          dateFormat="dd.mm.yy"
          class="primeng-table__calendar-filter-col"
          placeholder="{{ 'common.search-by-last-rate-update-date' | translate }}"
          (onSelect)="!calendarSelectionModeMap.get('timestamp') ?  searchByDate($event, 'timestamp') : null"
          selectionMode="{{ calendarSelectionModeMap.get('timestamp') | calendarSelectionMode}}"
          [ngModel]="calendarSelectionModeMap.get('timestamp') ? rangeDateMap.get('timestamp') : ''"
          (ngModelChange)="assignRangeDateValue($event, 'timestamp')"
          [showButtonBar]="true"
          [readonlyInput]="true"
          [showIcon]="true"
          [clearButtonStyleClass]="'display--false'"
          [showOnFocus]="false"
          [inputStyleClass]="'global-input'"
          [styleClass]="'global-width--100'"
          [(ngModel)]="dateFilterField">
          <ng-template pTemplate="footer">
            <div class="global-flex global-align__items-center global-justify__space-between global-margin-top__1rem">
              <button
                type="button"
                class="global-button global-height--fit-content global-margin--none"
                (click)="toggleRangeSelectionModeApplied('timestamp')">
                <i [ngClass]="!calendarSelectionModeMap.get('timestamp') | calendarButtonClass"></i>
              </button>
              <button
                *ngIf="calendarSelectionModeMap.get('timestamp')"
                type="button"
                class="global-button global-margin--none"
                [disabled]=" rangeDateMap.get('timestamp').isEmpty() || rangeDateMap.get('timestamp').includes(null)"
                (click)="searchByDate(rangeDateMap.get('timestamp'), 'timestamp')"
              >{{ 'common.search' | translate }}
              </button>
            </div>
            <span
              class="global-text-align__center global-margin-top__1rem"
            >{{calendarSelectionModeMap.get('timestamp') | calendarButtonLabel | translate}}</span>
          </ng-template>
        </p-calendar>
      </td>
      <td>
        <button
          class="button button__submit-button button__submit-button--color button__submit-button--background-color button__submit-button--fit-content global-align--self-end"
          data-cy="reset-filters-button"
          (click)="resetFilters()"
        >{{'common.reset-filters' | translate}}
        </button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">
        {{'common.no-records-available' | translate}}
      </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-currency>
    <tr
      [pRowToggler]="currency"
      [pEditableRow]="currency"
      class="global-pointer">
      <td>{{currency.name}}</td>
      <td>{{currency.shortname}}</td>
      <td>{{currency.activeCurrencyRate?.pricePerUnitInEUR | localeNumber}}</td>
      <td>{{convertTimestampToStringData(currency.activeCurrencyRate?.timestamp) | translate}}</td>
      <td
        class="global-text-align__center">
        <button
          pSaveEditableRow
          type="button"
          class="global-background-color--green global-width--fit-content  global-margin__small-right
         button button__submit-button button__submit-button--color"
          data-cy="assign-button"
          (click)="emitAssignedCurrency(currency)"
        >{{'common.assign' | translate}}</button>
      </td>
    </tr>
  </ng-template>

</p-table>
