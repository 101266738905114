import {Injectable} from '@angular/core';
import {ErrorMessage} from '../../core/sdk/model-dto';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  private errorMessage: ErrorMessage;
  private errorCode: number;

  setErrorData(errorMessage: ErrorMessage, errorCode: number): void {
    this.errorMessage = errorMessage;
    this.errorCode = errorCode;
  }

  public get errorData(): { errorCode: number, errorMessage: string } {
    return {errorCode: this.errorCode, errorMessage: this.errorMessage?.message};
  }

}
