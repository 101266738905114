import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstanceValues} from '../../constance-values/constance-values';
import {NormTestAtTemperature} from '../../../core/sdk/model-producttype';
import {ProductQualityNormType} from '../../../core/sdk/enums-types';

@Component({
  selector: 'app-norm-test-at-temperature-form',
  templateUrl: './norm-test-at-temperature-form.component.html',
})
export class NormTestAtTemperatureFormComponent implements OnInit {

  public formGroup: FormGroup;
  public dateOfPerformingTestValue: number = null;
  private integerValidators = [Validators.required, Validators.min(ConstanceValues.MIN_NUMBER),
    Validators.max(ConstanceValues.MAX_NUMBER), Validators.pattern(ConstanceValues.NUMBERS_WITH_DIGITS)];

  protected readonly ProductQualityNormType = ProductQualityNormType;

  @Input() public normTestAtTemperature: NormTestAtTemperature;
  @Input() public normType: ProductQualityNormType;
  @Output() public closeViewEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() public submitFormEmitter: EventEmitter<NormTestAtTemperature> = new EventEmitter<NormTestAtTemperature>();

  constructor(private formBuilder: FormBuilder) {
  }

  public ngOnInit(): void {
    this.initFormGroup();
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      temperature: [this.normTestAtTemperature?.temperature ?? 0, this.integerValidators],
      value: [this.normTestAtTemperature?.value ?? 0, this.integerValidators],
      dateOfPerformingTest: [this.normTestAtTemperature?.dateOfPerformingTest ?
        new Date(this.normTestAtTemperature.dateOfPerformingTest) : null]
    });
  }

  public onSubmit(): void {
    this.normTestAtTemperature = {...this.normTestAtTemperature, ...this.formGroup.value};
    this.normTestAtTemperature.dateOfPerformingTest = this.dateOfPerformingTestValue ? this.dateOfPerformingTestValue :
      this.dateOfPerformingTestControl.value?.getTime();
    this.submitFormEmitter.emit(this.normTestAtTemperature);
  }

  public get temperatureControl(): AbstractControl {
    return this.formGroup.get('temperature');
  }

  public get valueControl(): AbstractControl {
    return this.formGroup.get('value');
  }

  public get dateOfPerformingTestControl(): AbstractControl {
    return this.formGroup.get('dateOfPerformingTest');
  }

  public onCancel(): void {
    this.closeViewEmitter.emit();
  }

  public assignDate(date: Date) {
    this.dateOfPerformingTestValue = date.getTime();
  }
}
