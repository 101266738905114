<ng-template [ngIf]="!splitProductStoredInstances?.isEmpty() && !showDetailsInfoFlag">
  <div
    *ngFor="let splitProductInstance of splitProductStoredInstances"
    class="mobile-split-product-tile"
    (click)="openDetailsView(splitProductInstance)">

    <div class="mobile-split-product-tile__info">
      <span
      >{{ 'mobile.quantity' | translate}}</span>
      <span class="mobile-split-product-tile__info-value"
      >{{ splitProductInstance.quantity }}</span>
    </div>

    <div class="mobile-split-product-tile__info">
      <span
      >{{ 'mobile.product-name'| translate }}</span>
      <span class="mobile-split-product-tile__info-value"
      >{{ splitProductInstance.productType ? splitProductInstance.productType.name : splitProductInstance.productForService.name }}</span>
    </div>

    <div class="mobile-split-product-tile__info">
      <span
      >{{ 'mobile.article-number'| translate }}</span>
      <span class="mobile-split-product-tile__info-value"
      >{{ splitProductInstance.productType ? splitProductInstance.productType.articleNumber : ('mobile.not-applicable' | translate) }}</span>
    </div>

    <div class="mobile-split-product-tile__info">
      <span
      >{{ 'mobile.quality'| translate }}</span>
      <span class="mobile-split-product-tile__info-value"
      >{{ splitProductInstance.productType ? splitProductInstance.productType.quality.name : ('mobile.not-applicable' | translate) }}</span>
    </div>

    <div class="mobile-split-product-tile__info">
      <span
      >{{ 'mobile.creation-date' | translate }}</span>
      <span class="mobile-split-product-tile__info-value"
      >{{ displayCreationDateInProperFormat(splitProductInstance.creationDate) }}</span>
    </div>

    <ng-template [ngIf]="!forRepack && splitProductInstance.destinationStorageArea">
      <div class="mobile-split-product-tile__info">
        <span
        >{{ 'mobile.storage-area' | translate }}</span>
        <span class="mobile-split-product-tile__info-value"
        >{{ splitProductInstance.destinationStorageArea.name }}</span>
      </div>
      <div class="mobile-split-product-tile__info">
        <span
        >{{ 'mobile.section' | translate }}</span>
        <span class="mobile-split-product-tile__info-value"
        >{{ splitProductInstance.destinationStorageArea.section.name }}</span>
      </div>
      <div class="mobile-split-product-tile__info">
        <span
        >{{ 'mobile.warehouse' | translate }}</span>
        <span class="mobile-split-product-tile__info-value"
        >{{ splitProductInstance.destinationStorageArea.section.warehouse.name }}</span>
      </div>
    </ng-template>

  </div>
</ng-template>

<ng-template [ngIf]="showDetailsInfoFlag">
  <app-mobile-split-product-stored-instance-details-tile
    [splitProductStoredInstance]="chosenSplitProduct"
    [forRepack]="forRepack"
    (submitEmitter)="reloadDataAfterSubmit()"
    (closeViewEmitter)="closeView()"
  ></app-mobile-split-product-stored-instance-details-tile>
</ng-template>
