import {ValidatorFn} from '@angular/forms';
import {FormOptions} from '../../interfaces/interfaces';

export class BaseFormField<T> {
  value: T | undefined;
  key: string;
  label: string;
  alert: string;
  placeholder: string;
  toolTip: string;
  colorPicker: string;
  inputStyle: string;
  labelStyle: string;
  order: number;
  controlType: number;
  type: string;
  accept: string;
  preview: string;
  options: { objects: T[], field: string };
  validators: ValidatorFn | ValidatorFn[];
  readOnly: boolean;
  category: string;
  translationModulePrefix: string;
  showAssignButton: boolean;

  constructor(options: FormOptions<T>) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.alert = options.alert || '';
    this.order = options.order === undefined ? 1 : options.order;
    this.placeholder = options.placeholder || '';
    this.toolTip = options.toolTip || '';
    this.colorPicker = options.colorPicker || '#000000';
    this.inputStyle = options.inputStyle || '';
    this.labelStyle = options.labelStyle || '';
    this.controlType = options.controlType || 0;
    this.type = options.type || '';
    this.accept = options.accept || '';
    this.preview = options.preview || '';
    this.options = options.options || {objects: [], field: ''};
    this.validators = options.validators || [];
    this.readOnly = options.readOnly || false;
    this.category = options.category;
    this.translationModulePrefix = options.translationModulePrefix;
    this.showAssignButton = options.showAssignButton;
  }
}
