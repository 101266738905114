import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslationKeyEnum} from '../../enums/translation-key-enum';
import {EnumService} from '../../services/enum.service';
import {ProductQualityNormService} from '../../services/product-quality-norm.service';
import {
  ProductQualityNormProgressionType, ProductQualityNormReversibilityType,
  ProductQualityNormType,
  StrengthNormType,
} from '../../../core/sdk/enums-types';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StrengthNormTemperaturePrefix} from '../../../core/sdk/model-enums';
import {
  ChangeInLengthNorm,
  NormTestAtTemperature,
  ProductQuality,
  ProductQualityNorm,
  StrengthNorm, ThermalExpansionNorm
} from '../../../core/sdk/model-producttype';
import {SpinnerService} from '../../services/spinner.service';
import {NotificationService} from '../../services/notification.service';
import {NotificationMessageType} from '../../enums/NotificationMessageType';
import {HttpErrorResponse} from '@angular/common/http';
import {ConstanceValues} from '../../constance-values/constance-values';
import {Utility} from '../../utilities/utility';

@Component({
  selector: 'app-product-quality-norm-form',
  templateUrl: './product-quality-norm-form.component.html',
})
export class ProductQualityNormFormComponent implements OnInit {
  public normTypeEnumOptions =
    this.enumService.transformEnumOptionsForPrimeDropdown(ProductQualityNormType, TranslationKeyEnum.PRODUCT_QUALITY_CATALOG);
  public temperaturePrefixEnumOptions = this.enumService.transformEnumOptionsForPrimeDropdown(StrengthNormTemperaturePrefix,
    TranslationKeyEnum.PRODUCT_QUALITY_CATALOG);
  public strengthNormTypeEnumOptions = this.enumService.transformEnumOptionsForPrimeDropdown(StrengthNormType,
    TranslationKeyEnum.PRODUCT_QUALITY_CATALOG);
  public progressionTypeEnumOptions =
    this.enumService.transformEnumOptionsForPrimeDropdown(ProductQualityNormProgressionType,
      TranslationKeyEnum.PRODUCT_QUALITY_CATALOG);
  public reversibilityTypeEnumOptions =
    this.enumService.transformEnumOptionsForPrimeDropdown(ProductQualityNormReversibilityType,
      TranslationKeyEnum.PRODUCT_QUALITY_CATALOG);
  public normTypeFieldValue: ProductQualityNormType;
  public formGroup: FormGroup;
  public showNormTestAtTemperatureForm: boolean = false;
  public normTestAtTemperatureList: NormTestAtTemperature[] = [];
  public dateOfPerformingNormCheck: number = null;
  private stringValidators = [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)];
  public normTestAtTemperature: NormTestAtTemperature;
  protected readonly ProductQualityNormType = ProductQualityNormType;

  @Input() public productQuality: ProductQuality;
  @Input() public productQualityNorm: ProductQualityNorm;
  @Input() public updateModeApplied: boolean = false;
  @Output() public closeViewEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private enumService: EnumService,
              protected productQualityNormService: ProductQualityNormService,
              private formBuilder: FormBuilder,
              private spinnerService: SpinnerService,
              private notificationService: NotificationService) {
  }

  public ngOnInit(): void {
    if (this.updateModeApplied) {
      switch (this.productQualityNorm.id.split('/')[0]) {
        case 'strength-norm' : {
          this.loadStrengthNormData();
          break;
        }
        case 'thermal-expansion-norm' : {
          this.loadThermalExpansionNormData();
          break;
        }
        case 'change-in-length-norm' : {
          this.loadChangeInLengthNormData();
          break;
        }
      }
    }
  }

  public initFormValues(): void {
    this.initFormGroup();
    switch (this.normTypeFieldValue) {
      case ProductQualityNormType.STRENGTH_TEST : {
        this.addFormControlsForStrengthNormType();
        this.assignNormTestAtTemperatureData();
        if (!this.updateModeApplied) {
          this.productQualityNorm = this.productQualityNormService.getNewEmptyStrengthNorm();
        }
        break;
      }
      case ProductQualityNormType.THERMAL_EXPANSION : {
        this.addFormControlsForThermalExpansionNormType();
        this.assignNormTestAtTemperatureData();
        if (!this.updateModeApplied) {
          this.productQualityNorm = this.productQualityNormService.getNewEmptyThermalExpansionNorm();
        }
        break;
      }
      case ProductQualityNormType.CHANGE_IN_LENGTH : {
        this.addFormControlsForChangeInLengthNormType();
        this.assignNormTestAtTemperatureData();
        if (!this.updateModeApplied) {
          this.productQualityNorm = this.productQualityNormService.getNewEmptyChangeInLengthNorm();
        }
        break;
      }
    }
  }

  private assignNormTestAtTemperatureData(): void {
    this.normTestAtTemperatureList = this.productQualityNormService.getNormTestAtTemperatureData();
  }

  private loadChangeInLengthNormData(): void {
    this.spinnerService.activateSpinner();
    this.productQualityNormService.getSingleChangeInLengthNorm(this.productQualityNorm.id).subscribe({
      next: (response: ChangeInLengthNorm) => {
        this.setNormData(ProductQualityNormType.CHANGE_IN_LENGTH, response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadStrengthNormData(): void {
    this.spinnerService.activateSpinner();
    this.productQualityNormService.getSingleStrengthNorm(this.productQualityNorm.id).subscribe({
      next: (response: StrengthNorm) => {
        this.setNormData(ProductQualityNormType.STRENGTH_TEST, response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private loadThermalExpansionNormData(): void {
    this.spinnerService.activateSpinner();
    this.productQualityNormService.getSingleThermalExpansionNorm(this.productQualityNorm.id).subscribe({
      next: (response: ThermalExpansionNorm) => {
        this.setNormData(ProductQualityNormType.THERMAL_EXPANSION, response);
        this.spinnerService.deactivateSpinner();
      },
      error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
    });
  }

  private setNormData(normType: number, response: StrengthNorm | ThermalExpansionNorm | ChangeInLengthNorm): void {
    this.normTypeFieldValue = normType;
    this.productQualityNormService.setNormTestAtTemperatureData(response.normTestAtTemperatureList);
    this.initFormValues();
  }

  private initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      dinNorm: [this.productQualityNorm?.dinNorm ?? '', this.stringValidators],
      dateOfPerformingNormCheck: [this.productQualityNorm?.dateOfPerformingNormCheck ?
        new Date(this.productQualityNorm.dateOfPerformingNormCheck) : null],
      additionalDescription: [this.productQualityNorm?.additionalDescription ?? '',
        Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]
    });
  }

  private addFormControlsForStrengthNormType(): void {
    this.formGroup.addControl('temperaturePrefix', this.formBuilder.control(
      (this.productQualityNorm as StrengthNorm)?.temperaturePrefix ?? null, [Validators.required]));
    this.formGroup.addControl('type', this.formBuilder.control(
      (this.productQualityNorm as StrengthNorm)?.type ?? null,
      [Validators.required]));
  }

  private addFormControlsForThermalExpansionNormType(): void {
    this.formGroup.addControl('progressionType', this.formBuilder.control(
      (this.productQualityNorm as ThermalExpansionNorm)?.progressionType ?? null, [Validators.required]));
    this.formGroup.addControl('reversibilityType', this.formBuilder.control(
      (this.productQualityNorm as ThermalExpansionNorm)?.reversibilityType ?? null, [Validators.required]));
  }

  private addFormControlsForChangeInLengthNormType(): void {
    this.formGroup.addControl('progressionType', this.formBuilder.control(
      (this.productQualityNorm as ChangeInLengthNorm)?.progressionType ?? null, [Validators.required]));
    this.formGroup.addControl('reversibilityType', this.formBuilder.control(
      (this.productQualityNorm as ChangeInLengthNorm)?.reversibilityType ?? null, [Validators.required]));
  }

  public resetNormType(): void {
    this.normTypeFieldValue = null;
    this.productQualityNormService.clearNormTestAtTemperatureData();
    this.formGroup.reset();
  }

  public onSubmit(): void {
    switch (this.normTypeFieldValue) {
      case ProductQualityNormType.STRENGTH_TEST : {
        !this.updateModeApplied ? this.createStrengthNormType(this.getNormTypeWithAssignedValues() as StrengthNorm) :
          this.updateStrengthNormType(this.getNormTypeWithAssignedValues() as StrengthNorm);
        break;
      }
      case ProductQualityNormType.THERMAL_EXPANSION : {
        !this.updateModeApplied ? this.createThermalExpansionNorm(this.getNormTypeWithAssignedValues() as ThermalExpansionNorm) :
          this.updateThermalExpansionNorm(this.getNormTypeWithAssignedValues() as ThermalExpansionNorm);
        break;
      }
      case ProductQualityNormType.CHANGE_IN_LENGTH : {
        !this.updateModeApplied ? this.createChangeInLengthNorm(this.getNormTypeWithAssignedValues() as ChangeInLengthNorm) :
          this.updateChangeInLengthNorm(this.getNormTypeWithAssignedValues() as ChangeInLengthNorm);
      }
    }
  }

  private createStrengthNormType(strengthNorm: StrengthNorm): void {
    if (strengthNorm.normTestAtTemperatureList.isEmpty()) {
      this.notificationService.displayNotificationToast(
        'product-quality-catalog.you-must-add-at-least-one-norm-test-at-temperature', NotificationMessageType.WARNING);
    } else {
      this.spinnerService.activateSpinner();
      this.productQualityNormService.createStrengthNorm(strengthNorm, this.productQuality.id)
        .subscribe({
          next: () => {
            this.handleSuccessResponseForCreatingNorm();
            this.spinnerService.deactivateSpinner();
          },
          error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
        });
    }
  }

  private createThermalExpansionNorm(thermalExpansionNorm: ThermalExpansionNorm): void {
    if (thermalExpansionNorm.normTestAtTemperatureList.isEmpty()) {
      this.notificationService.displayNotificationToast(
        'product-quality-catalog.you-must-add-at-least-one-norm-test-at-temperature', NotificationMessageType.WARNING);
    } else {
      this.spinnerService.activateSpinner();
      this.productQualityNormService.createThermalExpansionNorm(thermalExpansionNorm, this.productQuality.id)
        .subscribe({
          next: () => {
            this.handleSuccessResponseForCreatingNorm();
            this.spinnerService.deactivateSpinner();
          },
          error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
        });
    }
  }

  private createChangeInLengthNorm(changeInLengthNorm: ChangeInLengthNorm): void {
    if (changeInLengthNorm.normTestAtTemperatureList.isEmpty()) {
      this.notificationService.displayNotificationToast(
        'product-quality-catalog.you-must-add-at-least-one-norm-test-at-temperature', NotificationMessageType.WARNING);
    } else {
      this.spinnerService.activateSpinner();
      this.productQualityNormService.createChangeInLengthNorm(changeInLengthNorm, this.productQuality.id)
        .subscribe({
          next: () => {
            this.handleSuccessResponseForCreatingNorm();
            this.spinnerService.deactivateSpinner();
          },
          error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
        });
    }
  }

  private handleSuccessResponseForCreatingNorm(): void {
    this.notificationService.displayNotificationToast(
      'product-quality-catalog.norm-created-successfully', NotificationMessageType.SUCCESS);
    this.resetNormType();
    this.closeViewEmitter.emit();
  }

  private updateStrengthNormType(strengthNorm: StrengthNorm): void {
    if (strengthNorm.normTestAtTemperatureList.isEmpty()) {
      this.notificationService.displayNotificationToast(
        'product-quality-catalog.you-must-add-at-least-one-norm-test-at-temperature', NotificationMessageType.WARNING);
    } else {
      this.spinnerService.activateSpinner();
      this.productQualityNormService.updateStrengthNorm(strengthNorm).subscribe({
        next: (response: StrengthNorm) => {
          this.handleSuccessResponseForUpdatingNorm(response);
          this.spinnerService.deactivateSpinner();
        },
        error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
    }
  }

  private updateThermalExpansionNorm(thermalExpansionNorm: ThermalExpansionNorm): void {
    if (thermalExpansionNorm.normTestAtTemperatureList.isEmpty()) {
      this.notificationService.displayNotificationToast(
        'product-quality-catalog.you-must-add-at-least-one-norm-test-at-temperature', NotificationMessageType.WARNING);
    } else {
      this.spinnerService.activateSpinner();
      this.productQualityNormService.updateThermalExpansionNorm(thermalExpansionNorm).subscribe({
        next: (response: ThermalExpansionNorm) => {
          this.handleSuccessResponseForUpdatingNorm(response);
          this.spinnerService.deactivateSpinner();
        },
        error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
    }
  }

  public updateChangeInLengthNorm(changeInLengthNorm: ChangeInLengthNorm): void {
    if (changeInLengthNorm.normTestAtTemperatureList.isEmpty()) {
      this.notificationService.displayNotificationToast(
        'product-quality-catalog.you-must-add-at-least-one-norm-test-at-temperature', NotificationMessageType.WARNING);
    } else {
      this.spinnerService.activateSpinner();
      this.productQualityNormService.updateChangeInLengthNorm(changeInLengthNorm).subscribe({
        next: (response: ChangeInLengthNorm) => {
          this.handleSuccessResponseForUpdatingNorm(response);
          this.spinnerService.deactivateSpinner();
        },
        error: (error: HttpErrorResponse) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
      });
    }
  }

  private handleSuccessResponseForUpdatingNorm(response: StrengthNorm | ThermalExpansionNorm): void {
    Utility.updateObjectInData(response, this.productQualityNormService.getProductQualityNormData());
    this.notificationService.displayNotificationToast(
      'product-quality-catalog.norm-updated-successfully', NotificationMessageType.SUCCESS);
    this.resetNormType();
    this.closeViewEmitter.emit();
  }

  private getNormTypeWithAssignedValues(): StrengthNorm | ThermalExpansionNorm {
    switch (this.normTypeFieldValue) {
      case ProductQualityNormType.STRENGTH_TEST : {
        const strengthNorm = (this.productQualityNorm as StrengthNorm);
        return this.normWithAssignedFormValues(strengthNorm, ProductQualityNormType.STRENGTH_TEST) as StrengthNorm;
      }
      case ProductQualityNormType.THERMAL_EXPANSION : {
        const thermalExpansionNorm = (this.productQualityNorm as ThermalExpansionNorm);
        return this.normWithAssignedFormValues(thermalExpansionNorm, ProductQualityNormType.THERMAL_EXPANSION) as StrengthNorm;
      }
      case ProductQualityNormType.CHANGE_IN_LENGTH : {
        const changeInLengthNorm = (this.productQualityNorm as ChangeInLengthNorm);
        return this.normWithAssignedFormValues(changeInLengthNorm, ProductQualityNormType.CHANGE_IN_LENGTH) as ChangeInLengthNorm;
      }
    }
  }

  private normWithAssignedFormValues(norm: StrengthNorm | ThermalExpansionNorm | ChangeInLengthNorm,
                                     typeOfNorm: number): StrengthNorm | ThermalExpansionNorm {
    norm = {...norm, ...this.formGroup.value};
    norm.normType = typeOfNorm;
    norm.dateOfPerformingNormCheck = this.dateOfPerformingNormCheck ? this.dateOfPerformingNormCheck :
      this.dateOfPerformingNormCheckControl.value?.getTime();
    norm.normTestAtTemperatureList = this.productQualityNormService.getNormTestAtTemperatureData();
    return norm;
  }

  public toggleShowNormTestAtTemperatureForm(): void {
    this.showNormTestAtTemperatureForm = !this.showNormTestAtTemperatureForm;
  }

  public startEditingNormTestAtTemperature(normTestAtTemperature: NormTestAtTemperature): void {
    this.normTestAtTemperature = normTestAtTemperature;
    this.toggleShowNormTestAtTemperatureForm();
  }

  public startCreatingNormTestAtTemperature(): void {
    this.normTestAtTemperature = this.productQualityNormService.getNewEmptyNormTestAtTemperature();
    this.toggleShowNormTestAtTemperatureForm();
  }

  public addNormTestAtTemperatureToList(normTestAtTemperature: NormTestAtTemperature): void {
    if (this.productQualityNormService.getNormTestAtTemperatureData().find(item => item.id === normTestAtTemperature.id) != null) {
      Utility.updateObjectInData(normTestAtTemperature, this.normTestAtTemperatureList);
    } else {
      this.productQualityNormService.addNormTestAtTemperatureToData(normTestAtTemperature);
    }
    this.toggleShowNormTestAtTemperatureForm();
  }

  public get dinNormControl(): AbstractControl {
    return this.formGroup.get('dinNorm');
  }

  public get temperaturePrefixControl(): AbstractControl {
    return this.formGroup.get('temperaturePrefix');
  }

  public get dateOfPerformingNormCheckControl(): AbstractControl {
    return this.formGroup.get('dateOfPerformingNormCheck');
  }

  public get typeControl(): AbstractControl {
    return this.formGroup.get('type');
  }

  public get reversibilityTypeControl(): AbstractControl {
    return this.formGroup.get('reversibilityType');
  }

  public get progressionTypeControl(): AbstractControl {
    return this.formGroup.get('progressionType');
  }

  public closeView(): void {
    this.productQualityNormService.clearNormTestAtTemperatureData();
    this.closeViewEmitter.emit();
  }

  public assignDate(date: Date): void {
    this.dateOfPerformingNormCheck = date.getTime();
  }

}
