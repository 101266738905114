import {Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {HttpClient} from '@angular/common/http';
import {SecurityCtrl} from '../sdk/bighero-controllers';
import {NewPassword, PasswordChange} from '../sdk/model-dto';
import {Observable} from 'rxjs';

@Injectable()
export class NewPasswordService {
  constructor(private httpService: HttpClient,
              private authService: AuthService,
              private securityCtrl: SecurityCtrl) {
  }

  public setNewPassword(token: string, password: string): Observable<void> {
    const newPassword: NewPassword = {token, password};
    return this.securityCtrl.newPassword(newPassword);
  }

  public changeCurrentPassword(oldPassword: string, newPassword: string): Observable<void> {
    const email = this.authService.getCurrentUser().email;
    const passwordChange: PasswordChange = {email, oldPassword, newPassword};
    return this.securityCtrl.changePassword(passwordChange);
  }
}
